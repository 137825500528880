import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { tap, map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class StaffService {

  constructor(private http: HttpClient) { }

  createStaff(toUpdateData: any): Observable<any> {
    const href = `${environment['creteStaff']}`;
    return this.http.post<any>(href, toUpdateData).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  createCityAdmin(toUpdateData: any): Observable<any> {
    const href = `${environment['createcityAdmin']}`;
    return this.http.post<any>(href, toUpdateData).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  createOrdinence(toUpdateData: any): Observable<any> {
    const href = `${environment['addOrdinace']}`;
    return this.http.post<any>(href, toUpdateData).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  updateAdmin(toUpdateData: any, staffId): Observable<any> {
    const href = `${environment['updateAdmin']}/${staffId}`;
    return this.http.post<any>(href, toUpdateData).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  getSigneOrdinace(ordinanceId): Observable<any> {
    const href = `${environment['getSingleOrdinance']}/${ordinanceId}`;
    return this.http.get<any>(href).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  updateOrdinance(toUpdateData: any, ordinanceId): Observable<any> {
    const href = `${environment['updateOrdince']}/${ordinanceId}`;
    return this.http.post<any>(href, toUpdateData).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  deleteOrdinance(ordinanceId): Observable<any> {
    const href = `${environment['deleteOrdinance']}/${ordinanceId}`;
    return this.http.post<any>(href, '').pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  getCitySingleAdmin(adminId): Observable<any> {
    const href = `${environment['getsingleAdmin']}/${adminId}`;
    return this.http.get<any>(href).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  setPassword(toUpdateData: any): Observable<any> {
    const href = `${environment['setPassword']}`;
    return this.http.post<any>(href, toUpdateData).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  createPasswordForAdmin(toUpdateData: any): Observable<any> {
    const href = `${environment['createPass']}`;
    return this.http.post<any>(href, toUpdateData).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  setStripId(toUpdateData: any): Observable<any> {
    const href = `${environment['setStripId']}`;
    return this.http.post<any>(href, toUpdateData).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }


  inviteSendForCityAndStaff(toUpdateData: any): Observable<any> {
    const href = `${environment['sendInviteForCity']}`;
    return this.http.post<any>(href, toUpdateData).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  deleteAdmin(adminId: number): Observable<any> {
    const href = `${environment['deleteAdmin']}/${adminId}`;
    return this.http.post<any>(href, '').pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  updateStaff(toUpdateData: any, id: number): Observable<any> {
    const href = `${environment['updateStaff']}/${id}`;
    return this.http.post<any>(href, toUpdateData).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  deleteStaff(id): Observable<any> {
    const href = `${environment['deleteStaff']}/${id}`;
    return this.http.post<any>(href, '').pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
            return data;
          }

        }
      )
    );
  }



  // invitationSend(toUpdateData: any,id:number): Observable<any> {
  //   const href = `${environment['sendInvitationForStaff']}/${id}`;
  //   return this.http.post<any>(href, toUpdateData).pipe(map((data: any) => {
  //     if (data.status === 'success') {
  //       return data.response;
  //     }
  //   }));
  // }



  getStaffList(type: any): Observable<any> {
    const href = `${environment['allStaff']}`;
    return this.http.get<any>(href, { params: type }).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
            return data;
          }

        }
      )
    );
  }

  getCityAdmin(type: any): Observable<any> {
    const href = `${environment['cityAdmin']}`;
    return this.http.get<any>(href, { params: type }).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
            return data;
          }

        }
      )
    );
  }


  getStaffById(id): Observable<any> {
    const href = `${environment['getSigleStaff']}/${id}`;
    return this.http.get<any>(href).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
            return data;
          }

        }
      )
    );
  }

  getDepartment(): Observable<any> {
    const href = `${environment['departments']}`;
    return this.http.get<any>(href).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
            return data;
          }
        }
      )
    )
  }

  getRoll(query: any): Observable<any> {
    console.log(query)
    const href = `${environment['allRole']}`;
    return this.http.get<any>(href, { params: query }).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
            return data;
          }
        }
      )
    )
  }

  getBlockDate(): Observable<any> {
    const href = `${environment['getBlockDate']}`;
    return this.http.get<any>(href).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
            return data;
          }
        }
      )
    )
  }

  showIndexBlockCalendar(page): Observable<any> {
    const href = `${environment['indexBlockCalendar']}`;
    return this.http.get<any>(href, { params: page }).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
            return data;
          }
        }
      )
    )
  }

  addBlockDate(data): Observable<any> {
    const href = `${environment['addBlockDate']}`;
    return this.http.post<any>(href, data).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
            return data;
          }

        }
      )
    );
  }

  voidSubmition(data): Observable<any> {
    const href = `${environment['voidSubmition']}`;
    return this.http.post<any>(href, data).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
            return data;
          }

        }
      )
    );
  }

  addINspectiondate(data): Observable<any> {
    const href = `${environment['addInspectionDate']}`;
    return this.http.post<any>(href, data).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
            return data;
          }

        }
      )
    );
  }


  getInspectionDate(page): Observable<any> {
    const href = `${environment['getInspectionDate']}`;
    return this.http.get<any>(href, { params: page }).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
            return data;
          }

        }
      )
    );
  }

  deleteBlockDate(data): Observable<any> {
    const href = `${environment['deleteBlockDate']}`;
    return this.http.post<any>(href, data).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
            return data;
          }

        }
      )
    );
  }

  deleteInspecion(data): Observable<any> {
    const href = `${environment['deleteInspection']}`;
    return this.http.post<any>(href, data).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
            return data;
          }

        }
      )
    );
  }
}



import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StaffService } from 'src/app/core/http';

@Component({
  selector: 'app-show-ward',
  templateUrl: './show-ward.component.html',
  styleUrls: ['./show-ward.component.css']
})
export class ShowWardComponent implements OnInit {
  public blockDate = []
  public isHide = false
  @ViewChild('deletePopup', { static: false }) deletePopup: ElementRef;
  @ViewChild('deleteInsPopup', { static: false }) deleteInsPopup: ElementRef;
  public offset: any = 10;
  public type: any = 1;
  public currentPagination: number = Number(1);
  public pagination: {
    pages: any,
    total: number,
  } = {
      pages: 0,
      total: 0,
    };
  public isWard = true;
  public isInspectionDate = false
  public tab:any;
  constructor(
    // private formBuilder: FormBuilder,
    private staffService: StaffService,
    private route: ActivatedRoute,
  ) { }
  // deletePopup
  ngOnInit() {
    this.getDate();
    this.getShowDate()
    this.tab =  localStorage.getItem('wardTab')
    console.log("Data is ",this.tab)
    if(this.tab){
      this.showwardAndInspectionDate(this.tab);
    }

  }
  public page = 1
  getDate() {
    this.staffService.showIndexBlockCalendar({ page: this.page }).subscribe(data => {
      this.blockDate = data['response']
      this.pagination.total = data.total;
      this.pagination.pages = Array(data.pages).fill(0).map((x, i) => i + 1);
      this.currentPagination = Number(data.page);
      console.log(this.blockDate, '+++++++++++++++++')
    })
  }

  public isnpectionDates = []
  public datePage = 1
  getShowDate() {
    this.staffService.getInspectionDate({ page: this.datePage }).subscribe(data => {
      this.isnpectionDates = data['response']
      this.pagination.total = data.total;
      this.pagination.pages = Array(data.pages).fill(0).map((x, i) => i + 1);
      this.currentPagination = Number(data.page);
      console.log(this.isnpectionDates, '+++++++++++++++++')
    })
  }

  paginateDate(page) {
    this.datePage = page
    this.getShowDate()
  }
  paginate(value) {
    
    this.page = value;
    this.getDate()
  }

  public blockDateId: any
  public fromdate: any
  getBlockDateId(date: any) {
    
    //this.blockDateId = id
    this.fromdate = date
  }

  deleteBlockDate() {
    this.staffService.deleteBlockDate({ from_date: this.fromdate }).subscribe(data => {
      this.blockDate = data['response']
      this.getDate()
      this.deletePopup.nativeElement.click()
      this.isHide = true
    })
  }
  showwardAndInspectionDate(value: any) {
    localStorage.setItem('wardTab',value)
    if (value == 1) {
      this.isWard = true;
      this.isInspectionDate = false
    } else {
      this.isWard = false;
      this.isInspectionDate = true
    }
    this.defaultPagination()
  }

  defaultPagination() {
    this.pagination.total = 0
    this.pagination.pages = 0;
    this.currentPagination = 1
  }

  deleteIns(){
    this.staffService.deleteInspecion({ id: this.fromdate }).subscribe(data => {
      this.blockDate = data['response']
      this.getShowDate()
      this.deleteInsPopup.nativeElement.click()
      this.isHide = true
    })
  }
  
}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { codeEnforcementSettingConfig } from 'src/app/configs';
import { AdminAuthenticationService } from 'src/app/core/authentication/admin-authentication.service';
import { DashboardService } from 'src/app/core/http';
import { CeComplaintService } from 'src/app/core/http/admin/ce-complaint.service';
import { compliant_details } from 'src/app/core/models/admin/code-enforcement/complantDetails.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-work-order-details',
  templateUrl: './work-order-details.component.html',
  styleUrls: ['./work-order-details.component.css']
})
export class WorkOrderDetailsComponent implements OnInit {

  public oneAtATime: boolean = true;
  @ViewChild('datePopUp', { static: false }) datePopUp: ElementRef;
  @ViewChild('assignInspector', { static: false }) assignInspector: ElementRef;
  @ViewChild('addWorkOrder', { static: false }) addWorkOrder: ElementRef;

  public complaintDetails: compliant_details = {
    id: null,
    address: null,
    complaint_type: null,
    user_phone: null,
    createdAt: null,
    also_know_as: null,
    inspection: [],
    service_Id: null,
    city_id: null,
    source:null,
    assign_inspector:null,
    google_address:null,
    location_type:null,
    complain_type:null,
    addresses:null,
    work_order_request:null,
    complaint_attachment:[]
  }
  public newArryj = [];
  public isAssignInspector = false
  public isAddedCourtTicket = false
  public courtTicketForm: FormGroup
  public workOrderCon: FormGroup
  public isAddWordOrder = false
  public inspectorList = [];
  public allSatfflist = [];
  public workOrderId: number;
  public setting: any
  public assignInspectorForm: FormGroup
  public inspectionForm: FormGroup
  public mytime: Date
  public env: any
  public isTrue = true;
  public complaintId: any
  public ins: any
  public imageName: any;
  public attachment: any
  public image = []
  public i = 1;
  public inspDeleteId:any;
  public isTrueDelete = false;
  public formData = new FormData()
  constructor(
    private _CEService: CeComplaintService,
    private router: Router,
    private route: ActivatedRoute,
    private _FB: FormBuilder,
    private _TS: ToastrService,
    private dashboardSrvice: DashboardService,
    private adminAuthService: AdminAuthenticationService,

  ) { this.setting = codeEnforcementSettingConfig }

  ngOnInit() {
    this.env = environment;

    this.onInIt()
    this.getLocalStorage()
    this.mytime = new Date();

    this.route.queryParams.subscribe(data => {
      this.workOrderId = data['id'];
      this.complaintId = data['cmpId']
    })
    if (this.workOrderId && this.complaintId) {
      // this.comlaintAllDetails()
      this.getWorkOrderDetails()
    }
    // this.getInspector();
  }

  onInIt() {
    this.inspectorControls();
    this.courtTicketCon();
    this.workOrderCont()
    this.inspectionControls();
    this.allSatff();
  }
  insDetails(id){
    this.isId = id
    this.complaintDetails.inspection.forEach((insp: any) => {
      if (id == insp.id) {
        this.ins = insp
        this.ins.active = insp.id
        console.log("inspection_type===>", this.ins.inspection_type)
        this.getWorkOrderDetails();
        this.isTrue = false;
      }
    })
    console.log("active data is",   this.ins)
  }
  inspectionControls() {
    this.inspectionForm = this._FB.group({
      inspection_date: ['', Validators.required],
      inspection_time: ['', Validators.required],
      contact_number: ['', Validators.required],
      follow_up_date: ['', Validators.required],
      location_occupied: ['', Validators.required],
      person_spoken_to: ['', Validators.required],
      number_of_days_to_abate: ['', Validators.required],
      vacate_premise_by: ['', Validators.required],
      inspection_findings: ['', Validators.required],
      internal_notes: ['', Validators.required],

    })
  }

  get inspectionCon() { return this.inspectionForm.controls }
  workOrderCont() {
    this.workOrderCon = this._FB.group({
      depratement: [null],
      due_date: [null],
      assign_to: [null],
      type_of_work: [null],
      phone_number: [null],
      description_of_work: [null],
      contact_person: [null],
    })
  }

  get workOrder() { return this.workOrderCon.controls }
  allSatff() {
    this._CEService.getSatff().subscribe(data => {
      this.allSatfflist = data.response;

    })
  }
  source_name:any;
  name:any;
  sourceName(source_id){
   this.source_name = this.allSatfflist.filter(item=>item.id == source_id)
   for (let i = 0; i < this.source_name.length; i++) {
    this.name = this.source_name[i].name;
   }
   return true
  }
  addWork() {
    
    if (this.workOrderCon.invalid) {
      this.isAddWordOrder = true
      return false
    }

    this.workOrderCon.value.inspection_id = this.ins.id
    this.workOrderCon.value.application_id = this.ins.application_id

    this._CEService.addWorkOrder(this.workOrderCon.value).subscribe(data => {
      this._TS.success('Work order added');
      this.addWorkOrder.nativeElement.click()
    })

  }
  courtTicketCon() {
    this.courtTicketForm = this._FB.group({
      date: ['', Validators.required],
      notes: [],

    })
  }
  inspectorControls() {
    this.assignInspectorForm = this._FB.group({
      inspector: ['', Validators.required],
      comment: [],
    })
  }

  get insCon() {
    return this.assignInspectorForm.controls
  }
  get courtCon() {
    return this.courtTicketForm.controls
  }

  comlaintAllDetails() {
    
    this._CEService.getAllComplaintDetails(this.workOrderId).subscribe(data => {
      this.complaintDetails = data.response;
      this.complaintDetails.inspection.forEach((insp: any, i) => {
        if (i == 0) {
          this.ins = insp
        }
      })
      console.log(this.complaintDetails)
    })
  }

  public workOrderDetails: any
  public inspectorName: any
 
  public isId:any;
  inspectorId:any;
  inspection =[];
  insDetail:any
  getWorkOrderDetails() {
    this.newArryj = [];
    const data = {
      camplaint_id: this.complaintId,
      id: this.workOrderId
    }
    this._CEService.getAllWorkOrderDetails(data).subscribe(wordOrder => {
      this.workOrderDetails = wordOrder['response']['work_order_request'];
      this.inspection = wordOrder.response.work_order_request['work-order-inspections']
      this.inspDeleteId
      if(this.inspection){
        this.inspection.forEach((item,i)=>{
          item.active = false;
          debugger
          if(i == 0 && this.isTrueDelete == false){
            item.active = true;
            this.insDetail= item
          }
          else if(this.inspDeleteId == item.id && this.isTrueDelete == true ){
            item.active = true;
            this.insDetail= item
          }
        })
      }
      console.log("workOrderDetails",this.inspection )
      this.complaintDetails = wordOrder['response']
      // this.getInspector();
      console.log("complain detail ", this.complaintDetails.inspection )
      // this.inspectorName = (wordOrder['response'].assign_inspector && wordOrder['response'].assign_inspector.assign_inspector.name)
      this.inspectorId = wordOrder.response.work_order_request ?  wordOrder.response.work_order_request.assign_inspector :null;
      this.getInspector()
      this.complaintDetails.inspection.forEach((insp: any, i) => {
        // debugger
       
        // if(this.isId == insp.id){
        //   insp.active = true;
        // }
        // if (i == 0) {
        //   this.ins = insp;
        //   this.workOrdrDetails = this.ins['work_order_request'][0]
        // }
        // this.ins.work_order_request.forEach((item,i) => {
        //   if(i == 0){
        //    item.Workactive = true;
        //   }
        //   console.log("sort Data is", i)
        // });
        // if(insp.active == true){
        //   this.newArryj.push(insp)
        //   this.ins = insp
        // }
        // if(insp.active != true){
        //   this.newArryj.push(insp)
        // }    
      })
      console.log(this.complaintDetails.inspection)
      // if(this.complaintDetails.inspection){
      //   // this.isTrue = true;
      //  this.complaintDetails.inspection.forEach((x:any) => {
        
      //   })
        // if(this.isTrue){
        //   this.newArryj.forEach(x=>{
        //     if(this.ins.id == x.id)
        //     x.active = true
        //   })
        // }
       

    })
  }


  insDetailsShow(item){
    this.insDetail= item
    this.inspection.forEach(element => {
      element.active = false
      if(item.id == element.id)
      element.active = true;
    });
//     this.insDetail.active = true
// console.log("value is ===>",item)
  }
  getInspector() {
    this.dashboardSrvice.getInspectors({ department_id: 1,city_id:this.complaintDetails.city_id }).subscribe(data => {
      this.inspectorList = data.response;
      if(this.inspectorId){
        this.inspectorList.filter(item=>{
         if(item.id == this.inspectorId){
             this.assignInspectorForm.patchValue({
              inspector:this.inspectorId
             })
         }
        })
      }
    })
  }

  assigenInspector() {
    
    if (this.assignInspectorForm.invalid) {
      this.isAssignInspector = true
      return false
    }
    // this.assignInspectorForm.value.assign_inspector = this.assignInspectorForm.value.inspector
    this.assignInspectorForm.value.order_id = this.workOrderDetails.id
    // this.assignInspectorForm.value.inspection_id = this.workOrderDetails.inspection_id
    // this.assignInspectorForm.value.application_id = this.workOrderDetails.application_id
    //this.assignInspectorForm.value.inspector = Number(this.assignInspectorForm.value.inspector)
    this._CEService.assignWorderInspector(this.assignInspectorForm.value).subscribe(data => {
      this.isAssignInspector = false
      this.getWorkOrderDetails()
      this.assignInspector.nativeElement.click()
      this.assignInspectorForm.reset()
      this._TS.success('Assign Inspector');
    })
  }

  // public isInspection = false
  // inspections() {
  //   if (this.mytime) {
  //     this.inspectionForm.controls.inspection_time.setErrors(null)
  //   }
  //   if (this.inspectionForm.invalid) {
  //     this.isInspection = true
  //     return false
  //   }
  //   this.inspectionForm.value.inspection_time = this.mytime
  //   this.inspectionForm.value.application_id = this.complaintDetails.id
  //   this._CEService.inspectionForCe(this.inspectionForm.value).subscribe(data => {
  //     this.inspectionForm.reset()
  //     this.isInspection = false;
  //     this._TS.success('Inspection successfully')
  //   })
  // }

  navigateInspectionPage() {
    // this.router.navigate(['admin/ce/ce-inspection'], { queryParams: { id: this.comlainId, city_id: this.complaintDetails.city_id } })

    this.router.navigate(['admin/ce/work-orders-inspection'], { queryParams: { id: this.workOrderId,app_id:this.complaintDetails['work_order_request']['application_id'], ins_id:this.complaintDetails['work_order_request']['inspection_id'],city_id: this.complaintDetails.city_id,complainId:this.complaintId}})
  }

  media(event1) {
    
    this.imageName = event1.target.files[0].name;
    this.attachment = event1.target.files[0]
    this.formData.append('work_order_inspection_attachment', this.attachment)
    this.image.push({ name: this.imageName, date: new Date(), id: this.i })
    this.i = this.i + 1
    this.uploadDoc('5')
    console.log(event1.target.files[0])
  }

  uploadDoc(ins) {
      this.isTrueDelete = true;
      console.log("isupload", this.insDetail.id)
      this.inspDeleteId = this.insDetail.id
       this.formData.append('wo_inspection_id', this.insDetail.id)
    // this.formData.append('application_id', this.workOrderDetails.application_id)

    this._CEService.uploadImageWorkorder(this.formData).subscribe(data => {
      this._TS.success('file uploaded successfully ')
      // this.comlaintAllDetails()
      this.image = []
      this.formData = new FormData()
      this.getWorkOrderDetails()

    })
  }
  deleteImage(id,wo_inspection_id) {
     this.inspDeleteId = wo_inspection_id;
     this.isTrueDelete = true;
    const data = {
      document_id: id,
      wo_inspection_id: this.insDetail.id,
      // application_id: inspection.application_id
    }
    this._CEService.deleteImageWorkOrder(data).subscribe(data => {
      this.getWorkOrderDetails()
      this._TS.success('Image deleted successfully')
    })
  }

  phoneNumberFormate() {
    var value: string
    var autoFillValue = '-'
    if (this.workOrderCon.value.phone_number && this.workOrderCon.value.phone_number.length === 3) {
      this.workOrderCon.controls.phone_number.setValue(this.workOrderCon.value.phone_number.concat(autoFillValue))
    }
    if (this.workOrderCon.value.phone_number && this.workOrderCon.value.phone_number.length === 7) {
      this.workOrderCon.controls.phone_number.setValue(this.workOrderCon.value.phone_number.concat(autoFillValue))
    }
  }

  keyPressNumbers(event) {
    
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57) && charCode != 45) {
      event.preventDefault();

      return false;
    } else {
      this.phoneNumberFormate()
    }

    
  }


  public workOrdrDetails: any
  workOrdrDeatils(details) {
    this.workOrdrDetails = details
    console.log(this.workOrdrDetails)
  }

  localstprageData: any;
  public department = {
    role_id: null,
    department_id: null,
  }

  

  getLocalStorage() {
    
    const department = this.adminAuthService.getPermission()
    if (department && department.role_id != 1 && department.role_id != 2) {
      this.department = department
      this.localstprageData = department.department;
    } else {
      const localStorageData = this.adminAuthService.getSaveDepartment()
      const department = this.adminAuthService.getPermission()
      this.department = localStorageData
      this.department.role_id = department.role_id
      this.localstprageData = localStorageData
    }
  }

  reset(){
    
    this.workOrderCon.reset()
  }
}

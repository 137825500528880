import { Component, OnInit,ViewChild,ElementRef} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StaffService } from '../../../../../core/http/admin/staff.service';
import { Department } from '../../../../../core/models/admin/department.model';
import { roles } from '../../../../../core/models/admin/roles.model';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminAuthenticationService } from 'src/app/core/authentication/admin-authentication.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { CeComplaintService } from 'src/app/core/http/admin/ce-complaint.service';

@Component({
  selector: 'app-staff-add-type',
  templateUrl: './staff-add-type.component.html',
  styleUrls: ['./staff-add-type.component.css']
})
export class StaffAddTypeComponent implements OnInit {

  constructor(    private formBuilder: FormBuilder,
    private staffService: StaffService,
    private router: Router,
    private route: ActivatedRoute,
    private adminAuthService: AdminAuthenticationService,
    private TS: ToastrService,
    private _CEService: CeComplaintService,) { }
  public addType: FormGroup;
  public addSubType: FormGroup;
  public isSubmittedType = false;
  public type_List = [];
  public sub_typeList =[];
  public type_id:any;
  public staffId:any;
  public isSubmittedSubType = false;
  @ViewChild('typeModal', { static: false }) typeModal: ElementRef;
  @ViewChild('subTypeModal', { static: false }) subTypeModal: ElementRef;
  @ViewChild('selectTypeNme', { static: false }) selectTypeNme: ElementRef;
  ngOnInit() {
    this.addSubTypeForm();
    this.addTypeForm();
    let data = JSON.parse(localStorage.getItem('currentAdmin'))
    this.staffId = data.id
    if( this.staffId){
      this.typeList();
      this.subTypeList();
    }
  }


  
 
 

  addTypeForm(){
    this.addType = this.formBuilder.group({
      type: ['', Validators.required],
    });
  }

  get addTypeFormValidaters () { return this.addType.controls }

  addSubTypeForm(){
    this.addSubType = this.formBuilder.group({
      typeId: ['', Validators.required],
      subtype: ['', Validators.required],
    });
  }
  get addSubTypeValidaters () { return this.addSubType.controls }

  addTypeNew(){
    this.addType.reset(); 
    this.type_id = null;
  }
  editType(value){
    this.type_id = value.id;
    if(value.id){
      this.addType.controls.type.setValue(value.type_name)
     }
  }
  addtype(){  
   
    if(this.addType.invalid){
      this.isSubmittedType = true;
      return true;
    }
    let payload = {
      type_name:this.addType.controls.type.value,
      // city_id: this.staffId,
      type_id:this.type_id
    }
    if(!this.type_id){
     delete  payload.type_id
    }
    this._CEService.addType(payload).subscribe(data => {
      this.type_List =  data;
      this.addType.reset();
      this.isSubmittedType = false;
      this.typeModal.nativeElement.click();
      if(!this.type_id){
        this.TS.success("Type add Successfully")
      }else{
        this.TS.success("Type edit Successfully")
      }
      this.typeList();
    })

  }
  addNewSubType(){
    this.addSubType.reset();
    this.selectTypeNme.nativeElement.value = "";
    this.typeSub_id = null;
    this.type_idForSubType = null;
    this.isSubmittedSubType = false;

  }
  resetForm(){
    this.addType.reset();
    this.isSubmittedType = false;
  }
  
  typeList(){
    this._CEService.typeList(this.staffId).subscribe(data => {
      this.type_List = data;
    })
  }
  subTypeList(){
    this._CEService.subTypeList(this.staffId).subscribe(data => {
      this.sub_typeList = data;
    })
  }
  public type_idForSubType:any;
  public typeSub_id:any;
  seleceType(id){
    this.type_idForSubType = id
  }
  addSubtype(){
    // if(!this.typeSub_id){
    //   this.addSubType.reset();
    // }
    if(this.addSubType.invalid){
      this.isSubmittedSubType = true;
      return true;
    }
    let payload = {
      type_name:this.addSubType.controls.subtype.value,
      // city_id: this.staffId,
      type_id:this.type_idForSubType,
      sub_type_id:this.typeSub_id
    }
    if(!this.typeSub_id){
      delete payload.sub_type_id
    }
    this._CEService.addSubType(payload).subscribe(data => {
      this.sub_typeList =  data;
      this.addSubType.reset();
      this.isSubmittedSubType = false;
      if(!this.typeSub_id){
        this.TS.success("Sub Type add Successfully")
      }else{
        this.TS.success("Sub Type edit Successfully")
      }
      this.subTypeModal.nativeElement.click();
      this.subTypeList();
    })
  }
  editSubType(value){
    this.typeSub_id = value.id;
    this.type_idForSubType = value.type_id
    if(value.id){
      this.addSubType.controls.typeId.setValue(value.type_id);
      this.addSubType.controls.subtype.setValue(value.type_name)
     }
  }

  deleteType(id,city_id){
    let payload  = {
      type_id:id,
      city_id:city_id
    }
    this._CEService.deleteType(payload).subscribe(data => {
      this.type_List = data;
      this.subTypeList();
      this.TS.success("type delete successfully")
    })
  }

  deleteSubType(id,city_id,type_id){
    let payload  = {
      sub_type_id:id,
      city_id:city_id,
      type_id:type_id
    }
    this._CEService.deleteSubType(payload).subscribe(data => {
      this.sub_typeList = data;
      this.TS.success("Sub type delete successfully")
    })
  }

}

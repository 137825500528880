import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CertificatesService {
  public downloadFile: string;
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  getAllCertificates(type: any): Observable<any> {
    const url = environment['allCertificates'];

    return this.http.get<any>(url, { params: type }).pipe(
      map(
        ({ status, ...rest }) => {
          if (status === 'success') {
          }
          return rest;
        }
      )
    );
  }



  filterCertificates(type: any): Observable<any> {
    const url = environment['certificatesFilters'];
    console.log(url, type)
    return this.http.get<any>(url, { params: type }).pipe(
      map(
        ({ status, ...rest }) => {
          if (status === 'success') {
          }
          return rest;
        }
      )
    );
  }


  requestedDate(type: any): Observable<any> {
    const url = environment['requestedDates'];
    console.log(url, type)
    return this.http.get<any>(url, { params: type }).pipe(
      map(
        ({ status, ...rest }) => {
          if (status === 'success') {
          }
          return rest;
        }
      )
    );
  }

  getCertifaicateFile(data: any): void {
    let params = new HttpParams();
    params = params.set('occupancy_type', data.type);
    this.downloadFile = `${environment.host}` + `${environment['certificatesFiles']}` + `${data.format}` + params.toString();
    window.open(this.downloadFile, '_blank');
  }

 

  getExportPaymentSummaryFile(data: any) {
    const admin = JSON.parse(localStorage.getItem('currentAdmin'));
    let params = new HttpParams();
    params = params.set('city_id', admin.id);
    params = params.set('role_id', admin.role_id);
    params = params.set('page', data.page);
    params = params.set('department_id', data.department_id);
    params = params.set('occupancy_type', data.occupancy_type);
    if (data.search_query) {
      params = params.set('search_query', data.search_query);
    }
    if (data.from) {
      params = params.set('from', data.from);
    }
    if (data.to) {
      params = params.set('to', data.to);
    }
    // params = params.set('search_query', data.search_query);
    // params = params.set('from', data.from);
    // params = params.set('to', data.to);
    params = params.set('paid', data.paid);
    params = params.set('unpaid', data.unpaid);
    this.downloadFile = `${environment.host}` + `${environment['exportPaymentSummaryFile']}` + `/${data.format}?` + params.toString();
    window.open(this.downloadFile, '_blank');
  }

  readUnredApplication(): Observable<any> {
    const url = environment['readUnreadApplciationAndComplaint'];
    return this.http.get<any>(url).pipe(
      map(
        ({ status, ...rest }) => {
          if (status === 'success') {
          }
          return rest;
        }
      )
    );
  }

  getWard(data): Observable<any> {
    const url = environment['cityWard'];
    return this.http.get<any>(url,{params:data}).pipe(
      map(
        ({ status, ...rest }) => {
          if (status === 'success') {
          }
          return rest;
        }
      )
    );
  }

  getFilterWard(): Observable<any> {
    const url = environment['cityFilterWard'];
    return this.http.get<any>(url).pipe(
      map(
        ({ status, ...rest }) => {
          if (status === 'success') {
          }
          return rest;
        }
      )
    );
  }

  getordianceCategoryList(id): Observable<any> {
    const url = `${environment['getordianceCategoryList']}/${id}`;
    return this.http.get<any>(url).pipe(
      map(
        ({ status, ...rest }) => {
          if (status === 'success') {
          }
          return rest;
        }
      )
    );
  }
}

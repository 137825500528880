import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { error } from 'jquery';
import { values } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { codeEnforcementSettingConfig } from 'src/app/configs';
import { DashboardService } from 'src/app/core/http';
import { CeComplaintService } from 'src/app/core/http/admin/ce-complaint.service';
import { compliant_details } from 'src/app/core/models/admin/code-enforcement/complantDetails.model';
import { AdminAuthenticationService } from 'src/app/core/authentication/admin-authentication.service';
import { CertificatesService } from 'src/app/core/http/admin/certificates.service';

@Component({
  selector: 'app-inspection',
  templateUrl: './inspection.component.html',
  styleUrls: ['./inspection.component.css']
})
export class InspectionComponent implements OnInit {
  public inspectionForm: FormGroup;
  public assignInspectionForm: FormGroup;
  public setting: any
  public mytime: any
  public comlainId: any;
  public env: any;
  public ordinanceForm: FormGroup;
  public postData = {
    // filters: key,
    admin_id: null,
    page: 1,
    category_name: null,
    filters: null
  }
  public testord = [
    {
      code: '555', voilation: 'test88', description: 'test255565', violation_id: 1
    }
  ]
  public department = {
    role_id: null,
    department_id: null,
  }
  public inspectorList = [];
  @ViewChild('odiancepopup', { static: false }) odiancepopup: ElementRef;
  @ViewChild('addOrd', { static: false }) addOrd: ElementRef;
  @ViewChild('search', { static: false }) search: ElementRef;
  @ViewChild('catname', { static: false }) catname: ElementRef;
  @ViewChild('ordiance', { static: false }) ordiance: ElementRef;

  public complaintDetails: compliant_details = {
    id: null,
    address: null,
    complaint_type: null,
    user_phone: null,
    createdAt: null,
    also_know_as: null,
    inspection: [],
    assign_inspector: null,
    google_address: null,
    location_type: null,
    complain_type: null,
    addresses:null,
    work_order_request:null,
    complaint_attachment: []



  }
  public city_id: any
  constructor(
    private _CEService: CeComplaintService,
    public service: CertificatesService,
    private router: Router,
    private route: ActivatedRoute,
    private _FB: FormBuilder,
    private _TS: ToastrService,
    private dashboardSrvice: DashboardService,
    private adminAuthService: AdminAuthenticationService,


  ) { this.setting = codeEnforcementSettingConfig }

  ngOnInit() {

    this.inspectionControls()
    this.ordinanceFormConrol();
    this.assignInspectionForms();
    this.route.queryParams.subscribe(data => {
      this.comlainId = data['id'];
    })
    if (this.comlainId) {
      this.comlaintAllDetails()
    }
    this.mytime = new Date();
    this.route.queryParams.subscribe(data => {
      this.city_id = data.city_id
    })
    this.department = this.adminAuthService.getPermission()
    const d = new Date()
    let time = d.getTime();
    this.inspectionForm.controls.inspection_date.setValue(d);
    this.inspectionForm.controls.inspection_time.setValue(time);
    this.getCategory();
  }

  ordinanceFormConrol() {
    this.ordinanceForm = this._FB.group({
      unit_number: ['', Validators.required],
      //floor: [],
      violation_remarks: [],
    })
  }
  get ordinanceFormCon() { return this.ordinanceForm.controls }

  getInspector() {
    this.dashboardSrvice.getInspectors({ department_id: 1, city_id: this.complaintDetails.city_id }).subscribe(data => {
      this.inspectorList = data.response;
      if (this.inspectorId) {
        this.inspectorList.filter(item => {
          if (item.id == this.inspectorId) {
            this.assignInspectionForm.patchValue({
              inspector: this.inspectorId
            })
          }
        })
      }
    })
  }
  assignInspectionForms() {
    this.assignInspectionForm = this._FB.group({
      inspector: ['', Validators.required],
      comment: [],
    });
  }
  get inspectionCons() { return this.assignInspectionForm.controls }
  assigenInspector() {
    if (this.assignInspectionForm.invalid) {
      // this.isAssignInspector = true
      return false
    }
    this.assignInspectionForm.value.application_id = this.complaintDetails.id;
    comment: null
    this.assignInspectionForm.value.inspector = Number(this.assignInspectionForm.value.inspector)
    this._CEService.assignInspector(this.assignInspectionForm.value).subscribe(data => {
      // this.isAssignInspector = false;
      this.comlaintAllDetails()
      // this.assignInspector.nativeElement.click()
      this.assignInspectionForm.reset()
      this._TS.success('Inspector has been assigned');
    })
  }
  inspectionControls() {
    this.inspectionForm = this._FB.group({
      inspection_date: ['', Validators.required],
      inspection_time: ['', Validators.required],
      action_taken: ['', Validators.required],
      // inspector:['', Validators.required],
      contact_number: ['', [
        // Validators.required,
        Validators.pattern('^[0-9]{3}-[0-9]{3}-[0-9]{4}$')
        // Validators.minLength(12),
      ]],
      follow_up_date: [null],
      location_occupied: ['',],
      person_spoken_to: ['',],
      number_of_days_to_abate: [null],
      vacate_premise_by: ['',],
      inspection_findings: [null, Validators.required],
      internal_notes: [null],
      violation_id: [null],
      inspection_type: [0, Validators.required],
      voliations_given: ['', Validators.required],
      tenant_fullAddress: ['', Validators.required],
      tenant_first_name: ['', Validators.required],
      // tenant_last_name:['',Validators.required],

      category: [null],
      code: [null],
      description: [null],
      to: [null],
      cc: [null],
      //location_violation: [null],
      city: ['', Validators.required]
    })
  }


  // "violation_id": "1",
  // "action_taken": 5,
  // "inspection_type": null,
  // "inspection_date": "hjk",
  // "inspection_time": "1",
  // "update_by": 1,
  // "contact_number": null,
  // "follow_up_date": null,
  // "location_occupied": null,
  // "person_spoken_to": null,
  // "number_of_days_to_abate": null,
  // "vacate_premise_by": null,
  // "inspection_findings": null,
  // "internal_notes": "hukj",
  // "status": null,


  get inspectionCon() { return this.inspectionForm.controls }


  public isInspection = false
  inspections() {

    // if (this.mytime) {
    //   this.inspectionForm.controls.inspection_time.setErrors(null)
    // }
    for(let i = 0 ; i < this.image.length ; i++){
    
      this.formData.append("inspection_attachment", this.image[i].at);
      }
    if (this.inspectionForm.value.action_taken != 4) {
      this.inspectionForm.controls.inspection_type.setErrors(null);
      this.inspectionForm.controls.voliations_given.setErrors(null);
      this.inspectionForm.controls.tenant_first_name.setErrors(null);
      // this.inspectionForm.controls.tenant_last_name.setErrors(null);
      this.inspectionForm.controls.tenant_fullAddress.setErrors(null);
      this.inspectionForm.controls.city.setErrors(null)

    } else {
      if (!this.inspectionForm.value.inspection_type) {
        this.inspectionForm.controls.inspection_type.setErrors({ required: true })

      }
      if (!this.inspectionForm.value.city) {
        this.inspectionForm.controls.city.setErrors({ required: true })

      }

    }
    if (this.inspectionForm.invalid) {
      this.isInspection = true
      return false
    }


    Object.keys(this.inspectionForm.value).forEach(value => {
      console.log("value is ===>", value)
      if (value != 'inspection_time' && value != 'violation_id') {
        if (this.inspectionForm.value[value]) {
          this.formData.append(value, this.inspectionForm.value[value])
        }
      }
    })
    //this.multipleOrd.push({ code: value.code, category: value.code, des: value.violation_description, id: value.id,city_id:value.city_id,unit_number:null,violation_remarks:null })

    this.formData.append('inspection_time', this.mytime)
    this.formData.append('application_id', this.comlainId)
    // this.formData.append('vi', this.multipleOrd)
    if (this.multipleOrd.length > 0) {
      console.log("multiboard length==>", this.multipleOrd)
      this.formData.append('ordinance_ids', JSON.stringify(this.multipleOrd))

      // for (let file of this.multipleOrd) {
      //   this.formData.append('ordinance_id[][code]', file.code);
      //   this.formData.append('ordinance_id[][category]', file.code);
      //   this.formData.append('ordinance_id[][violation_description]', file.violation_description);
      //   this.formData.append('ordinance_id[][id]', file.id);
      //   this.formData.append('ordinance_id[][unit_number]', file.unit_number);
      //   this.formData.append('ordinance_id[][violation_remarks]', file.violation_remarks);


      // }
    }



    this.inspectionForm.value.inspection_time = this.mytime
    this.inspectionForm.value.application_id = this.complaintDetails.id
    console.log("this.formData",this.formData);
    // return
    this._CEService.inspectionForCe(this.formData).subscribe(data => {
      this.inspectionForm.reset()
      this.isInspection = false;
      this.image = []
      this.formData = new FormData()
      this.isShow = false
      this.multipleOrd = [];
      this.back();
      this._TS.success('Inspection has been done successfully.')
    }, error => {
      this.formData = new FormData()
    })
  }
  searchOrdiance(key: any) {
    this.catname.nativeElement.value = "";
    // const postData = {
    //   filters: key,
    //   admin_id: ,
    //   page: this.page,
    // }
    this.postData.admin_id = this.city_id;
    this.postData.filters = key;


    if (key.length == 0) {
      delete this.postData.filters
    }

    // this.getOrdinance();

    this.dashboardSrvice.getOrdinanceData(this.postData).subscribe(({ response, ...pagination }) => {
      this.ordinance = response.docs;
      this.ordinance.map(data => {
        data.isChecked = false;
        this.multipleOrd.map((odiance, i) => {
          if (data.id == odiance.id) {
            data.isChecked = true
          }
        })
      });

      console.log(this.ordinance)
      this.totalPagination = response.total;
      this.offset = response.offset;
      this.currentPagination = response.currentPage;
    });
  }
  public inspectorId: any;
  comlaintAllDetails() {

    this._CEService.getAllComplaintDetails(this.comlainId).subscribe(data => {
      this.complaintDetails = data.response;
      this.inspectorId = data.response.assign_inspector ? data.response.assign_inspector.inspector : null;
      this.getInspector()
    })
  }

  back() {
    this.router.navigate(['/admin/ce/complain-details'], { queryParams: { id: this.comlainId } })
  }

  public isShow = false
  selectType(value) {
    if (value == 4) {
      this.isShow = true

    } else {
      this.isShow = false

    }
  }
  public isSelectOrd = true
  public multipleOrd: any = []
  selectMultipleOdiance(value, event) {

    if (event.target.checked) {
      if (value) {
        this.isSelectOrd = false
        this.inspectionForm.controls.category.setValue(value.voilation)
        this.inspectionForm.controls.code.setValue(value.code)
        this.inspectionForm.controls.violation_id.setValue(value.violation_id)
        this.inspectionForm.controls.description.setValue(value.description)
        this.inspectionForm.controls.city.setValue('xyz')
        this.multipleOrd.push({ code: value.code, category: value.code, des: value.violation_description, id: value.id, city_id: value.city_id, unit_number: null, violation_remarks: null })

      }
    } else if (!event.target.checked) {
      this.multipleOrd.forEach((data, i) => {
        if (data.violation_id == value.violation_id) {
          this.multipleOrd.splice(i, 1)
        }
      })
    }



  }

  hideOdianceModel() {
    if (this.multipleOrd.length > 0) {
      this.odiancepopup.nativeElement.click()
    } else {
      this._TS.warning('please add at least one city code')
    }

  }
  public isInspections = false
  inpectionType(value: any) {

    if (value == 1) {
      this.isInspections = true
    } else {
      this.isInspections = false
    }
  }

  public imageName: any;
  public attachment: any
  public image = []
  public i = 1
  public  formDelete:any;
  public formData = new FormData()
  media(event1) {

    this.imageName = event1.target.files[0].name;
    this.attachment = event1.target.files[0];
    this.image.push({ name: this.imageName, date: new Date(), id: this.i,'at':this.attachment})
    this.i = this.i+1
    console.log(this.image)
   
  }
 
  deleteImage(id) {
    this.image.forEach((data, i) => {
      if (data.id == id) {
        this.image.splice(i, 1);
      }
    })
  }

  phoneNumberFormate() {
    var value: string
    var autoFillValue = '-'
    if (this.inspectionForm.value.contact_number && this.inspectionForm.value.contact_number.length === 3) {
      this.inspectionForm.controls.contact_number.setValue(this.inspectionForm.value.contact_number.concat(autoFillValue))
    }
    if (this.inspectionForm.value.contact_number && this.inspectionForm.value.contact_number.length === 7) {
      this.inspectionForm.controls.contact_number.setValue(this.inspectionForm.value.contact_number.concat(autoFillValue))
    }
  }



  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 48 || charCode > 57) && charCode != 45) {
      event.preventDefault();

      return false;
    } else {
      this.phoneNumberFormate()
    }
  }

  public ordinance: any = [];
  public totalPagination: number = Number(0);
  public offset: any = 1;
  public currentPagination: number = Number(1);
  public page = 1
  public getOrdinance(page) {

    this.page = page
    this.postData.page = page
    let data = JSON.parse(localStorage.getItem('currentAdmin'))
    this.postData.admin_id = this.city_id;

    if (!this.postData.category_name) {
      delete this.postData.category_name
    }
    if (!this.postData.filters) {
      delete this.postData.filters
    }

    this.dashboardSrvice.getOrdinanceData(this.postData).subscribe(({ response, ...pagination }) => {
      this.ordinance = response.docs;

      this.ordinance.map(data => {
        data.isChecked = false;
        this.multipleOrd.map((odiance, i) => {
          if (data.id == odiance.id) {
            data.isChecked = true
          }
        })
      });

      console.log(this.ordinance)
      this.totalPagination = response.total;
      this.offset = response.offset;
      this.currentPagination = response.currentPage;
    });
  }

  paginate(value: any) {
    this.getOrdinance(value);
  }

  removeOrd(ord) {

    this.multipleOrd.forEach((data, i) => {
      if (ord.id == data.id) {
        this.multipleOrd.splice(i, 1)
      }
    })
  }

  public selectOdianceId: any
  getId(ord) {
    this.selectOdianceId = ord.id
  }
  public isordinance = false;
  addFloorAndUnitNumber() {
    if (!this.ordinanceFormCon.unit_number.value) {
      this.isordinance = true;
      return;
    }

    this.ordinanceForm
    if (this.selectOdianceId && this.multipleOrd.length > 0) {
      this.multipleOrd.map(data => {
        if (this.selectOdianceId == data.id) {
          data.unit_number = this.ordinanceForm.value.unit_number
          data.violation_remarks = this.ordinanceForm.value.violation_remarks
          //  data.isFloorAdd = true
          this.addOrd.nativeElement.click()
          this.ordinanceForm.reset();
          this.ordiance.nativeElement.click();
          this.isordinance = false;

        }
      })


    }



  }
  isTrue = false;
  selectCheck(val) {
    console.log(val)
    this.isTrue = val;

  }

  selectCategory(val) {
    this.postData.filters = val;
    this.search.nativeElement.value = "";
    if (!this.postData.category_name || this.postData.category_name == 'All') {
      delete this.postData.category_name
    }
    if (!this.postData.filters) {
      delete this.postData.filters
    }
    this.dashboardSrvice.getOrdinanceData(this.postData).subscribe(({ response, ...pagination }) => {
      this.ordinance = response.docs;
      this.ordinance.map(data => {
        data.isChecked = false;
        this.multipleOrd.map((odiance, i) => {
          if (data.id == odiance.id) {
            data.isChecked = true
          }
        })
      });

      console.log(this.ordinance)
      this.totalPagination = response.total;
      this.offset = response.offset;
      this.currentPagination = response.currentPage;
    });
  }
  public getordianceCategoryList = []
  getCategory() {
    this.service.getordianceCategoryList(this.city_id,).subscribe(data => {
      this.getordianceCategoryList = data.response;

    })
  }
  VoilationGivenTo(value) {
    if (value == 1) {
      this.inspectionForm.controls.tenant_first_name.setErrors(null);
      // this.inspectionForm.controls.tenant_last_name.setErrors(null)
      this.inspectionForm.controls.tenant_fullAddress.setErrors(null)
    } else if (value == 2) {

    }
  }
  ordinanaceBlank() {
    this.multipleOrd = [];
  }

  clearOrdiance(){
    this.ordinanceForm.reset();
    this.isordinance = false;
  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StaffService } from 'src/app/core/http';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { CertificatesService } from 'src/app/core/http/admin/certificates.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-ward',
  templateUrl: './add-ward.component.html',
  styleUrls: ['./add-ward.component.css']
})
export class AddWardComponent implements OnInit {
  public addnewStaffForm: FormGroup;
  public isUpdate = false;
  public isSubmitted = false;
  public staffId: number
  public singleAdminData = {
    name: null,
    email: null,
    phone: null,
    status: null,
    city_name: null,
    stripe_account_id: null
  }

  minDate: Date;
  constructor(
    private formBuilder: FormBuilder,
    private staffService: StaffService,
    private route: ActivatedRoute,
    private router: Router,
    public _CS: CertificatesService,
    public _TS: ToastrService,

  ) { this.minDate = new Date(); }

  ngOnInit() {
    
    this.staffForm();
    this.route.queryParams.subscribe(staff => {
      this.staffId = staff.staffId;
      if (this.staffId) {
        this.getSingleAdmin()
      }
    })
    this.getWards()
  }

  staffForm() {
    this.addnewStaffForm = this.formBuilder.group({

      ward: ['', Validators.required],
      from_date: ['', Validators.required],
      to_date: ['', Validators.required],


    });


  }



  getSingleAdmin() {
    this.staffService.getCitySingleAdmin(this.staffId).subscribe(data => {
      this.singleAdminData = data;
      this.isUpdate = true;
      this.addnewStaffForm.controls.name.setValue(this.singleAdminData.name)
      this.addnewStaffForm.controls.email.setValue(this.singleAdminData.email)
      this.addnewStaffForm.controls.phone.setValue(this.singleAdminData.phone)
      this.addnewStaffForm.controls.status.setValue(this.singleAdminData.status)
      this.addnewStaffForm.controls.city_name.setValue(this.singleAdminData.city_name)
      this.addnewStaffForm.controls.stripe_account_id.setValue(this.singleAdminData.stripe_account_id)
    })
  }


  updateCity() {

    this.staffService.updateAdmin(this.addnewStaffForm.value, this.staffId).subscribe(data => {
      this.addnewStaffForm.reset()
      this.isUpdate = false
      this.router.navigate(['/admin/manage/city'])
    })
  }

  addNewCityAdmin() {
    this.isSubmitted = true
    if (this.addnewStaffForm.invalid) {
      return false
    }
    if (this.addnewStaffForm.value.status && this.addnewStaffForm.value.status != '') {
      this.addnewStaffForm.value.status = 1
    } else {
      this.addnewStaffForm.value.status = 0

    }
    this.staffService.createCityAdmin(this.addnewStaffForm.value).subscribe(data => {
      this.addnewStaffForm.reset()
      this.isSubmitted = false,
        this.router.navigate(['/admin/manage/city'])

    })
  }

  selectStatus(event) {
    if (event.target.checked) {
      this.addnewStaffForm.value.status = 1
    } else {
      this.addnewStaffForm.value.status = 0

    }
  }

  get staffMember() { return this.addnewStaffForm.controls }



  addBlockDate() {
    if (this.addnewStaffForm.invalid) {
      this.isSubmitted = true
      return false
    }
    this.staffService.addBlockDate(this.addnewStaffForm.value).subscribe(data => {
      this._TS.success('Ward added')
      this.isSubmitted = false
      this.addnewStaffForm.reset()
    })
  }

  public ward = []
  getWards() {
    const localStorageData = JSON.parse(localStorage.getItem('currentAdmin'));
    this._CS.getWard({ city_id: localStorageData['id'] }).subscribe(data => {
      this.ward = data['response']
    })
  }

  public selectDate: any
  onValueChange(value) {
    this.selectDate = value
    console.log(value)
  }
}

import * as _ from 'lodash';
import { of as ObservableOf, Observable } from 'rxjs';

export const settingConfig = {

  credentialsKeyForAdmin: 'currentAdmin',
  super_admin_Role_id: 1,
  city_admin_Role_id: 2,
  managerRole_id: 3,
  clerkRole_id: 4,
  inspector_Role_id: 5,

  current_use: [
    {
      key: 1,
      value: "Residential"
    },
    {
      key: 2,
      value: "Commercial"
    },
    {
      key: 3,
      value: "Mixed Use"
    },
  ],
  current_status: [
    {
      key: 1,
      value: "Vacant"
    },
    {
      key: 2,
      value: "Occupied"
    }
  ],
  role: [
    {
      key: 1,
      value: "Seller/Owner"
    },
    {
      key: 2,
      value: "Seller's Agent"
    },
    {
      key: 3,
      value: "Buyer (Auction or Sheriff Sale or Bank owned)"
    }
  ],

  cco_role: [
    {
      key: 4,
      value: "Tenant"
    },
    {
      key: 5,
      value: "Property owner"
    },
    {
      key: 6,
      value: "Buyer"
    }
  ],

  roleCH: [
    {
      key: 1,
      value: "Property Owner"
    },
    {
      key: 2,
      value: "Property Manager"
    },

  ],
  owner_type: [
    {
      key: 1,
      value: "Single Individual"
    },
    {
      key: 2,
      value: "Business Entity/Trust"
    },
    {
      key: 3,
      value: "Bank"
    }
  ],
  buyer_type: [
    {
      key: 1,
      value: "Individual"
    },
    {
      key: 2,
      value: "Business"
    }
  ],
  buyer_business_type: [
    {
      key: 1,
      value: "Corporation"
    },
    {
      key: 2,
      value: "Partnership"
    },
    {
      key: 3,
      value: "LLC"
    }
  ],
  point_of_contact: [
    {
      key: 1,
      value: "Applicant"
    },
    {
      key: 2,
      value: "Property Owner"
    },
    {
      key: 3,
      value: "Someone else"
    }
  ],
  type_of_transaction: [
    {
      key: 1,
      value: 'Sale'
    },
    {
      key: 2,
      value: 'Re-finance'
    },
    {
      key: 3,
      value: 'Ownership Transfer'
    },
    {
      key: 4,
      value: 'Fire Certificate'
    }
  ],

  type_of_transaction_for_owner_seller: [
    {
      key: 4,
      value: 'Obtain a Fire Certificate'
    }
  ],

  type_of_transaction_for_buyer: [
    {
      key: 1,
      value: 'Sale'
    },
  ],

  type_of_transaction_Forcco: [
    {
      key: 1,
      value: 'Sale'
    },
    {
      key: 2,
      value: 'Re-finance'
    },
    {
      key: 3,
      value: 'Ownership Transfer'
    },
    {
      key: 4,
      value: 'Obtain a Certificate Of Occupancy'
    },
    {
      key: 5,
      value: 'New tanant'
    }
  ],
  type_of_commercial: [
    {
      key: 1,
      value: 'Retail Business'
    },
    {
      key: 2,
      value: 'Retail Food Establishment'
    },
    {
      key: 3,
      value: 'Day Care'
    },
    {
      key: 4,
      value: 'Office'
    },
    {
      key: 5,
      value: 'Other'
    }
  ],
  occupancy_type: [
    {
      key: 1,
      value: 'Certificate of Occupancy',
      shortForm: 'CO'
    },
    {
      key: 2,
      value: 'Certificate of Habitability',
      shortForm: 'COH',
    },
    {
      key: 3,
      value: 'Certificate of Continued Occupancy',
      shortForm: 'CCO'
    },
  ],
  checkStatus: [
    {
      key: 0,
      value: 'Incomplete'
    },
    {
      key: 1,
      value: 'Submission'
    },
    {
      key: 2,
      value: 'Pending inspection',
    },
    {
      key: 3,
      value: 'Inspected',
    },
    {
      key: 4,
      value: 'Illegal conversation',
    },
    {
      key: 5,
      value: 'Cancelled'
    },
    {
      key: 6,
      value: 'Ward'
    },
    {
      key: 7,
      value: 'Inspector'
    }
  ],
  applicantFiles: [
    {
      key: 1,
      value: 'applicant_photo_id'
    },
    {
      key: 2,
      value: 'buyer_id'
    },
    {
      key: 3,
      value: 'seller_id',
    },
    {
      key: 4,
      value: 'owner_authorization',
    },
    {
      key: 5,
      value: '_',
    },
    {
      key: 6,
      value: 'power_of_attorney',
    },
    {
      key: 7,
      value: 'corporate_formation',
    },
  ],

  applicantFilesForShow: [
    {
      key: 1,
      value: 'Applicant Id'
    },
    {
      key: 2,
      value: 'Buyer Id'
    },
    {
      key: 3,
      value: 'Seller Id',
    },
    {
      key: 4,
      value: 'Owner authorization',
    },
    {
      key: 5,
      value: 'Sales Contract',
    },
    {
      key: 6,
      value: 'Power of attorney',
    },
    {
      key: 7,
      value: 'Corporate formation',
    },
  ],
  application_status: [
    {
      key: 0,
      value: "Draft"
    },
    {
      key: 1,
      value: "Completed"
    },
    // {
    //   key: 2,
    //   value: "Submitted" // Change Submitted to pending review saying by client
    // },
    {
      key: 2,
      value: "Pending Review" // Change Submitted to pending review saying by client
    },
    {
      key: 3,
      value: "Recieved"
    },
    {
      key: 4,
      value: "Transfered"
    },
    {
      key: 5,
      value: "Cancelled"
    },
    {
      key: 6,
      value: 'Incomplete'
    },
    {
      key: 7,
      value: 'Complete' // Change Accept Application to Submitted saying by client
    },
    {
      key: 8,
      value: 'CO Issued' // Change Issue Permanent Certificate to CO Issued saying by client
    }, {
      key: 9,
      value: 'TCO Issued' // Change Issue Tempoarary Certificate to TCO Issued saying by client
    }, {
      key: 10,
      value: 'Illegal Conversion'
    },
    {
      key: 11,
      value: 'Withdrawn Application'
    },
    // {
    //   key: 12,
    //   value: 'Re-Submitted'
    // },
  ],

  doc_type: [
    {
      key: '1',
      value: 'Applicant Photo Id ,Driver License'
    },
    {
      key: '2',
      value: 'Copy Of Lease'
    },
    {
      key: "3",
      value: 'Tax Id Certificates'
    },
    {
      key: "4",
      value: 'Business Certificate'
    },
    {
      key: "5",
      value: 'Business Formation Documents'
    },
    {
      key: "6",
      value: 'Sales Contract'
    },
  ],

  scheduleInspectionTimeRange: [
    {
      key: 1,
      value: '9AM-12AM'
    },
    {
      key: 2,
      value: '1PM-3:30PM'
    }
  ],
  inspection_status: [
    {
      key: 0,
      value: "In Progress"
    },
    {
      key: 1,
      value: "Initialized"
    },
    {
      key: 2,
      value: "Updated"
    },
    {
      key: 3,
      value: "Cancelled"
    },
    {
      key: 4,
      value: "Failed"
    }
  ],

  inspection_type_status: [
    {
      key: 1,
      value: "Initial"
    },
    {
      key: 2,
      value: "Re-Inspection"
    },
    {
      key: 3,
      value: "Building"
    },
    {
      key: 4,
      value: "Electrical"
    },
    {
      key: 5,
      value: "Plumbing"
    },
    {
      key: 6,
      value: "Fire"
    },
  ],

  submission_decision_type: [
    {
      key: 1,
      value: "Issue Permanent Certificate"
    },
    {
      key: 2,
      value: "Issue Temporary Certificate"
    },
    {
      key: 3,
      value: "Illegal Conversion"
    }
  ],

  decision_status: [
    // {
    //   key: 9,
    //   value: "Void"
    // },
    {
      key: 2,
      value: "Pass"
    },
    {
      key: 3,
      value: "Fail"
    },
    {
      key: 4,
      value: "No-Entry"
    }
  ],

  fire_decision_status: [
    {
      key: 2,
      value: "Pass"
    },
    {
      key: 3,
      value: "Fail"
    },
    {
      key: 4,
      value: "No-Entry"
    },
    {
      key: 11,
      value: "Temporary Certificates"
    }
  ],

  fire_Use: [
    {
      key: 1,
      value: "Residential"
    },
    {
      key: 3,
      value: "Mixed Use"
    },
  ],

  current_Status_Fire: [
    {
      key: 1,
      value: "Vacant"
    },
    {
      key: 2,
      value: "Occupied"
    },
  ],

  assesor_decision_status: [
    {
      key: 5,
      value: "Deny"
    },
    {
      key: 6,
      value: "Approved"
    },
  ],

  zoning_decision_status: [
    {
      key: 12,
      value: "Open permit or violations"
    },
    {
      key: 6,
      value: "Approved"
    },
  ],
  payment_status: [
    {
      key: 0,
      value: 'Unpaid'
    },
    {
      key: null,
      value: 'Unpaid'
    },
    {
      key: 1,
      value: 'Pending'
    },
    {
      key: 2,
      value: 'Fail'
    },
    {
      key: 3,
      value: 'Paid'
    },
    {
      key: 4,
      value: 'Dispute'
    },
    {
      key: 5,
      value: 'Waived'
    }
  ],
  transaction_type: [
    {
      key: 1,
      value: 'Online'
    },
    {
      key: 2,
      value: 'Offline'
    }
  ],
  application_type: [
    {
      key: 1,
      value: 'Fire Fee'
    },
    {
      key: 2,
      value: 'Housing Fee'
    },
    {
      key: 3,
      value: 'No-Entry Fee'
    }
  ],

  submission_Application_status: [
    {
      key: 1,
      value: 'Submission Incomplete'
    },
    {
      key: 2,
      value: 'Accept Submission'
    }
  ],

  taxes_decision_status: [
    {
      key: 7,
      value: 'Current'
    },
    {
      key: 8,
      value: 'Outstanding Balance'
    }
  ],

  balanceType: [
    {
      key: 1,
      value: 'Municipal Lien'
    },
    {
      key: 2,
      value: 'Outside Lien'
    }, {
      key: 3,
      value: 'Property Taxes'
    },
    {
      key: 4,
      value: 'Sewer Usage Charge'
    }
  ],

  ward: [
    {
      key: 'East',
      value: 'East'
    },
    {
      key: 'West',
      value: 'West'
    }, {
      key: 'North',
      value: 'North'
    },
    {
      key: 'South',
      value: 'South'
    }
  ],

  certificate_decision_type: [
    {
      key: 1,
      value: 'Issue  Permanent Certificate'
    },
    {
      key: 2,
      value: 'Issue Temporary Certificate'
    },
    {
      key: 3,
      value: 'Illegal Conversion'
    },
  ],




  progressBar: {
    type: 12.50,
    what: 25,
    where: 37.50,
    applicant: 50,
    owner: 62.50,
    buyer: 75,
    description: 87.50,
    upload: 100
  },

  userState: [
    {
      key: 1,
      value: 'Alabama (AL)'

    },
    {
      key: 2,
      value: 'American Samoa (AS)'

    }, {
      key: 3,
      value: 'Arizona (AZ)'


    }, {
      key: 4,
      value: 'Colorado (CO)'


    }, {
      key: 5,
      value: 'Connecticut (CT)'

    }, {
      key: 6,
      value: 'Georgia (GA)'

    }, {
      key: 7,
      value: 'Guam (GU)'

    }, {
      key: 8,
      value: 'Hawaii (HI)'

    }, {
      key: 9,
      value: 'Iowa (IA)'
    }, {
      key: 10,
      value: 'Idaho (ID)'
    },
    {
      key: 11,
      value: 'Illinois (IL)'
    },
    {
      key: 12,
      value: 'Indiana (IN)'

    }, {
      key: 13,
      value: 'Kansas (KS)'
    }, {
      key: 14,
      value: 'Kentucky (KY)'

    }, {
      key: 15,
      value: 'Louisiana (LA)'
    }, {
      key: 17,
      value: 'Massachusetts (MA)'

    }, {
      key: 18,
      value: 'Maryland (MD)'

    }, {
      key: 19,
      value: 'Maine (ME)'

    }, {
      key: 20,
      value: 'Minnesota (MN)'
    }, {
      key: 21,
      value: 'Missouri (MO)'

    }, {
      key: 22,
      value: 'Mississippi (MS)'

    }, {
      key: 23,
      value: 'Montana (MT)'

    }, {
      key: 24,
      value: 'North Carolina (NC)'

    }, {
      key: 25,
      value: 'North Dakota (ND)'

    }, {
      key: 26,
      value: 'Nebraska (NE)'

    }, {
      key: 27,
      value: 'New Hampshire (NH)'

    }, {
      key: 28,
      value: 'New Jersey (NJ)'

    }, {
      key: 29,
      value: 'New Mexico (NM)'

    }, {
      key: 30,
      value: 'Nevada (NV)'

    }, {
      key: 31,
      value: 'Ohio (OH)'


    }, {
      key: 32,
      value: 'Oklahoma (OK)'

    }, {
      key: 33,
      value: 'Oregon (OR)'

    }, {
      key: 34,
      value: 'Pennsylvania (PA)'

    }, {
      key: 35,
      value: 'Puerto Rico (PR)'

    }, {
      key: 36,
      value: 'Rhode Island (RI)'

    },
    {
      key: 37,
      value: 'South Carolina (SC)'


    }, {
      key: 38,
      value: 'South Dakota (SD)'


    }, {
      key: 39,
      value: 'Tennessee (TN)'


    }, {
      key: 40,
      value: 'Texas (TX)'

    }, {
      key: 41,
      value: 'Utah (UT)'


    }, {
      key: 42,
      value: 'Rhode Island (RI)'

    }, {
      key: 43,
      value: 'Virgin Islands (VI)'


    }, {
      key: 44,
      value: 'Virginia (VA)'


    }, {
      key: 45,
      value: 'Vermont (VT)'


    }, {
      key: 46,
      value: 'Washington (WA)'


    }, {
      key: 47,
      value: 'Wisconsin (WI)'


    }, {
      key: 48,
      value: 'West Virginia (WV)'


    },
    {
      key: 49,
      value: 'Wyoming (WY)'
    },

  ],

  country: 'US',
  currency: 'usd',
  account_holder_type: 'individual',
  housingDepartmentId: 1,
  fireDepartmentId: 2,
  assesorDepartmentId: 3,
  taxesDepartmentId: 4,
  zonigDepartmentId: 5,
  ccoDepartmentId: 6,
  applicationComplete_Status: 1,
  applicationpayment_Status: 2,



  getSettingAsObservable(prop: any, condition: any): Observable<any> {
    const object = _.find(this[prop], {
      key: condition
    });

    return ObservableOf(object ? object.value : '');
  },

  getSetting(prop: any, condition: any): any {
    const object = _.find(this[prop], {
      key: condition
    });

    return object ? object.value : '';
  },

  getPaymentStatus(prop: any, condition: any): any {
    const object = _.find(this[prop], {
      key: condition
    });

    return object ? object.value : '';
  },

  getApplicantRole(prop: any, condition: any) {
    const object = _.find(this[prop], {
      key: condition
    });
    return object ? object.value : '';
  },

  getSettingShortForm(prop: any, condition: any): any {
    const object = _.find(this[prop], {
      key: condition
    });

    return object ? object.shortForm : '';
  }

  // is_inspected: // 1-inspected ,2-void inspection , 3-Re-schedule


}



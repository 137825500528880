import { Component, OnInit } from '@angular/core';
import { PaymentsService } from 'src/app/core/http';
import { paymentsSummary } from '../../../../core/models/admin/paymetsSummary.model'
import { Router, ActivatedRoute } from '@angular/router';
import { CertificatesService } from 'src/app/core/http/admin/certificates.service';
@Component({
  selector: 'app-paymets-summary',
  templateUrl: './paymets-summary.component.html',
  styleUrls: ['./paymets-summary.component.css']
})
export class PaymetsSummaryComponent implements OnInit {
  public bsRangeValue: Date[];
  public paymetsSummary: paymentsSummary[];
  public currentPagination: number = Number(1);
  public searchQuery: string = '';
  public from: Date;
  public to: Date;
  public type: any = 1;
  public offset: any = 10;
  public pagination: {
    pages: any,
    total: number,
  } = {
      pages: 0,
      total: 0,
    };

  public currentUser: any;
  public amountTotal = 0;
  public filterOption = 1;
  public paidApplications = 0;
  public unpaidApplications = 0;
  public isHousing = false;
  public isFire = false;
  public departmentId: any;
  public occupancyType: any;

  constructor(
    private paymetsService: PaymentsService,
    private router: Router,
    private route: ActivatedRoute,
    private certificateService: CertificatesService) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentAdmin'));
    this.route.queryParams.subscribe((data) => {
      this.isHousing = data.housing;
      this.departmentId = data.department_id;
      this.occupancyType = data.occupancy_type;
      this.isFire = data.fire;
      this.paymentSummary({ page: this.type })
    })
  }

  paymentSummary(params: object) {
    this.paymetsService.getPaymetsSummary({...params, department_id: this.departmentId, occupancy_type: this.occupancyType}).subscribe((data) => {
      this.amountTotal = data.totalAmount ? data.totalAmount : 0;
      this.paymetsSummary = data.response;
      this.offset = data.offset
      // if (this.currentUser.department_id == 1 && this.currentUser.role_id == 4) {
      //   this.paymetsSummary.forEach((payObj: any) => {
      //     if (payObj.department_id == 0 || payObj.department_id == 16) {
      //       if (payObj.certificate_fee) {
      //         this.amountTotal += Number(payObj.certificate_fee)
      //       }
      //     } else if ((payObj.department_id == 1 ||  payObj.department_id == 6) && (payObj.decision == 3 || payObj.decision == 7)) {
      //       if (payObj.re_inspection_fee) {
      //         this.amountTotal += Number(payObj.re_inspection_fee)
      //       }
      //     } else if ((payObj.department_id == 1 ||  payObj.department_id == 6) && (payObj.decision == 4 || payObj.decision == 8)) {
      //       if (payObj.no_entry_fee) {
      //         this.amountTotal += Number(payObj.no_entry_fee)
      //       }
      //     } else if (payObj.department_id == 20) {
      //       if (payObj.amount) {
      //         this.amountTotal += Number(payObj.amount)
      //       }
      //     }
      //   });
      // } else if (this.currentUser.department_id == 2 && this.currentUser.role_id == 4) {
      //   this.paymetsSummary.forEach((payObj: any) => {
      //     if (payObj.department_id == 0) {
      //       if (payObj.fire_fee) {
      //         this.amountTotal += Number(payObj.fire_fee)
      //       }
      //     } else if ((payObj.department_id == 2) && (payObj.decision == 3)) {
      //       if (payObj.re_inspection_fee) {
      //         this.amountTotal += Number(payObj.re_inspection_fee)
      //       }
      //     } else if ((payObj.department_id == 2) && (payObj.decision == 4)) {
      //       if (payObj.no_entry_fee) {
      //         this.amountTotal += Number(payObj.no_entry_fee)
      //       }
      //     } else if (payObj.department_id == 21) {
      //       if (payObj.amount) {
      //         this.amountTotal += Number(payObj.amount)
      //       }
      //     }
      //   });
      // } else {
      //   this.paymetsSummary.forEach((payObj: any) => {
      //     if(payObj.amount) {
      //       this.amountTotal += Number(payObj.amount);
      //     }
      //   });
      // }
      this.pagination.total = data.total;
      this.pagination.pages = Array(data.currentPage).fill(0).map((x, i) => i + 1);
      this.currentPagination = Number(data.currentPage);
      return false
    })
  }

  searchFilter(): void {
    if (this.searchQuery.length > 2) {

      if (this.from && this.to) {
        this.paymentSummary({ search_query: this.searchQuery, from: this.from, to: this.to ,page:this.type, paid : this.paidApplications, unpaid: this.unpaidApplications })
      } else {
        this.paymentSummary({ search_query: this.searchQuery, page:this.type, paid : this.paidApplications, unpaid: this.unpaidApplications })
      }
    }
    if (this.searchQuery.length == 0) {
      if (this.from && this.to) {
        this.paymentSummary({ from: this.from, to: this.to,page:this.type, paid : this.paidApplications, unpaid: this.unpaidApplications })
      } else {
        //this.paginate(this.pagination.pages)
        this.paymentSummary({ page:this.type, paid : this.paidApplications, unpaid: this.unpaidApplications })
      }
    }
  }

  roundTheValue(value: number): number {
    return Math.floor(value);
  }
  helpPaginate(at: number, index: number): boolean {
    if (at === 1) {
      return index <= (this.currentPagination + 1);
    }

    if (at === 2) {
      return index == this.roundTheValue((this.pagination.pages.length / 2));
    }

    if (at === 3) {
      return index >= (this.pagination.pages.length - 2);
    }
    return false;
  }


  paginate(value: number): void {
    debugger
    this.type = value
    if(this.from && this.to && this.searchQuery.length == 0){
      this.paymentSummary({ page: value,from: this.from, to: this.to, paid : this.paidApplications, unpaid: this.unpaidApplications });
    }
    else if(this.from && this.to && this.searchQuery.length > 0){
      this.paymentSummary({search_query:this.searchQuery, page: value,from: this.from, to: this.to, paid : this.paidApplications, unpaid: this.unpaidApplications });
    }
    else if (value && this.searchQuery.length == 0){
      this.paymentSummary({ page: value, paid : this.paidApplications, unpaid: this.unpaidApplications});
    }
    else if (value && this.searchQuery.length > 0){
      this.paymentSummary({ page: value,search_query:this.searchQuery, paid : this.paidApplications, unpaid: this.unpaidApplications});
    }

  }


  navigateOnDetailPage(applicationId) {
    this.router.navigate(['/admin/certificates/details/', applicationId])

  }

  seelctDate(event) {
    if (event) {
      this.from = event[0].toISOString()
      this.to = event[1].toISOString()
      this.dateFilter();
    }
  }

  dateFilter() {
    if(this.searchQuery.length > 2){
      this.paymentSummary({ page: this.type,search_query:this.searchQuery, from: this.from, to: this.to , paid : this.paidApplications, unpaid: this.unpaidApplications})
    }else{
      this.paymentSummary({ page: this.type, from: this.from, to: this.to, paid : this.paidApplications, unpaid: this.unpaidApplications })

    }
  }

  naviagateByurl(){
    if (this.departmentId == 1 && this.occupancyType == 1) {
      this.router.navigate(['admin/certificates/housing'], {queryParams : {tabType: 1}});
    } else if (this.departmentId == 1 && this.occupancyType == 2) {
      this.router.navigate(['admin/certificates/housing'], {queryParams : {tabType: 2}});
    } else if (this.departmentId == 6 && this.occupancyType == 3) {
      this.router.navigate(['admin/certificates/cco']);
    } else if (this.departmentId == 2 && this.occupancyType == 1) {
      this.router.navigate(['admin/certificates/fire']);
    } else if (this.departmentId == 3 && this.occupancyType == 1) {
      this.router.navigate(['admin/certificates/assesor']);
    } else if (this.departmentId == 4 && this.occupancyType == 1) {
      this.router.navigate(['admin/certificates/taxes']);
    } else if (this.departmentId == 5 && this.occupancyType == 1) {
      this.router.navigate(['admin/certificates/zoning']);
    }
  }

  seeFilteredPayments(val) {
    this.bsRangeValue = [];
    this.from = null;
    this.to = null;
    if(val == 1) {
      this.paidApplications = 0;
      this.unpaidApplications = 0;
      this.filterOption = 1;
      this.paymentSummary({ page: 1 })
    } else if(val == 2) {
      this.paidApplications = 1;
      this.unpaidApplications = 0;
      this.filterOption = 2;
      this.paymentSummary({ page: 1, paid: 1 })
    } else if(val == 3) {
      this.paidApplications = 0;
      this.unpaidApplications = 1;
      this.filterOption = 3;
      this.paymentSummary({ page: 1, unpaid: 1 })
    }
  }

  getExportFile(format) {
    let body: any = {}
    body.format = format;
    body.department_id = this.departmentId;
    body.occupancy_type = this.occupancyType;
    body.page = this.type;
    body.search_query = this.searchQuery;
    body.from = this.from;
    body.to = this.to ;
    body.paid = this.paidApplications;
    body.unpaid =  this.unpaidApplications;
    this.certificateService.getExportPaymentSummaryFile(body);
  }

  openRecipet(url){
    window.open(url)
  }
}

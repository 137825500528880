import { Component, OnInit } from '@angular/core';
import { Certificates } from 'src/app/shared';
import { FormGroup, FormControl } from '@angular/forms';
import { CertificatesService } from 'src/app/core/http/admin/certificates.service';
import { settingConfig } from 'src/app/configs/settings.config';
import { DashboardService } from 'src/app/core/http';
import { AdminAuthenticationService } from 'src/app/core/authentication/admin-authentication.service';
import { Department } from '../../../../core/models/admin/department.model'
import { Router, ActivatedRoute } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import * as moment from 'moment';

@Component({
  selector: 'app-taxes',
  templateUrl: './taxes.component.html',
  styleUrls: ['./taxes.component.css']
})
export class TaxesComponent implements OnInit {
  public settings: any;
  public modify: any = {}
  public certificates: Certificates[] = [];
  public offset: any = 1;
  public applyFilter = []
  public type: number = Number(1);
  public currentPagination: number = Number(1);
  public totalPagination: number = Number(0);
  public searchQuery: string = '';
  public bsRangeValueInspected: Date[];
  public localstprageData: Department = {
    id: null,
    email: null,
    name: null,
    status: null,
  }
  public filterForm = new FormGroup({
    checkStatus: new FormControl()
  });

  public filtersApplied = 0;

  inspectorFilter = false;
  inspectorModalOpened = false;

  constructor(
    private certificateService: CertificatesService,
    private dashboardService: DashboardService,
    private router: Router,
    private route: ActivatedRoute,
    private adminAuthService: AdminAuthenticationService
  ) {
    this.settings = settingConfig;
  }
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'Deselect All',
    itemsShowLimit: 3,
    allowSearchFilter: true,

  };
  public ward = [];
  public applicationPaymentStatus: number;
  ngOnInit() {
    Object.keys(this.priorApproval).forEach(data => {
      if (this.priorApproval[data].isSelected) {
        this.modify[data]=1;
        this.applyallFilter()
      }
    })
    this.route.queryParams.subscribe(data => {
      this.modify = { ...data };
      if (Object.keys(this.modify).length > 0) {
        this.filter()
        this.checkpriorApprovalValue();
        this.checkInspectionValue();
        this.checkhValueStatus();

        this.applyallFilter()


      } else {
        this.getAllCertificate({ page: this.currentPagination });

      }
    })
    this.applicationPaymentStatus = this.settings.applicationpayment_Status;

    this.getRole();
    this.getLocalStorage();
    this.getLocalStorage1();
    this.getInspector();
    // this.settings.ward.map(data => {
    //   this.ward.push({ id: data.key, name: data.value })
    // })
    if (this.department.role_id != 5) {
      this.groupBy['inspector'] = {
        text: "Inspector",
        value: 0,
        isSelected: false
      }
      this.groupByFilter = Object.keys(this.groupBy);
    }
  }

  checkpriorApprovalValue() {

    Object.keys(this.modify).forEach(data => {
      Object.keys(this.priorApproval).forEach(checkValue => {
        if (data === checkValue) {
          this.priorApproval[checkValue].isSelected = true
          this.priorApproval[checkValue].value = 1
        }
      })


    })
  }

  checkhValueStatus() {
    Object.keys(this.modify).forEach(data => {
      Object.keys(this.filterObject).forEach(checkValue => {
        if (data === checkValue) {
          this.filterObject[checkValue].isSelected = true
          this.filterObject[checkValue].value = 1
        }
      })
    })
  }


  checkInspectionValue() {
    Object.keys(this.modify).forEach(data => {
      Object.keys(this.inspectionStatus).forEach(checkValue => {
        if (data === checkValue) {
          this.inspectionStatus[checkValue].isSelected = true
          this.inspectionStatus[checkValue].value = 1
        }
      })
    })
  }


  navigateByurl(value: string) {
    if (value == "assesor") {
      localStorage.setItem('department', JSON.stringify({ department_id: 3, department_name: 'assesor' }));
      this.router.navigate(['/admin/certificates/assesor'])
    } else if (value == "texes") {
      localStorage.setItem('department', JSON.stringify({ department_id: 4, department_name: 'taxes' }));
      this.router.navigate(['/admin/certificates/taxes'])

    } else if (value == "zoning") {
      localStorage.setItem('department', JSON.stringify({ department_id: 5, department_name: 'zoning' }));
      this.router.navigate(['/admin/certificates/zoning'])

    }
  }

  navigateOnDetailPage(certificates) {
    if (certificates.application_status != 5) {
      this.router.navigate(['/admin/certificates/details/', certificates.id], { queryParams: { ...this.modify } })
    }
  }

  private getAllCertificate(params: object): void {
    if (params['department'] != 4) {
      params['department'] = 4;
    }
    this.certificateService.getAllCertificates(params).subscribe(({ response, ...pagination }) => {
      this.certificates = response;
      this.totalPagination = pagination.total;
      this.offset = pagination.offset;
      this.currentPagination = pagination.currentPage;
    });
  }

  private filterCertificate(params: any): void {

    this.certificateService.filterCertificates(params).subscribe(({ response, ...pagination }) => {
      this.totalPagination = pagination.total;
      this.offset = pagination.offset;
      this.currentPagination = pagination.currentPage;
      this.certificates = response;
    });
  }
  public page: number = 1;
  paginate(value: any) {
    this.page = value
    //this.getAllCertificate({ page: value });
    if (this.isResult || this.modify['inspector_is_See_All'] == 1) {
      this.getAllCertificate({ department: 4, page: value, ...this.modify })
    }
    else if (this.modify['inspector_is_See_All'] != 1 && this.inspectorFilter) {
      this.getAllCertificate({ department: 4, page: value, ...this.modify, inspectorsId: JSON.stringify(this.selectInspectors) })
    }
    else if (!this.isResult && this.from && this.to) {
      this.getAllCertificate({ department: 4, page: value, from: this.from, to: this.to,is_submitted_date:1 })
    }

    else if (!this.isResult && Object.keys(this.modify).length > 0 ) {
      this.getAllCertificate({ department: 1, type: this.type, page: value, ...this.modify })
    }
    else if (Object.keys(!this.modify)) {
      this.getAllCertificate({ department: 4, page: value });

    }
  }

  searchCertificate() {
    if (this.searchQuery.length >= 3) {
      if (this.searchQuery.length >= 3) {
        if (this.isResult || this.modify['inspector_is_See_All'] == 1) {
          this.filterCertificate({ search_query: this.searchQuery, department: this.settings.taxesDepartmentId, ...this.modify, page: this.page });
        }
        else if (this.from && this.to) {
          if (this.modify['inspector_is_See_All'] == 1) {
            this.filterCertificate({ search_query: this.searchQuery, department: this.settings.taxesDepartmentId, from: this.from, to: this.to, page: this.page, ...this.modify });
          } else {
            this.filterCertificate({ search_query: this.searchQuery, department: this.settings.taxesDepartmentId, from: this.from, to: this.to, page: this.page });
          }
        } else {
          if (this.modify['inspector_is_See_All'] == 1) {
            this.filterCertificate({ search_query: this.searchQuery, department: this.settings.taxesDepartmentId, page: this.page, ...this.modify });
          } else {
            this.filterCertificate({ search_query: this.searchQuery, department: this.settings.taxesDepartmentId, page: this.page });
          }
        }
      }
    }
    if (this.searchQuery.length == 0) {
      if (this.isResult || this.modify['inspector_is_See_All'] == 1) {
        this.filterCertificate({ department: this.settings.taxesDepartmentId, ...this.modify, page: this.page })
      }
      else if (this.from && this.to) {
        if (this.modify['inspector_is_See_All'] == 1) {
          this.filterCertificate({ department: this.settings.taxesDepartmentId, from: this.from, to: this.to, page: this.page, ...this.modify })
        } else {
          this.filterCertificate({ department: this.settings.taxesDepartmentId, from: this.from, to: this.to, page: this.page });
        }
      } else {
        if(this.modify['inspector_is_See_All'] == 1) {
          this.filterCertificate({ department: this.settings.taxesDepartmentId, page: this.page, ...this.modify });
        } else {
          this.filterCertificate({ department: this.settings.taxesDepartmentId, page: this.page });
        }
      }
    }
  }

  // onSubmit() {
  //   const status = Number(this.filterForm.get('checkStatus').value);
  //   if (status >= 0 && status <= 5) {
  //     this.filterCertificate({
  //       checkStatus: status
  //     });
  //   }
  //   if (status === null || status === undefined) {
  //     this.paginate(this.currentPagination);
  //   }
  // }

  getCertifaicateFile(format: string) {
    let body: any = {}
    body.format = format;
    body.type = this.type;
    this.certificateService.getCertifaicateFile(body);
  }

  activateCurrentApplication(id: number, applicationStatus) {
    if (applicationStatus != 11) {
      const activateRequest = {
        application_id: id,
        application_status: 1,
        action: 'activate'
      };
      this.dashboardService.updateApplicationStatus(activateRequest).subscribe(data => {
        if (data) {
          this.getAllCertificate({ page: this.currentPagination,type:this.type });
          this.clearAll();
        }
      });
    } else {
      const payload = {
        application_id: id
      };
      this.dashboardService.activateApplicationByAdmin(payload).subscribe((data: any) => {
        if (data) {
          this.clearAll();
        }
      });
    }

  }

  roleId: number;
  getRole() {
    this.adminAuthService.getUserRole().subscribe(data => {
      this.roleId = data;
    })
  }

  public department = {
    role_id: null,
    department_id: null,
    id:null,
  }
  localstprageData1: any;
  getLocalStorage1() {
    const department = this.adminAuthService.getPermission()
    if (department && department.role_id != 1 && department.role_id != 2) {
      this.department = department
      this.localstprageData1 = department.department;
      this.getWards()

    } else {
      const localStorageData = this.adminAuthService.getSaveDepartment()
      const department = this.adminAuthService.getPermission()
      this.department = localStorageData
      this.department.role_id = department.role_id
      this.department.id = department.id

      this.localstprageData = localStorageData
      this.getWards()
    }
  }

  getWards() {
    this.certificateService.getFilterWard().subscribe(data => {
      this.ward = data['response']
      let i = 0
      this.ward.map(data => {
        this.ward.push({ id: i, name: data.ward })
        i = i + 1
      })
      this.ward = this.ward.filter(flt => {
        if (flt.id) {
          return flt
        }
      })
    })

  }

  getLocalStorage() {
    const department = this.adminAuthService.getPermission()
    this.localstprageData = department.department;
  }



  public inspectors = []
  getInspector() {
    const data = {
      department_id: Number(this.department.department_id)
    }
    this.dashboardService.getInspectors(data).subscribe(data => {
      this.inspectors = data.response;
    })
  }

  clearAll() {

    // this.modify = {}
    this.bsRangeValueInspected=[];
    this.filtersApplied = 0;
    for (let key in this.modify) {
      if (key != 'inspector_is_See_All') {
        delete this.modify[key];
      }
    }
    // this.applyFilter = Object.keys(this.modify);
    this.applyFilter = [];
    Object.keys(this.priorApproval).forEach((value) => {
      this.priorApproval[value].value = 0
      this.priorApproval[value].isSelected = false
    })
    Object.keys(this.inspectionStatus).forEach((value) => {
      this.inspectionStatus[value].isSelected = false
      this.inspectionStatus[value].value = 0
    })
    Object.keys(this.filterObject).forEach((value) => {
      this.filterObject[value].isSelected = false
      this.filterObject[value].value = 0
    })
    Object.keys(this.groupBy).forEach(value => {
      this.groupBy[value].isSelected = false
      this.groupBy[value].value = 0
    })
    this.router.navigate(['/admin/certificates/taxes'], { queryParams: { ...this.modify } })
    if(this.from && this.to ){
     this.from = null
     this.to = null
     this.getAllCertificate({ page: this.currentPagination, ...this.modify });
    }
    this.getAllCertificate({ page: this.currentPagination, ...this.modify });

  }

  public filterObject = {
    cancel: {
      text: "Cancel",
      value: 0,
      isSelected: false
    },
    inspected: {
      text: "Inspected",
      value: 0,
      isSelected: false
    },
    pendingInspection: {
      text: "Pending Inspection",
      value: 0,
      isSelected: false
    },
    illegalconversion: {
      text: "Illegal conversion",
      value: 0,
      isSelected: false
    },
    incomplete: {
      text: "Incomplete",
      value: 0,
      isSelected: false
    },
    issueTemporaryCertificate: {
      text: "Issue Temporary Certificate",
      value: 0,
      isSelected: false
    },
    issueCertificate: {
      text: "Issued Certificate",
      value: 0,
      isSelected: false
    },
    accepted: {
      text: "Accepted",
      value: 0,
      isSelected: false
    }
  }
  public filters: string[] = Object.keys(this.filterObject);

  public inspectionStatus = {
    outstadningBalance: {
      text: "Outstanding Balance	",
      value: 0,
      isSelected: false
    },
    current: {
      text: "Current",
      value: 0,
      isSelected: false
    },

  }

  public inspection: string[] = Object.keys(this.inspectionStatus);


  housingInspectionStatus(check, event) {
    console.log(event.target.checked)
    this.inspectionStatus[check].value = event.target.checked ? 1 : 0;
    // this.modify = {};
    Object.keys(this.inspectionStatus).forEach((value) => {
      if (this.inspectionStatus[value].value === 1) {
        this.isResult = true,
          this.modify[value] = this.inspectionStatus[value].value;
        this.inspectionStatus[value].isSelected = true
      }
      else {
        Object.keys(this.modify).forEach((objvalue, i) => {
          if (value == objvalue) {
            delete this.modify[objvalue]

          }

        })
      }
      if (this.from && this.to) {
        Object.keys(this.dateSet).forEach((date) => {
          console.log(this.dateSet[date], '**********')
          this.modify[date] = this.dateSet[date]
          this.modify[date] = this.dateSet[date]
        })
      }
    })
   // this.sendValueInurl()
  }

  sendValueInurl() {
    this.router.navigate(['/admin/certificates/taxes'], { queryParams: { ...this.modify } })
    this.applyallFilter()
    if (this.dateKey == 'sub') {
      this.filterDateAndInspection()
    }
  }

  applyallFilter() {

    this.applyFilter = Object.keys(this.modify);
    this.filtersApplied = this.modify['inspector_is_See_All'] == 1 ? this.applyFilter.length - 1 : this.applyFilter.length;
    if (this.modify['from'] && this.modify['from']) {
      this.applyFilter = this.applyFilter.filter(count => {
        if (count != "from" && count !=  "to") {
          return count
        }

      })
      console.log(this.applyFilter.length)
    }

  }
  public groupBy = {
    ward: {
      text: "Ward",
      value: 0,
      isSelected: false
    },
    // inspector: {
    //   text: "Inspector",
    //   value: 0,
    //   isSelected: false
    // },
  }

  public groupByFilter: string[] = Object.keys(this.groupBy);

  public priorApproval = {
    draft: {
      text: "Draft",
      value: 0,
      isSelected: false
    },
    complete: {
      text: "Complete",
      value: 0,
      isSelected: false
    },
    submitted: {
      text: "Submitted",
      value: 0,
      isSelected: false
    },
    withdrawn: {
      text: "Withdrawn",
      value: 0,
      isSelected: false
    },
    // payment_status_pending: {
    //   text: "Payment pending",
    //   value: 0,
    //   isSelected: false
    // },
  }

  public priorApprovals: string[] = Object.keys(this.priorApproval);


  sendRequestForPariorApproval(check, event) {
    if (check == "submitted" && !event.target.checked) {
      this.priorApproval.submitted.isSelected = event.target.checked
    }
    console.log(event.target.checked)
    this.priorApproval[check].value = event.target.checked ? 1 : 0;
    //this.modify = {};
    Object.keys(this.priorApproval).forEach((value) => {
      if (this.priorApproval[value].value === 1) {
        this.isResult = true,
          this.modify[value] = this.priorApproval[value].value;
        this.priorApproval[value].isSelected = true
      }
      else {
        Object.keys(this.modify).forEach((objvalue, i) => {
          if (value == objvalue) {
            delete this.modify[objvalue]

          }

        })
      }
      if (this.from && this.to) {
        Object.keys(this.dateSet).forEach((date) => {
          console.log(this.dateSet[date], '**********')
          this.modify[date] = this.dateSet[date]
          this.modify[date] = this.dateSet[date]
        })
      }
    })
    //this.sendValueInurl()

  }

  public isResult = false
  sendRequest(check, event) {
    console.log(event.target.checked)
    this.filterObject[check].value = event.target.checked ? 1 : 0;
    //this.modify = {};
    Object.keys(this.filterObject).forEach((value) => {
      if (this.filterObject[value].value === 1) {
        this.isResult = true
        this.modify[value] = this.filterObject[value].value;
        this.filterObject[value].isSelected = true
      }
      else {
        Object.keys(this.modify).forEach((objvalue, i) => {
          if (value == objvalue) {
            delete this.modify[objvalue]

          }

        })
      }
    })
    if (this.from && this.to) {
      Object.keys(this.dateSet).forEach((date) => {
        console.log(this.dateSet[date], '**********')
        this.modify[date] = this.dateSet[date]
        this.modify[date] = this.dateSet[date]
      })
    }
    //this.sendValueInurl()
  }

  public selectInspectors = [];
  public selectWard = []
  selectInspector(selectedItems: any[], seelctValue,valueType) {
    if (seelctValue == 'inspector') {
      if(valueType == 'all'){
        this.selectInspectors = selectedItems;
        this.groupBy[seelctValue].isSelected = true;
      }else{
        this.selectInspectors.push(selectedItems);
        this.groupBy[seelctValue].isSelected = true
      }
    }
    else if (seelctValue == 'ward' ) {
      if(valueType == 'all'){
        this.selectWard = selectedItems;
        this.groupBy[seelctValue].isSelected = true;
      }else{
        this.selectWard.push(selectedItems);
        this.groupBy[seelctValue].isSelected = true
      }
    
    }

  }

  deSelect(value, seelctValue) {
    debugger
    if (seelctValue == 'inspector') {
      if(this.selectInspectors){
        this.selectInspectors.map((data, i) => {
          if (value.id == data.id) {
            this.selectInspectors.splice(i, 1)
          }
        })
      }
      if (this.selectInspectors.length == 0 || value.length == 0) {
        this.groupBy[seelctValue].isSelected = false;
        this.selectInspectors = [];
      }
    } else if (seelctValue == 'ward') {
      if(this.selectWard){
        this.selectWard.map((data, i) => {
          console.log("data is ===>",data,value)
          if (value.id == data.id) {
            this.selectWard.splice(i, 1)
          }
        })
      }
      if (this.selectWard.length == 0 || value.length == 0) {
        this.groupBy[seelctValue].isSelected = false;
        this.selectWard = [];
      }
    }

  }

  filter(val = null) {
    // if (this.priorApproval.submitted.isSelected) {
    //   this.modify['submitted'] = 1
    // }
    let selectWard = this.selectWard.map(item=>item.name)
    if(selectWard.length > 0){
      this.modify.ward = JSON.stringify(selectWard)
    }else{
      delete this.modify.ward;
    }
    
    
    let selectInspectors = this.selectInspectors.map(item=>item.id)
    if(selectInspectors.length > 0){
      this.modify.inspectorsId = JSON.stringify(selectInspectors)
    }else{
      delete this.modify.inspectorsId;
    }
    if (this.selectInspectors.length > 0 && this.selectWard.length > 0 && (val == null || this.groupByFilter.length > 1)) {
      this.applicationFilter({ department: this.department.department_id, ...this.modify, inspectorsId: JSON.stringify(selectInspectors), ward: JSON.stringify(selectWard), })
    } else if (this.selectInspectors.length > 0 && val == 3) {
      if (this.inspectorModalOpened) {
        delete this.modify["inspector_is_See_All"];
        this.inspectorFilter = true;
        this.applicationFilter({ department: this.department.department_id, ...this.modify, inspectorsId: JSON.stringify(selectInspectors) })
      } else if (this.groupByFilter.length > 1) {
        this.applicationFilter({ department: this.department.department_id, ...this.modify, inspectorsId: JSON.stringify(selectInspectors) })
      }
    } else if (this.selectInspectors.length == 0 && this.selectWard.length > 0 && val == null)
      this.applicationFilter({ department: this.department.department_id, ...this.modify, ward: JSON.stringify(selectWard), })
    else {
      this.applicationFilter({ department: this.department.department_id, ...this.modify, })
    }
  }

  inspectorSelect(check, event) {
    this.groupBy[check].value = event.target.checked ? 1 : 0;
    Object.keys(this.groupBy).forEach(value => {
      if (this.groupBy[value].value === 1) {

        this.groupBy[value].isSelected = true
      }
    })
  }

  preventDeafultChecked(event){
    event.preventDefault();
  }

  clearAllFilter() {
    this.modify = {}
    Object.keys(this.filterObject).forEach((value) => {
      this.filterObject[value].isSelected = false;
      this.filterObject[value].value = 0;
      this.isResult = false;
      this.getAllCertificate({ page: this.currentPagination });
    })
    Object.keys(this.priorApproval).forEach((value1) => {
      this.priorApproval[value1].isSelected = false;
      this.priorApproval[value1].value = 0
    })
    Object.keys(this.inspectionStatus).forEach((value1) => {
      this.inspectionStatus[value1].isSelected = false;
      this.inspectionStatus[value1].value = 0
    })

    Object.keys(this.groupBy).forEach((value1) => {
      this.groupBy[value1].isSelected = false;
      this.groupBy[value1].value = 0
      this.selectInspectors = [];
    })
    this.applyallFilter();
    this.router.navigate(['/admin/certificates/taxes'])

  }

  applicationFilter(params: object) {
    this.certificateService.getAllCertificates(params).subscribe(({ response, ...pagination }) => {
      this.certificates = response;
      this.totalPagination = pagination.total;
      this.offset = pagination.offset;
      this.currentPagination = pagination.currentPage;
    })
  }

  public from: any;
  public to: any;
  public dateSet = {
    from: null,
    to: null,
  }
  public dateKey:string
  selectDate(event,value) {
    if (event) {
      this.from = moment(event[0]).format("YYYY-MM-DDT00:00:00.000") + "Z";
      this.to = moment(event[1]).format("YYYY-MM-DDT00:00:00.000") + "Z";
      if (this.from && this.to) {
        this.dateSet.from = this.from,
          this.dateSet.to = this.to
          this.dateKey = value
        //this.filterDateAndInspection()

      }
    }
  }

  filterDateAndInspection() {
    if (this.isResult) {
      if (this.from && this.to) {
        Object.keys(this.dateSet).forEach((date, value) => {
          console.log(this.dateSet[date], '**********')
          this.modify[date] = this.dateSet[date]
          this.modify[date] = this.dateSet[date]
        })
      }
      this.searchBydate({ department: this.settings.taxesDepartmentId, ...this.modify, page: this.page })
    }
    else {
      this.searchBydate({ department: this.settings.taxesDepartmentId, from: this.from, to: this.to, page: this.page,is_submitted_date:1 })
    }
  }

  searchBydate(params: object) {

    this.certificateService.filterCertificates(params).subscribe(({ response, ...pagination }) => {
      this.certificates = response;
      this.totalPagination = pagination.total;
      this.offset = pagination.offset;
      this.currentPagination = pagination.currentPage;
    });
  }

  navigateOnTimeLinePage(applicationId) {
    this.router.navigate(['/admin/certificates/time-line'], { queryParams: { applicationId: applicationId } })
  }
  public insdateSet = {
    inspectionfrom: null,
    inspectionto: null,
  }
  public schdateSet = {
    schedule_date_from: null,
    schedule_date_to: null,
  }
  inspectionfrom: Date
  inspectionto: Date

  schedule_date_from: Date
  schedule_date_to: Date

  selectInspectionDate(event, isScheduled) {
    if (event && !isScheduled) {
      this.inspectionfrom = event[0].toISOString()
      this.inspectionto = event[1].toISOString()
      if (this.inspectionfrom && this.inspectionto) {
        this.insdateSet.inspectionfrom = this.inspectionfrom,
          this.insdateSet.inspectionto = this.inspectionto
        //this.dateKey = value
      }
      if (this.inspectionfrom && this.inspectionto) {
        Object.keys(this.insdateSet).forEach((date) => {
          console.log(this.insdateSet[date], '**********')
          this.modify[date] = this.insdateSet[date]
        })
      }

      if (this.schedule_date_from && this.schedule_date_to) {
        Object.keys(this.schdateSet).forEach((date) => {
          console.log(this.schdateSet[date], '**********')
          delete this.modify[date];
        })
      }
    } else if (event && isScheduled) {
      this.schedule_date_from = event[0].toISOString()
      this.schedule_date_to = event[1].toISOString()
      if (this.schedule_date_from && this.schedule_date_to) {
        this.schdateSet.schedule_date_from = this.schedule_date_from,
          this.schdateSet.schedule_date_to = this.schedule_date_to
        //this.dateKey = value
      }
      if (this.schedule_date_from && this.schedule_date_to) {
        Object.keys(this.schdateSet).forEach((date) => {
          console.log(this.schdateSet[date], '**********')
          this.modify[date] = this.schdateSet[date];
          this.isResult = true
        })
      }
      if (this.inspectionfrom && this.inspectionto) {
        Object.keys(this.insdateSet).forEach((date) => {
          console.log(this.insdateSet[date], '**********')
          delete this.modify[date] ;
          this.isResult = true
        })
      }
    }
  }

  public isScheduled = false;

  checkInspectedOrScheduledFilter(val){
    if (val == 1) {
      this.isScheduled = true;
    } else if (val == 2) {
      this.isScheduled = false;
    }
  }

  seeAllApplication(val) {
    this.inspectorModalOpened = false;
    this.inspectorFilter = false;
    // this.inspectors = [];
    // this.selectInspectors = [];
    // this.selectInspectors.map((data, i) => {
    //   this.selectInspectors.splice(i, 1)
    // })
    if (val == 1) {
      delete this.modify["inspector_is_See_All"];
      this.filter(val);
    } else if (val == 2) {
      this.modify['inspector_is_See_All'] = 1;
      this.filter(val);
    }
  }

  applyInspectorFilter() {
    this.inspectorModalOpened = true;
  }

  goToPaymentsSummary(){
    this.router.navigate(['admin/certificates/paymetsSummary'],  { queryParams: { taxes: true, department_id: 4, occupancy_type: 1} })
  }

  applyTodayAndTommarowfilter(value:string){
    if(value == 'isRequestedDateToday'){
      this.applicationFilter({ department: 4, isRequestedDateToday:1 })

    }
    else if(value == 'isRequestedDateTomorrow'){
      this.applicationFilter({ department: 4, isRequestedDateTomorrow:1 })

    }
    else if (value == 'isRequestedDateTodayInspected') {
      if (!this.isScheduled) {
        this.applicationFilter({ department: 4, inspected_days: 1 })
      } else {
        this.applicationFilter({ department: 4, scheduled_days: 1 })
      }
    }
    else if (value == 'isRequestedDateNext7Days') {
      if (!this.isScheduled) {
        this.applicationFilter({ department: 4, inspected_days: 2 })
      } else {
        this.applicationFilter({ department: 4, scheduled_days: 2 })
      }
    }
    else if (value == 'isRequestedDateNext10Days') {
      if (!this.isScheduled) {
        this.applicationFilter({ department: 4, inspected_days: 3 })
      } else {
        this.applicationFilter({ department: 4, scheduled_days: 3 })
      }
    }
  }
  selectedItems: any[] = [];
  selectedItemsWard: any[] = [];
  resetFilter(seelctValue){
    this.selectedItems = [];
    if(seelctValue == 'inspector'){
      this.groupBy[seelctValue].isSelected = false;
      this.selectInspectors = [];
    }else if(seelctValue == 'ward'){
      this.groupBy[seelctValue].isSelected = false;
      this.selectWard = [];
      this.selectedItemsWard = []
    }else if(seelctValue == 'all'){
      this.selectWard = [];
      this.selectInspectors = [];
      this.selectedItemsWard = []
    }

 }
 resetSearchFilter(){
  this.searchQuery = '';
 }
}

import { ToastrService } from 'ngx-toastr';
import { Inspector } from './../../../../../core/models/';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardService } from './../../../../../core/http/admin/dashboard.service';
import { Certificates, AddressSearch } from 'src/app/shared/index';
import { Addresses } from 'src/app/shared/index';
import { appToaster, settingConfig } from 'src/app/configs';
import { ApplicationService } from 'src/app/core/http';
import { environment } from 'src/environments/environment';
import { FormGroup, FormControl, Validators, FormControlName, FormBuilder } from '@angular/forms';
import * as moment from 'moment';
import { AdminAuthenticationService } from 'src/app/core/authentication/admin-authentication.service';

@Component({
  selector: 'app-certificate-detail',
  templateUrl: './certificate-detail.component.html',
  styleUrls: ['./certificate-detail.component.css']
})
export class CertificateDetailComponent implements OnInit {
  public updateId: number;
  public EMAIL_REGEX = '[a-z0-9!#$%&\'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*';

  public currentAdminRole: number;
  public hideForm: boolean;
  public isSubmit = false;
  public showDoublePopups = false;
  public showInspection = true;
  public showViolations = false;
  public showAddInspectionForm = false;
  public showAddViolationForm = false;
  public showOrdinancePopup = false;
  public showOwnerBusinessFile = false;
  public showOwnerBankFile = false;
  public showCancelInspection = false;
  public showScheduleReInspection = false;

  public isEmail: boolean = true;
  public isNotes: boolean = false;
  public isPickUp: boolean = false;

  public scheduleInspectionForm: FormGroup;
  public cancelRequestForm: FormGroup;
  public transferApplicationForm: FormGroup;
  public locationForm: FormGroup;
  public updateWardForm: FormGroup;
  public contactPersonForm: FormGroup;
  public applicantForm: FormGroup;
  public ownerForm: FormGroup;
  public currentUseForm: FormGroup;
  public editManualLocationForm: FormGroup;
  public addInspectionForm: FormGroup;
  public violationForm: FormGroup;
  public submissionReview: FormGroup;
  public firstPaymentForm: FormGroup;
  public emailForm: FormGroup;
  public pickUpForm: FormGroup
  // tslint:disable-next-line: max-line-length
  public streetNameIndex = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  public searchQuery: AddressSearch = {
    query: null,
    search: 1
  };
  public showSomeOne = false;
  public showOwnerBuisnessField = false;
  public editAddress: Array<Addresses>;
  public settings: any;
  public routeId: number;
  public showApplicant: boolean = true;
  public showCurrentOwner: boolean = false;
  public showBuyer: boolean = false;
  public showDescription: boolean = false;

  public ApplicantFile: string = null;
  public BuyerApplicantFile: string = null;
  public powerOfAttorneyFile: string = null;
  public ownerCorporateFormationFile: string = null;

  public currentPagination: number = Number(1);
  public totalPagination: number = Number(0);
  public offset: any = 1;
  public ordinance: [{}];

  public env: any;
  public certificateDetail: Certificates = {
    id: null,
    address: null,
    ward: null,
    applicant_address: null,
    applicant_email: null,
    applicant_name: null,
    applicant_phone: null,

    owner_name: null,
    owner_email: null,
    owner_phone: null,
    owner_address: null,
    owner_type: null,

    buyer_address: null,
    buyer_business: null,
    buyer_business_type: null,
    buyer_email: null,
    buyer_id: null,
    buyer_name: null,
    buyer_phone: null,
    buyer_type: null,

    is_attic_finished: null,
    is_basement_finished: null,
    no_of_bathrooms: null,
    no_of_bedrooms: null,
    no_of_commercial_units: null,
    no_of_kitchens: null,
    no_of_residential_units: null,
    point_of_contact: null,
  };

  public inspectors: Array<Inspector> = [];
  public assignedInspectorId: number;
  public scheduleInspector: {
    inspection_date: null,
    inspection_range: null,
    updatedAt: null,
    admin: {
      id: null,
      name: null,
      email: null
    }
  };
  public changeSubmissionReview = {
    decision_type: null,
    expiration_date: null
  };

  public addresses: Addresses = {
    ward: null,
    sbl: null,
    block: null,
    lot: null,
    primary_owner: null,
    street: null,
    city: null,
    state: null,
    land: null,
    property_class: null,
    google_address:null,

  };
  public locationAddressForStreet: Array<Addresses> = [{
    street: null,
    sbl: null,
    primary_owner: null,
    address: null,
    city: null,
    id: null,
    google_address:null,

  }];
  public addressOfDoublePopups: Array<Addresses>;
  public showHideManualLocationForm = false;
  public displayLocationForm = true;
  public selectedAddressId = null;
  public applicantAttachment: [{}] = [{
    attachment: null,
    id: null,
    application_id: null
  }];
  public showAllInspection: [{}];
  public AllViolations: [{}];
  public ordinanceId: number;

  public allTransaction: [{}];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dashboardService: DashboardService,
    private applicationService: ApplicationService,
    private toastr: ToastrService,
    private adminAuthService: AdminAuthenticationService
    //private formBuilder: FormBuilder
  ) {
    this.settings = settingConfig;
  }

  ngOnInit() {
    this.env = environment;
    this.routeId = Number(this.route.snapshot.paramMap.get('id'));
    this.dashboardService.getCertificateDetail(this.routeId).subscribe(data => {

      if (data.status === 'success') {
        this.certificateDetail = data.response;
        this.addresses = data.response.addresses;
        this.inspectors = data.response.inspectors;
        this.applicantAttachment = data.response.applicantAttachment;
        this.scheduleInspector = data.response.inspectionSchedule;
        this.showAllInspection = data.response.showAllInspection;
        this.AllViolations = data.response.allViolation;
        this.changeSubmissionReview = data.response.submissionReview;
        this.allTransaction = data.response.allTransaction;
        this.showOwnerBusinessFile = this.certificateDetail.owner_type === 2 ? true : false;
        this.showOwnerBankFile = this.certificateDetail.owner_type === 3 ? true : false;
        if (data.response.applicant_photo_id && data.response.applicant_photo_id != null) {
          this.ApplicantFile = data.response.applicant_photo_id;
        }
        if (data.response.buyer_id && data.response.buyer_id != null) {
          this.BuyerApplicantFile = data.response.buyer_id;
        }
        if (data.response.corporate_formation && data.response.corporate_formation != null) {
          this.ownerCorporateFormationFile = data.response.corporate_formation;
        }
        if (data.response.power_of_attorney && data.response.power_of_attorney) {
          this.powerOfAttorneyFile = data.response.power_of_attorney;
        }
      }
    });

    this.currentAdminRole = JSON.parse(localStorage.getItem("currentAdmin")).role_id;
    this.initForms();
    this.getPermission()
  }

  deleteDashboardMedia(Id: number, file: number) {
    let query = {
      id: Id,
      fileNo: file
    };
    this.dashboardService.deleteDashboardMedia(query).subscribe(data => {
      if (data.status === 'success') {
        if (query.fileNo === 1) {
          this.ApplicantFile = null;
        }
        if (query.fileNo === 2) {
          this.powerOfAttorneyFile = null;
        }
        if (query.fileNo === 3) {
          this.ownerCorporateFormationFile = null;
        }
        if (query.fileNo === 4) {
          this.BuyerApplicantFile = null;
        }
      }
    });
  }

  mediaUpload(event, name) {
    const media = {
      id: this.certificateDetail.id,
      name,
      value: event.target.files[0]
    };
    this.dashboardService.postDashboardMedia(media).subscribe(data => {
      if (data.status === 'success') {
        if (name === 'applicant_photo_id') {
          this.ApplicantFile = data.response.applicant_photo_id;
        }
        if (name === 'power_of_attorney') {
          this.powerOfAttorneyFile = data.response.power_of_attorney;
        }
        if (name === 'corporate_formation') {
          this.ownerCorporateFormationFile = data.response.corporate_formation;
        }
        if (name === 'buyer_id') {
          this.BuyerApplicantFile = data.response.buyer_id;
        }
      }
    });
  }

  showAndHideWhomForm(formName: string) {
    if (formName === 'applicant') {
      this.showApplicant = true;
      this.showCurrentOwner = false;
      this.showBuyer = false;
      this.showDescription = false;
    }
    if (formName === 'currentOwner') {
      this.showApplicant = false;
      this.showCurrentOwner = true;
      this.showBuyer = false;
      this.showDescription = false;
    }
    if (formName === 'buyer') {
      this.showApplicant = false;
      this.showCurrentOwner = false;
      this.showBuyer = true;
      this.showDescription = false;
    }
    if (formName === 'description') {
      this.showApplicant = false;
      this.showCurrentOwner = false;
      this.showBuyer = false;
      this.showDescription = true;
    }
  }

  // assignInsepctor(): boolean {
  //   this.hideForm = true;
  //   const inspector = {
  //     staff_id: this.assignedInspectorId,
  //     application_id: this.certificateDetail.id,
  //     status: 1
  //   };
  //   this.dashboardService.assignInspector(inspector).subscribe(data => {
  //     console.log(data, 'after inspector assigned');
  //   });
  //   if (!this.assignedInspectorId) {
  //     this.toastr.error("Please choose inspector");
  //     return false;
  //   }
  // }
  private initForms() {
    this.scheduleInspection();
    this.cancelRequest();
    this.transferApplication();
    this.editLocation();
    this.manualLocationForm();
    this.updateWard();
    this.editpointOfContact();
    this.editApplicant();
    this.editOwner();
    this.editCurrentUse();
    this.addNewInspection();
    this.addNewViolation();
    this.addsubmissionReview();
    this.addFirstPaymentDetail();
    this.sendEmail();
    this.pickUp();
  }

  // Schedule Inspection Start... //
  private scheduleInspection() {
    this.scheduleInspectionForm = new FormGroup({
      inspectionDate: new FormControl('', [Validators.required]),
      inspectionTimeRange: new FormControl('', [Validators.required]),
      inspectionDescription: new FormControl('', [Validators.required, Validators.minLength(10)]),
      inspectionEmailTo: new FormControl('', [Validators.required, Validators.email]),
      inspectionEmailCC: new FormControl(),
      assignedInspectorId: new FormControl('', [Validators.required])
    });
  }



  submitInspectionForm() {
    this.isSubmit = true;
    const inpectionData = {
      inspection_date: moment(this.scheduleInspectionForm.get('inspectionDate').value).format('YYYY/MM/DD'),
      inspection_range: this.scheduleInspectionForm.get('inspectionTimeRange').value,
      inspection_description: this.scheduleInspectionForm.get('inspectionDescription').value,
      inspection_email_to: this.scheduleInspectionForm.get('inspectionEmailTo').value,
      inspection_email_cc: this.scheduleInspectionForm.get('inspectionEmailCC').value,
      staff_id: this.scheduleInspectionForm.get('assignedInspectorId').value,
      application_id: this.certificateDetail.id,
      inspection_status: 1,  // initialized
      status: 1  // Application Assign Status
    };
    if (!this.scheduleInspectionForm.invalid) {
      console.log(inpectionData, "INSPECTION DATA");
      this.dashboardService.scheduleInspection(inpectionData).subscribe(data => {
        this.isSubmit = false;
        this.scheduleInspectionForm.reset();
      });
    }
  }
  // Schedule Inspection ends... //

  // Cancel Request starts... //
  private cancelRequest() {
    this.cancelRequestForm = new FormGroup({
      cancelReason: new FormControl('', [Validators.required, Validators.minLength(10)])
    });
  }

  submitCancelRequest() {
    this.isSubmit = true;
    this.hideForm = true;
    const cancelRequestData = {
      request_cancel_reason: this.cancelRequestForm.get('cancelReason').value,
      application_id: this.certificateDetail.id,
      application_status: 5,
      action: 'cancel'
    };
    this.dashboardService.updateApplicationStatus(cancelRequestData).subscribe(data => {
      this.isSubmit = false;
      if (data) {
        this.router.navigate(['/admin/certificates/housing']);
      }
    });
  }
  // Cancel Request ends... //

  // Transfer Application Starts...//
  private transferApplication() {
    this.transferApplicationForm = new FormGroup({
      ownershipEmail: new FormControl(),
      ownershipName: new FormControl(),
      ownershipPhone: new FormControl(),
      ownershipAddress: new FormControl()
    });
  }

  submitTransferApplicationForm() { // later work after requirement decided
    const transferApplicationData = {

    };
  }
  // Transfer Application ends...//

  // Edit Location Starts...//
  DisplayManualLocationForm() {
    this.showHideManualLocationForm = true;
    this.displayLocationForm = false;
  }
  hideManualLocationForm() {
    this.showHideManualLocationForm = false;
    this.displayLocationForm = true;
  }
  private editLocation() {
    this.locationForm = new FormGroup({
      streetIndex: new FormControl(),
      streetName: new FormControl(),
      unitNumber: new FormControl(),
      address: new FormControl('', [Validators.required]),
      sblNumber: new FormControl('', [Validators.required])
    });
  }

  searchAddressByStreetIndex() {
    this.searchQuery.query = this.locationForm.get('streetIndex').value;
    this.editAddress = null;
    this.dashboardService.searchAddressByStreetIndex(this.searchQuery).subscribe(data => {
      this.locationAddressForStreet = data;
      console.log(data, 'RETURNED ADDRESS');
    });
  }


  selectAddressFromStreet() {
    this.showDoublePopups = true;
    this.addressOfDoublePopups = this.locationAddressForStreet.map(elem => {
      if (elem.id == this.locationForm.get('streetName').value) {
        return elem;
      }
    });
  }
  selectedAddressFromPopup(item) {
    this.showDoublePopups = false;
    this.hideForm = true;
    this.locationForm.patchValue({
      sblNumber: item.sbl,
      address: item.address
    });
    this.selectedAddressId = item.id;
  }

  submitEditLocationForm() {
    this.isSubmit = true;
    const toUpdateData = {
      modal: "location",
      address_id: this.selectedAddressId,
      application_id: this.certificateDetail.id
    };
    if (!this.locationForm.invalid) {
      this.hideForm = true;
      this.updateApplicantDetail(toUpdateData);
      this.isSubmit = false;
      this.locationForm.reset();
    }
  }

  private manualLocationForm() {
    this.editManualLocationForm = new FormGroup({
      manualAddress: new FormControl('', [Validators.required]),
      manualUnitNumber: new FormControl('', [Validators.required])
    });
  }

  submitManualLocationForm() {
    this.isSubmit = true;
    const toUpdateData = {
      modal: 'manualLocation',
      address: this.editManualLocationForm.get('manualAddress').value,
      unit: this.editManualLocationForm.get('manualUnitNumber').value,
      application_id: this.certificateDetail.id
    };
    if (!this.editManualLocationForm.invalid) {
      this.hideForm = true;
      this.updateApplicantDetail(toUpdateData);
      this.isSubmit = false;
      this.editManualLocationForm.reset();
    }
  }
  // Edit Location Starts...//

  // Edit Ward starts...//
  prefillWardForm() {
    this.updateWardForm.setValue({
      ward: this.certificateDetail.ward
    });
  }
  private updateWard() {
    this.updateWardForm = new FormGroup({
      ward: new FormControl('', [Validators.required])
    });
  }

  submitWardForm() {
    this.isSubmit = true;
    const toUpdateData = {
      modal: 'ward',
      ward: this.updateWardForm.get('ward').value,
      application_id: this.certificateDetail.id,
    };
    if (!this.updateWardForm.invalid) {
      this.hideForm = true;
      this.updateApplicantDetail(toUpdateData);
      this.isSubmit = false;
      this.updateWardForm.reset();
    }
  }
  // Edit Ward starts...//

  // Edit Point Of Contact starts...//
  prefillPointOfContactForm() {
    this.contactPersonForm.setValue({
      point_of_contact: this.certificateDetail.point_of_contact,
      contact_person_name: this.certificateDetail.contact_person_name,
      contact_person_phone: this.certificateDetail.contact_person_phone
    });
  }
  private editpointOfContact() {
    this.contactPersonForm = new FormGroup({
      point_of_contact: new FormControl('', [Validators.required]),
      contact_person_name: new FormControl(),
      contact_person_phone: new FormControl(),
    });
  }

  showSomeOneElseField(event: any) {
    if (event.target.value == 3) {
      this.showSomeOne = true;
      this.contactPersonForm.get('contact_person_name').setValidators([Validators.required]);
      this.contactPersonForm.get('contact_person_phone').setValidators([Validators.required]);
    }
    if (event.target.value != 3) {
      this.showSomeOne = false;
      this.contactPersonForm.get('contact_person_name').clearValidators();
      this.contactPersonForm.get('contact_person_phone').clearValidators();
    }
  }

  submitContactPersonForm() {
    this.isSubmit = true;
    const toUpdateData = {
      modal: 'pointOfContact',
      point_of_contact: this.contactPersonForm.get('point_of_contact').value,
      contact_person_name: this.contactPersonForm.get('contact_person_name').value,
      contact_person_phone: this.contactPersonForm.get('contact_person_phone').value,
      application_id: this.certificateDetail.id
    };
    if (!this.contactPersonForm.invalid) {
      this.hideForm = true;
      this.updateApplicantDetail(toUpdateData);
      this.isSubmit = false;
      this.contactPersonForm.reset();
    }
  }
  // Edit Point Of Contact ends...//

  // Show Owner Buisness starts...//
  fillCurrentOwner() {
    this.ownerForm.setValue({
      owner_type: this.certificateDetail.owner_type,
      owner_name: this.certificateDetail.owner_name,
      owner_phone: this.certificateDetail.owner_phone,
      owner_address: this.certificateDetail.owner_address,
      owner_email: this.certificateDetail.owner_email,
      owner_business: this.certificateDetail.owner_business
    });
  }
  private editOwner() {
    this.ownerForm = new FormGroup({
      owner_type: new FormControl('', [Validators.required]),
      owner_name: new FormControl('', [Validators.required]),
      owner_phone: new FormControl('', [Validators.required]),
      owner_address: new FormControl('', [Validators.required]),
      owner_email: new FormControl('', [Validators.required]),
      owner_business: new FormControl()
    });
  }

  showOwnerBuisness(event: any) {
    if (event.target.value == 2 || event.target.value == 3) {
      this.showOwnerBuisnessField = true;
      this.ownerForm.get('owner_business').setValidators([Validators.required]);
    } else {
      this.showOwnerBuisnessField = false;
      this.ownerForm.get('owner_business').clearValidators();
    }
  }

  submitOwnerForm() {
    this.isSubmit = true;
    const toUpdateData = {
      modal: 'owner',
      owner_type: parseInt(this.ownerForm.get('owner_type').value),
      owner_name: this.ownerForm.get('owner_name').value,
      owner_phone: this.ownerForm.get('owner_phone').value,
      owner_address: this.ownerForm.get('owner_address').value,
      owner_email: this.ownerForm.get('owner_email').value,
      owner_business: this.ownerForm.get('owner_business').value,
      application_id: this.certificateDetail.id
    };
    if (this.ownerForm.invalid) {
      this.hideForm = false;
    }
    if (!this.ownerForm.invalid) {
      this.hideForm = true;
      this.updateApplicantDetail(toUpdateData);
      this.isSubmit = false;
      this.ownerForm.reset();
    }
  }
  // Show Owner Buisness ends...//

  // Edit Applicant starts...//
  fillApplicantionData() {
    this.applicantForm.setValue({
      applicant_name: this.certificateDetail.applicant_name,
      applicant_phone: this.certificateDetail.applicant_phone,
      applicant_email: this.certificateDetail.applicant_email,
      applicant_address: this.certificateDetail.applicant_address
    });
  }
  private editApplicant() {
    this.applicantForm = new FormGroup({
      applicant_name: new FormControl('', [Validators.required]),
      applicant_phone: new FormControl('', [Validators.required]),
      applicant_email: new FormControl('', [Validators.required]),
      applicant_address: new FormControl('', [Validators.required])
    });
  }

  submitApplicantForm() {
    this.isSubmit = true;
    const toUpdateData = {
      modal: 'applicant',
      applicant_name: this.applicantForm.get('applicant_name').value,
      applicant_phone: this.applicantForm.get('applicant_phone').value,
      applicant_email: this.applicantForm.get('applicant_email').value,
      applicant_address: this.applicantForm.get('applicant_address').value,
      application_id: this.certificateDetail.id
    };
    if (this.applicantForm.invalid) {
      this.hideForm = false;
    }
    if (!this.applicantForm.invalid) {
      this.updateApplicantDetail(toUpdateData);
      this.isSubmit = false;
      this.hideForm = true;
      this.applicantForm.reset();
    }
  }
  // Edit Applicant ends...//

  // Current Use starts...//
  prefillCurrentUse() {
    this.currentUseForm.setValue({
      current_use: this.certificateDetail.current_use
    });
  }
  private editCurrentUse() {
    this.currentUseForm = new FormGroup({
      current_use: new FormControl('', [Validators.required])
    });
  }

  submitCurrentUse() {
    this.isSubmit = true;
    const toUpdateData = {
      modal: 'currentUse',
      current_use: this.currentUseForm.get('current_use').value,
      application_id: this.certificateDetail.id
    };
    if (!this.currentUseForm.invalid) {
      this.hideForm = true;
      this.updateApplicantDetail(toUpdateData);
      this.isSubmit = false;
      this.currentUseForm.reset();
    }
  }
  // Current Use Ends...//

  // Cancel Inspector Starts...//
  cancelInspector(status: number) {
    this.hideForm = true;
    const cancelInspectorData = {
      application_id: this.certificateDetail.id,
      inspection_status: status
    };
    this.dashboardService.cancelInspector(cancelInspectorData).subscribe(data => {
      console.log(data, "Cancelled Inspector Successfully");
    });
  }
  // Cancel Inspector ends...//

  public updateApplicantDetail(toUpdateData: any) {
    this.dashboardService.updateApplicantDetail(toUpdateData).subscribe(data => {
      this.certificateDetail = data;
      this.addresses = data.addresses;
      this.showOwnerBusinessFile = this.certificateDetail.owner_type === 2 ? true : false;
      this.showOwnerBankFile = this.certificateDetail.owner_type === 3 ? true : false;
    });
  }

  // Inspection starts ... //
  showHideInspectionViolation(value: number) {
    if (value === 1) {
      this.showInspection = true;
      this.showViolations = false;
      //this.showAddInspectionForm = false;
    }
    if (value === 2) {
      this.showViolations = true;
      this.showInspection = false;
      this.showAddViolationForm = false;
    }
  }
  showHideAddInspViolForm(showHide: string) {
    if (showHide === 'show' && this.showInspection === true) {
      this.showAddInspectionForm = true;
      this.showAddViolationForm = false;
    }
    if (showHide === 'hide' && this.showInspection === true) {
      this.showAddInspectionForm = false;
      this.showAddViolationForm = false;
    }
    if (showHide === 'show' && this.showViolations === true) {
      this.showAddInspectionForm = false;
      this.showAddViolationForm = true;
    }
    if (showHide === 'hide' && this.showViolations === true) {
      this.showAddInspectionForm = false;
      this.showAddViolationForm = false;
    }
  }

  private addNewInspection() {
    this.addInspectionForm = new FormGroup({
      inspectionDate: new FormControl('', [Validators.required]),
      inspectionType: new FormControl('', [Validators.required]),
      inspectionDecision: new FormControl('', [Validators.required]),
      no_of_residential_units: new FormControl(),
      no_of_commercial_units: new FormControl(),
      remark: new FormControl('', [Validators.required])
    });
  }

  changeaddInspectionForm(operation: boolean, fieldName: string) {
    const field = this.addInspectionForm.get(fieldName);
    if (field) {
      const fieldValue = field.value;
      if (fieldValue >= 0 && operation) {
        field.setValue(fieldValue + 1);
      }
      if (fieldValue > 0 && !operation) {
        field.setValue(fieldValue - 1);
      }
    }
  }

  submitAddInspectionForm() {
    this.isSubmit = true;
    const toAddInspection = {
      inspection_date: moment(this.addInspectionForm.get('inspectionDate').value).format('YYYY/MM/DD'),
      type: this.addInspectionForm.get('inspectionType').value,
      decision: this.addInspectionForm.get('inspectionDecision').value,
      no_of_residential_units: this.addInspectionForm.get('no_of_residential_units').value,
      no_of_commerical_units: this.addInspectionForm.get('no_of_residential_units').value,
      remark: this.addInspectionForm.get('remark').value,
      application_id: this.certificateDetail.id,
    };
    if (!this.addInspectionForm.invalid) {
      this.dashboardService.addNewInspection(toAddInspection).subscribe(data => {
        this.showAllInspection = data;
        this.isSubmit = false;
      });
    }
    this.addInspectionForm.reset();
  }
  // Inspection ends... //

  // Violation starts...//
  private addNewViolation() {
    this.violationForm = new FormGroup({
      violation: new FormControl('', [Validators.required]),
      code: new FormControl(),
      violation_description: new FormControl(),
      floor: new FormControl('', [Validators.required]),
      unitNo: new FormControl(),
      remark: new FormControl('', [Validators.required])
    });
  }

  clearViolationForm() {
    this.violationForm.reset();
  }

  showOrdinanceTable() {
    this.showOrdinancePopup = true;
    this.getOrdinance({ page: this.currentPagination });
  }
  public getOrdinance(params: object) {
    this.dashboardService.getOrdinanceData(params).subscribe(({ response, ...pagination }) => {
      this.ordinance = response.docs;
      this.totalPagination = pagination.total;
      this.offset = pagination.offset;
      this.currentPagination = pagination.currentPage;
    });
  }
  paginate(value: any) {
    this.getOrdinance({ page: value });
  }

  selectedOrdinanceFromPopup(ordinance) {
    this.showOrdinancePopup = false;
    this.hideForm = true;
    this.violationForm.patchValue({
      code: ordinance.code,
      violation: ordinance.violation,
      violation_description: ordinance.violation_description
    });
    this.ordinanceId = ordinance.id;
  }
  submitViolationForm() {

    this.isSubmit = true;
    const toSubmit = {
      ordinance_id: this.ordinanceId,
      floor: parseInt(this.violationForm.get('floor').value),
      unit_number: parseInt(this.violationForm.get('unitNo').value),
      remarks: this.violationForm.get('remark').value,
      application_id: this.certificateDetail.id
    };
    if (!this.violationForm.invalid) {
      this.dashboardService.submitNewViolation(toSubmit).subscribe(data => {
        this.isSubmit = false;
        this.AllViolations = data;
        this.showHideInspectionViolation(2)
      });
    }
    this.violationForm.reset();
  }
  // Violation ends...//

  // Applicant Attachments starts...//
  uploadApplicantAttachment(event) {
    const media = {
      application_id: this.certificateDetail.id,
      name: 'attachment',
      attachment: event.target.files[0]
    };
    this.dashboardService.uploadApplicantAttachment(media).subscribe(data => {
      this.applicantAttachment = data;
    });
  }

  deleteApplicantAttachment(imageId) {
    const toDelete = {
      application_id: this.certificateDetail.id,
      id: imageId
    };
    this.dashboardService.deleteApplicantAttachment(toDelete).subscribe(data => {
      this.applicantAttachment = data;
    });
  }
  // Applicant Attachment ends...//

  // Submission Review Starts...//
  private addsubmissionReview() {
    this.submissionReview = new FormGroup({
      decision_type: new FormControl('', [Validators.required]),
      expiration_date: new FormControl('', [Validators.required])
    });
  }

  submitSubmissionReview() {
    this.isSubmit = true;
    const toSubmit = {
      decision_type: this.submissionReview.get('decision_type').value,
      expiration_date: moment(this.submissionReview.get('expiration_date').value).format('YYYY/MM/DD'),
      application_id: this.certificateDetail.id,
    };
    if (!this.submissionReview.invalid) {
      this.dashboardService.saveSubmissionReview(toSubmit).subscribe(data => {
        this.changeSubmissionReview = data;
        this.isSubmit = false;
      });
    }
    this.submissionReview.reset();
  }
  // Submission Review Ends...//

  // Payment Starts..//
  private addFirstPaymentDetail() {
    this.firstPaymentForm = new FormGroup({
      application_type: new FormControl('', [Validators.required]),
      amount: new FormControl('', [Validators.required])
    });
  }

  submitFirstPaymentForm() {
    this.isSubmit = true;
    const toSubmit = {
      application_type: this.firstPaymentForm.get('application_type').value,
      amount: this.firstPaymentForm.get('amount').value,
      transaction_type: 2,
      application_id: this.certificateDetail.id
    };

    if (!this.firstPaymentForm.invalid) {
      this.dashboardService.savePaymentDetail(toSubmit).subscribe(data => {
        this.allTransaction = data;
        this.isSubmit = false;
      });
    }
    this.firstPaymentForm.reset();
  }
  // Payments Ends...//

  // Email starts...//
  private sendEmail() {
    this.emailForm = new FormGroup({
      toEmail: new FormControl('', [Validators.required,
      Validators.maxLength(250),
      Validators.pattern(this.EMAIL_REGEX)]),
      ccEmail: new FormControl('', [Validators.required,
      Validators.maxLength(250),
      Validators.pattern(this.EMAIL_REGEX)]),
      subject: new FormControl('', [Validators.required, Validators.minLength(10)]),
      message: new FormControl('', [Validators.required])
    });
  }
  get f() { return this.emailForm.controls; }


  private pickUp() {
    this.pickUpForm = new FormGroup({
      image: new FormControl(),
      notes: new FormControl('', [Validators.required])
    });
  }
  submitEmailForm() {
    this.isSubmit = true;
    if (this.emailForm.invalid) {
      return false;
    }
    const toSubmit = {
      to_email: this.emailForm.get('toEmail').value,
      cc_email: this.emailForm.get('ccEmail').value,
      subject: this.emailForm.get('subject').value,
      message: this.emailForm.get('emailText').value,
      application_id: this.certificateDetail.id
    };
    this.dashboardService.sendApplicantEmail(toSubmit).subscribe(data => {
      console.log(data, "SENT EMAIL")
    })
  }


  emailAndNotesaAndPickup(value: number) {
    if (value === 1) {
      this.isEmail = true;
      this.isNotes = false;
      this.isPickUp = false;
    }
    else if (value === 2) {
      this.isEmail = false;
      this.isNotes = true;
      this.isPickUp = false;
    } else {
      this.isEmail = false;
      this.isNotes = false;
      this.isPickUp = true;
    }
  }
  // Email Ends...//
  public isEdit: boolean = false;

  getFeeAndPaymentsData(item) {
    this.firstPaymentForm.controls['application_type'].setValue(item.application_type);
    this.firstPaymentForm.controls['amount'].setValue(item.amount);
    this.isEdit = true;
    this.updateId = item.id;
  }
  resetUpdateFeeAndPayments() {
    this.firstPaymentForm.reset();
    this.isEdit = false;
  }

  updateFeeAndPaymets() {
    const updateData = {
      application_type: this.firstPaymentForm.value.application_type,
      amount: this.firstPaymentForm.value.amount,
      id: this.updateId,
      application_id: this.certificateDetail.id
    }
    this.dashboardService.updateFeeAndPaymets(updateData).subscribe(data => {
      this.allTransaction = data;
      this.isEdit = false;
    })
  }
  points = [];
  signatureImage;
  saveImage(data) {
    this.signatureImage = data;
  }

  addPichUp() {
    const pichUpData = {
      notes: this.pickUpForm.value.notes,
      image: this.signatureImage
    }
    this.dashboardService.addPichUpNotes(pichUpData).subscribe(data => {
      console.log(data)
    })
  }
  public permissions: any = []
  getPermission() {
    this.permissions = this.adminAuthService.getPermission();
    this.permissions = this.permissions.permissions
  }
}

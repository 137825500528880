import { ApplicationService } from "./../../../http/users/application.service";
import { AuthenticationService } from "./../../../authentication/authentication.service";
import { Router, ActivatedRoute } from "@angular/router";
import {
  Component,
  OnInit,
  Output,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import { UsersService } from "src/app/core/http";
import { EventEmitter } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { forEach } from "lodash";
import { AddComplaintService } from "src/app/core/http/users/code-Enforcement/add-complaint.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  public currentUser: any;
  public inspectionsFee = false;
  public allCity = [];
  public cityForm: FormGroup;
  public isApplicationEditable: boolean = false;
  public currentURL: string;
  public currentTab: any;
  public application: any;
  public params: string = "";
  private subscription: Subscription = new Subscription();
  constructor(
    private router: Router,
    public authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private usersService: UsersService,
    private applicationService: ApplicationService,
    private toasterService: ToastrService,
    private _Service: AddComplaintService,
    private _FB: FormBuilder
  ) {}
  public applicationStatus: number = 1;
  public addApplication: boolean = false;
  public istrue: boolean = true;
  public applicationStatusForIncomplete = {
    applicationId: null,
    application_status: null,
    occupancy_type: null,
    payment_status: null,
  };
  public isUploadDoc = true;
  public reviewIncompleteApplicationPage = false;
  public reviewPageforCHAfterApplicationComplete = false;
  public unitDecision: any;
  public unitStatus: any;
  public isDataaddUnit = true;

  @ViewChild("cityListModal", { static: false }) cityListModal: ElementRef;
  @ViewChild("citySelected", { static: false }) citySelected: ElementRef;

  ngOnInit() {
    this.whatControls();
    this.getCity();

    this.applicationService.isaddUnitBehavourSubject.subscribe((data) => {
      if (data) {
        console.log("data is isaddUnitBehavourSubject", data);
        this.isDataaddUnit = data;
        this.subscription.add(
          this.route.queryParams.subscribe((data) => {
            this.params = data.param2;
            let applicationId = null;
            const objectKeys = Object.keys(data);
            if (objectKeys.length) {
              applicationId = data.id;
            } else {
              applicationId = this.applicationService.getApplication().id;
            }
            console.log(
              "header data is ==>",
              applicationId,
              this.isDataaddUnit
            );
            if (applicationId && applicationId) {
              this.subscription.add(
                this.applicationService
                  .getApplicationById(applicationId)
                  .subscribe((data: any) => {
                    this.application = data;
                    this.application.addFeeByAdmin = [];
                    this.application.newArrayForHousingAndFire = [];
                    this.application.initialPaymets = [];
                    this.application.unitPayments = [];

                    // this.application.unitDetails = [{application_id
                    //   :
                    //   5934,
                    //   createdAt
                    //   :
                    //   "2023-08-10T05:17:41.000Z",
                    //   decision
                    //   :
                    //   4,
                    //   document
                    //   :
                    //   null,
                    //   id
                    //   :
                    //   2520,
                    //   no_of_bathrooms
                    //   :
                    //   24234,
                    //   no_of_bedrooms
                    //   :
                    //   344,
                    //   no_of_kitchens
                    //   :
                    //   432,
                    //   status
                    //   :
                    //   null,
                    //   submitted_date
                    //   :
                    //   "2023-08-10T05:19:07.000Z",
                    //   tenant_name
                    //   :
                    //   "Jogh  Aihkns",
                    //   unit
                    //   :
                    //   "1312412",
                    //   updatedAt
                    //   :
                    //   "2023-08-10T17:29:06.000Z"}
                    // ]

                    this.application.isFeeForNoEntry = false;
                    this.application.isUnitLeftToPay = false;
                    console.log(
                      "Application hedaer data is ",
                      this.application
                    );
                    this.application["paymentDetails"].map((item) => {
                      // this.newArrayForHousingAndFire.push(item)
                      if (
                        (item.status == 0 || item.status == 2) &&
                        (item.department_id == 1 ||
                          item.department_id == 2 ||
                          item.department_id == 5)
                      ) {
                        this.application.newArrayForHousingAndFire.push(item);
                      } else if (
                        (item.status == 0 || item.status == 2) &&
                        (item.department_id == 0 || item.department_id == 16)
                      ) {
                        this.application.initialPaymets.push(item);
                      } else if (
                        (item.status == 0 ||
                          item.status == 2 ||
                          item.status == null) &&
                        (item.department_id == 20 || item.department_id == 21)
                      ) {
                        this.application.addFeeByAdmin.push(item);
                      }
                    });
                    if (this.application.unitDetails) {
                      let unitPayCount = 0;
                      this.application.unitDetails.map((unit: any) => {
                        // this.application.unitsSchedule.forEach(unitObj => {
                        //   let iSunitObj = unitObj ? unitObj.unit : null;
                        //   let iSunitObjSub = iSunitObj ? unitObj.unit.unit : null;
                        //   // console.log("unit error unitObj===>",unitObj + "unit"+unit.unit)

                        //   if (iSunitObjSub == unit.unit) {
                        //     unit.inspection_date = unitObj.inspection_date
                        //   }
                        // });

                        if (unit.status == null || unit.status == 2) {
                          this.application.isUnitPayments = true;
                        }
                        console.log("unit decision", unit.decision);
                        console.log(
                          "payment status",
                          this.application.payment_status
                        );
                        console.log("unit status ", unit.status);
                        if (
                          unit.decision == null &&
                          this.application.payment_status == 3 &&
                          (unit.status == null ||
                            unit.status == 2 ||
                            unit.status == 0)
                        ) {
                          this.application.isAddUnits = true;
                        }

                        if (
                          unit.status == null ||
                          unit.status == 2 ||
                          unit.status == 0
                        ) {
                          this.application.paymentDetails.forEach((payObj) => {
                            if (
                              payObj.status == null ||
                              payObj.status == 0 ||
                              payObj.status == 2
                            ) {
                              const unitsList = JSON.parse(payObj.unit_ids);
                              // console.log("this.application is ",unitsList)
                              //  console.log("this.application type",typeof(unitsList))
                              if (typeof unitsList == "object") {
                                if (unitsList) {
                                  unitsList.forEach((unitObj) => {
                                    if (unitObj.id == unit.id) {
                                      // this.application.isAddUnits = true;
                                      if (payObj.decision == 8) {
                                        this.application.isFeeForNoEntry = true;
                                      }
                                    }
                                  });
                                }
                              }
                            }
                          });

                          this.application.paymentDetails.forEach((payObj) => {
                            if (payObj.status == 3 || payObj.status == 5) {
                              const unitsList = JSON.parse(payObj.unit_ids);
                              console.log(
                                "second this.application is ",
                                unitsList
                              );
                              if (unitsList) {
                                unitsList.forEach((unitObj) => {
                                  if (unitObj.id == unit.id) {
                                    unitPayCount++;
                                  }
                                });
                              }
                            }
                          });

                          if (
                            unitPayCount != this.application.unitDetails.length
                          ) {
                            this.application.isUnitLeftToPay = true;
                          }
                        }

                        if (unit.status != null) {
                          this.application.isInspectionComplete = true;
                          //return false
                        } else {
                          this.application.isInspectionComplete = true;
                        }

                        if (
                          unit.decision == 3 &&
                          (unit.status == null || unit.status == 2) &&
                          this.application.failedInspectionCount > 1
                        ) {
                          if (this.application.unitPayments.length == 0) {
                            this.application.unitPayments.push({
                              decision: unit.decision,
                              status: this.application.status,
                              value: "Fail Fee",
                              feeType: 7,
                            });
                          }
                          if (this.application.unitPayments.length > 0) {
                            this.application.unitPayments.map((value) => {
                              if (value.value != "Fail Fee") {
                                this.application.unitPayments.push({
                                  decision: unit.decision,
                                  status: this.application.status,
                                  value: "Fail Fee",
                                  feeType: 7,
                                });
                              }
                            });
                          }
                          // this.application.unitPayments.push({ decision: unit.decision, status: this.application.status, value: 'Fail Fee', feeType: 7 })
                        }

                        if (unit.decision == null && unit.status == null) {
                          // if (this.application.unitPayments.length == 0) {
                          //   this.application.unitPayments.push({ decision: unit.decision, status: this.application.status, value: 'Initial Fee', feeType: 6 })

                          // }

                          if (this.application.unitPayments.length > 0) {
                            this.application.unitPayments.every((value) => {
                              if (
                                value.feeType != 6 &&
                                unit.decision == null &&
                                unit.status == null
                              ) {
                                this.application.unitPayments.push({
                                  decision: unit.decision,
                                  status: this.application.status,
                                  value: unit.unit + " initial fee",
                                  feeType: 6,
                                });
                                return false;
                              }
                            });
                          }
                          // this.application.unitPayments.push({ decision: unit.decision, status: this.application.status, value: 'Fail Fee', feeType: 7 })
                        }

                        if (
                          unit.decision == 4 &&
                          (unit.status == null || unit.status == 2)
                        ) {
                          if (this.application.unitPayments.length == 0) {
                            this.application.unitPayments.push({
                              decision: unit.decision,
                              status: this.application.status,
                              value: "No-Entry Fee",
                              feeType: 8,
                            });
                          }
                          if (this.application.unitPayments.length > 0) {
                            this.application.unitPayments.map((value) => {
                              if (value.value != "No-Entry Fee") {
                                this.application.unitPayments.push({
                                  decision: unit.decision,
                                  status: this.application.status,
                                  value: "No-Entry Fee",
                                  feeType: 8,
                                });
                              }
                            });
                          }
                          // this.application.unitPayments.push({ decision: unit.decision, status: data.status, value: 'No-Entry Fee', feeType: 8 })
                        }
                        // else if(unit.decision == null && (unit.status == null || unit.status == 2)){
                        //   this.application.unitPayments.push({ decision: unit.decision, status: data.status, value: 'Extra Unit', feeType: 13 })
                        // }
                      });
                    }

                    console.log(
                      "after data admin pay data =>",
                      this.application
                    );

                    if (this.application.occupancy_type == 1) {
                      if (
                        this.application.application_housing_status == 4 ||
                        this.application.application_housing_status == 3 ||
                        this.application.application_fire_status == 3 ||
                        this.application.application_fire_status == 4
                      ) {
                        this.istrue = false;
                      }
                    }

                    let application = this.application
                      ? this.application.units
                      : null;
                    if (application) {
                      // this.addFeeByAdmin = [];

                      for (let i = 0; i < application.length; i++) {
                        this.unitDecision = this.application.units[i].decision;
                        this.unitStatus = this.application.units[i].status;
                        console.log("des", this.unitDecision);
                        console.log("status", this.unitStatus);
                        if (
                          this.application.units[i].decision == 4 &&
                          this.application.units[i].status == null
                        ) {
                          this.istrue = false;
                          console.log("run");
                        } else if (
                          this.application.units[i].decision == 3 &&
                          this.application.units[i].status == null
                        ) {
                          this.istrue = false;
                          console.log("run");
                        }
                      }
                    }
                  })
              );
            }
          })
        );
      }
    });
    if (this.isDataaddUnit) {
      console.log("header data is ==>", this.isDataaddUnit);
    }

    // this.currentTab = this.applicationService.currentTab;
    // console.log(this.currentTab )
    /** GET CURRENT ROUTE */
    this.applicationService.uploadDoc.subscribe((data) => {
      this.isUploadDoc = data;
    });
    this.applicationService.currentTa.subscribe((data) => {
      this.currentTab = data;
    });
    this.applicationService.applicationForEdit.subscribe((data: any) => {
      this.applicationStatus = data;
      console.log(this.applicationStatus, "this.applicationStatus");
      // if(data.payment_status && data.application_status){
      //   this.applicationStatusForIncomplete = data;
      // }
    });
    this.applicationService.isEditable.subscribe((editable) => {
      this.isApplicationEditable = editable;
    });

    this.applicationService.incomepleteApplicationStatus.subscribe(
      (editable: any) => {
        this.applicationStatusForIncomplete = editable;
      }
    );

    this.applicationService.isEditable1.subscribe((add) => {
      this.addApplication = add;
    });
    this.authenticationService.getUserInfo().subscribe((user) => {
      this.currentUser = user ? user.email : null;
    });

    this.currentURL = this.router.url;

    if (this.currentURL.includes("incomplete/review")) {
      this.reviewIncompleteApplicationPage = true;
    } else if (this.currentURL.includes("app/modify")) {
      this.reviewPageforCHAfterApplicationComplete = true;
    }

    if (this.currentURL === "/") {
      this.isApplicationEditable = false;
    }
  }

  goToLogin(type: string) {
    if (type == "user") {
      this.router.navigateByUrl("/auth/login");
    } else if (type == "admin") {
      this.router.navigateByUrl("/auth/admin-login");
    }
  }

  goToDashboard() {
    this.router.navigateByUrl("/dashboard/certificates");
  }

  saveAndExitApplication() {
    localStorage.setItem("headerItem", "header");
    this.applicationService.changeSaveAndExit(true);
    this.router.navigate(["/dashboard/certificates"]);
  }

  goToRoute(route: string): void {
    this.router.navigateByUrl(route);
  }
  navigate() {
    this.router.navigate(["/dashboard/certificates"]);
  }
  navigateToPaymentPage() {
    console.log("fun call 1");
    if (this.currentTab.occupancy_type == 1) {
      this.router.navigate(["/dashboard/payments"], {
        queryParams: {
          application_id: this.currentTab.applicationId,
          feeType: 0,
          applicationType: this.currentTab.occupancy_type,
        },
      });
      localStorage.setItem(
        "tabValue",
        JSON.stringify(this.currentTab.occupancy_type)
      );
    }
    if (this.currentTab.occupancy_type == 3) {
      this.router.navigate(["/dashboard/payments"], {
        queryParams: {
          application_id: this.currentTab.applicationId,
          feeType: 16,
          applicationType: this.currentTab.occupancy_type,
        },
      });
      localStorage.setItem(
        "tabValue",
        JSON.stringify(this.currentTab.occupancy_type)
      );
    } else if (this.currentTab.occupancy_type == 2) {
      this.router.navigate(["/dashboard/payments"], {
        queryParams: {
          application_id: this.currentTab.applicationId,
          feeType: 6,
          applicationType: this.currentTab.occupancy_type,
        },
      });
      localStorage.setItem(
        "tabValue",
        JSON.stringify(this.currentTab.occupancy_type)
      );
    }
  }
  isPayNow = false;

  navigateToPaymentPages() {
    console.log("fun call 2");
    console.log("payment status =====>", this.application);

    let addFeeByAdmin = this.application.addFeeByAdmin
      ? this.application.addFeeByAdmin
      : [];
    let newArrayForHousingAndFire = this.application.newArrayForHousingAndFire
      ? this.application.newArrayForHousingAndFire
      : null;

    if (this.application.occupancy_type == 1) {
      let feeType;
      if (this.application.payment_status != 3) {
        feeType = 0;
      } else if (this.application.application_extend.last_payment_status == 2) {
        feeType = 20;
      } else if (addFeeByAdmin.length > 1 || addFeeByAdmin.length == 1) {
        feeType = 20;
      } else if (
        newArrayForHousingAndFire == 1 &&
        !(addFeeByAdmin.length > 1 || addFeeByAdmin.length == 1)
      ) {
        this.inspectionsFee = true;
        feeType = 1;
        console.log("feetype one is ");
      } else {
        feeType = 5;
      }
      this.router.navigate(["/dashboard/payments"], {
        queryParams: {
          application_id: this.application.id,
          feeType: feeType,
          applicationType: this.application.occupancy_type,
        },
      });
      localStorage.setItem(
        "tabValue",
        JSON.stringify(this.application.occupancy_type)
      );
    } else if (this.application.occupancy_type == 2) {
      let feeType;

      if (this.unitDecision == 4 && this.unitStatus == 0) {
        feeType = 8;
      } else if (
        this.unitDecision == 3 &&
        (this.unitStatus == 0 ||
          this.unitStatus == 2 ||
          this.unitStatus == null)
      ) {
        feeType = 7;
      } else if (addFeeByAdmin.length > 1 || addFeeByAdmin.length == 1) {
        feeType = 20;
      } else if (
        this.unitDecision == 0 &&
        (this.unitStatus == 0 ||
          this.unitStatus == 2 ||
          this.unitStatus == null)
      ) {
        feeType = 6;
      } else if (this.unitDecision == null && this.unitStatus == null) {
        feeType = 6;
      } else if (
        this.application.application_extend.last_payment_status == null
      ) {
        feeType = 20;
      }
      this.router.navigate(["/dashboard/payments"], {
        queryParams: {
          application_id: this.application.id,
          feeType: feeType,
          applicationType: this.application.occupancy_type,
        },
      });
      localStorage.setItem(
        "tabValue",
        JSON.stringify(this.application.occupancy_type)
      );
    }
  }
  submitApplication() {
    this.route.queryParams.subscribe((data: any) => {
      if (data && data.id) {
        const payload = {
          application_id: data.id,
        };
        this.applicationService
          .submitIncompletApplication(payload)
          .subscribe((data) => {
            this.toasterService.success("Application has been submitted");
            this.router.navigate(["/dashboard/certificates"]);
          });
      }
    });
  }

  logoutUser() {
    this.authenticationService.logout().subscribe((res) => {
      if (res) {
        this.applicationService.apllicationStatus(1);
        this.ngOnInit();
        this.router.navigate(["/"]);
      }
    });
  }

  navigateTOCodeInforcement() {
    this.router.navigate(["/addComplained"]);
  }

  navigateToDashboardPage() {
    this.router.navigate(["/dashboard/certificates"]);
  }

  getCity() {
    this._Service.getCity().subscribe((data) => {
      this.allCity = data.response.docs;
    });
  }
  isCitySubmit = false;
  whatControls() {
    this.cityForm = this._FB.group({
      city: ["", Validators.required],
    });
  }
  addComplaint() {
    localStorage.removeItem("tabOneTwoData");
    sessionStorage.removeItem("complaint");
    if (this.cityForm.invalid) {
      this.isCitySubmit = true;
      return;
    }

    console.log("city id", this.cityForm.controls.city.value);
    // return
    localStorage.setItem(
      "city_idForComplainAdd",
      this.cityForm.controls.city.value
    );
    this.router.navigate(["/addComplained"]);

    this.cityListModal.nativeElement.click();
  }
  get cityFormValidaters() {
    return this.cityForm.controls;
  }
  blankSelectField() {
    this.cityForm.reset();
    this.citySelected.nativeElement.value = "";
    this.isCitySubmit = false;
  }
  public payments = {
    isPayment: null,
  };

  paymentsPageNavigate(application, feeType: number, applicationType) {
    // debugger
    this.payments = JSON.parse(localStorage.getItem("payments"));
    if (this.payments != null) {
      if (this.payments.isPayment && this.payments.isPayment != null) {
        localStorage.removeItem("payments");
      }
    }
    this.router.navigate(["/dashboard/payments"], {
      queryParams: {
        application_id: application.id ? application.id : application,
        feeType: feeType,
        applicationType: applicationType,
        ward: application.ward,
      },
    });
    // this.setTabValue()
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Login } from 'src/app/shared';
import { of as observableOf, Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';

const credentialsKey = 'currentAdmin';

@Injectable({
  providedIn: 'root'
})
export class AdminAuthenticationService {

  constructor(
    private http: HttpClient,
    private router: Router
  ) {
  }

  login(loginData: Login): Observable<any> {
    const href = `${environment['adminLogin']}`;
    return this.http.post<any>(href, loginData).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
            const storage = localStorage;
            storage.setItem(credentialsKey, JSON.stringify(data.response));
          }
          return data;
        }
      )
    );
  }

  logout(): Observable<boolean> {

    /// Remove Local Data of user
    sessionStorage.removeItem(credentialsKey);
    localStorage.removeItem(credentialsKey);

    /// Remove Local data of application
    sessionStorage.removeItem('application');
    localStorage.removeItem('application');
    return observableOf(true);
  }

  getUserInfo(): Observable<any> {
    const savedCredentials = this.getUser();
    return observableOf(savedCredentials);
  }

  isLogin() {
    if (localStorage.getItem(credentialsKey)) {
      return true;
    }
    return false;

  }

  getToken() {
    const savedCredentials = this.getUser();
    return savedCredentials && savedCredentials['token'];
  }

  getUserRole(): Observable<any> {
    const savedCredentials = this.getUser();
    return observableOf(savedCredentials['role_id']);
  }
  getAdmin(): Observable<any> {
    const savedCredentials = this.getUser();
    return observableOf(savedCredentials);
  }

  getUserType() {
    const savedCredentials = this.getUser();
    if (this.isLogin()) {
      return savedCredentials['role'];
    } else {
      return false;
    }


  }
  public savedCredentials: any = {

  }

  public getUser() {
    this.savedCredentials = sessionStorage.getItem(credentialsKey) || localStorage.getItem(credentialsKey);
    if (this.savedCredentials == null) {
      this.savedCredentials = {
        role_id: null
      }
      return (this.savedCredentials);
    } else {
      return JSON.parse(this.savedCredentials);

    }
  }

  setUser(user: any): void {
    localStorage.setItem(credentialsKey, JSON.stringify(user));
  }

  getPermission() {
    const localStorageData = JSON.parse(localStorage.getItem(credentialsKey));
    return localStorageData
  }

  getSaveDepartment() {
    
    const localStorageData = JSON.parse(localStorage.getItem('department'));
    return localStorageData
  }
}

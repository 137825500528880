import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  TemplateRef,
  AfterViewInit,
} from "@angular/core";
import { appToaster, settingConfig } from "src/app/configs";
import { Observable } from "rxjs";
import { Certificates, AddressSearch } from "src/app/shared/index";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import * as moment from "moment";
import { DashboardService } from "./../../../../../core/http/admin/dashboard.service";
import { AdminAuthenticationService } from "src/app/core/authentication/admin-authentication.service";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { environment } from "src/environments/environment";
import { Toast, ToastrService } from "ngx-toastr";

@Component({
  selector: "app-inspections",
  templateUrl: "./inspections.component.html",
  styleUrls: ["./inspections.component.css"],
})
export class InspectionsComponent implements OnInit {
  @Input() previousApplicationBtnClicked: any;
  //public dropdownSettings:any = {};
  image: any;
  @Input() certificatesChild: Observable<any>;
  public inspectionForm: FormGroup;
  @ViewChild("inspectionModal", { static: false }) inspectionModal: ElementRef;
  @ViewChild("odiancepopup", { static: false }) odiancepopup: ElementRef;
  @ViewChild("addFlorInFire", { static: false }) addFlorInFire: ElementRef;
  @ViewChild("rescheduleByInspector", { static: false })
  rescheduleByInspector: ElementRef;
  @ViewChild("ccmail", { static: false }) ccmail: ElementRef;

  // public decisionForm: FormGroup;
  @Output() messageEvent = new EventEmitter<string>();
  inspection_type_status = [
    {
      key: 3,
      value: "Building",
    },
    {
      key: 4,
      value: "Electrical",
    },
    {
      key: 5,
      value: "Plumbing",
    },
    {
      key: 6,
      value: "Fire",
    },
  ];
  public typeValue: any;
  public typekey: any;
  public fireInspectionForm: FormGroup;
  public isInspectionAndViolationForm = false;
  public currentStatusInspectionForm: FormGroup;
  public ordinanceForm: FormGroup;
  public habitivilityInspectionForm: FormGroup;
  public department = {
    role_id: null,
    department_id: null,
  };
  public isInspection = false;
  public showOwnerBusinessFile = false;
  public isViolation = false;
  public isCurrentStatusInspection = false;

  public violationForm: FormGroup;
  public env: any;
  public certificateDetail: Certificates = {
    id: null,
    address: null,
    ward: null,
    applicant_address: null,
    applicant_email: null,
    applicant_name: null,
    applicant_phone: null,

    owner_name: null,
    owner_email: null,
    owner_phone: null,
    owner_address: null,
    owner_type: null,

    buyer_address: null,
    buyer_business: null,
    buyer_business_type: null,
    buyer_email: null,
    buyer_id: null,
    buyer_name: null,
    buyer_phone: null,
    buyer_type: null,

    is_attic_finished: null,
    is_basement_finished: null,
    no_of_bathrooms: null,
    no_of_bedrooms: null,
    no_of_commercial_units: null,
    no_of_kitchens: null,
    no_of_residential_units: null,
    point_of_contact: null,
    application_housing_status: null,
    application_zoning_status: null,
    application_taxes_status: null,
    application_assessor_status: null,
    application_fire_status: null,
  };
  public settings: any;
  public showAllInspection: [];
  public certificate = "/api/admin/download-certificate/";
  public inspectionData: any;
  //public AllViolations: [];
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: "item_id",
    textField: "value",
    selectAllText: "Select All",
    unSelectAllText: "Deselect All",
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };
  public units = [];
  dropdownSettings1: IDropdownSettings = {
    singleSelection: false,
    idField: "id",
    textField: "unit",
    selectAllText: "Select All",
    unSelectAllText: "Deselect All",
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };
  public currentAdmin;
  failedInspectionCount: any;

  public inspectionRescheduleForm: FormGroup;
  public inspectionObjForReschedule: any;
  public isRescheduleByInspectorSubmit = false;

  constructor(
    private formBuilder: FormBuilder,
    private dashboardService: DashboardService,
    private modalService: BsModalService,
    private adminAuthService: AdminAuthenticationService,
    private _TS: ToastrService
  ) {
    this.settings = settingConfig;
  }
  minDate: Date;
  modalRef: BsModalRef;
  //message: string;
  public Units = [];
  public allUnits = [];
  public allTransaction = [];
  public isWaivOffFire = false;
  public isWaivOffHousing = false;
  public inspectionType = [];
  public insType = [];

  public failOrPassInspectionExist = false;
  public withoutEnterCCValue: any;
  ngOnInit() {
    this.inspectionData = JSON.parse(localStorage.getItem("inspectionData"));
    this.currentAdmin = JSON.parse(localStorage.getItem("currentAdmin"));
    console.log(this.currentAdmin, "currentAdmin");
    this.env = environment;
    this.minDate = new Date();
    this.certificatesChild.subscribe((data) => {
      this.certificateDetail = data;
      console.log("===========================>");
      console.log("application fire data==>", data);
      console.log("===========================>");

      console.log(this.certificateDetail, "this.certificateDetail");
      this.certificateDetail["failedUnitIds"] = [];
      this.certificateDetail["passedUnitIds"] = [];
      this.certificateDetail["failedUnitIds"] = [];
      this.certificateDetail["passedUnitIds"] = [];
      this.assiendInspector();
      this.failedInspectionCount = 0;
      this.allTransaction = data.allTransaction;
      this.failOrPassInspectionExist = false;
      this.allTransaction.map((data: any, i) => {
        if (data.status == 5 && data.department_id == 2) {
          this.isWaivOffFire = true;
        }
        if (data.status != 5 && data.department_id == 2) {
          this.isWaivOffFire = false;
        }
        if (data.status == 5 && data.department_id == 1) {
          this.isWaivOffHousing = true;
        }
        if (data.status != 5 && data.department_id == 1) {
          this.isWaivOffHousing = false;
        }
        if (this.certificateDetail["showAllInspection"].length > 0) {
          this.certificateDetail["showAllInspection"].forEach(
            (inspectionObj) => {
              if (
                (inspectionObj.decision == 3 || inspectionObj.decision == 2) &&
                (inspectionObj.department_id == 1 ||
                  inspectionObj.department_id == 6)
              ) {
                this.failOrPassInspectionExist = true;
              }
              const failedUnitId = this.certificateDetail["failedUnitIds"].find(
                (failedUnitId) => failedUnitId.unit_id == inspectionObj.unit_id
              );
              const passedUnitId = this.certificateDetail["passedUnitIds"].find(
                (passedUnitId) => passedUnitId.unit_id == inspectionObj.unit_id
              );
              if (
                inspectionObj.decision == 3 &&
                (inspectionObj.department_id == 1 ||
                  inspectionObj.department_id == 6) &&
                inspectionObj.isVoid == null &&
                ((passedUnitId &&
                  passedUnitId.unit_id !== inspectionObj.unit_id) ||
                  this.certificateDetail["showAllInspection"].length == 1)
              ) {
                this.failedInspectionCount = this.failedInspectionCount + 1;
                this.certificateDetail["failedUnitIds"].push({
                  unit_id: inspectionObj.unit_id,
                  type_value: inspectionObj.type_value,
                });
              } else if (
                inspectionObj.decision == 2 &&
                (inspectionObj.department_id == 1 ||
                  inspectionObj.department_id == 6) &&
                inspectionObj.isVoid == null
              ) {
                this.certificateDetail["passedUnitIds"].push({
                  unit_id: inspectionObj.unit_id,
                  type_value: inspectionObj.type_value,
                });
                if (
                  failedUnitId &&
                  failedUnitId.unit_id === inspectionObj.unit_id &&
                  this.certificateDetail["showAllInspection"].length > 1
                ) {
                  let unitFailedCount = 0;
                  for (
                    let i = 0;
                    i < this.certificateDetail["failedUnitIds"].length;
                    i++
                  ) {
                    if (
                      this.certificateDetail["failedUnitIds"][i].unit_id ==
                      inspectionObj.unit_id
                    ) {
                      unitFailedCount++;
                    }
                  }
                  if (
                    unitFailedCount ==
                    this.certificateDetail["failedUnitIds"].length
                  ) {
                    this.failedInspectionCount = 0;
                  } else {
                    this.failedInspectionCount = this.failedInspectionCount - 1;
                  }
                }
              }
            }
          );
        }
      });
      this.Units = data.units;
      this.showAllInspection = data.showAllInspection;
      if (
        this.department.department_id == 1 ||
        this.department.department_id == 6
      ) {
        this.getInspection();
      }
      if (this.department.department_id == 2) {
        this.inspectionForFire();
      }
      this.seltedEmailBuyerAndApplicant();

      if (this.certificateDetail.occupancy_type == 2) {
        this.unitAccouringByInspector();
      }
    });
    this.initForm();
    this.initializeRescheduleInspectionFormByInspector();

    this.fireInspectionForm.controls.message.setValue(
      "Upon inspection of your property in reference to your application for a Certificate of Occupancy, we found the attached violations. Based on the Township’s Ordinance you can correct the identified violations and reschedule another inspection, alternatively the buyer can agree to purchase the property “as-is” and take the responsibility of correcting the violations after closing."
    );

    //   this.inspectionForm.controls.message.setValuse('Upon inspection of your property in reference to your application for a Certificate of Occupancy, we found the attached violations. Based on the Township’s Ordinance you can correct the identified violations and reschedule another inspection, alternatively the buyer can agree to purchase the property “as-is” and take the responsibility of correcting the violations upon closing. If the buyer intends to take responsibility for correcting the violations upon closing, she or he will need to complete an affidavit sign and notarized it and submit it to this office. Once we received the signed and notarized Affidavit we will provide you with a Temporary Certificate of Occupancy')
    this.getLocalStorage();

    this.insType = this.settings.inspection_type_status.filter((type) => {
      if (type.key == 1 || type.key == 2) {
        return type;
      }
    });
  }
  getCCvalue(dd) {
    this.withoutEnterCCValue = dd.target.value;
  }
  assiendInspectors() {
    const data = {
      application_id: this.certificateDetail.id,
      department_id: this.department.department_id,
    };
    this.dashboardService.getAssignedInspector(data).subscribe((data) => {
      this.assignInspector = data.response;
      console.log(data.response, "+++++++++++++++++++++++");
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: "modal-sm" });
  }

  confirm(): void {
    this.message = "Confirmed!";
    this.modalRef.hide();
  }

  decline(): void {
    this.message = "Declined!";
    this.modalRef.hide();
  }
  message: string = "dd";
  sendMessage() {
    this.messageEvent.emit(this.message);
  }

  selectedItems: {};
  selectEmail(event) {
    this.selectedItems;
    if (event == "0") {
      this.inspectionForm.controls["inspection_email_cc"].setValue(null);
    } else {
      this.inspectionForm.controls["inspection_email_cc"].setValue(event);
    }
  }

  public isSelectDecisionFail = false;
  public isVoidSelect = true;
  public isShowDetails = false;
  changeDecision(value: String) {
    if (value == "3") {
      this.isViolationSection = true;
      this.isSelectDecisionFail = true;
      this.isVoidSelect = true;
      this.isShowDetails = true;
      this.inspectionForm.controls.message.setValue(
        "Upon inspection of your property in reference to your application for a Certificate of Occupancy, we found the attached violations. Based on the Township’s Ordinance you can correct the identified violations and reschedule another inspection, alternatively the buyer can agree to purchase the property “as-is” and take the responsibility of correcting the violations after closing."
      );
      this.inspectionForm.controls["inspection_email_to"].setValue(
        this.certificateDetail.applicant_email
      );
    } else if (value == "4") {
      this.isSelectDecisionFail = true;
      this.isViolationSection = false;
      this.isVoidSelect = false;
      this.isShowDetails = false;
      if (this.department.department_id == 6) {
        this.inspectionForm.controls.message.setValue(
          "Upon inspection of your property in reference to your application for a Certificate of continued occupancy, we found the attached violations. Based on the Township’s Ordinance you can correct the identified violations and reschedule another inspection, alternatively the buyer can agree to purchase the property “as-is” and take the responsibility of correcting the violations upon closing. If the buyer intends to take responsibility for correcting the violations upon closing, she or he will need to complete an affidavit sign and notarized it and submit it to this office. Once we received the signed and notarized Affidavit we will provide you with a Temporary Certificate of Occupancy."
        );
      } else {
        this.inspectionForm.controls.message.setValue(
          "Upon inspection of your property in reference to your application for a Certificate of Occupancy, we found the attached violations. Based on the Township’s Ordinance you can correct the identified violations and reschedule another inspection, alternatively the buyer can agree to purchase the property “as-is” and take the responsibility of correcting the violations upon closing. If the buyer intends to take responsibility for correcting the violations upon closing, she or he will need to complete an affidavit sign and notarized it and submit it to this office. Once we received the signed and notarized Affidavit we will provide you with a Temporary Certificate of Occupancy."
        );
      }
      this.inspectionForm.controls["inspection_email_to"].setValue(
        this.certificateDetail.applicant_email
      );
    } else if (value == "9") {
      this.inspectionForm.controls.message.setValue(null);

      this.isVoidSelect = false;
      this.isViolationSection = false;
      this.isSelectDecisionFail = false;
      this.isShowDetails = false;
    } else {
      this.inspectionForm.controls.message.setValue(null);
      this.isSelectDecisionFail = false;
      this.isViolationSection = false;
      this.isVoidSelect = true;
      this.isShowDetails = false;
    }
  }
  public emailBuyerAndApplicatnt = [];
  seltedEmailBuyerAndApplicant() {
    this.emailBuyerAndApplicatnt = [];
    if (this.certificateDetail.applicant_email) {
      this.emailBuyerAndApplicatnt.push({
        item_id: "1",
        value: this.certificateDetail.applicant_email,
      });
    } else if (this.certificateDetail.buyer_email) {
      this.emailBuyerAndApplicatnt.push({
        item_id: "2",
        value: this.certificateDetail.buyer_email,
      });
    } else [];
  }

  get inspections() {
    return this.inspectionForm.controls;
  }
  get violations() {
    return this.violationForm.controls;
  }
  get getInspectionAndViolationForm() {
    return this.fireInspectionForm.controls;
  }
  get getCurrentStatusAndInspection() {
    return this.currentStatusInspectionForm.controls;
  }

  initForm() {
    this.inspection();
    this.violation();
    this.inspectionAndViolation();
    this.currentStatusAndInspectionTime();
    this.ordinanceFormConrol();
    this.habitibilityForm();
    //  this.decisions();
  }
  habitibilityForm() {
    this.habitivilityInspectionForm = this.formBuilder.group({
      unit: ["", Validators.required],
      inspectiondateAndTime: ["", Validators.required],
      inspctionType: ["", Validators.required],
      decision: ["", Validators.required],
      violation_remarks: [""],
      remarks: ["", Validators.required],
      cc: [""],
      inspection_email_to: ["", Validators.required],
      odiance: ["", Validators.required],
    });
  }

  get havitibilityControl() {
    return this.habitivilityInspectionForm.controls;
  }

  ordinanceFormConrol() {
    this.ordinanceForm = this.formBuilder.group({
      unit_number: [],
      floor: [],
      violation_remarks: [],
    });
  }

  selectOdianceId: any;
  getOrdeianceId(odiance) {
    this.ordinanceForm.reset();
    this.selectOdianceId = odiance.id;
  }

  addFloorAndUnitNumber() {
    this.multipleOdiace;
    this.ordinanceForm;
    if (this.selectOdianceId && this.multipleOdiace.length > 0) {
      this.multipleOdiace.map((data) => {
        if (this.selectOdianceId == data.id) {
          data.floor = this.ordinanceForm.value.floor;
          data.unit_number = this.ordinanceForm.value.unit_number;
          data.violation_remarks = this.ordinanceForm.value.violation_remarks;
          data.isFloorAdd = true;
        }
      });

      if (this.odianenceId.length > 0) {
        this.odianenceId.map((data) => {
          if (this.selectOdianceId == data.id) {
            data.floor = this.ordinanceForm.value.floor;
            data.unit_number = Number(this.ordinanceForm.value.unit_number);
            data.violation_remarks = this.ordinanceForm.value.violation_remarks;
            data.department_id = this.department.department_id;
            data.application_id = this.certificateDetail.id;
          } else {
          }
        });
      }
    }
    this.addFlorInFire.nativeElement.click();
    this.ordinanceForm.reset();
    console.log(this.odianenceId);
    console.log(this.multipleOdiace);
  }

  updateFloorInfo(ordinance) {
    this.ordinanceForm.controls.floor.setValue(ordinance.floor);
    this.ordinanceForm.controls.unit_number.setValue(ordinance.unit_number);
    this.ordinanceForm.controls.violation_remarks.setValue(
      ordinance.violation_remarks
    );
  }

  localstprageData: any;
  getLocalStorage() {
    const department = this.adminAuthService.getPermission();
    if (department && department.role_id != 1 && department.role_id != 2) {
      this.department = department;
      this.localstprageData = department.department;
    } else {
      const localStorageData = this.adminAuthService.getSaveDepartment();
      const department = this.adminAuthService.getPermission();
      this.department = localStorageData;
      this.department.role_id = department.role_id;
      this.localstprageData = localStorageData;
    }
  }

  currentStatusAndInspectionTime() {
    this.currentStatusInspectionForm = this.formBuilder.group({
      inspection_Type: ["", Validators.required],
      decision: ["", Validators.required],
      use: ["", Validators.required],
      currentStatus: ["", Validators.required],
      inspectionDateAndTime: ["", Validators.required],
    });
  }
  inspection() {
    this.inspectionForm = this.formBuilder.group({
      inspectionType: [""],
      inspectionDate: ["", Validators.required],
      inspectionDecision: ["", Validators.required],
      insType: ["", Validators.required],
      no_of_commercial_units: ["", Validators.required],
      no_of_residential_units: ["", Validators.required],
      no_of_kitchen: [""],
      no_of_bedrooms: [""],
      basement_finished: [""],
      basement_aprt: [""],

      // unit_number: [''],
      // violation_remarks: [''],
      remark: ["", Validators.required],
      inspection_email_to: ["", Validators.required],
      inspection_email_cc: [""],
      odiance: ["", Validators.required],
      message: [""],
    });
  }

  violation() {
    this.violationForm = this.formBuilder.group({
      violation: ["", Validators.required],
      code: ["", Validators.required],
      violation_description: ["", Validators.required],
      inspectionDate: ["", Validators.required],
      floor: ["", Validators.required],
      unitNo: ["", Validators.required],
      remark: ["", Validators.required],
    });
  }

  // addInspiction(value: string) {
  //   this.isInspection = true;
  //   this.inspectionForm.value.inspection_date = moment(this.inspectionForm.value.inspectionDate).format('YYYY/MM/DD'),
  //     this.inspectionForm.value.type = this.inspectionForm.value.inspectionType
  //   this.inspectionForm.value.application_id = this.certificateDetail.id,
  //     this.showOwnerBusinessFile = this.certificateDetail.owner_type === 2 ? true : false;
  //   this.inspectionForm.value.no_of_commercial_units = Number(this.inspectionForm.value.no_of_commercial_units),
  //     this.inspectionForm.value.no_of_residential_units = Number(this.inspectionForm.value.no_of_residential_units)
  //   this.inspectionForm.value.decision = this.inspectionForm.value.inspectionDecision
  //   this.dashboardService.addNewInspection(this.inspectionForm.value).subscribe(data => {
  //     this.showAllInspection = data;
  //     this.isInspection = false;
  //     //this.resetForm(value);
  //   });
  // }

  inspectionAndViolation() {
    this.fireInspectionForm = this.formBuilder.group({
      inspectionDate: ["", Validators.required],
      inspectiontype: ["", Validators.required],
      use: ["", Validators.required],
      decision: ["", Validators.required],
      smokedetector: ["", Validators.required],
      carbon_monoxide: ["", Validators.required],
      combo: ["", Validators.required],
      no_of_dwelling_unit: ["", Validators.required],
      no_of_commercial_units: ["", Validators.required],
      no_of_extinguishers: ["", Validators.required],
      remarks: ["", Validators.required],
      to: ["", Validators.required],
      cc: [""],
      // floor: [''],
      // unit_number: [''],
      no_of_violation: ["", Validators.required],
      currentStatus: ["", Validators.required],
      odiance: ["", Validators.required],
      message: [""],
    });
  }

  public selectOdianceMeassege: string;
  public fireInspectorSubmitBtnDisable = true;

  inspectionForFireByInspector() {
    this.isInspectionAndViolationForm = true;
    // if (!this.isViolationSection) {
    //   this.fireInspectionForm.controls.floor.setErrors(null);
    //   this.fireInspectionForm.controls.unit_number.setErrors(null);
    // }

    if (this.isViolationSection && this.odianenceId.length == 0) {
      // this.isOrdianceMessage = true
      // this.selectOdianceMeassege = "Please select ordianence";
      // return false
      this.fireInspectionForm.controls.odiance.setErrors({ required: true });
    }

    if (this.inspectionFire.length == 0) {
      this.initialType = 1;
    }
    if (this.inspectionFire.length > 0) {
      this.inspectionFire.every((data, i) => {
        if (
          i == 0 &&
          (data.isVoid == 0 || data.isVoid == null || data.isVoid == 5)
        ) {
          this.initialType = 1;
          // this.fireInspectorSubmitBtnDisable = false;
          return false;
        } else {
          // this.fireInspectorSubmitBtnDisable = false;
          this.initialType = 2;
          return false;
        }
      });
    }

    if (this.initialType) {
      this.fireInspectionForm.controls.inspectiontype.setErrors(null);
    }

    if (!this.isReInspection || this.isReInspection) {
      this.fireInspectionForm.controls.no_of_commercial_units.setErrors(null);
    }

    if (
      this.fireInspectionForm.value.use == "1" &&
      this.fireInspectionForm.value.decision == "2"
    ) {
      this.fireInspectionForm.controls.no_of_dwelling_unit.setErrors(null);
      this.fireInspectionForm.controls.no_of_commercial_units.setErrors(null);
      this.fireInspectionForm.controls.to.setErrors(null);
      this.fireInspectionForm.controls.cc.setErrors(null);
      this.fireInspectionForm.controls.no_of_violation.setErrors(null);
      this.fireInspectionForm.controls.currentStatus.setErrors(null);
      this.fireInspectionForm.controls.odiance.setErrors(null);

      if (this.fireInspectionForm.invalid) {
        this.fireInspectorSubmitBtnDisable = true;
        return false;
      } else {
        this.fireInspectorSubmitBtnDisable = false;
      }
    } else if (
      (this.fireInspectionForm.value.use == "1" ||
        this.fireInspectionForm.value.use == "3") &&
      this.fireInspectionForm.value.decision == "3"
    ) {
      this.fireInspectionForm.controls.no_of_dwelling_unit.setErrors(null);
      this.fireInspectionForm.controls.no_of_commercial_units.setErrors(null);
      this.fireInspectionForm.controls.currentStatus.setErrors(null);

      if (this.fireInspectionForm.invalid) {
        this.fireInspectorSubmitBtnDisable = true;
        return false;
      } else {
        this.fireInspectorSubmitBtnDisable = false;
      }
    } else if (
      (this.fireInspectionForm.value.use == "1" ||
        this.fireInspectionForm.value.use == "3") &&
      this.fireInspectionForm.value.decision == "4"
    ) {
      this.fireInspectionForm.controls.no_of_dwelling_unit.setErrors(null);
      this.fireInspectionForm.controls.no_of_commercial_units.setErrors(null);
      this.fireInspectionForm.controls.to.setErrors(null);
      this.fireInspectionForm.controls.cc.setErrors(null);
      this.fireInspectionForm.controls.no_of_violation.setErrors(null);
      this.fireInspectionForm.controls.currentStatus.clearValidators();
      this.fireInspectionForm.controls.currentStatus.setErrors(null);
      this.fireInspectionForm.controls.no_of_extinguishers.setErrors(null);
      this.fireInspectionForm.controls.combo.setErrors(null);
      this.fireInspectionForm.controls.carbon_monoxide.setErrors(null);
      this.fireInspectionForm.controls.smokedetector.setErrors(null);
      this.fireInspectionForm.controls.remarks.setErrors(null);
      this.fireInspectionForm.controls.odiance.setErrors(null);

      if (this.fireInspectionForm.invalid) {
        this.fireInspectorSubmitBtnDisable = true;
        return false;
      } else {
        this.fireInspectorSubmitBtnDisable = false;
      }
    } else if (
      (this.fireInspectionForm.value.use == "1" ||
        this.fireInspectionForm.value.use == "3") &&
      this.fireInspectionForm.value.decision == "11"
    ) {
      this.fireInspectionForm.controls.currentStatus.clearValidators();
      this.fireInspectionForm.controls.currentStatus.setErrors(null);
      this.fireInspectionForm.controls.no_of_dwelling_unit.setErrors(null);
      this.fireInspectionForm.controls.no_of_commercial_units.setErrors(null);
      this.fireInspectionForm.controls.to.setErrors(null);
      this.fireInspectionForm.controls.cc.setErrors(null);
      this.fireInspectionForm.controls.no_of_violation.setErrors(null);
      this.fireInspectionForm.controls.no_of_extinguishers.setErrors(null);
      this.fireInspectionForm.controls.combo.setErrors(null);
      this.fireInspectionForm.controls.carbon_monoxide.setErrors(null);
      this.fireInspectionForm.controls.smokedetector.setErrors(null);
      this.fireInspectionForm.controls.remarks.setErrors(null);
      this.fireInspectionForm.controls.odiance.setErrors(null);

      if (this.fireInspectionForm.invalid) {
        this.fireInspectorSubmitBtnDisable = true;
        return false;
      } else {
        this.fireInspectorSubmitBtnDisable = false;
      }
    } else if (
      this.fireInspectionForm.value.use == "3" &&
      this.fireInspectionForm.value.decision == "2"
    ) {
      this.fireInspectionForm.controls.to.setErrors(null);
      this.fireInspectionForm.controls.cc.setErrors(null);
      this.fireInspectionForm.controls.no_of_violation.setErrors(null);
      this.fireInspectionForm.controls.currentStatus.setErrors(null);
      this.fireInspectionForm.controls.odiance.setErrors(null);

      if (this.fireInspectionForm.invalid) {
        this.fireInspectorSubmitBtnDisable = true;
        return false;
      } else {
        this.fireInspectorSubmitBtnDisable = false;
      }
    }

    if (!this.fireInspectorSubmitBtnDisable) {
      const data = {
        application_id: this.certificateDetail.id,
        department_id: this.department.department_id,
        inspection_date: this.fireInspectionForm.value.inspectionDate,
        remark: this.fireInspectionForm.value.remarks,
        decision: this.fireInspectionForm.value.decision,
        type: this.initialType,
        no_commercial_units: Number(
          this.fireInspectionForm.value.no_of_commercial_units
        ),
        inspection_email_to: this.fireInspectionForm.value.to,
        inspection_email_cc: this.ccEmails,
        current_use: this.fireInspectionForm.value.use,
        smoke_detectors: this.fireInspectionForm.value.smokedetector,
        carbon_monoxide: this.fireInspectionForm.value.carbon_monoxide,
        combo: this.fireInspectionForm.value.combo,
        no_of_fire_extinguishers:
          this.fireInspectionForm.value.no_of_extinguishers,
        no_of_violations: this.fireInspectionForm.value.no_of_violation,
        current_status: Number(this.fireInspectionForm.value.currentStatus),
        no_dwelling_units: this.fireInspectionForm.value.no_of_dwelling_unit,
        no_of_bathrooms: this.fireInspectionForm.value.no_of_bathrooms,
        floor: this.fireInspectionForm.value.floor,
        unit_number: Number(this.fireInspectionForm.value.unit_number),
        ordinance_id: this.odianenceId,
        message: this.fireInspectionForm.value.message,
        violation_remarks: this.fireInspectionForm.value.violation_remarks,
      };
      this.formData = new FormData();
      this.formData.append("violationImage", this.attachment);
      console.log("data is ", this.inspectionForm.value.inspection_email_cc);

      Object.keys(data).map((key) => {
        if (
          key != "ordinance_id" &&
          data[key] &&
          key != "inspection_email_cc"
        ) {
          this.formData.append(key, data[key]);
        } else if (
          key == "ordinance_id" &&
          this.odianenceId &&
          this.odianenceId.length > 0
        ) {
          this.formData.append(
            "ordinance_id",
            JSON.stringify(this.odianenceId)
          );
        } else if (key == "inspection_email_cc") {
          this.formData.append(
            "inspection_email_cc",
            JSON.stringify(this.ccEmails)
          );
        }
      });
      this.dashboardService
        .inspectionForFire(this.formData)
        .subscribe((data) => {
          this.fireInspectionForm.reset();
          this.inspectionAndViolation();
          this.multipleOdiace = [];
          this.isViolationSection = false;
          this.image = "";
          this.odianenceId = [];
          this.ccEmails = [];
          this.isMialSection = false;
          this.isInspectionAndViolationForm = false;
          this.fireInspectorSubmitBtnDisable = true;
          this.inspectionForFire();
          this.messageEvent.emit(this.message);
        });
    }
  }

  public imageName: any;
  public attachment: any;
  public value: any;
  formData: any;
  media(event1) {
    this.imageName = event1.target.files[0].name;
    this.attachment = event1.target.files[0];

    // this.formData = new FormData();
    // this.formData.append(
    //   "violationImage",
    //   event1.target.files[0],

    // );
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.image = event.target.result;
    };
    reader.readAsDataURL(event1.target.files[0]);
    console.log(this.image);
    console.log(event1.target.files[0]);
  }

  currentStatusAndInspection() {
    this.isCurrentStatusInspection = true;
    if (this.currentStatusInspectionForm.invalid) {
      return false;
    }
  }

  public initialType: number;
  public isReInspection = true;
  public housingInspectionSubmitBtnDisable = false;
  AddInspectionForHousing() {
    this.housingInspectionSubmitBtnDisable = true;
    this.isInspection = true;
    // if (!this.isViolationSection) {
    //   this.inspectionForm.controls.unit_number.setErrors(null)
    //   this.inspectionForm.controls.violation_remarks.setErrors(null)

    // }
    if (this.certificateDetail.occupancy_type == 3) {
      this.inspectionForm.controls.no_of_residential_units.setErrors(null);
    }
    if (!this.isSelectDecisionFail) {
      this.inspectionForm.controls.inspection_email_to.setErrors(null);
      this.inspectionForm.controls.inspection_email_cc.setErrors(null);
    }
    if (this.inspectionForm.value.inspectionDecision == "9") {
      this.inspectionForm.controls.remark.setErrors(null);
      this.inspectionForm.controls.inspection_email_to.setErrors(null);
      this.inspectionForm.controls.inspection_email_cc.setErrors(null);
      this.inspectionForm.controls.no_of_residential_units.setErrors(null);
      this.inspectionForm.controls.no_of_commercial_units.setErrors(null);
    }
    if (this.inspectionForm.value.inspectionDecision == "4") {
      this.inspectionForm.controls.remark.setErrors(null);
      this.inspectionForm.controls.inspection_email_cc.setErrors(null);
      this.inspectionForm.controls.no_of_residential_units.setErrors(null);
      this.inspectionForm.controls.no_of_commercial_units.setErrors(null);
      this.inspectionForm.controls.odiance.setErrors(null);
    }
    if (this.inspectionForm.value.inspectionDecision == "2") {
      this.inspectionForm.controls.odiance.setErrors(null);
      this.inspectionForm.controls.no_of_commercial_units.setErrors(null);
      this.inspectionForm.controls.no_of_residential_units.setErrors(null);
    }
    if (this.isViolationSection && this.odianenceId.length == 0) {
      // this.isOrdianceMessage = true
      // this.selectOdianceMeassege = "Please select ordianence";
      // return false
      this.inspectionForm.controls.odiance.setErrors({ required: true });
    }

    if (this.allInspection.length == 0) {
      this.initialType = 1;
    }
    if (this.allInspection.length > 0) {
      this.allInspection.every((data, i) => {
        if (
          i == 0 &&
          (data.isVoid == 0 || data.isVoid == null || data.isVoid == 5)
        ) {
          this.initialType = 1;
          return false;
        } else {
          this.initialType = 2;
          return false;
        }
      });
    }
    if (!this.isReInspection || this.isReInspection) {
      this.inspectionForm.controls.no_of_residential_units.setErrors(null);
      this.inspectionForm.controls.no_of_commercial_units.setErrors(null);
    }

    if (this.certificateDetail.occupancy_type != 3) {
      this.inspectionForm.controls.insType.setErrors(null);
    }
    if (this.inspectionForm.invalid) {
      this.housingInspectionSubmitBtnDisable = false;
      return false;
    }

    const data = {
      application_id: this.certificateDetail.id,
      department_id: this.department.department_id,
      inspection_date: this.inspectionForm.value.inspectionDate,
      remark: this.inspectionForm.value.remark,
      decision: this.inspectionForm.value.inspectionDecision,
      // type: Number(this.inspectionForm.value.inspectionType),
      type: this.initialType,
      no_of_residential_units: Number(
        this.inspectionForm.value.no_of_residential_units
      ),
      no_of_commerical_units: Number(
        this.inspectionForm.value.no_of_commercial_units
      ),
      inspection_email_to: this.inspectionForm.value.inspection_email_to,
      // inspection_email_cc: this.inspectionForm.value.inspection_email_cc,
      inspection_email_cc: this.ccEmails
        ? this.ccEmails
        : this.withoutEnterCCValue,
      no_of_kitchen: this.inspectionForm.value.no_of_kitchen,
      no_of_bedrooms: this.inspectionForm.value.no_of_bedrooms,
      basement_finished: this.inspectionForm.value.basement_finished,
      basement_aprt: this.inspectionForm.value.basement_aprt,
      message: this.inspectionForm.value.message,
      type_key: this.typeValue,
      type_value: this.typekey,
      ordinance_id: this.odianenceId,
    };
    console.log("data is ", this.inspectionForm.value.inspection_email_cc);

    //  var formData = new FormData();
    // this.formData.append(
    //   "violationImage",
    //   event1.target.files[0],

    // );
    this.formData = new FormData();
    // const ord = JSON.stringify(this.odianenceId)
    // console.log(ord)
    // const neword = JSON.parse(ord)
    // console.log(neword, 'newordddddddddddddddddddddddd')
    if (this.attachment) {
      this.formData.append("violationImage", this.attachment);
    }
    if (this.ccEmails.length == 0) {
      if (this.withoutEnterCCValue) {
        if (this.validators[0].name == "must_be_email") {
          this._TS.error("Enter a valid email address");
          this.housingInspectionSubmitBtnDisable = false;
          return false;
        }
      }
    }

    Object.keys(data).forEach((key) => {
      if (key != "ordinance_id" && data[key] && key != "inspection_email_cc") {
        this.formData.append(key, data[key]);
      } else if (key == "ordinance_id" && data[key]) {
        this.formData.append("ordinance_id", JSON.stringify(this.odianenceId));
      } else if (key == "inspection_email_cc") {
        if (this.ccEmails.length == 0) {
          this.formData.append(
            "inspection_email_cc",
            JSON.stringify([this.withoutEnterCCValue])
          );
          if (this.withoutEnterCCValue) {
            if (this.validators[0].name == "must_be_email") {
              this._TS.error("Enter a valid email address");
              return false;
            }
          }
        } else {
          this.formData.append(
            "inspection_email_cc",
            JSON.stringify(this.ccEmails)
          );
        }
      }
    });

    console.log("email.com", this.validators[0].name);
    // return;
    this.dashboardService.inspection(this.formData).subscribe((data) => {
      this.inspectionForm.reset();
      this.messageEvent.emit(this.message);
      // this.isWaivOff = false
      this.isInspection = false;
      this.multipleOdiace = [];
      this.ccEmails = [];
      this.withoutEnterCCValue = null;
      this.isViolationSection = false;
      this.image = "";
      this.odianenceId = [];
      this.isSelectDecisionFail = false;
      this.isVoidSelect = true;
      this.getInspection();
      this.housingInspectionSubmitBtnDisable = false;
    });
  }

  selectinsType(value: any) {
    this.typekey = Number(value);
    this.inspection_type_status.forEach((data) => {
      if (this.typekey == data.key) {
        this.typeValue = data.value;
      }
    });
  }
  allInspection = [];
  public lastInspection = [];
  public isVoid: number = 0;
  public isFailandNoEntry = true;
  getInspection() {
    this.dashboardService
      .getInspectionForHousing(
        this.department.department_id,
        this.certificateDetail.id
      )
      .subscribe((data) => {
        this.allInspection = data.response;
        console.log(this.allInspection, "this.allInspection");
        this.allTransaction.map((data, i) => {
          this.allInspection.map((value) => {
            if (data.decision == 3 || (data.decision == 4 && i == 0)) {
              this.isFailandNoEntry = false;
            }
            if (data.inspection_id == value.id && data.status == 1) {
              value.isVoid = 2;
            } else if (data.inspection_id == value.id && data.status == 5) {
              value.isVoid = 5;
            }
          });
        });
        if (this.allInspection.length > 0) {
          this.allInspection.every((data, i) => {
            if (
              i == 0 &&
              (data.isVoid == 0 || data.isVoid == null || data.isVoid == 5)
            ) {
              this.initialType = 1;
              return false;
            } else {
              this.initialType = 2;
              this.isReInspection = false;
              return false;
            }
          });
        }
        console.log(this.allInspection, "snnnnnnnnnnnnnnnn");
        // this.findFeildInspection();
        if (this.allInspection.length > 0) {
          this.lastInspection = this.allInspection.filter((data, i) => {
            if (i == 0) {
              return data;
            }
          });
          this.isVoid = this.lastInspection[0].isVoid;
          console.log(this.isVoid);
        }
        console.log(this.lastInspection);
      });
  }
  public failedStatusForHousingVilation = [];
  findFeildInspection() {
    this.failedStatusForHousingVilation = this.allInspection.filter((_) => {
      if (_.decision == 3) {
        return _;
      }
    });
  }
  public validators = [this.must_be_email];
  public errorMessages = {
    must_be_email: "Enter valid email adress!",
  };
  private must_be_email(control: FormControl) {
    var EMAIL_REGEXP =
      /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
    if (
      control.value != "" &&
      (control.value.length <= 5 || !EMAIL_REGEXP.test(control.value))
    ) {
      return { must_be_email: true };
    }
    return null;
  }
  public inspectionFire = [];
  public lastInspectionFire = [];
  public isVoidFire: number = 0;
  inspectionForFire() {
    this.dashboardService
      .getInspectionForFire(
        this.department.department_id,
        this.certificateDetail.id
      )
      .subscribe((data) => {
        this.inspectionFire = data.response;

        this.allTransaction.map((data) => {
          this.inspectionFire.map((value) => {
            if (data.inspection_id == value.id && data.status == 1) {
              value.isVoid = 2;
            }
            if (data.inspection_id == value.id && data.status == 5) {
              value.isVoid = 5;
            }
          });
        });

        if (this.inspectionFire.length > 0) {
          this.inspectionFire.every((data, i) => {
            if (
              i == 0 &&
              (data.isVoid == 0 || data.isVoid == null || data.isVoid == 5)
            ) {
              this.initialType = 1;
              return false;
            } else {
              this.initialType = 2;
              this.isReInspection = false;
              return false;
            }
          });
        }
        if (this.inspectionFire.length > 0) {
          this.lastInspectionFire = this.inspectionFire.filter((data, i) => {
            if (i == 0) {
              return data;
            }
          });
          this.isVoidFire = this.lastInspectionFire[0].isVoid;
          console.log(this.isVoid);
        }
        this.findFeildInspectionForFire();
      });
  }

  findFeildInspectionForFire() {
    this.failedStatusForHousingVilation = this.inspectionFire.filter((_) => {
      if (_.decision == 3) {
        return _;
      }
    });
  }

  violationFire = [];
  violationForFire(inspectionId) {
    this.dashboardService
      .getAllViolationForFire(inspectionId)
      .subscribe((data) => {
        this.violationFire = data.response;
        this.violationForm.reset();
        this.isViolation = false;
      });
  }

  // allAssesorInspection = [{}]
  // getAssesorInspection(){
  //   this.dashboardService.getAssesorInspection(this.certificateDetail.id).subscribe(data=>{
  //     this.allAssesorInspection = data.response
  //   })
  // }

  public showOrdinancePopup = false;
  showOrdinanceTable() {
    this.showOrdinancePopup = true;
    this.getOrdinance({ page: this.currentPagination });
  }

  public ordinance: any = [];
  public totalPagination: number = Number(0);
  public offset: any = 1;
  public currentPagination: number = Number(1);
  public getOrdinance(params: any) {
    params.admin_id = this.certificateDetail["admin_id"];
    this.dashboardService
      .getOrdinanceData(params)
      .subscribe(({ response, ...pagination }) => {
        this.ordinance = response.docs;
        this.ordinance.map((data) => {
          data.isChecked = false;
          this.multipleOdiace.map((odiance, i) => {
            if (data.id == odiance.id) {
              data.isChecked = true;
            }
          });
        });

        console.log(this.ordinance);
        this.totalPagination = response.total;
        this.offset = response.offset;
        this.currentPagination = response.currentPage;
      });
  }

  paginate(value: any) {
    this.getOrdinance({ page: value });
  }

  public hideForm = false;
  odianceId: number;
  selectedOrdiance(item: any) {
    // if(this.department.department_id == 1){
    //   this.inspectionForm.controls.value.
    // }
    this.hideForm = true;
    this.odianceId = item.id;
    this.violationForm.controls["violation"].setValue(item.violation);
    this.violationForm.controls["code"].setValue(item.code);
    this.violationForm.controls["violation_description"].setValue(
      item.violation_description
    );
  }

  public multipleOdiace = [];
  public isOrdianceMessage = false;
  public odianenceId = [];
  selectMultipleOdiance(item: any, event) {
    item.isFloorAdd = false;
    if (event == "delete") {
      this.multipleOdiace.map((data, i) => {
        if (item.id == data.id) {
          this.multipleOdiace.splice(i, 1);
          this.odianenceId.splice(i, 1);
        }
      });
    } else {
      if (event.target.checked) {
        this.multipleOdiace.push(item);
        this.odianenceId.push({
          id: item.id,
          floor: null,
          application_id: null,
          department_id: null,
          unit_number: null,
          violation_remarks: null,
        });
        if (this.odianenceId.length > 0) {
          this.isOrdianceMessage = false;
        }
      } else {
        this.multipleOdiace.map((data, i) => {
          if (item.id == data.id) {
            this.multipleOdiace.splice(i, 1);
            this.odianenceId.splice(i, 1);
          }
          if (this.odianenceId.length == 0) {
            this.isOrdianceMessage = true;
          }
        });
      }
    }
    if (
      (this.department.department_id == 1 ||
        this.department.department_id == 6) &&
      this.odianenceId.length > 0
    ) {
      this.inspectionForm.controls.odiance.setErrors(null);
      this.habitivilityInspectionForm.controls.odiance.setErrors(null);
    } else if (
      this.department.department_id == 2 &&
      this.odianenceId.length > 0
    ) {
      this.fireInspectionForm.controls.odiance.setErrors(null);
    }
  }

  public isMixedUse = false;
  public isResidetialSelect = false;
  public isMixedUseSelect = false;
  public isViolationUser = false;
  changeValueBySelectInputInfire(value: string) {
    if (value == "1") {
      this.isMixedUse = false;
      this.isCurrentStatus = false;

      if (!this.isSelectNoEntry) {
        this.isResidetialSelect = false;
        this.isViolationUser = false;
        this.isMixedUseSelect = false;
      } else if (this.isDecisionPass) {
        this.isViolationUser = false;
        this.isResidetialSelect = true;
        this.isMixedUseSelect = false;
      } else {
        this.isResidetialSelect = true;
        this.isViolationUser = true;
        this.isMixedUseSelect = false;
      }
    } else if (value == "3") {
      this.isMixedUse = true;
      if (!this.isSelectNoEntry) {
        this.isMixedUse = true;
        this.isResidetialSelect = false;
        this.isMixedUseSelect = false;
        this.isViolationUser = false;
      } else if (this.isDecisionPass) {
        this.isViolationUser = false;
        this.isResidetialSelect = true;
        this.isMixedUseSelect = true;
      } else {
        this.isResidetialSelect = true;
        this.isMixedUseSelect = true;
        this.isViolationUser = true;
      }
    } else {
      this.isResidetialSelect = false;
      this.isMixedUseSelect = false;
      this.isViolationUser = false;
    }
  }

  public isMialSection = false;
  public isSelectNoEntry = true;
  public isDecisionPass = false;
  public isCurrentStatus = false;
  public isViolationSection = false;
  changeDecisionInput(value: string) {
    if (value == "2") {
      this.fireInspectionForm.controls.message.setValue(null);
      this.isViolationSection = false;
      this.isDecisionPass = true;
      this.isResidetialSelect = true;
      this.isViolationUser = false;
      this.isCurrentStatus = false;

      if (this.isMixedUse) {
        this.isMixedUseSelect = true;
      } else {
        console.log();
      }
      this.isSelectNoEntry = true;
      this.isMialSection = false;
    } else if (value == "3") {
      this.isCurrentStatus = false;
      this.isDecisionPass = false;
      this.isResidetialSelect = true;
      this.isViolationUser = true;
      this.isViolationSection = true;
      if (this.isMixedUse) {
        this.isMixedUseSelect = true;
      } else {
        this.isMixedUseSelect = false;
      }
      this.isMialSection = true;
      this.isSelectNoEntry = true;
      this.fireInspectionForm.controls["to"].setValue(
        this.certificateDetail.applicant_email
      );
      this.fireInspectionForm.controls.message.setValue(
        "Upon inspection of your property in reference to your application for a Certificate of Occupancy, we found the attached violations. Based on the Township’s Ordinance you can correct the identified violations and reschedule another inspection, alternatively the buyer can agree to purchase the property “as-is” and take the responsibility of correcting the violations after closing."
      );
    } else if (value == "4") {
      this.isViolationSection = false;

      this.isSelectNoEntry = false;
      this.isDecisionPass = false;
      this.isMialSection = true;
      this.isResidetialSelect = false;
      this.isViolationUser = false;
      this.isMixedUseSelect = false;
      this.isCurrentStatus = false;
      this.fireInspectionForm.controls.message.setValue(
        "Upon inspection of your property in reference to your application for a Certificate of Occupancy, we found the attached violations. Based on the Township’s Ordinance you can correct the identified violations and reschedule another inspection, alternatively the buyer can agree to purchase the property “as-is” and take the responsibility of correcting the violations upon closing. If the buyer intends to take responsibility for correcting the violations upon closing, she or he will need to complete an affidavit sign and notarized it and submit it to this office. Once we received the signed and notarized Affidavit we will provide you with a Temporary Certificate of Occupancy."
      );
      this.fireInspectionForm.controls["to"].setValue(
        this.certificateDetail.applicant_email
      );
    } else if (value == "11") {
      this.isViolationSection = false;

      this.isSelectNoEntry = false;
      this.isDecisionPass = false;
      this.isMialSection = false;
      this.isCurrentStatus = true;
      this.isViolationUser = false;
      this.isResidetialSelect = false;
      this.isMixedUseSelect = false;
      this.fireInspectionForm.controls.message.setValue(null);
    }
  }

  currentStatus(value: string) {
    if (value == "2") {
      this.isMialSection = true;
      this.fireInspectionForm.controls.message.setValue(null);
      this.fireInspectionForm.controls["to"].setValue(
        this.certificateDetail.applicant_email
      );
    } else {
      this.isMialSection = false;
    }
  }

  public inspectionId: any;
  getInspectionId(inspectionId: any) {
    this.inspectionId = inspectionId;
  }

  changeInspectionStatusInVoid() {
    const data = {
      id: this.inspectionId,
    };
    console.log(data);
    this.dashboardService.changeStatusInVoid(data).subscribe((data) => {
      this.hideModal();
      this._TS.success("Voided Inspection");
      if (this.department.department_id == 1) {
        this.getInspection();
      } else {
        this.inspectionForFire();
      }
      this.messageEvent.emit(this.message);
    });
  }

  hideModal() {
    this.inspectionModal.nativeElement.click();
  }

  hideOdianceModel() {
    this.odiancepopup.nativeElement.click();
  }

  hideImage() {
    this.image = "";
  }

  searchOrdiance(key: any) {
    const data = {
      filters: key,
      page: this.currentPagination,
      admin_id: this.certificateDetail["admin_id"],
    };

    this.dashboardService
      .getOrdinanceData(data)
      .subscribe(({ response, ...pagination }) => {
        this.ordinance = response.docs;
        this.ordinance.map((data) => {
          data.isChecked = false;
          this.multipleOdiace.map((odiance, i) => {
            if (data.id == odiance.id) {
              data.isChecked = true;
            }
          });
        });

        console.log(this.ordinance);
        this.totalPagination = response.total;
        console.log("pagination value is ===>", response);
        this.offset = response.offset;
        this.currentPagination = response.currentPage;
      });
  }
  public isHabitibilty = false;
  habitivilityInspection() {
    this.isHabitibilty = true;
    if (this.habitivilityInspectionForm.value.decision == 2) {
      this.habitivilityInspectionForm.controls.violation_remarks.setErrors(
        null
      );
      this.habitivilityInspectionForm.controls.inspection_email_to.setErrors(
        null
      );
      this.habitivilityInspectionForm.controls.odiance.setErrors(null);
    }
    if (
      this.habitivilityInspectionForm.value.decision == 3 ||
      this.habitivilityInspectionForm.value.decision == 4
    ) {
      this.habitivilityInspectionForm.controls["inspection_email_to"].setValue(
        this.certificateDetail.applicant_email
      );
    }
    if (this.habitivilityInspectionForm.value.decision == 4) {
      this.habitivilityInspectionForm.controls.odiance.setErrors(null);
    }

    if (this.habitivilityInspectionForm.invalid) {
      return false;
    }
    const data = {
      application_id: this.certificateDetail.id,
      department_id: this.department.department_id,
      unit_id: this.habitivilityInspectionForm.value.unit,
      type: this.habitivilityInspectionForm.value.inspctionType,
      decision: this.habitivilityInspectionForm.value.decision,
      inspection_date:
        this.habitivilityInspectionForm.value.inspectiondateAndTime,
      remark: this.habitivilityInspectionForm.value.remarks,
      message: this.habitivilityInspectionForm.value.violation_remarks,
      inspection_email_cc: this.ccEmails,
      inspection_email_to:
        this.habitivilityInspectionForm.value.inspection_email_to,
      ordinance_id: this.odianenceId,
    };

    this.formData = new FormData();
    this.formData.append("violationImage", this.attachment);
    Object.keys(data).map((key) => {
      if (
        key != "ordinance_id" &&
        data[key] &&
        key != "unit_id" &&
        key != "inspection_email_cc"
      ) {
        this.formData.append(key, data[key]);
      } else if (key == "ordinance_id" && this.odianenceId) {
        this.formData.append("ordinance_id", JSON.stringify(this.odianenceId));
      } else if (
        key == "unit_id" &&
        this.habitivilityInspectionForm.value.unit
      ) {
        this.formData.append(
          "unit_id",
          JSON.stringify(this.habitivilityInspectionForm.value.unit)
        );
      } else if (key == "inspection_email_cc") {
        this.formData.append(
          "inspection_email_cc",
          JSON.stringify(this.ccEmails)
        );
      }
    });

    this.dashboardService.inspection(this.formData).subscribe((data) => {
      this.habitivilityInspectionForm.reset();
      this.image = false;
      this.isNoEntry = false;
      this.isFail = false;
      this.isHabitibilty = false;
      this.getInspection();
      this.unitAccouringByInspector();
      this.messageEvent.emit(this.message);
    });
  }

  public isFail = false;
  public isNoEntry = false;
  habitivilityFailAndNoEntry(value) {
    if (value == 4) {
      this.isNoEntry = true;
      this.isFail = false;
      this.habitivilityInspectionForm.controls["inspection_email_to"].setValue(
        this.certificateDetail.applicant_email
      );
    } else if (value == 2) {
      this.isNoEntry = false;
      this.isFail = false;
    } else if (value == 3) {
      this.isFail = true;
      this.isNoEntry = false;
      this.habitivilityInspectionForm.controls["inspection_email_to"].setValue(
        this.certificateDetail.applicant_email
      );
    }
  }

  public isUnitPresent = true;
  unitAccouringByInspector() {
    const data = {
      application_id: this.certificateDetail.id,
    };
    this.dashboardService.getunitByInspector(data).subscribe((data) => {
      this.allUnits = data.response;
      if (this.allUnits.length > 0) {
        this.isUnitPresent = false;
      }
      data.response.map((data, i) => {
        if (i == 0) {
          this.allUnits = [];
        }

        if (data.decision == 1 && (data.status == 3 || data.status == 5)) {
          console.log("call");
          this.allUnits.push({ id: data.id, unit: data.unit });
        }
      });
    });
  }
  public assignInspector = [];
  public inspectionLeft = false;
  public findunit_decession: any;
  assiendInspector() {
    const data = {
      application_id: this.certificateDetail.id,
      department_id: this.department.department_id,
    };
    this.dashboardService.getAssignedInspector(data).subscribe((data) => {
      this.inspectionLeft = false;
      this.assignInspector = data.response;
      this.findunit_decession = this.assignInspector.find((value) => {
        let decision = value.unit ? value.unit.decision : null;
        if (decision == 1) return true;
      });

      this.inspectionType = [];
      let filteredInspectionType = [];
      let filteredInspectionType2 = [];
      let filteredInspectionType3 = [];
      if (this.assignInspector.length > 0) {
        this.assignInspector.forEach((inspectorObj) => {
          if (inspectorObj.is_inspected == null) {
            this.inspectionLeft = true;
          }
        });

        console.log(this.assignInspector, "this.assignInspector");
        debugger;
        this.assignInspector.forEach((insp) => {
          if (
            insp.type_value != null &&
            insp.type_key != null &&
            insp.is_inspected != 1 &&
            insp.is_inspected != 4
          ) {
            this.inspectionType.push({
              key: insp.type_value,
              value: insp.type_key,
            });
          }
        });
        console.log(this.inspectionType, " this.inspectionType.");
        this.assignInspector.forEach((inspectorObj) => {
          if (inspectorObj.is_inspected == 1) {
            filteredInspectionType = this.inspectionType.filter(
              (obj) => inspectorObj.type_value !== obj.key
            );
          }
          debugger;
          if (filteredInspectionType.length > 0) {
            this.inspectionType = filteredInspectionType;
          }
        });
        this.certificateDetail["failedUnitIds"].forEach((obj) => {
          filteredInspectionType2 = this.inspectionType.filter(
            (obj1) => obj.type_value !== obj1.key
          );
        });
        debugger;
        if (filteredInspectionType2.length > 0) {
          this.inspectionType = filteredInspectionType2;
        }
        this.certificateDetail["passedUnitIds"].forEach((obj) => {
          filteredInspectionType3 = this.inspectionType.filter(
            (obj1) => obj.type_value !== obj1.key
          );
        });
        debugger;
        if (filteredInspectionType3.length > 0) {
          this.inspectionType = filteredInspectionType3;
        }
        console.log(this.inspectionType);
      }
      console.log(this.inspectionLeft, "this.inspectionLeft");
    });
  }

  public items = ["tet", "dd"];
  public ccEmails = [];
  onAdding(value) {
    this.ccEmails.push(value.name);
    console.log(this.ccEmails);
  }

  onRemoving(value) {
    this.ccEmails.forEach((data, i) => {
      if (data == value.name) {
        this.ccEmails.splice(i, 1);
      }
    });
    console.log(this.ccEmails);
  }

  initializeRescheduleInspectionFormByInspector() {
    this.inspectionRescheduleForm = this.formBuilder.group({
      inspection_date: ["", [Validators.required]],
      inspectionTime: ["", [Validators.required]],
    });
  }

  rescheduleInspectionByInspector() {
    this.isRescheduleByInspectorSubmit = true;
    if (this.inspectionRescheduleForm.invalid) {
      return false;
    }
    const payload = {
      application_id: this.certificateDetail.id,
      department_id: this.department.department_id,
      inspection_date:
        moment(this.inspectionRescheduleForm.value.inspection_date).format(
          "YYYY-MM-DDT00:00:00.000"
        ) + "Z",
      inspection_range: Number(
        this.inspectionRescheduleForm.value.inspectionTime
      ),
      inspector_id: this.assignInspector[0].staff_id,
      type_value: 2,
    };
    this.dashboardService.assignedInspector(payload).subscribe((data) => {
      this.rescheduleByInspector.nativeElement.click();
      this.inspectionRescheduleForm.reset();
      this.isRescheduleByInspectorSubmit = false;
      this.assiendInspector();
      this.messageEvent.emit(this.message);
      this.dashboardService.assignedInspectionsLatest.next(data);
    });
  }
  ordinanaceBlank() {
    this.multipleOdiace = [];
  }
}

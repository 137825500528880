import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { StaffService } from 'src/app/core/http';
import { staff } from '../../../../core/models/admin/index'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-manage-city',
  templateUrl: './manage-city.component.html',
  styleUrls: ['./manage-city.component.css']
})
export class ManageCityComponent implements OnInit {
  @ViewChild('exampleModals', { static: false }) exampleModals: ElementRef;
  @ViewChild('stripeIdModel', { static: false }) stripeIdModel: ElementRef;

  @ViewChild('inviteSend', { static: false }) inviteSend: ElementRef;

  public staffList: staff[];
  public setPasswordForm: FormGroup;
  public stripForm: FormGroup;

  public isHide = false
  public isHidePasswordModel = false;
  submitted = false;
  public offset: number = 10;
  public type: number = 1;
  public currentPagination: number = Number(1);
  public pagination: {
    pages: any,
    total: number,
  } = {
      pages: 0,
      total: 0,
    };
  constructor(private staffSerivce: StaffService,

    private router: Router,
    private _TS: ToastrService,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.allCityAdmin({ page: this.type });
    this.passwordForm();
    this.stripIdForm();

  }


  allCityAdmin(params: object): void {

    this.staffSerivce.getCityAdmin(params).subscribe((data) => {
      this.staffList = data.response.docs;
      this.offset = data.response.offset;
      this.pagination.total = data.response.total;
      this.pagination.pages = Array(data.response.pages).fill(0).map((x, i) => i + 1);
      this.currentPagination = Number(data.response.currentPage);

    })
  }

  paginate(value: number): void {
    this.allCityAdmin({ page: value });
  }

  getSingleStaff(staffId) {
    this.router.navigate(['/admin/add/city'], { queryParams: { staffId: staffId } })
  }
  staffId: number
  getStaffId(staffId: number) {
    this.staffId = staffId
  }

  deleteStaff() {
    this.staffSerivce.deleteStaff(this.staffId).subscribe(data => { })
    this.allCityAdmin({ page: this.type });
    this.isHide = true
  }

  get f() { return this.setPasswordForm.controls; }

  passwordForm() {
    this.setPasswordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      cnfPassword: ['', Validators.required]
    }, {
    });
  }

  stripIdForm() {
    this.stripForm = this.formBuilder.group({
      stripId: ['', Validators.required],
    },
    );
  }

  get stripControls() { return this.stripForm.controls }
  staffIdForSetPass: number
  findDepartmentId(value: any) {
    this.staffIdForSetPass = value
  }

  public isStrip = false;
  setStripId() {
    this.isStrip = true;
    if (this.stripForm.invalid) {
      return false
    }

    const data = {
      admin_id: this.staffIdForSetPass,
      stripe_account_id: this.stripForm.value.stripId
    }
    this.staffSerivce.setStripId(data).subscribe(data => {
      this.stripeIdModel.nativeElement.click();
    })
  }

  setPassword() {

    this.submitted = true;
    if (this.setPasswordForm.invalid) {
      return false
    }
    this.setPasswordForm.value.id = this.staffIdForSetPass;
    this.staffSerivce.createPasswordForAdmin(this.setPasswordForm.value).subscribe(data => {
      this.submitted = false;
      this.setPasswordForm.reset();
      this._TS.success("password change successfully")
      this.hideModel()
    })

  }
  hideModel() {
    this.exampleModals.nativeElement.click();
  }



  passwordMatch(value: string) {
    if (value == 'conpass') {
      return this.setPasswordForm.controls.password.value === this.setPasswordForm.controls.cnfPassword.value
        ? false : this.setPasswordForm.get('cnfPassword').setErrors({ 'incorrect': true });
    } else if (value == 'pass' && this.setPasswordForm.controls.password.value === this.setPasswordForm.controls.cnfPassword.value) {
      this.setPasswordForm.get('cnfPassword').setErrors(null);
    } else if (value == 'pass' && this.setPasswordForm.controls.password.value != this.setPasswordForm.controls.cnfPassword.value) {
      this.setPasswordForm.get('cnfPassword').setErrors({ 'incorrect': true });
    }
  }


  // deleteAdmin() {
  //   
  //   this.staffSerivce.deleteAdmin(this.staffId).subscribe(data => {
  //     this.allCityAdmin({ page: this.type })
  //   })
  // }

  sendInvitation() {
    this.staffSerivce.inviteSendForCityAndStaff({ id: this.staffIdForSetPass }).subscribe(data => {
      // this._TS.success('Invite send on your mail');
      this.inviteSend.nativeElement.click()
    })
  }
}

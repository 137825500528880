import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { error } from 'jquery';
import { values } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { codeEnforcementSettingConfig } from 'src/app/configs';
import { DashboardService } from 'src/app/core/http';
import { CeComplaintService } from 'src/app/core/http/admin/ce-complaint.service';
import { AddComplaintService } from 'src/app/core/http/users/code-Enforcement/add-complaint.service';
import { compliant_details } from 'src/app/core/models/admin/code-enforcement/complantDetails.model';
import { AdminAuthenticationService } from 'src/app/core/authentication/admin-authentication.service';

@Component({
  selector: 'app-work-order-inspection',
  templateUrl: './work-order-inspection.component.html',
  styleUrls: ['./work-order-inspection.component.css']
})
export class WorkOrderInspectionComponent implements OnInit {
  public inspectionForm: FormGroup;
  public assignInspectionForm: FormGroup;
  public setting: any
  public my_started_time: any
  public my_ended_time: any

  public comlainId: any;
  public env: any;
  public ordinanceForm: FormGroup;
  public formData = new FormData()
  public ins_id: any
  public app_id: any
  public workOrderId: any
  public currentDate:any;
  public complainId:any;
  public testord = [
    {
      code: '555', voilation: 'test88', description: 'test255565', violation_id: 1
    }
  ]
  @ViewChild('odiancepopup', { static: false }) odiancepopup: ElementRef;
  @ViewChild('addOrd', { static: false }) addOrd: ElementRef;

  public complaintDetails: compliant_details = {
    id: null,
    address: null,
    complaint_type: null,
    user_phone: null,
    createdAt: null,
    also_know_as: null,
    inspection: [],
    assign_inspector:null,
    google_address:null,
    location_type:null,
    complain_type:null,
    addresses:null,
    work_order_request:null,
    complaint_attachment:[]



  }
  public city_id: any;
  public department = {
    role_id: null,
    department_id: null,
  }
  constructor(
    private _CEService: CeComplaintService,
    private router: Router,
    private route: ActivatedRoute,
    private _FB: FormBuilder,
    private _TS: ToastrService,
    private addComplaint: AddComplaintService,
    private dashboardSrvice: DashboardService,
    private adminAuthService: AdminAuthenticationService,

  ) { this.setting = codeEnforcementSettingConfig }

  ngOnInit() {
    this.currentDate = new Date()
    this.housingManager()
    this.inspectionControls()
    this.ordinanceFormConrol();
    this.assignInspectionForms();
    this.route.queryParams.subscribe(data => {
      this.comlainId = data['id'];
      this.complainId = data['complainId'];
      this.app_id = data['app_id']
      this.ins_id = data['ins_id']
      this.workOrderId = data['id'];

    })

    if (this.workOrderId && this.complainId) {
      // this.comlaintAllDetails()
      this.getWorkOrderDetails()
    }
  
    this.my_started_time = new Date();
    this.my_ended_time = new Date();

    this.route.queryParams.subscribe(data => {
      this.city_id = data.city_id
    });
    this.department = this.adminAuthService.getPermission()
    console.log("depart",this.department)

  }
  inspectorList = [];
  public inspectorId:any;
  
  getInspector() {
    this.dashboardSrvice.getInspectors({ department_id: 1,city_id:this.complaintDetails.city_id }).subscribe(data => {
      this.inspectorList = data.response;
      if(this.inspectorId){
        this.inspectorList.filter(item=>{
         if(item.id == this.inspectorId){
             this.assignInspectionForm.patchValue({
              inspector:this.inspectorId
             })
         }
        })
      }
    })
  }

  assignInspectionForms(){
    this.assignInspectionForm = this._FB.group({
      inspector:['',Validators.required],
      comment: [],
  });
  }
  isAssignInspector:any
  get inspectionCons() { return this.assignInspectionForm.controls }
  assigenInspector() {
    if (this.assignInspectionForm.invalid) {
      // this.isAssignInspector = true
      return false
    }
    // this.assignInspectionForm.value.application_id = this.complaintDetails.id;
    this.assignInspectionForm.value.order_id = this.workOrderId
    this.assignInspectionForm.value.inspector = Number(this.assignInspectionForm.value.inspector)
    this._CEService.assignWorderInspector(this.assignInspectionForm.value).subscribe(data => {
      // this.isAssignInspector = false;
      this.getWorkOrderDetails()
      // this.assignInspector.nativeElement.click()
      this.assignInspectionForm.reset()
      this._TS.success('Inspector has been assigned');
    })
  }

  newArryj = [];
  workOrderDetails = [];
  // complaintDetails = [];
  getWorkOrderDetails() {
    this.newArryj = [];
    const data = {
      camplaint_id: this.complainId,
      id: this.workOrderId
    }
    this._CEService.getAllWorkOrderDetails(data).subscribe(wordOrder => {
      this.workOrderDetails = wordOrder['response']['work_order_request'];
      this.complaintDetails = wordOrder['response'];
      this.inspectorId = wordOrder.response.work_order_request ?  wordOrder.response.work_order_request.assign_inspector :null;
      this.getInspector();
      // this.getInspector();
      // console.log("complain detail ", this.complaintDetails.inspection )
      // this.inspectorName = (wordOrder['response'].assign_inspector && wordOrder['response'].assign_inspector.assign_inspector.name)
      // this.getInspector()
      // this.complaintDetails.inspection.forEach((insp: any, i) => {
      //   debugger
       
      //   if(this.isId == insp.id){
      //     insp.active = true;
      //   }
       
      //   if (i == 0) {
      //     this.ins = insp;
      //     this.workOrdrDetails = this.ins['work_order_request'][0]

      //   }
      //   if(insp.active == true){
      //     this.newArryj.push(insp)
      //     this.ins = insp
      //   }
      //   if(insp.active != true){
      //     this.newArryj.push(insp)
      //   }    
      // })
      // console.log(this.complaintDetails.inspection)
      // // if(this.complaintDetails.inspection){
      // //   // this.isTrue = true;
      // //  this.complaintDetails.inspection.forEach((x:any) => {
        
      // //   })
      //   if(this.isTrue){
      //     this.newArryj.forEach(x=>{
      //       if(this.ins.id == x.id)
      //       x.active = true
      //     })
      //   }
      //   this.ins.work_order_request.forEach((item,i) => {
      //     if(i == 0){
      //      item.Workactive = true;
      //     }
      //     console.log("sort Data is", i)
      //   });

    })
  }

  ordinanceFormConrol() {
    this.ordinanceForm = this._FB.group({
      unit_number: [],
      //floor: [],
      violation_remarks: [],
    })
  }
  inspectionControls() {
    this.inspectionForm = this._FB.group({
      date: ['', Validators.required],
      time_started: ['', Validators.required],
      time_ended: ['', Validators.required],
      supervisor: ['', Validators.required],
      cost_of_material: ['', Validators.required],
      cost_of_personnel: ['', Validators.required],
      description: [null],
   //   status: [5]
    })
  }
  onKeyPress(event: KeyboardEvent) {
    const inputValue = event.target as HTMLInputElement;
    const keyCode = event.keyCode;
    console.log("key code",keyCode)

    if (keyCode === 8 ) {
      // this.amount = '';
            // this.inspectionForm['controls'].cost_of_material.setValue(null)

      return;
    }

    if ((keyCode < 48 || keyCode > 57) && keyCode !== 190) {
      event.preventDefault();
      return;
    }

    if (inputValue.value.length === 0) {
      inputValue.value = '$';
    }

    if (inputValue.value === '$0') {
      inputValue.value = '$';
      // event.preventDefault();
      return;
    }
  }
// }
isTrue = false
  addDoller(event) {
    if(event.target.value.length > 0){
      this.isTrue = true
      // this.inspectionForm['controls'].cost_of_material.setValue(value.concat(this.inspectionForm.value.cost_of_material))
     }else if(event.target.value.length == 0){
      this.isTrue = false
     }
  }
  isTruep = false
  addDollerp(event) {
    if(event.target.value.length > 0){
      this.isTruep = true
      // this.inspectionForm['controls'].cost_of_material.setValue(value.concat(this.inspectionForm.value.cost_of_material))
     }else if(event.target.value.length == 0){
      this.isTruep = false
     }
  }

  get inspectionCon() { return this.inspectionForm.controls }
  public isInspection = false
  inspections() {
    for(let i = 0 ; i < this.image.length ; i++){
      this.formData.append("work_order_inspection_attachment", this.image[i].work_order_inspection_attachment);
      }
    if (this.inspectionForm.invalid) {
      this.isInspection = true
      return false
    }
    Object.keys(this.inspectionForm.value).forEach(value => {
      if (value != 'time_started' && value != 'time_ended') {
        this.formData.append(value, this.inspectionForm.value[value])

      }
    })
    this.formData.append('time_started', this.inspectionForm.value.time_started)
    //this.formData.append('supervisor', this.supervisor)

    this.formData.append('time_ended', this.inspectionForm.value.time_ended)
    this.formData.append('work_order_request_id', this.workOrderId)
    this.formData.append('application_id', this.app_id)
    this.formData.append('inspection_id', this.ins_id)
    this._CEService.inspectionForWorkOrder(this.formData).subscribe(data => {
      this.inspectionForm.reset()
      this.isInspection = false;
      this.image = []
      this.formData = new FormData()
      this.isShow = false
      //   this.multipleOrd = []
      this.back();
      this._TS.success('Inspection has been done successfully.')
    }, error => {
      this.formData = new FormData()
    })
  }

  addDollerSign(value){
    console.log("data is===>",value.target.value.length)
  }



  back() {
    this.router.navigate(['/admin/ce/work-orders-details'], { queryParams: { cmpId: this.app_id, id: this.workOrderId } })
  }

  public isShow = false
  selectType(value) {
    if (value == 4) {
      this.isShow = true

    } else {
      this.isShow = false

    }
  }


  public imageName: any;
  public attachment: any
  public image = []
  public i = 1
  media(event1) {
    this.imageName = event1.target.files[0].name;
    this.attachment = event1.target.files[0]
    this.image.push({ name: this.imageName, date: new Date(), id: this.i,'work_order_inspection_attachment':this.attachment })
    this.i = this.i + 1
    console.log(event1.target.files[0])

  }

  deleteImage(id) {
    this.image.forEach((data, i) => {
      if (data.id == id) {
        this.image.splice(i, 1)
      }
    })
  }

  public housingManger = []
  housingManager() {
    
    this.addComplaint.getSatff().subscribe(data => {
      let staff = data
      if (data) {
        this.housingManger = data.response.filter(manger => {
          if (manger.role_id == 3) {
            return manger
          }
        })

      }
    })
  }
  public supervisor: any;
  selectManager(mangerId) {
    
    this.supervisor = Number(mangerId)
  }


  timeChange(){
    
    this.inspectionForm.controls.time_ended.setValue(null)
  }
}



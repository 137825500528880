import { FireComponent } from './certificates/fire/fire.component';
import { AssesorComponent } from './certificates/assesor/assesor.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HousingComponent } from './certificates/housing/housing.component';
import { TaxesComponent } from './certificates/taxes/taxes.component';
import { CertificateDetailComponent } from './certificates/housing/certificate-detail/certificate-detail.component';
import { ZoningComponent } from './certificates/zoning/zoning.component';
import { PaymetsSummaryComponent } from './certificates/paymets-summary/paymets-summary.component';
import { ManageStaffComponent } from './certificates/manage-staff/manage-staff.component';
import { AddnewStaffMemberComponent } from './certificates/housing/addnew-staff-member/addnew-staff-member.component';
import { RolesAndPermissionsComponent } from './certificates/housing/roles-and-permissions/roles-and-permissions.component';
import { ManageDepartmentAndRoleComponent } from './certificates/manage-department-and-role/manage-department-and-role.component';
import { ActiveInactiveroleComponent } from './certificates/housing/active-inactiverole/active-inactiverole.component';
import { CertificateDetailsComponent } from './certificates/housing/certificate-details/certificate-details.component';
import { AdminPermissionGuard } from 'src/app/core/guards/admin-permission.guard';
import { ManageCityComponent } from './certificates/manage-city/manage-city.component';
import { AddCityComponent } from './certificates/add-city/add-city.component';
import { AddOrdinanceComponent } from './certificates/add-ordinance/add-ordinance.component';
import { OrdinanceListComponent } from './certificates/ordinance-list/ordinance-list.component';
import { ApplicationTimelineComponent } from './certificates/application-timeline/application-timeline.component';
import { ComplaintComponent } from './code-enforcement/complaint/complaint.component';
import { AddCopmalaintFromAdminComponent } from './code-enforcement/add-copmalaint-from-admin/add-copmalaint-from-admin.component';
import { ComplaintDetailsComponent } from './code-enforcement/complaint-details/complaint-details.component';
import { InspectionComponent } from './code-enforcement/inspection/inspection.component';
import { WorkOrdersComponent } from './code-enforcement/work-orders/work-orders.component';
import { WorkOrderDetailsComponent } from './code-enforcement/work-order-details/work-order-details.component';
import { WorkOrderInspectionComponent } from './code-enforcement/work-order-inspection/work-order-inspection.component';
import { ShowWardComponent } from './certificates/show-ward/show-ward.component';
import { AddWardComponent } from './certificates/add-ward/add-ward.component';
import { BuilingCCOComponent } from './certificates/builing-cco/builing-cco.component';
import { AddInspectionDateComponent } from './certificates/add-inspection-date/add-inspection-date.component';
import { CourtDetailsComponent } from './code-enforcement/court-details/court-details.component';
import { NotesDetailsComponent } from './code-enforcement/notes-details/notes-details.component';
import { StaffAddTypeComponent } from './certificates/housing/staff-add-type/staff-add-type.component';
import { SummaryIndexComponent } from './code-enforcement/summary-index/summary-index.component';
//import { SearchPipe } from './pipe/search.pipe';

const routes: Routes = [
  {
    path: 'certificates',
    children: [
      {
        path: 'housing',
        component: HousingComponent
      },
      {
        path: 'cco',
        component: BuilingCCOComponent
      },
      {
        path: 'housing/detail/:id',
        component: CertificateDetailComponent
      },
      {
        path: 'details/:id',
        component: CertificateDetailsComponent
      },
      {
        path: 'assesor',
        component: AssesorComponent
      },
      {
        path: 'taxes',
        component: TaxesComponent
      },
      {
        path: 'zoning',
        component: ZoningComponent
      },
      {
        path: 'fire',
        component: FireComponent
      },
      {
        path: 'paymetsSummary',
        component: PaymetsSummaryComponent
      },
      {
        path: 'time-line',
        component: ApplicationTimelineComponent
      }
    ]
  },

  {
    path: 'ce',
    children: [
      {
        path: 'complaint',
        component: ComplaintComponent
      },

      {
        path: 'add-complaint',
        component: AddCopmalaintFromAdminComponent
      },
      {
        path: 'complain-details',
        component: ComplaintDetailsComponent
      },
      {
        path: 'ce-inspection',
        component: InspectionComponent
      },
      {
        path: 'work-orders',
        component: WorkOrdersComponent
      },
      {
        path: 'work-orders-details',
        component: WorkOrderDetailsComponent
      },
      {
        path: 'work-orders-inspection',
        component: WorkOrderInspectionComponent
      },
      {
        path: 'court',
        component: CourtDetailsComponent
      },
      {
        path: 'notes',
        component: NotesDetailsComponent
      },
      {
        path: 'summary-index',
        component: SummaryIndexComponent
      },


    ]
  },
  {
    path: 'department',
    component: ManageDepartmentAndRoleComponent
  },
  {
    path: 'role',
    component: ActiveInactiveroleComponent
  },
  {
    path: 'roles/permissions',
    component: RolesAndPermissionsComponent
  },
  {
    path: 'manage/staff',
    component: ManageStaffComponent
  },
  {
    path: 'addnew/staff',
    component: AddnewStaffMemberComponent
  },
  {
    path: 'addType/staff',
    component: StaffAddTypeComponent
  },
  {
    path: 'manage/city',
    component: ManageCityComponent
  },
  {
    path: 'add/city',
    component: AddCityComponent,
  },
  {
    path: 'add/ordinance',
    component: AddOrdinanceComponent
  },
  {
    path: 'ordinance/list',
    component: OrdinanceListComponent
  },
  {
    path: 'show-ward',
    component: ShowWardComponent
  },
  {
    path: 'add-ward',
    component: AddWardComponent
  },
  {
    path: 'add-inspection-date',
    component: AddInspectionDateComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule {
  static components = [
    HousingComponent,
    AssesorComponent,
    FireComponent,
    TaxesComponent,
    CertificateDetailComponent,
    ZoningComponent
  ]
}

import { ApplicationService } from './../http/users/application.service';
import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../authentication/authentication.service';
import { AdminAuthenticationService } from '../authentication/admin-authentication.service';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private applicaionService: ApplicationService,
    private adminAuthenticationService: AdminAuthenticationService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headersConfig = {
      
      // Commented Because of File Upload Form Data
      // 'Content-Type': 'application/json',
      'Accept': 'application/json'
    };
    let token = null;
    token = this.authenticationService.getToken();

    const adminToken = this.adminAuthenticationService.getToken();
    if (adminToken) {
      token = adminToken;
    }

    if (token && req.url != 'http://api.ipify.org/?format=json') {
      headersConfig['Authorization'] = `Bearer ${token}`;
    }

    const request = req.clone({ setHeaders: headersConfig });
    return next.handle(request);
  }
}

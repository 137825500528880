import { Router } from '@angular/router';
import { AuthenticationService } from './../../../authentication/authentication.service';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {

  public saveAndExit: boolean = false;
  public isLogin: boolean = false;
  constructor(
    public authenticationService: AuthenticationService,
    private router: Router
  ) { }

  ngOnInit() {
    this.isLogin = this.authenticationService.isLogin();
    const currentURL = this.router.url;
    if (currentURL === '/') {
      this.isLogin = false;
    }
  }

}

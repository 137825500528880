import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StaffService } from 'src/app/core/http';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { CertificatesService } from 'src/app/core/http/admin/certificates.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-inspection-date',
  templateUrl: './add-inspection-date.component.html',
  styleUrls: ['./add-inspection-date.component.css']
})
export class AddInspectionDateComponent implements OnInit {
  public addnewStaffForm: FormGroup;
  public isUpdate = false;
  public isSubmitted = false;
  public staffId: number
  public singleAdminData = {
    name: null,
    email: null,
    phone: null,
    status: null,
    city_name: null,
    stripe_account_id: null
  }

  

  minDate: Date;
  constructor(
    private formBuilder: FormBuilder,
    private staffService: StaffService,
    private route: ActivatedRoute,
    private router: Router,
    public _CS: CertificatesService,
    public _TS: ToastrService,

  ) { this.minDate = new Date(); }


  
  ngOnInit() {
    
    this.staffForm();
    this.route.queryParams.subscribe(staff => {
      this.staffId = staff.staffId;

    })
    this.getWards()
  }

  staffForm() {
    this.addnewStaffForm = this.formBuilder.group({

      ward: ['', Validators.required],
      inspection_per_day: ['', Validators.required],
      total_unit: ['', Validators.required],


    });

    


  }









  
  selectStatus(event) {
    if (event.target.checked) {
      this.addnewStaffForm.value.status = 1
    } else {
      this.addnewStaffForm.value.status = 0

    }
  }

  get staffMember() { return this.addnewStaffForm.controls }



  addDate() {
    if (this.addnewStaffForm.invalid) {
      this.isSubmitted = true
      return false
    }
    this.staffService.addINspectiondate(this.addnewStaffForm.value).subscribe(data => {
      this._TS.success('Inspection & Unit limit per day Added')
      this.addnewStaffForm.reset()
    })
  }

  public wards = []
  getWards() {
    
    const localStorageData = JSON.parse(localStorage.getItem('currentAdmin'));
    this._CS.getWard({ city_id: localStorageData['id'] }).subscribe(data => {
      this.wards = data['response']
    })
  }

  public selectDate: any
  onValueChange(value) {
    this.selectDate = value
    console.log(value)
  }
}

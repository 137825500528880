import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap, debounce } from 'rxjs/operators';
import { of as observableOf, Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import { Login } from '../../shared/models/login.model';
import { Router } from '@angular/router';
const credentialsKey = 'currentUser';
const credentialsKeyForAdmin = 'currentAdmin';

@Injectable()
export class AuthenticationService {
  public sendOtpMail: string;
  constructor(
    private http: HttpClient,
    private router: Router
  ) {
  }

  login(loginData: Login): Observable<any> {
    const href = `${environment['login']}`;
    return this.http.post<any>(href, loginData).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
            const storage = localStorage;
            storage.setItem(credentialsKey, JSON.stringify(data.response));
          }
          return data;
        }
      )
    );
  }

  sendOtpForForgotPass(loginData: any): Observable<any> {
    const href = `${environment['sendotp']}`;
    return this.http.post<any>(href, loginData).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {

          }
          return data;
        }
      )
    );
  }

  verifyOtp(loginData: any): Observable<any> {
    const href = `${environment['verifyOtp']}`;
    return this.http.post<any>(href, loginData).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {

          }
          return data;
        }
      )
    );
  }

  resetPassword(loginData: any): Observable<any> {
    const href = `${environment['resetPassword']}`;
    return this.http.post<any>(href, loginData).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {

          }
          return data;
        }
      )
    );
  }

  createPasswordForAdmin(loginData: any): Observable<any> {
    const href = `${environment['createPasswordForAdmin']}`;
    return this.http.post<any>(href, loginData).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {

          }
          return data;
        }
      )
    );
  }

  verifyEmail(token): Observable<any> {
    const href = `${environment['verifyEmail']}`;
    return this.http.get<any>(href, { params: token }).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }


  userTransferApllicationEmialVerify(token): Observable<any> {
    console.log("dashborad")
    const href = `${environment['userTransferEmailVerify']}`;
    return this.http.get<any>(href, { params: token }).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }


  // public isType = 'candy'; var = false; = varification
  // public isType1 = 'candy'; var = true; = varification1
  logout(): Observable<boolean> {

    /// Remove Local Data of user
    sessionStorage.removeItem(credentialsKey);
    localStorage.removeItem(credentialsKey);

    /// Remove Local data of application
    sessionStorage.removeItem('application');
    localStorage.removeItem('application');

    /// Remove Local Data of Admin
    sessionStorage.removeItem('currentAdmin');
    localStorage.removeItem('currentAdmin');

    return observableOf(true);
  }

  getUserInfo(): Observable<any> {
    const savedCredentials = this.getUser();
    return observableOf(savedCredentials);
  }

  getAdminInfoAndUserInfo() {
    const adminsavedCredentials = this.getAdmin();
    const userSavedCredentials = this.getUser();
    if (adminsavedCredentials != null) {
      return observableOf(adminsavedCredentials);
    } else {
      return observableOf(userSavedCredentials);
    }

  }

  isLogin() {
    if (localStorage.getItem(credentialsKey)) {
      return true;
    }
    return false;

  }

  isLoginAdmin() {
    if (localStorage.getItem(credentialsKeyForAdmin)) {
      return true;
    }
    return false;

  }

  getToken() {
    const savedCredentials = this.getUser();
    return savedCredentials && savedCredentials['token'];
  }

  getUserRole(): Observable<any> {
    const savedCredentials = this.getUser();
    return observableOf(savedCredentials['role']);
  }

  getUserType() {
    const savedCredentials = this.getUser();
    if (this.isLogin()) {
      return savedCredentials['role'];
    } else {
      return false;
    }


  }

  public getUser() {
    const savedCredentials = sessionStorage.getItem(credentialsKey) || localStorage.getItem(credentialsKey);
    return JSON.parse(savedCredentials);
  }

  setUser(user: any): void {
    localStorage.setItem(credentialsKey, JSON.stringify(user));
  }

  private getAdmin() {
    const savedCredentials = sessionStorage.getItem(credentialsKey) || localStorage.getItem(credentialsKeyForAdmin);
    return JSON.parse(savedCredentials);
  }

  createPasswordApplicant(payload): Observable<any> {
    const href = `${environment['verifyEmail']}`;
    return this.http.post<any>(href, payload).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

}


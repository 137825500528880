import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, ReactiveFormsModule } from '@angular/forms';
import { Certificates, AddressSearch } from 'src/app/shared/index';
import { DashboardService } from './../../../../../core/http/admin/dashboard.service';
import { ActivatedRoute, Router } from '@angular/router';
import { settingConfig } from 'src/app/configs';
import * as moment from 'moment';
import { Addresses } from 'src/app/shared/index';
import { AdminAuthenticationService } from 'src/app/core/authentication/admin-authentication.service';
import { Subject, Subscription } from 'rxjs';
import { ApplicationService } from 'src/app/core/http';
import { ToastrService } from 'ngx-toastr';
// import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import { environment } from 'src/environments/environment';
import { CertificatesService } from 'src/app/core/http/admin/certificates.service';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { data } from 'jquery';
import * as _ from 'lodash';  

@Component({
  selector: 'app-certificate-details',
  templateUrl: './certificate-details.component.html',
  styleUrls: ['./certificate-details.component.css']
})
export class CertificateDetailsComponent implements OnInit, OnDestroy {
  public searchQuery: AddressSearch = {
    query: null,
    search: 1,
  };
  @ViewChild('transeferApplication', { static: false }) transeferApplication: ElementRef;
  @ViewChild('attechment', { static: false }) attechment: ElementRef;
  @ViewChild('addUnits', { static: false }) addUnits: ElementRef;
  @ViewChild('uploadpopup', { static: false }) uploadpopup: ElementRef;
  @ViewChild('singleupload', { static: false }) singleupload: ElementRef;
  @ViewChild('Waiveooff', { static: false }) Waiveooff: ElementRef;
  @ViewChild('voidtransaction', { static: false }) voidtransaction: ElementRef;
  @ViewChild('addfee', { static: false }) addfee: ElementRef;
  // @ViewChild('sig1', { static: false }) sig1: SignaturePad;
  private signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    'minWidth': 5,
    'canvasWidth': 500,
    'canvasHeight': 300
  };
  certificates: any = new Subject<any>();
  public picUpattectment= new FormData();
  public addFeeByAdmin = [];
  public isNotesSubmit = false;
  public isPickupSubmit = false;
  public isSignaturePad = false;
  public applicantAttachment = []
  public cco_doc = []
  public lastPaymentDate: string;
  public submissionReview = {
    first_payment_date: null
  }

  public currentPaginations: number = Number(1);
  public totalPaginations: number = Number(0);
  public offsets: any = 1;
  public pagination = {
    page: 1,
    application_id:''
  };
  public Units = []
  public addRefundArray;
  public updateRefundItemData: any;
  public allUnits = []
  public updateRefundForm: FormGroup;
  public templateLink = "/api/admin/download-certificate/"
  public transferApplicactionForm: FormGroup;
  public feeAndPaymetsForm: FormGroup;
  // public inspectionForm: FormGroup;
  // public violationForm: FormGroup;
  public ownerForm: FormGroup;
  public updateLocationForm: FormGroup;
  // public submissionIncompleteForm: FormGroup;
  // public assigneInpectorForm: FormGroup;
  // public decisionForm: FormGroup;
  public decisionAndccForm: FormGroup;
  // public applicationStatusAndDateForm: FormGroup;
  public inspectionAndViolationForm: FormGroup;
  public uploadImageForm: FormGroup;
  public buyerForm: FormGroup
  public descriptionForm: FormGroup;
  public cancelRequestForm: FormGroup;
  public someoneElse: FormGroup;
  public isCancelRequest = false;
  public isDescription = false;
  public isBuyer = false;
  public isCurrentStatusInspection = false;
  public isInspectionAndViolationForm = false;
  public isApplicationStatusAndDate = false;
  public isDecision = false;
  public isDecisionAndcc = false;
  public isViolation = false;
  public isTransferApplication = false;
  public isFeeAndPayments = false;
  public isInspection = false;
  public ApplicantFile: string = null;
  public BuyerApplicantFile: string = null;
  public ownerCorporateFormationFile: string = null;
  public powerOfAttorneyFile: string = null;
  public seller_id: string = null;
  public sales_contract: string = null;
  public owner_authorization: string = null
  public env: any;
  public showOwnerBusinessFile = false;
  public showOwnerBankFile = false;
  public isHide = false
  public isSendMailForIncompleteSubmission = false;
  public isAssignInspector = false;
  public isSubmissionApplicationStatus = true;
  public property_description_type = '1';
  public id: any
  public medias:any;
  public formData:any;
  public file_type:any;
  public file:any;
  public typecheck:any;
  public typecheckimg:any;
  public msg:boolean=false;
  public msg1:boolean=false;
  public document:any;
  public showfilename:boolean=false;
  public department = {
    role_id: null,
    department_id: null,
  }

  public emailForm: FormGroup;
  public pickUpForm: FormGroup;
  public notesForm: FormGroup;
  public addFeeForm: FormGroup;

  public isEmail: boolean = true;
  public isNotes: boolean = false;
  public isPickUp: boolean = false;
  public EMAIL_REGEX = '[a-z0-9!#$%&\'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*';
  public settings: any;
  public AllViolations: [{}];
  public allTransaction: [{}];
  public ordinanceId: number;
  public submissionStatus: [{}];
  public ownerType;
  // public address: Array<Addresses>;

  public previousApplicationBtnClicked = false;

  public documentsUploadList = [];
  public documentsUploadListCCO = [];

  private subscription: Subscription = new Subscription();

  public certificateDetail: Certificates = {
    id: null,
    address: null,
    ward: null,
    applicant_address: null,
    applicant_email: null,
    applicant_name: null,
    applicant_phone: null,
    also_known_as: null,
    owner_name: null,
    owner_email: null,
    owner_phone: null,
    owner_address: null,
    owner_type: null,
    createdAt: null,
    buyer_address: null,
    buyer_business: null,
    buyer_business_type: null,
    buyer_email: null,
    buyer_id: null,
    buyer_name: null,
    buyer_phone: null,
    buyer_type: null,

    is_attic_finished: null,
    is_basement_finished: null,
    no_of_bathrooms: null,
    no_of_bedrooms: null,
    no_of_commercial_units: null,
    no_of_kitchens: null,
    no_of_residential_units: null,
    point_of_contact: null,
    application_housing_status: null,
    role: null,
    //applicantAttachment:null,
  };
  public addresses: Addresses = {
    ward: null,
    sbl: null,
    block: null,
    lot: null,
    primary_owner: null,
    street: null,
    city: null,
    state: null,
    land: null,
    address: null,
    google_address: null,
    property_class: null,
  };
  public pickupMail: number = 1

  public emailAccordionExpanded = false;
  public isRefunded = false;
  public tagEmailsTo = [];
  public tagEmailsCC = [];
  public selectedTagEmailItems = [];
  public selectedTagEmailItemsCC = [];

  constructor(
    private formBuilder: FormBuilder,
    private dashboardService: DashboardService,
    private route: ActivatedRoute,
    private adminAuthService: AdminAuthenticationService,
    public applicationService: ApplicationService,
    private toasterService: ToastrService,
    public _CS: CertificatesService,
    private router: Router,
    public authenticationService: AuthenticationService) {
    this.settings = settingConfig;
  }
  public modify = {}
  public routeId: number
  public tabType: any

  public isAddFeeSubmit = false;
  public currentAdmin: any;

  ngOnInit() {
    this.env = environment;
    this.routeId = Number(this.route.snapshot.params.id)
    this.route.queryParams.subscribe(data => {
      this.modify = { ...data }
      this.tabType = data.tabType

      console.log("modify data is ===>",this.modify)
    })
    this.authenticationService.getAdminInfoAndUserInfo().subscribe(admin => {
      this.currentAdmin = admin ? admin : null;
    });
    this.initForms();
    this.getLocalStorage()
    this.getCertificate();
    this.getSubmitionAppliction();

    //this.getUserForSuggestion()
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


  // ngAfterViewInit() {
  //   // this.signaturePad is now available
  //   this.signaturePad.set('minWidth', 5); // set szimek/signature_pad options at runtime
  //   this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
  // }

  // drawComplete() {
  //
  //   // will be notified of szimek/signature_pad's onEnd event
  //   console.log(this.signaturePad.toDataURL());
  // }

  // drawStart() {
  //
  //   // will be notified of szimek/signature_pad's onBegin event
  //   console.log('begin drawing');
  // }

  naviagateByurl(url: string) {
    if (url == "/admin/certificates/housing") {
      this.router.navigate([url], { queryParams: { ...this.modify, tabType: this.tabType } })

    } else {
      this.router.navigate([url], { queryParams: { ...this.modify } })

    }
  }

  // demoOnChange(elRef) {
  //   var event = document.createEvent('HTMLEvents');
  //   event.initEvent('place_changed', true, false);
  //   elRef.dispatchEvent(event)
  // }

  message: string;
  receiveMessage($event) {
    this.message = $event
    console.log("message val",this.message)
    this.ngOnInit();
  }
  suggestionData = []
  email = []
  getUserForSuggestion() {
    this.dashboardService.getUsersDepartment(this.department.department_id).subscribe(data => {
      this.suggestionData = data.response;
      this.email = this.suggestionData.map((data) => {
        return data.email
      })
    })
  }


  // deleteFee(value: any) {
  //   const data = {
  //     id: value.id
  //   }
  //   this.dashboardService.deleteFee(data).subscribe(data => {
  //     this.getCertificate()
  //   })
  // }
  typeaheadOnSelect(event) {
    this.suggestionData.map((data) => {
      if (event.item == data.email) {
        this.transferApplicactionForm.controls['ownershipName'].setValue(data.first_name);
        this.transferApplicactionForm.controls['ownershipPhone'].setValue(data.mobile_number);
        //this.transferApplicactionForm.controls['ownershipEmail'].setValue(this.certificateDetail.applicant_email);
        this.transferApplicactionForm.controls['ownershipAddress'].setValue(data.address);
      }
    })
  }



  // inspectors: [{}]
  // getInspector() {
  //   const data = {
  //     department_id: this.department.department_id
  //   }
  //   this.dashboardService.getInspectors(data).subscribe(data => {
  //     this.inspectors = data.response;
  //   })

  // }


  localstprageData: any;
  getLocalStorage() {
    const department = this.adminAuthService.getPermission()
    if (department && department.role_id != 1 && department.role_id != 2) {
      this.department = department
      this.localstprageData = department.department;
    } else {
      const localStorageData = this.adminAuthService.getSaveDepartment()
      const department = this.adminAuthService.getPermission()
      this.department = localStorageData
      this.department.role_id = department.role_id
      this.localstprageData = localStorageData
    }
  }

  getSubmitionAppliction() {
    this.submissionStatus = this.settings.application_status.filter((_) => {
      if (_.key == 6 || _.key == 7) {
        return _
      }
    })
  }

  public clerkmail = [{}];
  getMailOfSubmissionClerk() {
    const data = {
      application_id: this.certificateDetail.id,
    }
    this.dashboardService.getClerkMail(data).subscribe(comlaint => {
      this.clerkmail = comlaint.response;
    })
  }
  selectSubmissionStatus(value: string) {
    if (value == "6") {
      this.isSendMailForIncompleteSubmission = true;
      this.isAssignInspector = false;
      this.isSubmissionApplicationStatus = false
    }
    else if (value == "7") {
      this.isSendMailForIncompleteSubmission = false;
      this.isAssignInspector = true;
      this.isSubmissionApplicationStatus = false
    } else if (value == "3") {
      this.isSendMailForIncompleteSubmission = false;
      this.isAssignInspector = false;
      this.isSubmissionApplicationStatus = true
    }

  }

  initForms() {
    this.transeferApplicationRequest();
    this.updateRefundForms();
    this.feeAndPayment();
    this.updateLocation();
    this.owner();
    this.sendEmail();
    this.pickUp();
    this.notesForm1();
    this.decisionAndCC()
    this.inspectionAndViolation();
    this.buyer();
    this.description();
    this.cancelRequest();
    this.uploadImageControls();
    this.someoneElseForm();
    this.addFeeByAdminForm();
    this.initTanentForm();
  }
  public imageName: any;
  public attachment: any
  media(event1) {
    this.imageName = event1.target.files[0].name;
    this.attachment = event1.target.files[0]

    var reader = new FileReader();
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.image = event.target.result;
    };
    reader.readAsDataURL(event1.target.files[0]);

  }
  uploadImageControls() {
    this.uploadImageForm = this.formBuilder.group({
      title: [''],
      notes: [''],
    })
  }

  hideImage() {

    this.image = ''
    this.attachment = ''
  }
  public image: any
  public isUpload = false
  uploadImageAndDocuments() {

    let id = (this.certificateDetail.id)
    this.isUpload = true
    var formData = new FormData();
    formData.append(
      "attachment",
      this.attachment

    );
    formData.append(
      'title',
      this.uploadImageForm.value.title,

    );
    formData.append(
      'notes',
      this.uploadImageForm.value.notes
    );
    formData.append(
      'application_id',
      this.certificateDetail.id.toString()
    )
    this.dashboardService.addAttecment(formData).subscribe(data => {
      this.hideAttectmentForm();
      this.uploadImageForm.reset();
      this.image = '';
      this.attachment = ''
      this.getCertificate()
      this.isUpload = false;
    }, error => {
      this.isUpload = false
    })
  }

  hideAttectmentForm() {
    this.attechment.nativeElement.click()
  }

  transeferApplicationRequest() {
    this.transferApplicactionForm = this.formBuilder.group({
      ownershipEmail: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      ownershipName: ['', Validators.required],
      ownershipLastName: ['', Validators.required],
      ownershipPhone: ['', [Validators.required, Validators.pattern('^[0-9]{3}-[0-9]{3}-[0-9]{4}$')]],
      ownershipAddress: ['', [Validators.required]]
    });
  }

  feeAndPayment() {
    this.feeAndPaymetsForm = this.formBuilder.group({
      application_type: ['', Validators.required],
      amount: ['', Validators.required]
    })
  }

  updateRefundForms() {
    this.updateRefundForm = this.formBuilder.group({
      fee: ['', Validators.required],
      note: ['', Validators.required]
    })
  }

  // inspection() {
  //   this.inspectionForm = this.formBuilder.group({
  //     inspectionType: ['', Validators.required],
  //     inspectionDate: ['', Validators.required],
  //     inspectionDecision: ['', Validators.required],
  //     no_of_commercial_units: ['', Validators.required],
  //     no_of_residential_units: ['', Validators.required],
  //     remark: ['', Validators.required],
  //   })
  // }

  updateLocation() {
    this.updateLocationForm = this.formBuilder.group({
      address: ['', Validators.required],
      ward: [''],
      current_use: ['', Validators.required],
      unit: [''],
      no_of_residential_units: [''],
      // building_number: ['', Validators.required],
      addresses: [''],
      building_number1: [''],
      google_address: []

    })
  }

  // submissionIncomplete() {
  //   this.submissionIncompleteForm = this.formBuilder.group({
  //     application_status: ['', Validators.required],
  //     comments: ['', Validators.required],
  //   })
  // }

  // assigneInpector() {
  //   this.assigneInpectorForm = this.formBuilder.group({
  //     application_status: ['', Validators.required],
  //     inspectionDate: ['', Validators.required],
  //     timeRange: ['', Validators.required],
  //     inpector: ['', Validators.required],
  //   })
  // }
  // public isAssignInpector = false;
  // assigneInpectors() {

  //   this.isAssignInpector = true;
  //   if (this.assigneInpectorForm.invalid) {
  //     return false
  //   }
  //   const data = {
  //     application_id: this.certificateDetail.id,
  //     application_status: this.assigneInpectorForm.value.application_status,
  //     inspection_date: this.assigneInpectorForm.value.inspectionDate,
  //     inspection_range: this.assigneInpectorForm.value.timeRange,
  //     inspector_id: Number(this.assigneInpectorForm.value.inpector),
  //     department_id: this.localstprageData.id

  //   }
  //   this.dashboardService.assignedInspector(data).subscribe(data => {
  //     this.assigneInpectorForm.reset();
  //   })

  // }


  // decisions() {
  //   this.decisionForm = this.formBuilder.group({
  //     desicion: ['', Validators.required],
  //     propertClass: ['', Validators.required],
  //     propertyOwner: ['', Validators.required],
  //     no_of_residential_units: ['', Validators.required],
  //     no_of_commercial_units: ['', Validators.required],
  //     comments: ['', Validators.required],
  //   })
  // }

  // decisionsAndProperty() {
  //   this.isDecision = true;
  //   if (this.decisionForm.invalid) {
  //     return false
  //   }
  // }


  decisionAndCC() {
    this.decisionAndccForm = this.formBuilder.group({
      desicion: ['', Validators.required],
      cc: ['', Validators.required],
      select: ['', Validators.required],
      message: ['', Validators.required],
    })
  }

  // decisionsAndCC() {
  //   this.isDecisionAndcc = true;
  //   if (this.decisionForm.invalid) {
  //     return false
  //   }
  // }

  // applicationAndDate() {
  //   this.applicationStatusAndDateForm = this.formBuilder.group({
  //     application_status: ['', Validators.required],
  //     date: ['', Validators.required],

  //   })
  // }

  // addApplicationStatusAndDate() {
  //   this.isApplicationStatusAndDate = true;
  //   if (this.applicationStatusAndDateForm.invalid) {
  //     return false
  //   }
  // }

  inspectionAndViolation() {
    this.inspectionAndViolationForm = this.formBuilder.group({
      inspectionDate: ['', Validators.required],
      inspectiontype: ['', Validators.required],
      use: ['', Validators.required],
      decision: ['', Validators.required],
      smokedetector: ['', Validators.required],
      carbon_monoxide: ['', Validators.required],
      combo: ['', Validators.required],
      no_of_dwelling_unit: ['', Validators.required],
      no_of_commercial_units: ['', Validators.required],
      no_of_extinguishers: ['', Validators.required],
      remarks: ['', Validators.required],
    })
  }

  insPectionAndViolationSub() {
    this.isInspectionAndViolationForm = true;
    if (this.inspectionAndViolationForm.invalid) {
      return false
    }
  }

  // currentStatusAndInspectionTime() {
  //   this.currentStatusInspectionForm = this.formBuilder.group({
  //     inspection_Type: ['', Validators.required],
  //     decision: ['', Validators.required],
  //     use: ['', Validators.required],
  //     currentStatus: ['', Validators.required],
  //     inspectionDateAndTime: ['', Validators.required],
  //   })
  // }

  // currentStatusAndInspection() {
  //   this.isCurrentStatusInspection = true;
  //   if (this.currentStatusInspectionForm.invalid) {
  //     return false
  //   }
  // }


  fillLocationForm() {

    this.getWards()
    this.updateLocationForm.controls['address'].setValue(this.certificateDetail.address ? this.certificateDetail.address : (this.certificateDetail.addresses && this.certificateDetail.addresses['property_location']) ? (this.certificateDetail.addresses && this.certificateDetail.addresses['property_location']) : this.certificateDetail.google_address);

    this.updateLocationForm.controls['google_address'].setValue(this.certificateDetail.google_address);
    this.updateLocationForm.controls['ward'].setValue(this.certificateDetail.ward);
    this.updateLocationForm.controls['current_use'].setValue(this.certificateDetail.current_use);
    this.updateLocationForm.controls['unit'].setValue(this.certificateDetail.unit);
    this.updateLocationForm.controls['no_of_residential_units'].setValue(this.certificateDetail.no_of_residential_units);
    this.updateLocationForm.controls['addresses'].setValue(this.certificateDetail.also_known_as);
    this.updateLocationForm.controls['building_number1'].setValue(this.certificateDetail.building_number);
  }

  fillSomeOneElseForm() {
    this.someoneElse.controls.contact_person_name.setValue(this.certificateDetail.contact_person_name)
    this.someoneElse.controls.contact_person_lastname.setValue(this.certificateDetail.contact_person_lastname)
    this.someoneElse.controls.contact_person_phone.setValue(this.certificateDetail.contact_person_phone)
  }
  address(event) {
    this.updateLocationForm.controls.google_address.setValue(event.formatted_address)
  }

  public isLocation = false
  updateLocations() {

    this.isLocation = true;
    if (this.updateLocationForm.invalid) {
      this.isHide = false
      return false
    }
    const data = {
      modal: 'location',
      address: this.selectAddress ? this.selectAddress : null,
      application_id: this.certificateDetail.id,
      address_id: this.addressId,
      unit: this.updateLocationForm.value.unit,
      current_use: this.updateLocationForm.value.current_use,
      no_of_residential_units: this.updateLocationForm.value.no_of_residential_units,
      ward: this.updateLocationForm.value.ward,
      also_known_as: this.updateLocationForm.value.addresses,
      building_number: this.updateLocationForm.value.building_number1,
      google_address: this.updateLocationForm.value.google_address
    }
    this.updateApplicantDetail(data);
  }

  public addressdata: any = [{}]
  public findAdress: any = [{}]
  searchAddress() {

    if (this.updateLocationForm.value.address.length > 1) {
      this.searchQuery.query = this.updateLocationForm.value.address;
      this.searchQuery['admin_id'] = this.certificateDetail['admin_id']

      if (this.searchQuery.query === null || this.searchQuery.query === '') {
        return false;
      }
      //this.address = null;
      this.applicationService.getSearchAddress(this.searchQuery).subscribe(data => {
        this.findAdress = data,
          this.addressdata = this.findAdress.response.map(data => {
            return data.address
          })
      })
    }

  }

  addressId: number
  public selectAddress: string;
  typeaheadOnSelectAddress(event) {
    this.findAdress.response.map((data => {
      if (event.value == data.address) {
        this.addressId = data.id;
        this.selectAddress = data.property_location
      }
    }))


  }

  public trascationId: number;
  paymentStatus: number
  waiveInfo(tansactionId: number, status: number) {
    if (status == 0 || status == null || status == 2) {
      this.trascationId = tansactionId;
      this.paymentStatus = status;
      this.ngOnInit();
    }

  }

  voidUnits() {
    const data = {
      id: this.trascationId,
    }
    if (this.paymentStatus != 0) {
      return false
    }
    this.dashboardService.voidtranscation(data).subscribe(data => {
      this.toasterService.success('Units have voided');
      this.voidtransaction.nativeElement.click()
      this.getCertificate();
    }, error => {
      this.toasterService.error(error)
    })
  }

  waiveOffPayments() {

    const data = {
      id: this.trascationId,
    }
    // if (this.paymentStatus != 0) {
    //   return false
    // }
    this.dashboardService.waiveOffpayment(data).subscribe(data => {
      this.toasterService.success('Payment have waived off');
      this.Waiveooff.nativeElement.click()
      this.getCertificate();
    })
  }

  // violation() {
  //   this.violationForm = this.formBuilder.group({
  //     violation: ['', Validators.required],
  //     code: ['', Validators.required],
  //     violation_description: ['', Validators.required],
  //     floor: ['', Validators.required],
  //     unitNo: ['', Validators.required],
  //     remark: ['', Validators.required],
  //   })
  // }

  owner() {
    this.ownerForm = this.formBuilder.group({
      owner_type: ['', Validators.required],
      owner_name: ['', Validators.required],
      owner_last_name: [''],
      owner_phone: ['', [Validators.required, Validators.pattern('^[0-9]{3}-[0-9]{3}-[0-9]{4}$')]],
      owner_address: ['', Validators.required],
      owner_business: ['', Validators.required],
      owner_email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]]
    })
  }
  public isSelectOwner = false;
  public isBankSelect = false;
  public isBusinessSelect = false;
  changeOwnerType(value: string) {
    if (value == '2') {
      this.isSelectOwner = true;
      this.isBankSelect = false;
      this.isBusinessSelect = true;
    } else if (value == '3') {
      this.isSelectOwner = true;
      this.isBankSelect = true;
      this.isBusinessSelect = false;
    }
    else {
      this.isSelectOwner = false;
      this.isBankSelect = false;
      this.isBusinessSelect = false;
    }


  }

  buyer() {
    this.buyerForm = this.formBuilder.group({
      buyer_type: ['', Validators.required],
      buyer_business_type: ['', Validators.required],
      buyer_name: ['', Validators.required],
      buyer_last_name: [''],

      buyer_phone: ['', [Validators.required, Validators.pattern('^[0-9]{3}-[0-9]{3}-[0-9]{4}$')]],
      buyer_address: ['', Validators.required],
      buyer_email: ['', Validators.required],
      buyer_business: ['', Validators.required]
    })
  }

  description() {
    this.descriptionForm = this.formBuilder.group({
      current_use: ['', Validators.required],
      no_of_bedrooms: [''],
      no_of_bathrooms: [''],
      no_of_kitchens: [''],
      no_of_residential_units: [''],
      is_basement_finished: ['', Validators.required],
      is_attic_finished: ['', Validators.required],
      contact_person_phone: [''],
      contact_person_name: [''],
      contact_person_lastname: [''],
      no_of_commercial_units: [''],


    })
  }

  someoneElseForm() {
    this.someoneElse = this.formBuilder.group({
      contact_person_phone: ['', [Validators.required, Validators.minLength(12), Validators.maxLength(12)]],
      contact_person_name: ['', Validators.required],
      contact_person_lastname: ['', Validators.required]
    })
    this.someoneElse.controls.contact_person_phone.setValue('+1')
  }

  addFeeByAdminForm() {
    this.addFeeForm = this.formBuilder.group({
      amount: ['', [Validators.required]],
      fee_type: ['', Validators.required],
      admin_fee_notes: ['']
    });
  }

  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 48 || charCode > 57) && charCode != 45) {
      event.preventDefault();

      return false;
    } else {
      this.phoneNumberFormate()
    }
  }

  phoneNumberFormate() {
    var value: string
    var autoFillValue = '-'
    if (this.someoneElse.value.contact_person_phone && this.someoneElse.value.contact_person_phone.length === 3) {
      this.someoneElse.controls.contact_person_phone.setValue(this.someoneElse.value.contact_person_phone.concat(autoFillValue))
    }
    if (this.someoneElse.value.contact_person_phone && this.someoneElse.value.contact_person_phone.length === 7) {
      this.someoneElse.controls.contact_person_phone.setValue(this.someoneElse.value.contact_person_phone.concat(autoFillValue))
    }
  }

  public isSomeoneelse = false
  updateSomeOneElse() {
    this.isSomeoneelse = true;
    if (this.someoneElse.invalid) {
      return false
    }
    this.someoneElse.value.application_id = this.certificateDetail.id;
    this.someoneElse.value.modal = 'description'
    this.updateApplicantDetail(this.someoneElse.value);
  }

  resetSomeElse() {
    this.someoneElse.reset();
  }

  selectPropertyDescriptionType(value) {
    this.property_description_type = value;
    this.descriptionForm.get('current_use').patchValue(value)
    if (this.property_description_type == '1') {
      this.descriptionForm.controls.no_of_residential_units.setErrors(null);
      this.descriptionForm.controls.no_of_residential_units.clearValidators();
      this.descriptionForm.controls.no_of_bedrooms.setErrors(null);
      this.descriptionForm.controls.no_of_bedrooms.clearValidators();
      this.descriptionForm.controls.is_attic_finished.setErrors(null);
      this.descriptionForm.controls.is_attic_finished.clearValidators();
      this.descriptionForm.controls.is_basement_finished.setErrors(null);
      this.descriptionForm.controls.is_basement_finished.clearValidators();
    } else {
      if (Number(this.descriptionForm.value.no_of_residential_units) > 0) {
        this.descriptionForm.controls.no_of_residential_units.clearValidators();
        this.descriptionForm.controls.no_of_residential_units.setErrors(null);
      } else {
        this.descriptionForm.controls.no_of_residential_units.setValidators(Validators.required);
        this.descriptionForm.controls.no_of_residential_units.setErrors({ required: true });
      }
      // this.descriptionForm.controls.no_of_residential_units.setErrors({'required': true});
      // this.descriptionForm.controls.no_of_residential_units.setValidators(Validators.required);
      // this.descriptionForm.controls.no_of_commercial_units.setErrors({'required': true});
      // this.descriptionForm.controls.no_of_commercial_units.setValidators(Validators.required);
      this.descriptionForm.controls.is_attic_finished.setErrors({ 'required': true });
      this.descriptionForm.controls.is_attic_finished.setValidators(Validators.required);
      this.descriptionForm.controls.is_basement_finished.setErrors({ 'required': true });
      this.descriptionForm.controls.is_basement_finished.setValidators(Validators.required);
    }
  }

  changeDescription(descriptionField: string, operation: boolean): void {
    debugger
    const field = this.descriptionForm.get(descriptionField);
    if (field) {
      const value: number = field.value;
      if (operation && value >= 0) {
        field.patchValue(Number(value) + 1);
      }
      if (!operation && value > 0) {
        field.patchValue(Number(value) - 1);
      }
    }
    // if (Number(this.descriptionForm.value.no_of_residential_units) > 0) {
    //   this.descriptionForm.controls.no_of_residential_units.clearValidators();
    //   this.descriptionForm.controls.no_of_residential_units.setErrors(null);
    // } else if (Number(this.descriptionForm.value.no_of_residential_units) == 0) {
    //   this.descriptionForm.controls.no_of_residential_units.setValidators(Validators.required);
    //   this.descriptionForm.controls.no_of_residential_units.setErrors({required: true});
    // }
    // else if (this.descriptionForm.value.no_of_commercial_units > 0) {
    //   this.descriptionForm.controls.no_of_residential_units.setErrors(null);
    // }
    this.descriptionForm.value.application_id = this.certificateDetail.id;
    this.descriptionForm.value.modal = 'description'
    if (this.descriptionForm.value.current_use == '1') {
      this.updateApplicantDetail(this.descriptionForm.value);
    }
    else {
      this.descriptionForm.patchValue({
        current_use: 1,
        no_of_residential_units: 1,
        no_of_bathrooms: 0,
        no_of_bedrooms: 0,
        no_of_commercial_units: 0,
        no_of_kitchens: 0,
        is_attic_finished: 0,
        is_basement_finished: 0,
      })
      this.descriptionForm.value.application_id = this.certificateDetail.id;
      this.descriptionForm.value.modal = 'description'
      this.updateApplicantDetail(this.descriptionForm.value);
    }

  }
  changeDescriptions(): void {
    debugger
    // if (Number(this.descriptionForm.value.no_of_residential_units) > 0) {
    //   this.descriptionForm.controls.no_of_residential_units.clearValidators();
    //   this.descriptionForm.controls.no_of_residential_units.setErrors(null);
    // } else if (Number(this.descriptionForm.value.no_of_residential_units) == 0) {
    //   this.descriptionForm.controls.no_of_residential_units.setValidators(Validators.required);
    //   this.descriptionForm.controls.no_of_residential_units.setErrors({required: true});
    // }
    // else if (this.descriptionForm.value.no_of_commercial_units > 0) {
    //   this.descriptionForm.controls.no_of_residential_units.setErrors(null);
    // }
    this.descriptionForm.value.application_id = this.certificateDetail.id;
    this.descriptionForm.value.modal = 'description'
    if (this.descriptionForm.value.current_use == '1') {
      this.updateApplicantDetail(this.descriptionForm.value);
    }
    else {
      this.descriptionForm.patchValue({
        current_use: 1,
        no_of_residential_units: 1,
        no_of_bathrooms: 0,
        no_of_bedrooms: 0,
        no_of_commercial_units: 0,
        no_of_kitchens: 0,
        is_attic_finished: 0,
        is_basement_finished: 0,
      })
      this.descriptionForm.value.application_id = this.certificateDetail.id;
      this.descriptionForm.value.modal = 'description'
      this.updateApplicantDetail(this.descriptionForm.value);
    }

  }

  updateDescription() {
    this.isDescription = true;
    if (this.descriptionForm.value.no_of_residential_units) {
      this.descriptionForm.controls.no_of_commercial_units.setErrors(null)
    }
    else if (this.descriptionForm.value.no_of_commercial_units) {
      this.descriptionForm.controls.no_of_residential_units.setErrors(null)
    }
    if (this.descriptionForm.invalid) {
      return false
    }
    this.descriptionForm.value.application_id = this.certificateDetail.id;
    this.descriptionForm.value.modal = 'description'
    this.updateApplicantDetail(this.descriptionForm.value);
  }

  fillDescription() {
    this.descriptionForm.controls['current_use'].setValue(this.certificateDetail.current_use);
    this.descriptionForm.controls['no_of_bedrooms'].setValue(this.certificateDetail.no_of_bedrooms);
    this.descriptionForm.controls['no_of_bathrooms'].setValue(this.certificateDetail.no_of_bathrooms);
    this.descriptionForm.controls['no_of_kitchens'].setValue(this.certificateDetail.no_of_kitchens);
    this.descriptionForm.controls['no_of_commercial_units'].setValue(this.certificateDetail.no_of_commercial_units);
    this.descriptionForm.controls['no_of_residential_units'].setValue(this.certificateDetail.no_of_residential_units);
    this.descriptionForm.controls['is_basement_finished'].setValue(this.certificateDetail.is_basement_finished);
    this.descriptionForm.controls['is_attic_finished'].setValue(this.certificateDetail.is_attic_finished);
    this.descriptionForm.controls['contact_person_phone'].setValue(this.certificateDetail.contact_person_phone);
    this.descriptionForm.controls['contact_person_name'].setValue(this.certificateDetail.contact_person_name);
    this.descriptionForm.controls['contact_person_lastname'].setValue(this.certificateDetail.contact_person_lastname);

  }

  fillBuyerForm() {
    if (this.certificateDetail.buyer_type == 2) {
      this.isBuyerType = true;
    } else {
      this.isBuyerType = false;
    }
    this.buyerForm.controls['buyer_type'].setValue(this.certificateDetail.buyer_type);
    this.buyerForm.controls['buyer_business_type'].setValue(this.certificateDetail.buyer_business_type);
    this.buyerForm.controls['buyer_business'].setValue(this.certificateDetail.buyer_business);
    this.buyerForm.controls['buyer_name'].setValue(this.certificateDetail.buyer_name);
    this.buyerForm.controls['buyer_last_name'].setValue(this.certificateDetail.buyer_last_name);
    this.buyerForm.controls['buyer_phone'].setValue(this.certificateDetail.buyer_phone);
    this.buyerForm.controls['buyer_address'].setValue(this.certificateDetail.buyer_address);
    this.buyerForm.controls['buyer_email'].setValue(this.certificateDetail.buyer_email);
  }

  updateBuyer() {

    this.isBuyer = true;
    if (!this.isBuyerType) {
      this.buyerForm.value.buyer_business = ''
      this.buyerForm.value.buyer_business_type = ''
      this.buyerForm.controls.buyer_business.setErrors(null);
      this.buyerForm.controls.buyer_business_type.setErrors(null)
    }
    if (this.buyerForm.invalid) {
      this.isHide = false
      return false
    }

    this.buyerForm.value.application_id = this.certificateDetail.id;
    this.buyerForm.value.modal = 'buyer'
    const buyerType = Number(this.buyerForm.value.buyer_type)
    let data
    if (buyerType == 2) {
      data = {
        application_id: this.certificateDetail.id,
        modal: 'buyer',
        buyer_type: Number(this.buyerForm.value.buyer_type),
        buyer_business_type: Number(this.buyerForm.value.buyer_business_type),
        buyer_name: this.buyerForm.value.buyer_name,
        buyer_last_name: null,
        buyer_phone: this.buyerForm.value.buyer_phone,
        buyer_address: this.buyerForm.value.buyer_address,
        buyer_email: this.buyerForm.value.buyer_email,
        buyer_business: this.buyerForm.value.buyer_business,
      }
    } else {
      data = {
        application_id: this.certificateDetail.id,
        modal: 'buyer',
        buyer_type: Number(this.buyerForm.value.buyer_type),
        buyer_business_type: Number(this.buyerForm.value.buyer_business_type),
        buyer_name: this.buyerForm.value.buyer_name,
        buyer_last_name: this.buyerForm.value.buyer_last_name,
        buyer_phone: this.buyerForm.value.buyer_phone,
        buyer_address: this.buyerForm.value.buyer_address,
        buyer_email: this.buyerForm.value.buyer_email,
        buyer_business: this.buyerForm.value.buyer_business,
      }
    }
    this.updateApplicantDetail(data);
  }

  public isBuyerType = false;
  changeBuyerType(value: string) {

    if (value == "2") {
      this.isBuyerType = true;
    } else {
      this.isBuyerType = false;
    }

  }

  get getBuyer() { return this.buyerForm.controls }
  get getDes() { return this.descriptionForm.controls }


  cancelRequest() {
    this.cancelRequestForm = this.formBuilder.group({
      cancelReason: ['', [Validators.required, Validators.minLength(10)]]
    })
  }
  get getCancelReq() { return this.cancelRequestForm.controls }

  submitCancelRequest() {

    this.isCancelRequest = true
    //this.isHide = true
    if (this.cancelRequestForm.invalid) {
      return false
    }
    this.isHide = true
    const cancelRequestData = {
      request_cancel_reason: this.cancelRequestForm.get('cancelReason').value,
      application_id: this.certificateDetail.id,
      application_status: 5,
      action: 'cancel'
    };
    this.dashboardService.updateApplicationStatus(cancelRequestData).subscribe(data => {

      if (data) {
        this.router.navigate(['/admin/certificates/housing']);
      }
    });
  }


  fillOwnwrForm() {
    this.ownerForm.reset();
    if (this.certificateDetail.owner_type == 2) {
      this.isBusinessSelect = true;
      this.isSelectOwner = true
    } else if (this.certificateDetail.owner_type == 3) {
      this.isSelectOwner = true;
      this.isBankSelect = true;
    }
    this.ownerForm.controls['owner_type'].setValue(this.certificateDetail.owner_type);
    this.ownerForm.controls['owner_name'].setValue(this.certificateDetail.owner_name);
    this.ownerForm.controls['owner_last_name'].setValue(this.certificateDetail.owner_last_name);
    this.ownerForm.controls['owner_phone'].setValue(this.certificateDetail.owner_phone);
    this.ownerForm.controls['owner_email'].setValue(this.certificateDetail.owner_email);
    this.ownerForm.controls['owner_address'].setValue(this.certificateDetail.owner_address);
    this.ownerForm.controls['owner_business'].setValue(this.certificateDetail.owner_business);


  }
  public isOwner = false
  updateOwner() {

    this.isOwner = true;

    if ((!this.isBankSelect) && (!this.isBusinessSelect)) {
      this.ownerForm.value.owner_business = ''
      this.ownerForm.controls.owner_business.setErrors(null)
    }

    if (this.ownerForm.invalid) {
      return false
    }

    this.ownerType = parseInt(this.ownerForm.get('owner_type').value)
    let toUpdateData;
    if (this.ownerType == 2) {
      toUpdateData = {
        modal: 'owner',
        owner_type: parseInt(this.ownerForm.get('owner_type').value),
        owner_name: this.ownerForm.get('owner_name').value,
        owner_last_name: null,
        // owner_last_name: this.ownerForm.get('owner_last_name').value,
        owner_phone: this.ownerForm.get('owner_phone').value,
        owner_address: this.ownerForm.get('owner_address').value,
        owner_email: this.ownerForm.get('owner_email').value,
        owner_business: this.ownerForm.value.owner_business,
        application_id: this.certificateDetail.id
      };
    } else {
      toUpdateData = {
        modal: 'owner',
        owner_type: parseInt(this.ownerForm.get('owner_type').value),
        owner_name: this.ownerForm.get('owner_name').value,
        owner_last_name: this.ownerForm.get('owner_last_name').value,
        owner_phone: this.ownerForm.get('owner_phone').value,
        owner_address: this.ownerForm.get('owner_address').value,
        owner_email: this.ownerForm.get('owner_email').value,
        owner_business: this.ownerForm.value.owner_business,
        application_id: this.certificateDetail.id
      };
    }
    this.updateApplicantDetail(toUpdateData);
  }


  get transferApplication() { return this.transferApplicactionForm.controls; }
  get feeAndpay() { return this.feeAndPaymetsForm.controls; }
  get someoneElseControls() { return this.someoneElse.controls }
  get location() { return this.updateLocationForm.controls }
  get owners() { return this.ownerForm.controls }

  get getDecisionAndCC() { return this.decisionAndccForm.controls }

  get getInspectionAndViolationForm() { return this.inspectionAndViolationForm.controls }


  transferApplicationReq() {

    if (this.transferApplicactionForm.invalid) {
      this.isTransferApplication = true;
      return false
    }
    const data = {
      application_id: this.certificateDetail.id,
      contact_person_email: this.transferApplicactionForm.value.ownershipEmail,
      contact_person_name: this.transferApplicactionForm.value.ownershipName,
      contact_person_phone: this.transferApplicactionForm.value.ownershipPhone,
      contact_person_address: this.transferApplicactionForm.value.ownershipAddress,
    }

    this.dashboardService.transferReq(data).subscribe(data => {
      this.transferApplicactionForm.reset();
      this.isTransferApplication = false;
      this.hideModel();
      this.getCertificate();
    })

  }

  hideModel() {
    this.transeferApplication.nativeElement.click();
  }


  addFeeAndPaymets(value: string) {
    this.isFeeAndPayments = true
    if (this.feeAndPaymetsForm.invalid) {
      return false
    }
    const feeAndPayment = {
      application_type: this.feeAndPaymetsForm.value.application_type,
      amount: this.feeAndPaymetsForm.value.amount,
      transaction_type: 2,
      application_id: this.certificateDetail.id
    }
    this.dashboardService.savePaymentDetail(feeAndPayment).subscribe(data => {
      this.allTransaction = data;
      this.resetForm(value)
    })
  }





  resetForm(value: string) {
    if (value == "transferApplication") {
      this.transferApplicactionForm.reset()
      this.isTransferApplication = false
    }
    else if (value == 'paymetsAndFee') {
      this.feeAndPaymetsForm.reset();
      this.isFeeAndPayments = false;
      this.isEdit = false;
    } else if (value == 'inspection') {
      // this.inspectionForm.reset();
      this.isInspection = false;
    }
  }

  public isWaivOff = false
  getCertificate() {
    debugger
    this.dashboardService.getCertificateDetail(this.routeId).subscribe(data => {
      console.log("value is ceee====>",data)
      if (data.status === 'success') {
        this.certificateDetail = data.response;
        this.addRefundArray = data.response.admin_refunded_amounts
        if (this.certificateDetail['application_extend'].is_previous_application == 1) {
          this.previousApplicationBtnClicked = true;
        }
        this.tagEmailsTo.push({ email: this.currentAdmin.email });
        this.tagEmailsCC.push({ email: this.currentAdmin.email });
        this.tagEmailsTo.push({ email: this.certificateDetail.applicant_email });
        this.tagEmailsCC.push({ email: this.certificateDetail.applicant_email });
        this.tagEmailsTo.push({ email: this.certificateDetail.buyer_email });
        this.tagEmailsCC.push({ email: this.certificateDetail.buyer_email });
        this.tagEmailsTo.push({ email: this.certificateDetail.owner_email });
        this.tagEmailsCC.push({ email: this.certificateDetail.owner_email });
        this.tagEmailsTo =  this.tagEmailsTo.filter(item=>item.email != null)
        console.log("tagEmailsTo",this.tagEmailsTo)
        this.submissionReview = data.response.application_extend;
        if (this.certificateDetail.occupancy_type == 2) {
          this.allUnits = data.response.units
          this.unitList()
        }
        // data.response.applicantAttachment.map(ele=>{

        // })
        this.applicantAttachment = data.response.applicantAttachment;

        this.cco_doc = data.response.application_cco_document
        this.documentsUploadList = [...this.applicantAttachment, ...this.certificateDetail['application_documents']];
        // console.log(this.documentsUploadList);
        this.documentsUploadList.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
        // console.log(this.documentsUploadList);
        this.documentsUploadListCCO = [...this.applicantAttachment, ...this.cco_doc, ...data.response.application_documents];
        this.documentsUploadListCCO.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
        this.certificates.next(this.certificateDetail)
        this.getMailOfSubmissionClerk();
        this.externalReview();
        this.getPicKUpNotes(this.pickupMail)
        this.allTransaction = data.response.allTransaction;
              console.log("tr datta=============================>",this.allTransaction)

        // this.allTransaction.shift();
        this.allTransaction.map((data: any) => {
          if (data.department_id == 0 && this.certificateDetail.occupancy_type != 2) {
            data.isFirePush = true
            this.allTransaction.push(data)
            //this.allTransaction[1]
          } else if (data.department_id == 5 && data.decision == 5) {
            data.isFireUnitFeePush = false;
            this.allTransaction.push({ ...data, isFireUnitFeePush: true });
          }
          if (data.status == 5) {
            this.isWaivOff = true
          }
          if ((data.status == 0 || data.status == 2 || data.status == null) && (data.department_id == 20 || data.department_id == 21)) {
            this.addFeeByAdmin.push(data);
          }
        })
      
        // if()
        



        //this.lastPaymentDate = this.allTransaction.filter
        // this.showAllInspection = data.response.showAllInspection;
        this.addresses = data.response.addresses;
        this.AllViolations = data.response.allViolation;
        this.showOwnerBankFile = this.certificateDetail.owner_type === 3 ? true : false;
        if (data.response.buyer_id && data.response.buyer_id != null) {
          this.BuyerApplicantFile = data.response.buyer_id;
        }
        if (data.response.seller_id && data.response.seller_id != null) {
          this.seller_id = data.response.seller_id;
        }
        if (data.response.owner_authorization && data.response.owner_authorization != null) {
          this.owner_authorization = data.response.owner_authorization;
        }
        if (data.response.sales_contract && data.response.sales_contract != null) {
          this.sales_contract = data.response.sales_contract;
        }
        if (data.response.applicant_photo_id && data.response.applicant_photo_id != null) {
          this.ApplicantFile = data.response.applicant_photo_id;
        }
        if (data.response.corporate_formation && data.response.corporate_formation != null) {
          this.ownerCorporateFormationFile = data.response.corporate_formation;
        }
        if (data.response.power_of_attorney && data.response.power_of_attorney) {
          this.powerOfAttorneyFile = data.response.power_of_attorney;
        }
      }
    })

  }
  public isEdit = false
  // public updateId: number
  // getFeeAndPaymentsData(item) {
  //   this.feeAndPaymetsForm.controls['application_type'].setValue(item.application_type);
  //   this.feeAndPaymetsForm.controls['amount'].setValue(item.amount);
  //   this.isEdit = true;
  //   this.updateId = item.id;
  // }

  // updateFeeAndPaymets(value: string) {
  //   const updateData = {
  //     application_type: this.feeAndPaymetsForm.value.application_type,
  //     amount: this.feeAndPaymetsForm.value.amount,
  //     id: this.updateId,
  //     application_id: this.certificateDetail.id
  //   }
  //   this.dashboardService.updateFeeAndPaymets(updateData).subscribe(data => {
  //     this.allTransaction = data;
  //     this.resetForm(value)
  //     this.isEdit = false;
  //   })
  // }

  deleteDashboardMedia(Id: number, file: number) {
    let query = {
      id: Id,
      fileNo: file
    };
    this.dashboardService.deleteDashboardMedia(query).subscribe(data => {
      if (data.status === 'success') {
        if (query.fileNo === 1) {
          this.ApplicantFile = null;
        }
        if (query.fileNo === 2) {
          this.powerOfAttorneyFile = null;
        }
        if (query.fileNo === 3) {
          this.ownerCorporateFormationFile = null;
        }
        if (query.fileNo === 4) {
          this.BuyerApplicantFile = null;
        }
      }
    });
    console.log("certificateDetail",this.certificateDetail)
  }
  isChangeList = true;
  changeVialotionLiastAndInspectionList(value: string) {
    if (value == "inspection") {
      this.isChangeList = true
    } else {
      this.isChangeList = false;
    }
  }


  fillApplicantionData() {
    this.isTranscationUpdate = true;
    this.transferApplicactionForm.controls['ownershipName'].setValue(this.certificateDetail.applicant_name);
    this.transferApplicactionForm.controls['ownershipLastName'].setValue(this.certificateDetail.applicant_last_name);
    this.transferApplicactionForm.controls['ownershipPhone'].setValue(this.certificateDetail.applicant_phone);
    this.transferApplicactionForm.controls['ownershipEmail'].setValue(this.certificateDetail.applicant_email);
    this.transferApplicactionForm.controls['ownershipAddress'].setValue(this.certificateDetail.applicant_address);
  }

  public isTranscationUpdate = false
  submitApplicantForm() {
    const toUpdateData = {
      modal: 'applicant',
      applicant_name: this.transferApplicactionForm.get('ownershipName').value,
      applicant_last_name: this.transferApplicactionForm.get('ownershipLastName').value,
      applicant_phone: this.transferApplicactionForm.get('ownershipPhone').value,
      applicant_email: this.transferApplicactionForm.get('ownershipEmail').value,
      applicant_address: this.transferApplicactionForm.get('ownershipAddress').value,
      application_id: this.certificateDetail.id
    };
    this.updateApplicantDetail(toUpdateData);
  }

  public updateApplicantDetail(toUpdateData: any) {
    if (this.transferApplicactionForm.invalid && toUpdateData.modal == 'applicant') {
      this.isTransferApplication = true;
      return false
    }
    if (this.ownerForm.invalid && toUpdateData.modal == 'owner') {
      this.isOwner = true;
      return false
    }
    this.isHide = true

    this.dashboardService.updateApplicantDetail(toUpdateData).subscribe(data => {
      this.certificateDetail = data;
      this.isHide = false
      this.getCertificate()
      this.transferApplicactionForm.reset();
      this.isTransferApplication = false
      this.addresses = data.addresses;
      this.showOwnerBusinessFile = this.certificateDetail.owner_type === 2 ? true : false;
      console.log(this.showOwnerBusinessFile)
      this.showOwnerBankFile = this.certificateDetail.owner_type === 3 ? true : false;
    }, error => {
      this.toasterService.error('Please void previous transaction after then add unit')
    });
  }
  externalReviews: any;
  externalReview() {
    this.dashboardService.externalReviews(this.certificateDetail.id).subscribe(data => {
      this.externalReviews = data.response;
    })
  }
  public name: string
  public doc_id: any
  fileName(docType, doc_id) {

    this.doc_id = doc_id
    const name = this.settings.getSetting('applicantFiles', docType)
    this.name = name
  }


  updateMedia(event) {
    console.log("image val===>",event.target.files[0])
    const media = {
      application_id: this.certificateDetail.id,
      name: this.name,
      value: event.target.files[0],
      doc_id: this.doc_id
    };
    this.dashboardService.updateMedia(media).subscribe(data => {
      if (data.status === 'success') {
        this.getCertificate()
        this.toasterService.success('update file successfully')
        this.hideUploadPopUp()
        // if (this.name === 'applicant_photo_id') {
        //   this.ApplicantFile = data.response.applicant_photo_id;
        // }
        // if (this.name === 'power_of_attorney') {
        //   this.powerOfAttorneyFile = data.response.power_of_attorney;
        // }
        // if (this.name === 'corporate_formation') {
        //   this.ownerCorporateFormationFile = data.response.corporate_formation;
        // }
        // if (this.name === 'buyer_id') {
        //   this.BuyerApplicantFile = data.response.buyer_id;
        // }
        // if (this.name === 'seller_id') {
        //   this.seller_id = data.response.seller_id;
        // }
        // if (this.name === 'owner_authorization') {
        //   this.owner_authorization = data.response.owner_authorization;
        // }
        // if (this.name === 'sales_contract') {
        //   this.sales_contract = data.response.sales_contract;
        // }
      }
    });
  }
  mediaUpload(event) {
    const media = {
      id: this.certificateDetail.id,
      name: this.name,
      value: event.target.files[0],
      doc_id: this.doc_id
    };
    this.dashboardService.postDashboardMedia(media).subscribe(data => {
      if (data.status === 'success') {
        this.toasterService.success('Upload file successfully')
        this.hideUploadPopUp()
        // if (this.name === 'applicant_photo_id') {
        //   this.ApplicantFile = data.response.applicant_photo_id;
        // }
        if (this.name === 'power_of_attorney') {
          this.powerOfAttorneyFile = data.response.power_of_attorney;
        }
        if (this.name === 'corporate_formation') {
          this.ownerCorporateFormationFile = data.response.corporate_formation;
        }
        if (this.name === 'buyer_id') {
          this.BuyerApplicantFile = data.response.buyer_id;
        }
        if (this.name === 'seller_id') {
          this.seller_id = data.response.seller_id;
        }
        if (this.name === 'owner_authorization') {
          this.owner_authorization = data.response.owner_authorization;
        }
        if (this.name === 'sales_contract') {
          this.sales_contract = data.response.sales_contract;
        }
      }
    });
  }


  deletCOandChDoc(document: any) {
    const data = {
      doc_id: document.id,
      application_id: document.application_id
    }
    this.dashboardService.deletecoandchDoc(data).subscribe(data => {
      this.getCertificate()
      this.toasterService.success('Delete file successfully')
    })
  }
  hideUploadPopUp() {
    this.uploadpopup.nativeElement.click();
    this.singleupload.nativeElement.click()
  }
  singleimage(id) {
    this.id = id
  }
  public docType: any
  ccoImage(docType: any) {
    this.docType = docType
  }

  singleAttecment(event) {
    var formData = new FormData();
    formData.append(
      "attachment",
      event.target.files[0],
    );
    formData.append(
      'id',
      this.id,
    )
    this.dashboardService.addApplicationAttecment(formData).subscribe(data => {
      this.hideUploadPopUp();
      this.getCertificate()
    })
  }

  ccouploadAttecment(event) {
    var formData = new FormData();
    formData.append(
      "document",
      event.target.files[0],
    );
    formData.append(
      'application_id',
      this.certificateDetail.id,
    );
    formData.append(
      'document_type',
      this.docType
    )
    this.dashboardService.ccoaddApplicationAttecment(formData).subscribe(data => {
      this.hideUploadPopUp();
      this.getCertificate()
    })
  }

  singleAttecmentDelete(id) {
    const data = {
      id: id
    }
    this.dashboardService.deleteAttecment(data).subscribe(data => {
      this.getCertificate();
    })
  }

  deleteCCo(id) {
    const data = {
      id: id,
      application_id: this.certificateDetail.id
    }
    this.dashboardService.deleteCCoAttecment(data).subscribe(data => {
      this.getCertificate();
    })
  }
  // for EmailAndNotes;

  private sendEmail() {
    this.emailForm = this.formBuilder.group({

      // toEmail: ['', [Validators.required,
      // Validators.maxLength(250),
      // Validators.pattern(this.EMAIL_REGEX)]],
      // ccEmail: [''],
      subject: ['', [Validators.required, Validators.minLength(10)]],
      message: ['', [Validators.required]]
    });
  }
  get f() { return this.emailForm.controls; }


  private pickUp() {
    this.pickUpForm = this.formBuilder.group({
      image: [''],
      reciepentName: ['', Validators.required],
      item: ['', Validators.required],
    });
  }

  private notesForm1() {
    this.notesForm = this.formBuilder.group({
      notes: ['', Validators.required]
    })
  }

  get notes() { return this.notesForm.controls }
  get getPicControls() { return this.pickUpForm.controls }


  saveNotes() {
    console.log("pick")
    this.isNotesSubmit = true;

    if (this.notesForm.invalid) {
      return false
    }
    const data = {
      application_id: this.certificateDetail.id,
      notes: this.notesForm.controls.notes.value
    }
    this.dashboardService.addNotes(data).subscribe(data => {
      this.notesForm.reset();
      this.isNotesSubmit = false;
      this.getPicKUpNotes(2)

    })
  }

  public isSubmit = false
  submitMail() {
    debugger
    this.isSubmit = true;
    if (this.emailForm.invalid || this.toEmails.length == 0) {
      return false
    }
    const data = {
      // to_email: this.emailForm.value.toEmail,
      // cc_email: this.emailForm.value.ccEmail,
      to_email: this.toEmails,
      subject: this.emailForm.value.subject,
      message: this.emailForm.value.message,
      application_id: this.certificateDetail.id
    }
    if (this.ccEmails.length > 0) {
      data['cc_email'] = this.ccEmails;
    }
    this.dashboardService.sendApplicantEmail(data).subscribe(data => {
      this.toasterService.success("Email sent successfully")
      console.log("email sent ")
      this.emailForm.reset();
      this.isSubmit = false;
      this.selectedTagEmailItems = [];
      this.selectedTagEmailItemsCC = [];
      this.toEmails = [];
       this.ccEmails = [];
      this.getPicKUpNotes(this.pickupMail)
    })
  }


  points = [];
  signatureImage:any;
  public isSaveImageButton = false
  saveImage(data) {
    this.signatureImage = data;
    // this.isSaveImageButton = true
    this.addPichUp();


  }

  

  public isAttecment = false
  public attechmentImage: any
  picupAttechment(event1) {
    this.picUpattectment = new FormData();
    this.picUpattectment.append(
      "attachment",
      event1.target.files[0],
    );
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.attechmentImage = event.target.result;
    };
    reader.readAsDataURL(event1.target.files[0]);
  }

  removeAttecmentImage() {
    this.attechmentImage = ''
  }


  addPichUp() {
    this.isPickupSubmit = true;
    if (this.pickUpForm.invalid) {
      return false
    }
    debugger
    if( this.signatureImage){
      this.picUpattectment.append(
        "image",
        this.signatureImage,
      );
    }
    if(  this.pickUpForm.value.reciepentName){
      this.picUpattectment.append(
        'reciepentName',
        this.pickUpForm.value.reciepentName,
      );
    }
    if( this.pickUpForm.value.item){
      this.picUpattectment.append(
        'item',
        this.pickUpForm.value.item,
      )
    }
  
   
   
    this.picUpattectment.append(
      "application_id",
      String(this.certificateDetail.id),
    );
    this.dashboardService.addPichUpNotes(this.picUpattectment).subscribe(data => {
      this.pickUpForm.reset()
      this.picUpattectment =new FormData;
      this.isSaveImageButton = false;
      this.isPickupSubmit = false;
      this.attechmentImage = null;
      // this.signaturePad.set('minWidth', 5);
      this.isSignaturePad = !this.isSignaturePad
      this.getPicKUpNotes(3)
    })
  }

  clear() {
  }



  emailAndNotesaAndPickup(value: number) {

    if (value === 1) {
      this.isEmail = true;
      this.isNotes = false;
      this.isPickUp = false;
      this.getPicKUpNotes(value)
    }
    else if (value === 2) {
      this.isEmail = false;
      this.isNotes = true;
      this.isPickUp = false;
      this.getPicKUpNotes(value)
    } else {
      this.isEmail = false;
      this.isNotes = false;
      this.isPickUp = true;
      this.getPicKUpNotes(value)
    }
  }

  public emailData = [];
  getPicKUpNotes(value) {
    const data = {
      application_id: this.certificateDetail.id,
      detailType: value
    }
    this.dashboardService.emailAndPicupList(data).subscribe(data => {
      this.emailData = data.response;
    })
  }

  deletePickUpEmail(id: number, value: number) {
    const data = {
      detailType: value
    }
    this.dashboardService.deleteEmailPickUp(data, id).subscribe(data => {
      this.getPicKUpNotes(value)
    })
  }

  public ward = []
  getWards() {
    this._CS.getWard({ city_id: this.certificateDetail['admin_id'] }).subscribe(data => {
      this.ward = data['response']
    })
  }

  applicationkeyPressNumbers(event, type: string) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 48 || charCode > 57) && charCode != 45) {
      event.preventDefault();

      return false;
    } else {
      this.phoneNumberFormateupdateApp(type)
    }
  }

  phoneNumberFormateupdateApp(type: string) {
    var value: string
    var autoFillValue = '-'
    if (type == 'applicant') {
      if (this.transferApplicactionForm.value.ownershipPhone.length === 3) {
        this.transferApplicactionForm.controls.ownershipPhone.setValue(this.transferApplicactionForm.value.ownershipPhone.concat(autoFillValue))
      }
      if (this.transferApplicactionForm.value.ownershipPhone.length === 7) {
        this.transferApplicactionForm.controls.ownershipPhone.setValue(this.transferApplicactionForm.value.ownershipPhone.concat(autoFillValue))
      }
    }
    else if (type == 'ownerphone') {
      if (this.ownerForm.value.owner_phone.length === 3) {
        this.ownerForm.controls.owner_phone.setValue(this.ownerForm.value.owner_phone.concat(autoFillValue))
      }
      if (this.ownerForm.value.owner_phone.length === 7) {
        this.ownerForm.controls.owner_phone.setValue(this.ownerForm.value.owner_phone.concat(autoFillValue))
      }
    }

    else if (type == 'buyerphone') {
      if (this.buyerForm.value.buyer_phone.length === 3) {
        this.buyerForm.controls.buyer_phone.setValue(this.buyerForm.value.buyer_phone.concat(autoFillValue))
      }
      if (this.buyerForm.value.buyer_phone.length === 7) {
        this.buyerForm.controls.buyer_phone.setValue(this.buyerForm.value.buyer_phone.concat(autoFillValue))
      }
    }



  }

  toggleAccordionArrow() {
    this.emailAccordionExpanded = !this.emailAccordionExpanded;
  }

  addFee() {
    this.isAddFeeSubmit = true;
    if (this.addFeeForm.invalid) {
      return false;
    }
    const payload = {
      ...this.addFeeForm.value,
      application_id: this.certificateDetail.id
    }
    this.subscription.add(this.applicationService.addFeeByAdmin(payload).subscribe((data: any) => {
      this.isAddFeeSubmit = false;
      this.addfee.nativeElement.click();
      this.getCertificate();
    }));
  }
  openRefundModal() {
    this.updateRefundForm.reset();
    this.isRefunded = true;
  }
  refundFee() {
    if (this.updateRefundForm.invalid) {
      return false;
    }
    this.dashboardService.addRefund({
      amount: this.updateRefundForm.value.fee,
      application_id: this.certificateDetail.id,
      description: this.updateRefundForm.value.note
    }).subscribe(data => {
      debugger
      this.isRefunded = false;
      this.getCertificate();
    })
  }

  updateRefund(item) {
    this.isRefunded = true;
    this.updateRefundItemData = item;
    this.updateRefundForm.patchValue({
      fee: item.amount,
      note: item.description
    })
  }

  UpdateRefundFee() {
    this.dashboardService.updateRefund({
      id: this.updateRefundItemData.id,
      amount: this.updateRefundForm.value.fee,
      application_id: this.updateRefundItemData.application_id,
      description: this.updateRefundForm.value.note
    }).subscribe(data => {
      this.isRefunded = false;
      this.getCertificate();
    })
  }

  deleteRefund(item) {
    debugger
    this.dashboardService.deleteRefunds({
      id: item.id,
      amount: item.amount,
      application_id: item.application_id,
      description: item.description,
    }).subscribe(data => {
      debugger
      this.getCertificate();
    })
  }

  public toEmails = [];
  public ccEmails = [];

  onAdding(event, type) {
    if (type == 1) {
      this.toEmails.push(event.email)
      // console.log(this.toEmails);
    } else if (type == 2) {
      this.ccEmails.push(event.email)
      // console.log(this.ccEmails);
    }
  }

  onRemoving(event, type) {
    if (type == 1) {
      this.toEmails.forEach((data, i) => {
        if (data == event.email) {
          this.toEmails.splice(i, 1)
        }
      })
      // console.log(this.toEmails)
    } else if (type == 2) {
      this.ccEmails.forEach((data, i) => {
        if (data == event.email) {
          this.ccEmails.splice(i, 1)
        }
      })
      // console.log(this.ccEmails)
    }
  }

  togglePreviousApplicationBtn() {
    this.previousApplicationBtnClicked = !this.previousApplicationBtnClicked;
  }

  public validators = [this.must_be_email];
  public errorMessages = {
    'must_be_email': 'Enter valid email adress!'
  };
  private must_be_email(control: FormControl) {
    var EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
    if (control.value != "" && (control.value.length <= 5 || !EMAIL_REGEXP.test(control.value))) {
      return { "must_be_email": true };
    }
    return null;
  }
  isTalantForm:boolean = false;
  documentimg=null;
  file_name:any;
  editOrDeleteUnitId:any;
  editUnits(value) {
      this.editOrDeleteUnitId = value.id;
      this.file_name = "";
      this.medias = "";
      if(this.file_name){
        this.documentimg=null;
      }else{
        this.documentimg=value.document;
      }
      console.log("id===>1593",value)
      // this.index = index;
      this.tanantForm.setValue({
        unit: value.unit,
        no_of_bedrooms: value.no_of_bedrooms,
        no_of_bathrooms: value.no_of_bathrooms,
        no_of_kitchens: value.no_of_kitchens,
        tenant_name: value.tenant_name
      })
      // this.isUpdate = true;
      this.isTalantForm = false;
    }
    tanantForm
    public  initTanentForm() {
    this.tanantForm = this.formBuilder.group({
      unit: ['', Validators.required],
      no_of_bedrooms: ['', Validators.required],
      no_of_kitchens: ['', Validators.required],
      no_of_bathrooms: ['', Validators.required],
      tenant_name: ['', Validators.required],
    });
  }

  get intentControl() {
    return this.tanantForm.controls
  };

  onUploadChange(event: any) {
    this.showfilename = false;
    this.documentimg =null;
    console.log(event);
    this.medias = event.target.files[0];
    this.file_name = this.medias.name;
    const allowed_typepdf= ['application/pdf','image/jpeg','image/jpg','image/png','application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
    this.typecheck = !_.includes(allowed_typepdf,this.medias.type)
    this.file_type = this.medias.type;
     if (!_.includes(allowed_typepdf,this.medias.type)) {
      if(this.file_type == 'image/webp' || this.file_type == 'image/gif'){
        console.log("only jpg and png  image are accepet")
        this.msg = true;
        this.msg1 = false;
         this.file_name = "";
         this.medias = "";
        return
      }
      if(this.file_type != 'application/pdf' || this.file_type != 'this.application/vnd.openxmlformats-officedocument.wordprocessingml.document'){
         console.log("only pdf and doc accepet")
          this.file_name = "";
         this.msg1 = true;
         this.msg = false;
         this.medias = "";
        return
      }
    }
   if(this.file_type == "image/jpeg" || this.file_type == "image/jpg" || this.file_type == "image/png" || this.file_type == 'application/pdf' || this.file_type == 'this.application/vnd.openxmlformats-officedocument.wordprocessingml.document'){
        this.msg = false;
        this.msg1 = false;
        this.formData = new FormData

    }
     console.log("medias name is ==>",this.file_name,this.file_type)
      if(this.file_type == "image/jpeg" || this.file_type == "image/jpg" || this.file_type == "image/png"){
        console.log("fun is call==>",this.file_type)
        // this.file_name = "";
         const reader = new FileReader();
        reader.onload = this.handleReaderLoaded.bind(this);
        reader.readAsBinaryString(this.medias)
      }
    }

  handleReaderLoaded(e) {
    this.document = 'data:image/png;base64,' + btoa(e.target.result) ;
    console.log("image is ",this.document)
  }
 
 blankTanat_id(){
    this.documentimg= null;
    this.file_name = "";
  }
  deleteDocument(){
    console.log("function click",)
    this.file_name = "";
    this.documentimg = null;  
    this.medias ="";
  }
  updateUnit() {
    this.isTalantForm = true;   
    console.log("1",this.tanantForm.invalid)
    if (this.tanantForm.invalid) {
      return false
    }
    this.addUnits.nativeElement.click()
    this.formData = new FormData
    this.formData.append('application_id',this.certificateDetail.id)
    this.formData.append('no_of_bathrooms',this.tanantForm.value.no_of_bathrooms)
    this.formData.append('no_of_bedrooms',this.tanantForm.value.no_of_bedrooms)
    this.formData.append('no_of_kitchens',this.tanantForm.value.no_of_kitchens)
    this.formData.append('tenant_name',this.tanantForm.value.tenant_name)
    this.formData.append('unit',this.tanantForm.value.unit)
    this.formData.append('id',this.editOrDeleteUnitId)
    if(this.medias){
      this.formData.append('document',this.medias)
    }else{
       this.formData.append('document',this.documentimg)
    }
      this.applicationService.addminEditUnit(
          this.formData
      ).subscribe((data: any) => {
        this.allUnits = data.response;
        this.unitList()

        if(data.status == 'success'){
          this.file_name = "";
          this.medias = "";
        }
        
      })
    this.isTalantForm = false;
  }

  unitList(){
    this.pagination.application_id = this.certificateDetail.id
    this.applicationService.unitList(this.pagination).subscribe((data: any) => {
      this.Units = data.response
      this.totalPaginations = data.total;
      this.offsets = data.offset;
      this.currentPaginations = data.currentPage;
      console.log("unit data val",data)
      console.log("total pag", this.totalPaginations,this.offsets,this.currentPaginations)
      console.log("value is ==>", this.Units)
      })
   
  }
  paginate(page) {
    this.Units = [];
    this.pagination['page'] = page;
    this.unitList();
  }

}
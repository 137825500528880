import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ErrorHandler, NgModule } from '@angular/core';
import { AgmCoreModule } from '@agm/core';


import { ToastrModule } from 'ngx-toastr';
import { NgxPaginationModule } from 'ngx-pagination';
import {
  NgxUiLoaderModule,
  NgxUiLoaderRouterModule,
  NgxUiLoaderHttpModule,
  NgxUiLoaderConfig,
  SPINNER,
  POSITION,
  PB_DIRECTION
} from 'ngx-ui-loader';

import { LayoutsModule } from './core/layouts.module';
import { CoreModule } from './core/core.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminModule } from './modules/admin/admin.module';
import { UserAuthGuard } from './core/guards/user-auth.guard';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CommonModule } from '@angular/common';
// import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { LoadingChunkErrorHandlerService } from './core/services/loading-chunk-error-handler.service';
// import { AdminPermissionGuard } from './core/guards/admin-permission.guard';
// import { SearchPipe } from './pipe/search.pipe';

const TOASTR_OPTIONS = {
  autoDismiss: true,
  maxOpened: 1,
  timeOut: 1500,
  closeButton:true,
  positionClass: 'toast-top-right',
  preventDuplicates: true,
}

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  // bgsColor: 'blue',
  // bgsOpacity: 0.5,
  // bgsPosition: POSITION.bottomCenter,
  // bgsSize: 60,
  // bgsType: SPINNER.rectangleBounce,
  // fgsColor: 'blue',
  // fgsPosition: POSITION.centerCenter,
  // fgsSize: 60,
  // fgsType: SPINNER.circle,
  // logoUrl: 'assets/images/Group 4045.png',
  // pbColor: 'blue',
  // pbDirection: PB_DIRECTION.leftToRight,
  // pbThickness: 5,
  // text: 'Welcome to Coop',
  // textColor: '#FFFFFF',
  // textPosition: POSITION.centerCenter
};
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    LayoutsModule,
    AdminModule,
    NgxPaginationModule,
    ToastrModule.forRoot(TOASTR_OPTIONS),
    // NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderModule,



    NgxUiLoaderRouterModule,
    NgxUiLoaderHttpModule,
    MatAutocompleteModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBlg_n_Iika9tpYMrEK8I1QFHl277ac4Y0'
    }),
    BsDatepickerModule.forRoot(),
    // TypeaheadModule.forRoot(),
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    TimepickerModule.forRoot(),
    AccordionModule.forRoot(),
  ],
  providers: [
    // {provide: ErrorHandler, useClass: LoadingChunkErrorHandlerService},
    UserAuthGuard,
    // AdminPermissionGuard,
    ...AppRoutingModule.PROVIDERS
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

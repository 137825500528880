import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup,FormBuilder,Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { data } from 'jquery';
import { codeEnforcementSettingConfig } from 'src/app/configs';
import { CeComplaintService } from 'src/app/core/http/admin/ce-complaint.service';
import { AddComplaintService } from 'src/app/core/http/users/code-Enforcement/add-complaint.service';
import { ToastrService } from 'ngx-toastr';
import { DashboardService } from 'src/app/core/http';
import { environment } from 'src/environments/environment';
import { AdminAuthenticationService } from 'src/app/core/authentication/admin-authentication.service';

@Component({
  selector: 'app-complaint',
  templateUrl: './complaint.component.html',
  styleUrls: ['./complaint.component.css']
})
export class ComplaintComponent implements OnInit {
  public searchQuery: any;
  public allComplaint = [];
  public allCity = [];
  public currentPagination: number = Number(1);
  public totalPagination: number = Number(0);
  public offset: any = 1;
  public page: number = 1
  public settings: any
  public searchString: string;
  public userData:any;
  public cityForm:FormGroup;
  public assignInspectorForm:FormGroup;
  public isAddedNoteTicket:any;
  public env = environment;
  @ViewChild('assignInspector', { static: false }) assignInspector: ElementRef;
  @ViewChild('cityListModal',{ static: false })cityListModal:ElementRef;
  @ViewChild('inspector',{ static: false })inspector:ElementRef
  @ViewChild('citySelected', { static: false }) citySelected: ElementRef;
  public adminData:any;
  public isTrue = true;
  constructor(
    private _CEService: CeComplaintService,
    private _Service: AddComplaintService,
    private router: Router,
    private _FB: FormBuilder,
    private _TS: ToastrService,
    private dashboardSrvice: DashboardService,
    private adminAuthService:AdminAuthenticationService,


  ) {
    this.getAdminData();
    this.settings = codeEnforcementSettingConfig
     this.userData = JSON.parse(localStorage.getItem('currentAdmin')) ;
    
     console.log("role_id===>", this.userData.role_id)
  }
  public payload = {
        page:1,
        searchString:null,
        inspector_id:null

  }

  ngOnInit() {
    this.whatControls();
    this.getCompalint();
    this.allSatff();
    this.getCity();
    this.inspectorControls();
  }

  searchApplication() {

  }
  getAdminData() {
    this.adminAuthService.getAdmin().subscribe(data => {
      this.adminData = data;
      console.log("roleId",this.adminData)
      if( this.adminData.role_id == 2 &&  (this.adminData.id == 361 || this.adminData.id == 452)){
        this.isTrue = false;
        // this.router.navigate(['/admin/ce/complaint'])
        // return true;
      }
      let city_id =  this.adminData ?  this.adminData.city_details : null;
      city_id = city_id ?  city_id.admin_id : null;
      if( (this.adminData.role_id == 2 || this.adminData.role_id == 3 || this.adminData.role_id == 4 || this.adminData.role_id == 5) &&  (city_id == 361 || city_id == 452)){
        this.isTrue = false;
        return true;
      }
    })
  }

  getCompalint() {
    if(!this.payload.searchString){
      delete this.payload.searchString
    }
    if(!this.payload.inspector_id){
      delete this.payload.inspector_id

    }
    this._CEService.getComplaint(this.payload).subscribe(com => {
      this.allComplaint = com.response;
      this.allComplaint.forEach(item => {
        item.assign_inspector =  item.assign_inspector[0];
      });
      this.totalPagination = com.total;
      this.offset = com.offset;
      this.currentPagination = com.page;
      console.log(this.allComplaint)
    })
  }

  paginate(page) {
    this.page = page;
    this.payload.page = page
    this.getCompalint()
  }
  redirectPage(){
    this.router.navigate(['/admin/certificates/housing'],{ queryParams: { tabType: 1 } })
  }
 

  navigateDetailsPage(id) {
    this.router.navigate(['/admin/ce/complain-details'], { queryParams: { id: id } })
  }

  searchComplain() {
    
    // if (this.searchQuery.length  > 1) {
      this.payload.searchString = this.searchQuery
      this.getCompalint()
      // this._CEService.searchComplaint({ searchString: this.searchQuery }).subscribe(com => {
      //   this.allComplaint = com['response'];
      //   this.totalPagination = com.total;
      //   this.offset = com.offset;
      //   this.currentPagination = com.page;
      // })
    // }else{
    //   this.getCompalint()
    // }
  }

  export(format: string){
      let  currentAdmin = JSON.parse(localStorage.getItem('currentAdmin'))
      let body: any = {}
      body.format = format;
      body.format = format;

      body.page = 1
      // body.type = this.type;
      // body.occupancy_type = this.type
      // let url='http://coop.appening.xyz/api/ce/admin/export-complain?page=1'
      let url = this.env.host + '/api/ce/admin/export-complain?page=1'
      if(currentAdmin.role_id == 1){
        currentAdmin.id = null;
      }
       console.log("download api===>",url + '&' + 'searchString=' + this.searchQuery + '&' + 'inspector_id=' + this.inspector_id + '&' + 'role_id=' + currentAdmin.role_id + '&' + 'city_id=' + currentAdmin.id )
      // return;

      window.open(url + '&' + 'searchString=' + this.searchQuery + '&' + 'inspector_id=' + this.inspector_id + '&' + 'role_id=' + currentAdmin.role_id + '&' + 'city_id=' + currentAdmin.id );

      // this._CEService.getComplaintFile(body);
    
  }
  allSatfflist = [];
  allSatff() {
    this._CEService.getRoleByIDstaff(5).subscribe(data => {
      this.allSatfflist = data.response;
    

    })
  }
  inspector_id:any
  selectInspector(value){
    this.inspector_id = value;
    this.payload.inspector_id = value
      this.getCompalint()
     console.log("data is ===>",value)
  }

  getCity() {
    this._Service.getCity().subscribe(data => {
      this.allCity = data.response.docs;
    })
  }
  isCitySubmit = false;
  whatControls() {
    this.cityForm = this._FB.group({
      city: ['', Validators.required],
    })
  }
  addComplaint(val) {
    debugger
    localStorage.removeItem('tabOneTwoData');
    sessionStorage.removeItem('complaint');
    console.log("city id", this.userData.id)
    let city_id
    
    if(this.userData.role_id != 1){
        if(this.userData.role_id == 1 || this.userData.role_id == 2){
          city_id =  this.userData.id;
        }else{
          city_id =  this.userData.city_details.admin_id;
        }
        localStorage.setItem("city_idForComplainAdd",city_id)
            this.router.navigate(['/admin/ce/add-complaint'])

    }else{
      if(val == 2){
        if(this.cityForm.invalid){
          this.isCitySubmit = true;
          return;
        }
        this.cityListModal.nativeElement.click();
            this.router.navigate(['/admin/ce/add-complaint'])

      }
    
      localStorage.setItem("city_idForComplainAdd",this.cityForm.controls.city.value)
    }
  }
  get cityFormValidaters() { return this.cityForm.controls }



  inspectorControls() {
    this.assignInspectorForm = this._FB.group({
      inspector: ['', Validators.required],
      comment: [],
      schedule_date:[]
    })
  }

  get insCon() {
    return this.assignInspectorForm.controls
  }
  application_id:any;
  inspectorList = [];
  city_id:any;
  inspectorId:any;
  schedule_date:any;
  getComplaintId(id,inspectorId,city_id,schedule_date){
   this.application_id = id;
   this.inspectorId = inspectorId
   this.city_id = city_id;
   this.schedule_date= schedule_date;
   
  
  if(city_id) {
   this.getInspector();
  }
  }

  getInspector() {
    this.dashboardSrvice.getInspectors({ department_id: 1,city_id:this.city_id }).subscribe(data => {
      this.inspectorList = data.response;
      this.assignInspectorForm.patchValue({
        inspector:this.inspectorId ? this.inspectorId : "",
        schedule_date:this.schedule_date ?new Date(this.schedule_date) :null
       })
      console.log("inspectorList",this.inspectorList)
    })
  }

  isAssignInspector = false;
  assigenInspector() {
    if (this.assignInspectorForm.invalid) {
      this.isAssignInspector = true
      return false
    }
    this.assignInspectorForm.value.application_id = this.application_id;
    this.assignInspectorForm.value.inspector = Number(this.assignInspectorForm.value.inspector)
    this._CEService.assignInspector(this.assignInspectorForm.value).subscribe(data => {
      this.isAssignInspector = false;
      this.getCompalint();
      this.assignInspector.nativeElement.click()
      this.assignInspectorForm.reset()
      this._TS.success('Inspector has been assigned successfully');
    })
  }
  goToSummary(){
    this.router.navigate(['/admin/ce/summary-index'])
  }
  resetFilter(){
    this.searchQuery = undefined;
    this.inspector_id = undefined; 
    this.payload.inspector_id = null;
    this.payload.searchString = null;
    this.inspector.nativeElement.value = "";
    this.getCompalint();
  }
  blankSelectField(){
    this.isCitySubmit = false;
    this.cityForm.reset();
    this.citySelected.nativeElement.value = "";
  }
}

import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  OnChanges,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router, RoutesRecognized } from "@angular/router";
import { forEach, transform } from "lodash";
import { DateFormatter } from "ngx-bootstrap/datepicker";
import { ToastrService } from "ngx-toastr";
import { filter, pairwise } from "rxjs/operators";
import { codeEnforcementSettingConfig } from "src/app/configs";
import { AuthenticationService } from "src/app/core/authentication/authentication.service";
import { ApplicationService } from "src/app/core/http";
import { AddComplaintService } from "src/app/core/http/users/code-Enforcement/add-complaint.service";
import { environment } from "src/environments/environment";
import { AddressSearch, Addresses } from "./../../../../shared/models";
import { CeComplaintService } from "src/app/core/http/admin/ce-complaint.service";

@Component({
  selector: "app-add-copmalaint-from-admin",
  templateUrl: "./add-copmalaint-from-admin.component.html",
  styleUrls: ["./add-copmalaint-from-admin.component.css"],
})
export class AddCopmalaintFromAdminComponent implements OnInit, OnChanges {
  public tabOneTwoData: any;
  public setting: any;
  public isAddressSelected = false;
  public addressSelected: any;
  public isNotListedAdress = true;
  public isAddress = false;
  public isPendingApplicationForAddress = false;
  public selectedAddressId: any;
  public selectedStreetAddressId: any;
  public type_List = [];
  public sub_typeList = [];
  public type_id: any;
  public searchQuery = {
    query: null,
    //search: 1,
    city_id: null,
  };

  public searchQueryAddress: AddressSearch = {
    query: null,
    search: 1,
  };
  public address: Array<Addresses>;

  public allSatfflist = [];
  public sessionKey = "complaint";
  public openLocationTab: boolean = false;
  public openGeolocationTab = false;
  public imageBasePath: string = `${environment.host}${environment.imageBasePath}`;
  public ipAddress: string;
  public location_type: number = 1;
  public isWhenSubmit = false;
  public isWhereSubmit = false;
  public currentTab: string = "what";
  public whatForm: FormGroup;
  public whenForm: FormGroup;
  public whereForm: FormGroup;
  public whoForm: FormGroup;
  public staffId: any;
  public type = "number";
  @ViewChild("datePopUp", { static: false }) datePopUp: ElementRef;
  @ViewChild("priority_s", { static: false }) priority_s: ElementRef;

  public mytime: Date;
  public time: any;
  constructor(
    private _CEService: AddComplaintService,
    private _FB: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private _TS: ToastrService,
    private applicationService: ApplicationService,
    private _authService: AuthenticationService,
    private _Service: CeComplaintService
  ) {
    this.setting = codeEnforcementSettingConfig;
  }

  ngOnInit() {
    this.onInIt();
    console.log("city_id", localStorage.getItem("city_idForComplainAdd"));
    this.staffId = localStorage.getItem("city_idForComplainAdd");
    this.city_id = this.staffId;

    if (this.staffId) {
      this.whereForm.controls.city.setValue(this.staffId);
      this.typeList();
    }
    this.mytime = new Date();
    this.route.queryParams.subscribe((data) => {
      if (data.tab) {
        this.currentTab = data.tab;
      }
    });
    this.router.events
      .pipe(
        filter((evt: any) => evt instanceof RoutesRecognized),
        pairwise()
      )
      .subscribe((events: RoutesRecognized[]) => {
        console.log("previous url", events[0].urlAfterRedirects);
        console.log("current url", events[1].urlAfterRedirects);
        if (events[0].urlAfterRedirects == "/comlaint/successfull") {
          this.router.navigate(["/admin/ce/complaint"]);
        }
      });
    if (this.currentTab == "who") {
      this.currentUser();
      // this.getIP();
      this.allSatff();
    }
    if (this.currentTab == "when") {
      this.getImage();
    }
    if (this.currentTab) {
      this.back(this.currentTab);
    }
    this.whatForm.controls.complaint_type.setValue(2);

    if (this.currentTab) {
      this.getCurrentAdmin();
    }
    this.getCity();
  }

  ngOnChanges() {
    this.priority_s.nativeElement.value = "";
  }

  onInIt() {
    this.whatControls();
    this.whenControls();
    this.whereControls();
    this.whoControls();
    const d = new Date();
    let time = d.getTime();
    this.whenForm.controls.incident_date.setValue(d);
    this.whenForm.controls.incident_time.setValue(time);
    // if(!this.whereForm.value.city_id){
    //   this.openGeolocationTab = true;
    //   this.whereForm.controls.address.disable();
    //   this.whereForm.controls.address.setValue(null);
    //   this.whereForm.controls.address_id.setValue(null)
    // }
  }

  whatControls() {
    this.whatForm = this._FB.group({
      complaint_type: ["", Validators.required],
      complaint__sub_type: ["", Validators.required],
      department: ["", Validators.required],
    });
  }

  whenControls() {
    this.whenForm = this._FB.group({
      incident_date: ["", Validators.required],
      incident_time: ["", Validators.required],
      notes: [""],
    });
  }

  whereControls() {
    this.whereForm = this._FB.group({
      address: ["", Validators.required],
      also_know_as: [null],
      street_name: ["", Validators.required],
      nearest_intersection: ["", Validators.required],
      location_details: ["", Validators.required],
      sbl: [""],

      city: ["", Validators.required],
      address_id: [null],
      // address: [null],
      unit: [null],
      building_number: [null],
      also_known_as: [null],
      isOwner: false,
      isBuyer: false,
      google_address: [null],
    });
  }

  whoControls() {
    this.whoForm = this._FB.group({
      priority: ["", Validators.required],
      pc_ip_address: [null],
      name: [null],
      user_email: [
        "",
        [
          Validators.required,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
        ],
      ],
      user_first_name: ["", Validators.required],
      user_last_name: [""],
      user_address: ["", Validators.required],
      user_phone: [
        "",
        [
          Validators.required,
          Validators.pattern("^[0-9]{3}-[0-9]{3}-[0-9]{4}$"),
        ],
      ],
      source: ["", Validators.required],
    });
  }

  get whenCon() {
    return this.whenForm.controls;
  }
  get whereCon() {
    return this.whereForm.controls;
  }
  get whoCon() {
    return this.whoForm.controls;
  }

  selectType(value: any) {
    this.whatForm.controls.complaint_type.setValue(value);
  }

  public addComplaintData: any = {};
  complaindata: any;
  complaint_id: any;

  addComlaint(currentForm: any, nextTab: string) {
    debugger;
    this.complaint_id = sessionStorage.getItem(this.sessionKey);
    if (currentForm == "whatForm") {
      if (this.whatForm.controls.complaint_type.invalid) {
        this._TS.error("Please select type");
      }
      if (this.whatForm.controls.department.invalid) {
        this._TS.error("Please select department");
      } else if (this.whatForm.controls.complaint__sub_type.invalid) {
        this._TS.error("Please select sub type");
      }
      if (this.whatForm.invalid) {
        return false;
      }
      this.addComplaintData = {
        tab: 1,
        complaint_type: Number(this.whatForm.value.department),
        complaint__sub_type: Number(this.whatForm.value.complaint__sub_type),
        // department: Number(this.whatForm.value.department)
      };
      if (!this.complaint_id || this.complaint_id == undefined) {
        localStorage.setItem(
          "tabOneTwoData",
          JSON.stringify(this.addComplaintData)
        );
        this.currentTab = "when";
        this.router.navigate(["/admin/ce/add-complaint"], {
          queryParams: { tab: this.currentTab },
        });
        return false;
      }
    }

    if (currentForm == "whenFrom") {
      //this.whenForm.controls.incident_time.setErrors(null)

      if (this.whenForm.invalid) {
        this.isWhenSubmit = true;
        return false;
      }

      this.addComplaintData = {
        tab: 2,
        image: this.urls,
        incident_date: this.whenForm.value.incident_date,
        incident_time: this.mytime,
        notes: this.whenForm.value.notes ? this.whenForm.value.notes : null,
      };
      // this.formData.delete("upload");

      // this.urls.forEach((item,i)=>{
      //   if(item.url_image){

      //     var file = this.DataURIToBlob(this.url_image)
      //     this.formData.append('upload', file)

      //   }
      // })
      // this.formData.append('tab',2);
      // this.formData.append('incident_date', (this.whenForm.value.incident_date));
      // this.formData.append('incident_time', ( this.mytime));
      // notes: this.whenForm.value.notes ? this.whenForm.value.notes : null

      // Object.keys(this.whenForm.value).forEach(value => {
      //   console.log("value is ===>",value)
      //   // if (value != 'inspection_time' && value != 'violation_id') {
      //     if(this.whenForm.value[value]){
      //       this.formData.append(value, this.whenForm.value[value]);

      //     }
      //   // }
      // })
      if (!this.complaint_id || this.complaint_id == undefined) {
        (this.addComplaintData.complaint_type = Number(
          this.whatForm.value.department
        )),
          (this.addComplaintData.complaint__sub_type = Number(
            this.whatForm.value.complaint__sub_type
          )),
          localStorage.setItem(
            "tabOneTwoData",
            JSON.stringify(this.addComplaintData)
          );
        this.currentTab = "where";
        this.router.navigate(["/admin/ce/add-complaint"], {
          queryParams: { tab: this.currentTab },
        });
        return false;
      }
    }

    if (currentForm == "whereForm") {
      if (this.whereForm.invalid) {
        this.isWhereSubmit = true;
        return false;
      }
      debugger;
      if (
        (!this.addressSelected || this.addressSelected == "") &&
        !this.openGeolocationTab &&
        this.location_type == 1
      ) {
        //
        this._TS.error("Please select address");
        console.log("plesae select address");
        return false;
      }
      if (
        (!this.selectedAddressId || this.selectedAddressId == "") &&
        this.openGeolocationTab &&
        this.location_type == 1
      ) {
        //
        this._TS.error("Please select google address");
        console.log("plesae selecte ");
        return false;
      }
      if (
        (!this.selectedStreetAddressId || this.selectedStreetAddressId == "") &&
        this.location_type == 2
      ) {
        //
        this._TS.error("Please select street address");
        return false;
      }
    }
    this._CEService.addComlaint(this.addComplaintData).subscribe((data) => {
      console.log(data);
      this.complaindata = data;
      this.currentTab = nextTab;
      console.log("data is==>", "call when tab ");
      const d = new Date();
      let time = d.getTime();
      this.whenForm.controls.incident_date.setValue(d);
      this.whenForm.controls.incident_time.setValue(time);
      if (this.currentTab == "who") {
        this.currentUser();
      }
      debugger;
      if (this.currentTab == "complete") {
        localStorage.setItem("id", data.response.id);
        this._TS.success("Your complaint has been registered.");
        // this.router.navigate(['admin/ce/complaint'])
        console.log("data is ===>", data.response.id);
        // return;
        //  this.router.navigate(['/admin/ce/complain-details'],{ queryParams: { id: data.response.id } })
        this.router.navigate(["/admin/ce/add-complaint"], {
          queryParams: { tab: this.currentTab },
        });

        sessionStorage.removeItem(this.sessionKey);
      } else {
        this.back(this.currentTab);
        this.router.navigate(["/admin/ce/add-complaint"], {
          queryParams: { tab: this.currentTab },
        });
      }
      // if(this.currentTab == 'who'){
      //   this.getCurrentAdmin()

      // }
    });
  }
  public isWhoSubmit = false;

  addComlaintWhereAndWhoTab(currentForm: any, nextTab: string) {
    this.selectLocationType(this.location_type);
    this.complaint = JSON.parse(sessionStorage.getItem(this.sessionKey));

    if (this.complaint == undefined || this.complaint == null) {
      (this.addComplaintData.complaint_type = Number(
        this.whatForm.value.department
      )),
        (this.addComplaintData.complaint__sub_type = Number(
          this.whatForm.value.complaint__sub_type
        )),
        (this.addComplaintData.image = this.urls),
        (this.addComplaintData.incident_date =
          this.whenForm.value.incident_date),
        (this.addComplaintData.incident_time = this.mytime),
        (this.addComplaintData.notes = this.whenForm.value.notes
          ? this.whenForm.value.notes
          : null);
    }

    if (currentForm == "whoForm") {
      this.selectInternalExterntype(this.user_type);

      if (this.whoForm.invalid) {
        this.isWhoSubmit = true;
        return false;
      }

      if (this.whereForm.invalid) {
        this.isWhereSubmit = true;

        // this.getIP();
      }
      if (this.user_type == 2 && !this.isSelectForGoogleAddress) {
        this._TS.error("please select address");
        return false;
      }
    }
    this.addComlaint(currentForm, nextTab);
  }
  public complaint: any;

  back(nexttab: string) {
    this.currentTab = nexttab;
    this.complaint = JSON.parse(sessionStorage.getItem(this.sessionKey));
    debugger;
    if (this.complaint) {
      if (this.currentTab == "where") {
        if (this.complaint && this.complaint.location_type == 1) {
          this.whereForm.controls.address.setValue(this.complaint.address);
          this.whereForm.controls.also_know_as.setValue(
            this.complaint.also_know_as
          );
        } else if (this.complaint && this.complaint.location_type == 2) {
          this.whereForm.controls.street_name.setValue(
            this.complaint.street_name
          );
          this.whereForm.controls.nearest_intersection.setValue(
            this.complaint.nearest_intersection
          );
          this.whereForm.controls.location_details.setValue(
            this.complaint.location_details
          );
        }
      } else if (this.currentTab == "when") {
        if (this.complaint != undefined || this.complaint != null) {
          this.urls = [];
          this.getImage();
        }
        const currentDate = new Date();
        // this.whenForm.controls.incident_date.setValue(new Date(this.complaint.incident_date ? this.complaint.incident_date : currentDate));
        this.whenForm.controls.notes.setValue(this.complaint.notes);
        // this.whenForm.controls.incident_time.setValue(new Date(this.complaint.incident_time));
      } else if (this.currentTab == "what") {
        // this.typeList();
        console.log("out tabOneTwoData");
        this.whatForm.controls.department.setValue(
          this.complaint.complaint_type
        );
        this.selectDepartMent(this.complaint.complaint_type);
      } else if (this.currentTab == "who") {
        if (this.complaint.priority) {
          this.whoForm.controls.priority.setValue(this.complaint.priority);
        }
        // this.priority_s.nativeElement.value = "";

        // this.whoForm.controls.pc_ip_address.setValue(this.complaint.pc_ip_address);
        this.whoForm.controls.name.setValue(this.complaint.name);
        if (this.complaint.name) {
          this.whoForm.controls.source.setValue(this.complaint.name);
        }

        this.whoForm.controls.user_email.setValue(this.complaint.user_email);
        this.whoForm.controls.user_first_name.setValue(
          this.complaint.user_first_name
        );
        this.whoForm.controls.user_last_name.setValue(
          this.complaint.user_last_name
        );
        this.whoForm.controls.user_address.setValue(
          this.complaint.user_address
        );
        this.whoForm.controls.user_phone.setValue(this.complaint.user_phone);
        this.notify_me_with_updates = this.complaint.notify_me_with_updates;
      }
    } else {
      this.tabOneTwoData = JSON.parse(localStorage.getItem("tabOneTwoData"));
      console.log("tabOneTwoData", this.tabOneTwoData);
      if (this.tabOneTwoData) {
        this.whatForm.controls.department.setValue(
          this.tabOneTwoData.complaint_type
        );
        this.selectDepartMent(this.tabOneTwoData.complaint_type);
        this.whatForm.controls.complaint__sub_type.setValue(
          this.tabOneTwoData.complaint__sub_type
        );
      }
    }
    this.router.navigate(["/admin/ce/add-complaint"], {
      queryParams: { tab: this.currentTab },
    });
  }

  public addressdata: any = [{}];
  public findAdress: any = [];
  public addressId = null;
  public selectAddress: string;

  public user_type: number = 2;
  public isSkip: boolean = true;

  selectInternalExterntype(type: any) {
    this.user_type = type;
    console.log("value is ==>", this.whoForm.controls.priority.value);
    debugger;
    if (this.user_type == 1) {
      this.whoForm.controls.user_email.setErrors(null),
        this.whoForm.controls.user_first_name.setErrors(null),
        this.whoForm.controls.user_last_name.setErrors(null),
        this.whoForm.controls.user_address.setErrors(null),
        this.whoForm.controls.user_phone.setErrors(null);
      if (!this.whoForm.controls.priority.value) {
        this.whoForm.controls.priority.setValidators(Validators.required);
        this.whoForm.controls.priority.setErrors({ required: true });
      }

      if (!this.whoForm.controls.source.value) {
        this.whoForm.controls.source.setValidators(Validators.required);
        this.whoForm.controls.source.setErrors({ required: true });
      }
      // this.descriptionForm.controls.no_of_residential_units.setValidators(Validators.required);
      // this.descriptionForm.controls.no_of_residential_units.setErrors({ required: true });

      this.addComplaintData = {
        user_type: 1,
        tab: 4,
        priority: Number(this.whoForm.value.priority),
        pc_ip_address: this.whoForm.value.pc_ip_address,
        name: this.whoForm.value.name,
        source: Number(this.whoForm.value.source),
        // email:null;
      };
      this.allSatff();
    } else if (this.user_type == 2) {
      this.whoForm.controls.priority.clearValidators(),
        this.whoForm.controls.priority.setErrors(null),
        // this.whoForm.controls.name.setErrors(null),
        this.whoForm.controls.source.clearValidators(),
        this.whoForm.controls.source.setErrors(null),
        // this.whoForm.controls.priority.setErrors({ required: true }),
        // this.whoForm.controls.name.setErrors({ required: true }),
        // this.whoForm.controls.source.setErrors({ required: true }),
        (this.addComplaintData = {
          user_type: 2,
          tab: 4,
          user_email: this.whoForm.value.user_email,
          pc_ip_address: this.whoForm.value.pc_ip_address,
          user_first_name: this.whoForm.value.user_first_name,
          user_last_name: this.whoForm.value.user_last_name,
          user_address: this.whoForm.value.user_address,
          user_phone: this.whoForm.value.user_phone,
          priority: 2,
          notify_me_with_updates: this.notify_me_with_updates,
        });
    }
  }

  selectPriority(value) {
    // if(value){
    console.log("selectPriority data is", value);

    this.whoForm.controls.priority.clearValidators();
    this.whoForm.controls.priority.setErrors(null);
    // this.whereForm.controls.street_name.setErrors

    // }
  }
  selectSource(value) {
    if (value) {
      console.log("selectSource data is", value);
      this.whoForm.controls.source.clearValidators();
      this.whoForm.controls.source.setErrors(null);
    }
  }

  phoneNumberFormate() {
    var value: string;
    var autoFillValue = "-";
    if (
      this.whoForm.value.user_phone &&
      this.whoForm.value.user_phone.length === 3
    ) {
      // this.type = 'text'
      this.whoForm.controls.user_phone.setValue(
        this.whoForm.value.user_phone.concat(autoFillValue)
      );
    }
    if (
      this.whoForm.value.user_phone &&
      this.whoForm.value.user_phone.length === 7
    ) {
      //this.type = 'text'
      this.whoForm.controls.user_phone.setValue(
        this.whoForm.value.user_phone.concat(autoFillValue)
      );
    }
  }
  keyPressNumbers(event) {
    var charCode = event.which ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57) && charCode != 45) {
      event.preventDefault();

      return false;
    } else {
      this.phoneNumberFormate();
    }
  }
  // getIP() {
  //   this._CEService.getIPAddress().subscribe((res: any) => {
  //     this.ipAddress = res.ip;
  //     this.whoForm.controls.pc_ip_address.setValue(this.ipAddress)
  //     console.log(this.ipAddress)
  //   });
  // }

  public isLogin = false;
  currentUser() {
    this.isLogin = this._authService.isLoginAdmin();
  }

  public urls = [];
  // public formData: any;
  public imageName: any;
  public attachment: any;
  reader: any;
  public i = 1;
  public formData = new FormData();
  url_image: any;
  detectFiles(event) {
    let files = event.target.files;
    this.complaint_id = sessionStorage.getItem(this.sessionKey);
    if (this.complaint_id == undefined || this.complaint_id == null) {
      if (files) {
        for (let file of files) {
          const fileExtension = file.name.split(".").pop();
          console.log("data==>", fileExtension);
          let reader = new FileReader();
          reader.onload = (e: any) => {
            if (
              fileExtension == "jpg" ||
              fileExtension == "jpeg" ||
              fileExtension == "png"
            ) {
              this.imageName = event.target.files[0].name;
              this.attachment = event.target.files[0];
              this.reader = new FileReader();
              this.reader.onload = function (event) {
                const imageString = event.target.result;
                localStorage.setItem("uploadedImage", imageString);
                console.log(
                  "Image uploaded and stored in localStorage",
                  event.target
                );
              };
              this.reader.readAsDataURL(this.attachment);
              this.url_image = localStorage.getItem("uploadedImage");
              this.urls.push({
                name: this.imageName,
                date: new Date(),
                id: this.i,
                url_image: this.url_image,
              });
              this.i = this.i + 1;
            } else {
              this._TS.error(fileExtension + "  " + "not allowed");
            }
          };
          reader.readAsDataURL(file);
        }
      }
    } else {
      this.detectFilesWithID(event);
    }
  }

  detectFilesWithID(event) {
    this.formData = new FormData();
    let files = event.target.files;
    //this.formData.append('attachment',files[0])
    if (files) {
      for (let file of files) {
        const fileExtension = file.name.split(".").pop();
        let reader = new FileReader();
        reader.onload = (e: any) => {
          if (
            fileExtension == "jpg" ||
            fileExtension == "jpeg" ||
            fileExtension == "png"
          ) {
            this.urls = [];
            this.formData.append("attachment", file);
            this.urls.push(e.target.result);
            if (files.length == this.urls.length) {
              this.uploadFile();
            }
          } else {
            this._TS.error(fileExtension + "  " + "not allowed");
          }
        };
        reader.readAsDataURL(file);
      }
    }
  }

  DataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  }

  uploadFile() {
    const applicationID = this._CEService.getApplicationID();
    this.formData.append("application_id", applicationID);
    this._CEService.uloadImage(this.formData).subscribe((data) => {
      this.getImage();
      this._TS.success("file uploaded successfully");
    });
  }

  public notify_me_with_updates: Number = 0;
  notifyMe(value: string) {
    if (value) {
      this.notify_me_with_updates = 1;
    } else if (!value) {
      this.notify_me_with_updates = 0;
    }
  }
  fileExtension: any;
  getImage() {
    this.complaint = JSON.parse(sessionStorage.getItem(this.sessionKey));

    this._CEService
      .getImage({ application_id: this.complaint.id })
      .subscribe((img) => {
        this.urls = img.response;
        this.urls.forEach((setPath) => {
          this.fileExtension = setPath["attachment"].split(".").pop();
          setPath.fileExtension = this.fileExtension;
          console.log(this.fileExtension); // outputs "jpg"
          setPath.imageBasePath = `${this.imageBasePath}${setPath["attachment"]}`;
        });
        console.log("value is ==>", this.urls);
      });
  }

  deleteImage(item) {
    this.complaint_id = sessionStorage.getItem(this.sessionKey);
    if (this.complaint_id == undefined || this.complaint_id == null) {
      this.urls.filter((value, i) => {
        if (item.id == value.id) {
          this.urls.splice(i, 1);
        }
      });
    } else {
      const image = {
        application_id: this.complaint.id,
        attachment_id: item.id,
      };
      this._CEService.deleteImage(image).subscribe((data) => {
        this._TS.success("file deleted successfully");
        this.getImage();
      });
    }
  }

  backToComaliantPage() {
    this.router.navigate(["/admin/ce/complaint"]);
    sessionStorage.removeItem(this.sessionKey);
  }

  selectTime() {
    this.datePopUp.nativeElement.click();
  }

  allSatff() {
    this._CEService.getSatff().subscribe((data) => {
      this.allSatfflist = data.response;
    });
  }

  selectLocationType(type: number) {
    this.location_type = Number(type);
    if (this.location_type == 1) {
      //  this.whereForm.reset()
      this.whereForm.controls.street_name.setErrors(null);
      this.whereForm.controls.nearest_intersection.setErrors(null);
      this.whereForm.controls.location_details.setErrors(null);
      this.addComplaintData = {
        tab: 3,
        location_type: this.location_type,
        address: this.whereForm.controls.address.value,
        address_id: this.addressId ? this.addressId : null,
        city_id: Number(this.whereForm.value.city),
        unit: this.whereForm.value.unit,
        building_number: this.whereForm.value.building_number,
        google_address: this.whereForm.value.google_address,
        also_know_as: this.whereForm.value.also_know_as,
      };
    } else if (this.location_type == 2) {
      // this.whereForm.reset()
      this.whereForm.controls.address.setErrors(null);
      this.whereForm.controls.also_know_as.setErrors(null);
      this.addComplaintData = {
        tab: 3,
        location_type: this.location_type,
        city_id: Number(this.whereForm.value.city),
        street_name: this.whereForm.value.street_name,
        nearest_intersection: this.whereForm.value.nearest_intersection,
        location_details: this.whereForm.value.location_details,
      };
    }
  }

  // googleAddress(value: any) {
  //   this.whereForm.controls.street_name.setValue(value['formatted_address'])

  // }

  public currentAdmin: any;
  getCurrentAdmin() {
    this._authService.getAdminInfoAndUserInfo().subscribe((user) => {
      this.currentAdmin = user;
      // if(this.currentAdmin){
      //  this.whoForm.controls.user_first_name.setValue(this.currentAdmin.name)
      //   this.whoForm.controls.user_email.setValue(this.currentAdmin.email)
      //   if(this.currentAdmin.phone){
      //     this.whoForm.controls.user_phone.setValue(this.currentAdmin.phone)
      //   }
      // }
    });
  }

  public city_id: any;
  selectCity(cityId) {
    this.city_id = cityId;
  }
  public allCity = [];
  getCity() {
    this._CEService.getCity().subscribe((data) => {
      this.allCity = data.response.docs;
    });
  }

  selectCheck(val) {
    if (val == true) {
      this.isSkip = false;
    } else {
      this.isSkip = true;
    }
  }
  complain_id: any;
  skipSource() {
    this.addComplaintData = {
      tab: 5,
      user_type: this.user_type,
    };

    this._CEService.addComlaint(this.addComplaintData).subscribe((data) => {
      this.complain_id = data.response.id;
      this._TS.success("Your complaint has been registered.");
      console.log("data is ===>", data.response.id);
      localStorage.setItem("id", data.response.id);
      this.router.navigate(["/admin/ce/add-complaint"], {
        queryParams: { tab: "complete" },
      });

      sessionStorage.removeItem(this.sessionKey);
    });

    // this.addComlaintWhereAndWhoTab('whoForm','exit')
  }

  goTocomplainDetail() {
    console.log("call");
    let complainid = localStorage.getItem("id");
    this.router.navigate(["/admin/ce/complain-details"], {
      queryParams: { id: complainid },
    });
  }

  searchAddress() {
    this.isAddressSelected = false;
    let application: any = null;
    // application = JSON.parse(sessionStorage.getItem('application'));
    this.searchQueryAddress.query = this.whereForm.get("address").value;
    console.log("data is ", this.searchQueryAddress, this.city_id);
    this.searchQueryAddress["admin_id"] = this.city_id
      ? this.city_id
      : this.applicationService.getSelectedCity();
    // if (this.searchQueryAddress.query === null || this.searchQueryAddress.query === '') {
    //   return false;
    // }
    // if (this.searchQueryAddress.query.length > 1) {
    this.address = null;
    this.applicationService
      .getSearchAddress(this.searchQueryAddress)
      .subscribe((data: any) => {
        this.address = data.response;
        if (this.address.length > 0) {
          this.isNotListedAdress = false;
        }
      });
    // }
  }

  selectedAddress(address: string, selectedObject): void {
    this.addressSelected = address;
    this.isNotListedAdress = false;
    this.isPendingApplicationForAddress = selectedObject.pending;
    this.isAddressSelected = true;

    const selectedAddress = this.whereForm.get("address");
    selectedAddress.disable({ onlySelf: true });
    console.log(
      "address val",
      selectedAddress.setValue(address ? address : selectedObject.address),
      address
    );

    selectedAddress.setValue(address ? address : selectedObject.address);

    const selectedAddressId = this.whereForm.get("address_id");
    selectedAddressId.setValue(selectedObject.id);

    this.address = null;
    this.whereForm.controls.address.setValue(address);

    console.log("address val", this.whereForm.value.address, address);

    // if (this.application.occupancy_type == 1) {
    //   this.getGeoLocation(selectedObject.singleAddress);

    // } else {
    //   this.getGeoLocation(selectedObject.singleStreet ? selectedObject.singleStreet : selectedObject.singleAddress);

    // }
    this.whereForm.controls.google_address.setValue(null);
    this.addressId = selectedObject.id;
    console.log("value is ===>", this.whereForm.controls.address.value);
  }
  locationInfo(status) {
    if (status) {
      this.openLocationTab = false;
    } else {
      this.openLocationTab = true;
    }
  }

  geolocationInfo(status) {
    if (status) {
      this.openGeolocationTab = false;
      // this.addressSelected = null;
      // this.isNotListedAdress = false;
      this.whereForm.controls.address.enable();
    } else {
      this.openGeolocationTab = true;
      this.isNotListedAdress = true;
      this.address = [];
      this.whereForm.controls.address.disable();
      this.whereForm.controls.address.setValue(null);
      this.whereForm.controls.address_id.setValue(null);
      this.addressId = null;
    }
  }

  isSelectForGoogleAddress: any;
  googleAddress(value: any, type: string) {
    this.whereForm.controls.google_address.setValue(value.formatted_address);
    this.whereForm.get("address").setValue(null);
    this.whereForm.get("address_id").setValue(null);
    this.whereForm.get("address").disable({ onlySelf: true });
    // this.whereForm.controls.address.clearValidators();
    // this.whereForm.controls.address.setErrors(null);
    // this.whereForm.controls.address_id.clearValidators();
    // this.whereForm.controls.address_id.setErrors(null);
    this.selectedAddressId = this.whereForm.controls.google_address.value;
    console.log("google value set ", this.selectedAddressId);
  }

  SelectForGoogleAddress(value: any, type: string) {
    this.whoForm.controls.user_address.setValue(value.formatted_address);
    this.isSelectForGoogleAddress = this.whoForm.controls.user_address.value;
    console.log(
      "isSelectForGoogleAddress value set ",
      this.isSelectForGoogleAddress
    );
  }

  googleStreetAddress(value: any, type: string) {
    console.log("google address", value.formatted_address);
    this.whereForm.controls.street_name.setValue(value.formatted_address);
    this.selectedStreetAddressId = this.whereForm.controls.street_name.value;
    console.log("google value set ", this.selectedStreetAddressId);
  }

  clearAddress() {
    const whereForm = this.whereForm.get("address");
    whereForm.enable({ onlySelf: false });
    whereForm.setValue(null);
    this.isAddressSelected = false;
    this.isAddress = false;
    this.addressId = null;
    this.isNotListedAdress = true;
    this.addressSelected = null;
  }
  typeList() {
    this._Service.typeList(this.staffId).subscribe((data) => {
      this.type_List = data.sort((a, b) =>
        a.type_name.trim().localeCompare(b.type_name.trim())
      );
    });
  }

  applicationSelected: any;
  selectDepartMent(val) {
    this.type_id = val;
    this.applicationSelected = val;
    this.whatForm.controls.department.setValue(val);
    if (this.type_id) {
      this.subTypeList();
    }
  }

  subTypeList() {
    this._CEService.subTypeList(this.type_id).subscribe((data) => {
      this.sub_typeList = data;
    });
  }
}

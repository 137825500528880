
/** MODUELS */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
/** END MODUELS */

/** COMPONENTS */
import { LayoutComponent } from './layouts/users/layout/layout.component';
import { HeaderComponent } from './layouts/users/header/header.component';
import { FooterComponent } from './layouts/users/footer/footer.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { AdminLayoutComponent } from './layouts/admin/layout/admin-layout.component';
import { NavbarComponent } from './layouts/admin/navbar/navbar.component';
import { SidebarComponent } from './layouts/admin/sidebar/sidebar.component';
/** End COMPONENTS */
import {OnlyNumberDirective} from './directives/number-only.directive';
import {GooglePlacesDirective} from './directives/google-places.directive';
import {AutofocusDirective} from './directives/autofocus.directive'
import {FocusInvalidInputDirective} from './directives/focus-invalid-input.directive'

const COMPONENTS = [
  AuthLayoutComponent,
  LayoutComponent,
  HeaderComponent,
  FooterComponent,
  AdminLayoutComponent,
  NavbarComponent,
  SidebarComponent,

];

const DIRECTIVES = [
  OnlyNumberDirective,
  GooglePlacesDirective,
  AutofocusDirective,
  FocusInvalidInputDirective,
  
]

const BASE_MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule
];



@NgModule({
  declarations: [
    ...COMPONENTS,
    ...DIRECTIVES
  ],
  imports: [
    ...BASE_MODULES
  ],
  exports: [
    ...BASE_MODULES,
    ...COMPONENTS,
    ...DIRECTIVES
    
    
  ],
  providers: [
    LayoutsModule
  ]
})
export class LayoutsModule {
  // static forRoot(): ModuleWithProviders {
  //   return <ModuleWithProviders>{
  //     ngModule: LayoutsModule,
  //   };
  // }
}

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { tap,map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AchAccountService {

  constructor(private http: HttpClient) { }

  addAchAccount(data): Observable<any> {
    const url = `${environment['addachaccount']}`;
    return this.http.post<any>(url, data).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  achAccountVerify(data): Observable<any> {
    const url = `${environment['achAccountVerify']}`;
    return this.http.post<any>(url, data).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  deleteAchAccount(data): Observable<any> {
    const url = `${environment['deletAchAccount']}`;
    return this.http.post<any>(url, data).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  getAchAccount(query): Observable<any> {
    const url = `${environment['achaccount']}`;
    return this.http.get<any>(url,{params:query}).pipe(
      map(
        ({ status, ...rest }) => {
          if (status === 'success') {
          }
          return rest;
        }
      )
    );
  }

  getAchAccountList(type:any): Observable<any> {
    const url = `${environment['achaccountList']}`;
    return this.http.get<any>(url,{ params: type }).pipe(
      map(
        ({ status, ...rest }) => {
          if (status === 'success') {
          }
          return rest;
        }
      )
    );
  }
}





import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StaffService } from 'src/app/core/http';
import { Router, ActivatedRoute } from '@angular/router';
import { settingConfig } from 'src/app/configs';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-add-ordinance',
  templateUrl: './add-ordinance.component.html',
  styleUrls: ['./add-ordinance.component.css']
})
export class AddOrdinanceComponent implements OnInit {
  public addnewStaffForm: FormGroup;
  public isSubmitted = false;
  public isUpdate = false
  public setting:any;
  ordinaceDetail = {
    code: null,
    violation: null,
    violation_description: null,
    category: null

  };
  constructor(
    private staffService: StaffService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public _TS: ToastrService

  ) {this.setting = settingConfig }

  ordinaceId: number
  ngOnInit() {
    this.staffForm()
    this.route.queryParams.subscribe(data => {
      this.ordinaceId = data.id;
    })
    if (this.ordinaceId) {
      this.getSingleOrdinace();
      this.isUpdate = true
    }
  }


  staffForm() {
    this.addnewStaffForm = this.formBuilder.group({
      code: ['', Validators.required],
      violation: ['', Validators.required],
      category: ['', Validators.required],
      description: ['', [Validators.required]],

    });
  }

  get staffMember() { return this.addnewStaffForm.controls }


  addOdinence() {
    this.isSubmitted = true
    if (this.addnewStaffForm.invalid) {
      return false
    }
    const savedCredentials: any = JSON.parse(localStorage.getItem(this.setting.credentialsKeyForAdmin));
    // if(savedCredentials){
    //   params.admin_id = savedCredentials.id
    // }
    const data = {
      admin_id:savedCredentials.id ? savedCredentials.id : null,
      code: this.addnewStaffForm.controls.code.value,
      violation: this.addnewStaffForm.controls.violation.value,
      violation_description: this.addnewStaffForm.controls.description.value,
      category: this.addnewStaffForm.controls.category.value,
    }
    this.staffService.createOrdinence(data).subscribe(data => {
      this.router.navigate(['/admin/ordinance/list'])
      this._TS.success(' ordinance added successfully')
    })
  }


  getSingleOrdinace() {
    
    this.staffService.getSigneOrdinace(this.ordinaceId).subscribe(data => {
      this.ordinaceDetail = data;
      this.addnewStaffForm.controls.code.setValue(this.ordinaceDetail.code);
      this.addnewStaffForm.controls.violation.setValue(this.ordinaceDetail.violation)
      this.addnewStaffForm.controls.description.setValue(this.ordinaceDetail.violation_description)
      this.addnewStaffForm.controls.category.setValue(this.ordinaceDetail.category)

    })
  }

  updateOrdinace() {
    const data = {
      code: this.addnewStaffForm.controls.code.value,
      violation: this.addnewStaffForm.controls.violation.value,
      violation_description: this.addnewStaffForm.controls.description.value,
      category: this.addnewStaffForm.controls.category.value,
    }
    this.staffService.updateOrdinance(data, this.ordinaceId).subscribe(data => {
      this.router.navigate(['/admin/ordinance/list']);
      this.isUpdate = false;
      this._TS.success('Ordinance updated successfully')

    })
  }
}

import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { Certificates } from 'src/app/shared/index';
import { Addresses } from './../../../shared/models/addresses.model';
import { AddressSearch } from './../../../shared/models';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccupancyApplication } from '../../models';
import { of as observableOf, Observable, BehaviorSubject } from 'rxjs';
import { google } from "google-maps";

import { environment } from 'src/environments/environment';
import { tap, map } from 'rxjs/operators';
import { Session } from 'selenium-webdriver';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {
  google: google;
  // private messageSource = new BehaviorSubject('first message');
  // currentMessage = this.messageSource.asObservable();

  public currentTab: string;
  private source = new BehaviorSubject(false);
  private isaddUnit = new BehaviorSubject(false);

  private value = new BehaviorSubject('type');
  private upload_doc = new BehaviorSubject(true);

  public application_status = new BehaviorSubject('1');
  private incomepleteApplication = new BehaviorSubject('v');
  private paymentData = new BehaviorSubject(null);

  public currentMessage = this.source.asObservable();
  public isaddUnitBehavourSubject = this.isaddUnit.asObservable();

  public uploadDoc = this.upload_doc.asObservable();

  public currentTa = this.value.asObservable();
  public incomepleteApplicationStatus = this.incomepleteApplication.asObservable()
  public getPaymentData = this.paymentData.asObservable()
  public applicationForEdit = this.application_status.asObservable()
  private applicationEditable: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isEditable: Observable<boolean> = this.applicationEditable.asObservable();

  private addApplication: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isEditable1: Observable<boolean> = this.addApplication.asObservable();

  public sessionApplication = 'application';

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService
  ) { }

  changeSaveAndExit(isIt: boolean) {
    this.source.next(isIt);
  }
  addUnit(isIt: boolean) {
    this.isaddUnit.next(isIt);
  }

  uploadDocu(value: boolean) {
    this.upload_doc.next(value)
  }

  incompletStatus(value: any) {
    this.incomepleteApplication.next(value)
  }

  sendPaymentData(value: any) {
    this.paymentData.next(value)
  }

  currentTabFor(value: any) {

    this.value.next(value)
  }
  apllicationStatus(value: any) {
    this.application_status.next(value)
  }

  postOccupancyApplication(data): Observable<any> {

    const href = `${environment['application']}`;
    const applicationID = this.getApplicationID();
    if (applicationID) {
      data['id'] = applicationID
    }
    const application = this.getApplication()
    if (application) {
      data = { ...application, ...data }
    }
    this.authenticationService.getUserInfo().subscribe(user => data.user_id = user.id);
    return this.http.post<any>(href, data).pipe(
      tap(
        (data) => {
          if (data.status === 'success' && Object.keys(data.response).length > 0) {
            console.log(data.response, "UPDATED")
            this.setApplication(data.response);
            this.changeIsEditable((data.response.application_status == 1 || data.response.application_status == 6) || true);
          }
          // false
          return data;
        }
      )
    );
  }

  isApplicationEditable(): Observable<boolean> {
    const application = this.getApplication();
    console.log(application, "APPLICTIOn")
    if (application && application['application_status']) {
      return observableOf(application['application_status'] === 1 || false);
    }
    return observableOf(false);
  }

  getSearchAddress(query: any): Observable<Addresses> {

    const href = `${environment['searchaddress']}`;
    return this.http.get<any>(href, { params: query }).pipe(
      tap(
        (data) => {
          if (data.status === 'success' && Object.keys(data.response).length > 0) {
            data.response.map((res: any) => {
              if (res.property_location) {
                res.singleAddress = res.property_location
                res.address = `${res.property_location}`
              }
              if (res.street) {
                res.singleStreet = res.street
                res.address = `${res.street}`
              }
            });
          }
          return data;
        }
      )
    );
  }

  getSearchAddressForCe(query: any): Observable<Addresses> {

    const href = `${environment['searchaddressForCe']}`;
    return this.http.get<any>(href, { params: query }).pipe(
      tap(
        (data) => {
          if (data.status === 'success' && Object.keys(data.response).length > 0) {
            data.response.map((res: any) => {
              if (res.property_location) {
                res.singleAddress = res.property_location
                res.address = `${res.property_location}`
              }
              if (res.street) {
                res.singleStreet = res.street
                res.address = `${res.street}`
              }
            });
          }
          return data;
        }
      )
    );
  }


  getTimelineApplication(applicationId: any): Observable<any> {
    const href = `${environment['timelineapplication']}/${applicationId}`
    return this.http.get<any>(href).pipe(
      map(
        ({ status, ...rest }) => {
          if (status === 'success') {
          }
          return rest;
        }
      )
    );
  }





  getUnits(units: any): Observable<any> {
    const href = `${environment['getUnits']}`
    return this.http.get<any>(href, { params: units }).pipe(
      map(
        ({ status, ...rest }) => {
          if (status === 'success') {
          }
          return rest;
        }
      )
    );
  }

  getAdminCity(): Observable<any> {
    const href = `${environment['adminCity']}`
    return this.http.get<any>(href).pipe(
      map(
        ({ status, ...rest }) => {
          if (status === 'success') {
          }
          return rest;
        }
      )
    );
  }



  verifyLink(query: any): Observable<any> {
    const href = `${environment['verifyLink']}`
    return this.http.post<any>(href, query).pipe(
      map(
        ({ status, ...rest }) => {
          if (status === 'success') {
          }
          return rest;
        }
      )
    );
  }

  submitIncompletApplication(query: any): Observable<any> {
    const href = `${environment['submitIncompleteApplication']}`
    return this.http.post<any>(href, query).pipe(
      map(
        ({ status, ...rest }) => {
          if (status === 'success') {
          }
          return rest;
        }
      )
    );
  }

  removeStaff(query: any): Observable<any> {
    const href = `${environment['removeStaff']}`
    return this.http.post<any>(href, query).pipe(
      map(
        ({ status, ...rest }) => {
          if (status === 'success') {
          }
          return rest;
        }
      )
    );
  }

  addStaffAndOwnerAndBuyer(query: any): Observable<any> {
    const href = `${environment['addOwnerAndBuyer']}`
    return this.http.post<any>(href, query).pipe(
      map(
        ({ status, ...rest }) => {
          if (status === 'success') {
          }
          return rest;
        }
      )
    );
  }

  completeApplication(query: any): Observable<any> {
    const href = `${environment['completeApplication']}`
    return this.http.post<any>(href, query).pipe(
      map(
        ({ status, ...rest }) => {
          if (status === 'success') {
          }
          return rest;
        }
      )
    );
  }

  uploadCCoDoc(query: any): Observable<any> {
    const href = `${environment['Uploadccodoc']}`
    return this.http.post<any>(href, query).pipe(
      map(
        ({ status, ...rest }) => {
          if (status === 'success') {
          }
          return rest;
        }
      )
    );
  }

  addSingleStaff(query: any): Observable<any> {
    const href = `${environment['addSingleStaff']}`
    return this.http.post<any>(href, query).pipe(
      map(
        ({ status, ...rest }) => {
          if (status === 'success') {
          }
          return rest;
        }
      )
    );
  }
  
  addminEditUnit(query: any): Observable<any> {
    const href = `${environment['adminEditUnit']}`
    return this.http.post<any>(href, query).pipe(
      map(
        ({ status, ...rest }) => {
          if (status === 'success') {
          }
          return rest;
        }
      )
    );
  }

  unitList(pagination: any): Observable<any> {
    const href = `${environment['unitAdminList']}`
      return this.http.get<any>(href,{params:pagination} ).pipe(
        map(
          (data) => {
            return data
            // false
          }
        )
      );
  }


  verifyAddress(query: any): Observable<any> {
    const href = `${environment['cheackaddress']}`
    return this.http.get<any>(href, { params: query }).pipe(
      map(
        ({ status, ...rest }) => {
          if (status === 'success') {
          }
          return rest;
        }
      )
    );
  }


  getCurrentUserApplication(id?: any): Observable<any> {

    const applicationExists = this.getApplication();
    if (applicationExists && applicationExists !== undefined && applicationExists !== false) {
      this.changeIsEditable((applicationExists.application_status == 1 || applicationExists.application_status == 6) || true);
      return observableOf(applicationExists);
      // false
    }
    if (id) {
      let params = {};
      params = { params: { id } };
      const href = `${environment['application']}`;
      return this.http.get<any>(href, params).pipe(
        map(
          (data) => {
            if (data.status === 'success' && Object.keys(data.response).length > 0) {
              this.setApplication(data.response);
              this.changeIsEditable((data.response.application_status == 1 || data.response.application_status == 6) || true);
            }
            // false
            return this.getApplication();
          }
        )
      );
    }

    return observableOf(false);

  }



  getApplicationById(id?: any) {

    if (id) {
      let params = {};
      params = { params: { id } };
      const href = `${environment['application']}`;
      return this.http.get<any>(href, params).pipe(
        map(
          (data) => {
            if (data.status === 'success' && Object.keys(data.response).length > 0) {
              this.setApplication(data.response);
              this.changeIsEditable((data.response.application_status == 1 || data.response.application_status == 6) || true);
            }
            return this.getApplication();
            // false
          }
        )
      );
    }
  }


  getUnitlist(pagination) {
    // pagination.page = 6
      let params = {};
      // params = { params: { pagination } };
      const href = `${environment['unitList']}`;
      return this.http.get<any>(href,{params:pagination} ).pipe(
        map(
          (data) => {
         return data
            // false
          }
        )
      );
    
  }

  getApplication() {
    const session = sessionStorage.getItem(this.sessionApplication);

    if (session) {
      return JSON.parse(session);
    }
    return false;
  }

  setApplication(application: any) {

    sessionStorage.setItem(this.sessionApplication, JSON.stringify(application));
  }

  setApplicationFirstAndsecondTab(application: any) {
    var localStorageData: any
    localStorageData = JSON.parse(sessionStorage.getItem(this.sessionApplication));
    if (localStorageData) {
      localStorageData = { ...localStorageData, ...application };
      // localStorageData = Object.assign(application);
      sessionStorage.setItem(this.sessionApplication, JSON.stringify(localStorageData));

    } else {
      sessionStorage.setItem(this.sessionApplication, JSON.stringify(application));

    }
  }

  postUploadMedia(media: any): Observable<any> {
    const href = `${environment['applicationMedia']}`;
    const formData = new FormData();
    formData.append(media.name, media.value);
    formData.append('id', media.id);
    return this.http.post<any>(href, formData).pipe(
      tap(
        (data) => {
          if (data.status === 'success' && Object.keys(data.response).length > 0) {
            this.setApplication(data.response);
          }
          return data.response;
        }
      )
    );

  }

  getApplicationID(): any {
    const application = this.getApplication()
    if (application) {
      return application['id']
    }
    return null;
  }

  setIsEditable(): Observable<any> {

    const href = `${environment['application']}`;
    const applicationID = this.getApplicationID();
    const data = {
      editable: 1
    };

    if (applicationID) {
      data['id'] = applicationID;
    };

    return this.http.post<any>(href, data).pipe(
      tap(
        (data) => {
          if (data.status === 'success' && Object.keys(data.response).length > 0) {
            this.setApplication(data.response);
            this.changeIsEditable((data.response.application_status == 1 || data.response.application_status == 6) || true);
          }
          // false
          return data;
        }
      )
    );
  }

  getUserCertificate(page: any, searchQuery): Observable<any> {
    const href = `${environment['userallCertificates']}` + `${page}`;
    return this.http.get<any>(href, { params: searchQuery }).pipe();
  }

  deleteSessionApplication(): boolean {
    if (sessionStorage.getItem(this.sessionApplication)) {
      sessionStorage.removeItem(this.sessionApplication);
      sessionStorage.removeItem('units');
      console.log("SESSION REMOVED")
      return true;
    }

    return false;
  }

  changeIsEditable(value: boolean): void {
    this.applicationEditable.next(value);
  }

  addApplicationFisttab(value: boolean): void {
    this.addApplication.next(value)
  }

  sendLinkOnbuyer(data): Observable<any> {
    console.log("dashborad")
    const href = `${environment['sendLinkOnBuyer']}`;
    return this.http.post<any>(href, data).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  needHelp(data): Observable<any> {
    console.log("dashborad")
    const href = `${environment['needHelp']}`;
    return this.http.post<any>(href, data).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  sendLinkUploadImage(data): Observable<any> {
    const href = `${environment['UploadImage']}`;
    return this.http.post<any>(href, data).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  assignMemeber(data): Observable<any> {
    const href = `${environment['assignMember']}`;
    return this.http.post<any>(href, data).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  sendTokenForVerify(data): Observable<any> {
    console.log("dashborad")
    const href = `${environment['tokenVerify']}`;
    return this.http.post<any>(href, data).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );


  }
  savePayments(data): Observable<any> {
    console.log("dashborad")
    const href = `${environment['payments']}`;
    return this.http.post<any>(href, data).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  cancelApplication(data): Observable<any> {
    console.log("dashborad")
    const href = `${environment['cancelApplication']}`;
    return this.http.post<any>(href, data).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  withdrawApplication(data): Observable<any> {
    console.log("dashborad")
    const href = `${environment['withdrawApplication']}`;
    return this.http.post<any>(href, data).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  cancelTransferApplication(data): Observable<any> {
    console.log("dashborad")
    const href = `${environment['cancelTransferApplication']}`;
    return this.http.post<any>(href, data).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  userTransferApplication(data): Observable<any> {
    console.log("dashborad")
    const href = `${environment['userTransferApplication']}`;
    return this.http.post<any>(href, data).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  assignInsDates(data): Observable<any> {
    console.log("dashborad")
    const href = `${environment['assignInspectionDateByuser']}`;
    return this.http.post<any>(href, data).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }


  applicationCompleteAfterIncomplete(data): Observable<any> {
    console.log("dashborad")
    const href = `${environment['applicationComplete']}`;
    return this.http.post<any>(href, data).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  addUnits(data): Observable<any> {
    console.log("dashborad")
    const href = `${environment['addUnits']}`;
    return this.http.post<any>(href, data).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  addUnitsAfterPayments(data): Observable<any> {
    console.log("dashborad")
    const href = `${environment['addUnitsAfterPayments']}`;
    return this.http.post<any>(href, data).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  getGeoLocation(address: string): Observable<any> {

    console.log('Getting address: ', address);
    let geocoder = new google.maps.Geocoder();
    return Observable.create(observer => {
      geocoder.geocode({
        'address': address
      }, (results, status) => {
        if (status == google.maps.GeocoderStatus.OK) {
          observer.next(results[0].geometry.location);
          observer.complete();
        } else {
          console.log('Error: ', results, ' & Status: ', status);
          observer.error();
        }
      });
    });
  }

  getLocationJson(term: string) {
    var term = "Mechelsesteenweg+64";
    return this.http.get('https://maps.googleapis.com/maps/api/geocode/json?address=Someroad+64&key=AIzkeystuffjXDm6eU5mPP9Nczg')
      .subscribe((json: any) => {
        var obj = JSON.parse(json);
        var jsonParsed = obj["results"];
      });
  }

  addFeeByAdmin(payload) {
    const href = `${environment['addFeeByAdmin']}`;
    return this.http.post<any>(href, payload).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  activateApplicationByUser(payload) {
    const href = `${environment['activateApplication']}`;
    return this.http.post<any>(href, payload).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  editIncompleteApplication(payload): Observable<any> {
    const href = `${environment['editIncompleteApplication']}`;
    return this.http.post<any>(href, payload).pipe(
      map(
        (data: any) => {
          if (data.status === 'success') {
            return data.response;
          }
        }
      )
    )
  }

  editUnit(payload): Observable<any> {
    const href = `${environment['editUnit']}`;
    return this.http.post<any>(href, payload).pipe(
      map(
        (data: any) => {
          if (data.status === 'success') {
            return data.response;
          }
        }
      )
    )
  }

  deleteUnit(payload): Observable<any> {
    const href = `${environment['deleteUnit']}`;
    return this.http.post<any>(href, payload).pipe(
      map(
        (data: any) => {
          if (data.status === 'success') {
            return data.response;
          }
        }
      )
    )
  }

  setSelectedCity(id) {
    sessionStorage.setItem('selectedCity', id);
  }

  getSelectedCity() {
    return sessionStorage.getItem('selectedCity')
  }

  removeSelectedCity() {
    sessionStorage.removeItem('selectedCity');
  }

}

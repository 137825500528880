import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RoleService } from '../../../../../core/http/admin/role.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-roles-and-permissions',
  templateUrl: './roles-and-permissions.component.html',
  styleUrls: ['./roles-and-permissions.component.css']
})
export class RolesAndPermissionsComponent implements OnInit {
  public addRoleForm: FormGroup;
  public isSelectPermition: boolean = false;
  public isSubmitted: boolean = false;
  // public permission = [];
  public searchText:string;
  public permission = []
  public selectedValue: boolean = true;
  public role_permissions = []
  public id: string
  public isToggelButton: boolean = false;
  public roleData: any;
  public department_id:string;

  constructor(
    private roleService: RoleService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.staffForm();
    this.route.queryParams.subscribe(data => {
      this.id = data.roleId
      this.department_id = data.department_id
    })
    if (this.id) {
      //this.getRoleById();
      this.getAllPermitions()

    } else {
      this.getAllPermitions();
    }

  }

  getRoleById() {
    this.roleService.getRoleById(this.id).subscribe(data => {
      this.roleData = data.response;
      this.addRoleForm.controls['name'].setValue(this.roleData.name);
      if (this.roleData.status == 0) {
        this.isToggelButton = false;
        this.addRoleForm.value.status = 0

      } else {
        this.isToggelButton = true;
        this.addRoleForm.value.status = 1

      }
      if (this.permission.length > 0) {
        this.roleData.role_permissions.map((_) => {
          this.permission.map((__) => {
            if (Number(_.role_permissions) === __.id) {
              __.isSelected = true;
              this.role_permissions.push(__.id);
            }
          })
        })
      } else {
        console.log('not found')
      }
    })
    console.log(this.permission);
  }


  updateRole() {
    if (this.addRoleForm.value.status == "" && this.addRoleForm.value.status != 0) {
      this.addRoleForm.value.status = 1
    } else if ((this.addRoleForm.value.status === "" || this.addRoleForm.value.status === true)) {
      this.addRoleForm.value.status = 1
    } else if (this.addRoleForm.value.status === false) {
      this.addRoleForm.value.status = 0
    }
    this.addRoleForm.value.role_permissions = this.role_permissions;
    this.roleService.updateRole(this.addRoleForm.value, this.id).subscribe(data => {
      this.router.navigate(['/admin/role'],{queryParams:{department_id:this.department_id}})
    })
  }

  staffForm() {
    this.addRoleForm = this.formBuilder.group({
      name: ['', Validators.required],
      status: [''],
      // role_permissions: ['', Validators.required],
    });
  }

  get roles() { return this.addRoleForm.controls };

  addRole() {
    this.isSubmitted = true;
    if (this.role_permissions.length == 0) {
      this.isSelectPermition = true
    }
    if (this.addRoleForm.invalid || this.role_permissions.length == 0) {
      return false;

    }
    if (this.addRoleForm.value.status == '') {
      this.addRoleForm.value.status = 0
    }
    this.addRoleForm.value.department_id = this.department_id
    this.addRoleForm.value.role_permissions = this.role_permissions
    this.roleService.createRole(this.addRoleForm.value).subscribe(data => {
      this.router.navigate(['/admin/role'],{queryParams:{department_id:this.department_id}})
    })
  }

  getAllPermitions() {
    this.roleService.getPermitions().subscribe(data => {
      this.permission = data.response;
      this.permission = this.permission.map((_) => {
        _.isSelected = false;
        return _;
      })
      if (this.id) {
        this.getRoleById();
      }
    })
  }

  //selecteValue: any;
  selectAll(value: any) {
    this.role_permissions = []
    this.permission = this.permission.map((_) => {
      _.isSelected = this.selectedValue;
      if (this.selectedValue) {
        this.role_permissions.push(_.id)
      } else {
        this.role_permissions = [];
      }

      return _;
    })
    this.selectedValue = !this.selectedValue
    if (this.role_permissions.length == 0) {
      this.isSelectPermition = true;
    } else {
      this.isSelectPermition = false;
    }
  }

  selectSingleValue(value: any, permissionsId) {
    if (value.target.checked == true) {
      this.role_permissions.push(permissionsId)
    } else if (value.target.checked == false) {
      const index = (this.role_permissions.indexOf(permissionsId));
      // const index = this.role_permissions.findIndex(_ => _.id === permissionsId);
      this.role_permissions.splice(index, 1);

    }
    if (this.role_permissions.length == 0) {
      this.isSelectPermition = true;
    } else {
      this.isSelectPermition = false;
    }
  }

  changeStatus(value: any) {
    if (value.target.checked) {
      this.addRoleForm.value.status = 1
    } else {
      this.addRoleForm.value.status = 0
    }
  }

}

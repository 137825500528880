import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { values } from "lodash";
import { ToastrService } from "ngx-toastr";
import { codeEnforcementSettingConfig } from "src/app/configs";
import { AdminAuthenticationService } from "src/app/core/authentication/admin-authentication.service";
import { ApplicationService, DashboardService } from "src/app/core/http";
import { CeComplaintService } from "src/app/core/http/admin/ce-complaint.service";
import { compliant_details } from "src/app/core/models/admin/code-enforcement/complantDetails.model";
import { environment } from "src/environments/environment";
import * as moment from "moment";

@Component({
  selector: "app-complaint-details",
  templateUrl: "./complaint-details.component.html",
  styleUrls: ["./complaint-details.component.css"],
})
export class ComplaintDetailsComponent implements OnInit {
  public oneAtATime: boolean = true;
  @ViewChild("datePopUp", { static: false }) datePopUp: ElementRef;
  @ViewChild("assignInspector", { static: false }) assignInspector: ElementRef;
  @ViewChild("addWorkOrder", { static: false }) addWorkOrder: ElementRef;
  @ViewChild("updatelocationPopoUp", { static: false })
  updatelocationPopoUp: ElementRef;

  public complaintDetails: compliant_details = {
    id: null,

    address: null,
    complaint_type: null,
    complaint__sub_type: null,
    user_phone: null,
    createdAt: null,
    also_know_as: null,
    inspection: [],
    service_Id: null,
    city_id: null,
    street_name: null,
    assign_inspector: null,
    google_address: null,
    location_type: null,
    complain_type: null,
    addresses: null,
    work_order_request: null,
    complaint_attachment: [],
  };
  public isAddressSelected = false;
  public addressSelected: any;
  public selectedAddressId: any;
  public isAssignInspector = false;
  public isAddedCourtTicket = false;
  public courtTicketForm: FormGroup;
  public notesTicketForm: FormGroup;
  public workOrderCon: FormGroup;
  public updateLocationFC: FormGroup;
  public inspectorName: any;
  public isAddWordOrder = false;
  public inspectorList = [];
  public comlainId: number;
  public setting: any;
  public assignInspectorForm: FormGroup;
  public inspectionForm: FormGroup;
  public mytime: Date;
  public env: any;
  public modiFiedInspection: any;
  public newArryj = [];
  public isTrue = true;
  constructor(
    private _CEService: CeComplaintService,
    private router: Router,
    private route: ActivatedRoute,
    private _FB: FormBuilder,
    private _TS: ToastrService,
    private adminAuthService: AdminAuthenticationService,
    private dashboardSrvice: DashboardService,
    public applicationService: ApplicationService
  ) {
    this.setting = codeEnforcementSettingConfig;
    this.route.queryParams.subscribe((data) => {
      this.comlainId = data["id"];
    });
  }

  ngOnInit() {
    this.env = environment;

    this.onInIt();
    this.getLocalStorage();
    this.mytime = new Date();

    if (this.comlainId) {
      this.comlaintAllDetails();
    }
    // this.getInspector();
  }

  onInIt() {
    this.inspectorControls();
    this.courtTicketCon();
    this.notesTicketCon();
    this.workOrderCont();
    this.updateLoacation();
    // this.inspectionControls()
  }
  openGeolocationTab = true;
  updateLoacation() {
    this.updateLocationFC = this._FB.group({
      location: [""],
      google_address: [""],
    });
  }

  get locationCon() {
    return this.updateLocationFC.controls;
  }
  location_type: any;
  fillLocation(value) {
    this.location_type = value.location_type;

    console.log("data is ===>", value);
    if (value.location_type == 1) {
      if (value.google_address) {
        this.openGeolocationTab = true;
        this.selectedAddressId = value.google_address;
        this.updateLocationFC.controls.google_address.setValue(
          value.google_address
        );
        this.updateLocationFC.controls.location.disable();
        this.updateLocationFC.controls.location.setValue(null);
      } else {
        this.openGeolocationTab = false;
        this.addressSelected = value.address;
        this.updateLocationFC.controls.location.setValue(value.address);
        this.updateLocationFC.controls.location.disable();
        this.isAddressSelected = true;
        this.addressId = value.address_id;
        this.updateLocationFC.controls.google_address.setValue(null);
      }
    } else if (this.location_type == 2) {
      this.openGeolocationTab = true;
      this.selectedAddressId = value.google_address;
      this.updateLocationFC.controls.google_address.setValue(value.street_name);
      this.updateLocationFC.controls.location.disable();
      this.updateLocationFC.controls.location.setValue(null);
    }
  }
  public addressdata: any = ["test", "test01"];
  public searchQuery = {
    query: null,
  };
  public findAdress: any = [];
  geolocationInfo(status) {
    if (status) {
      this.openGeolocationTab = false;
      this.isAssignInspector = true;
      this.updateLocationFC.controls.location.enable();
      this.updateLocationFC.controls.location.setValidators(
        Validators.required
      );
      this.updateLocationFC.controls.location.setErrors({ required: true });
      this.isAddressSelected = false;
      this.updateLocationFC.controls.google_address.clearValidators();
      this.updateLocationFC.controls.google_address.setErrors(null);
    } else {
      this.openGeolocationTab = true;
      this.isAddressSelected = false;
      this.findAdress = [];
      this.updateLocationFC.controls.location.disable();
      this.updateLocationFC.controls.location.setValue(null);
      this.updateLocationFC.controls.google_address.setValidators(
        Validators.required
      );
      this.updateLocationFC.controls.google_address.setErrors({
        required: true,
      });

      this.updateLocationFC.controls.location.clearValidators();
      this.updateLocationFC.controls.location.setErrors(null);
      // this.updateLocationFC.controls.address_id.setValue(null)
    }
  }
  blankAddress() {
    this.findAdress = [];
  }
  searchAddress() {
    this.isAddressSelected = false;

    if (this.updateLocationFC.value.location.length == 0) {
      this.addressSelected = "";
    }

    if (this.updateLocationFC.value.location.length > 1) {
      this.searchQuery.query = this.updateLocationFC.value.location;
      this.searchQuery["admin_id"] = this.complaintDetails["city_id"];

      if (this.searchQuery.query === null || this.searchQuery.query === "") {
        return false;
      }
      //this.address = null;
      this.applicationService
        .getSearchAddress(this.searchQuery)
        .subscribe((data) => {
          (this.findAdress = data["response"]),
            // this.addressdata = this.findAdress.response.map(data => {
            //   return data.address
            // })
            console.log(this.findAdress);
        });
    }
  }

  addressId = null;
  public selectAddress: string;
  typeaheadOnSelectAddress(event) {
    this.findAdress.response.map((data) => {
      if (event.value == data.address) {
        this.addressId = data.id;
        this.selectAddress = data.property_location;
      }
    });
  }
  selectedAddress(address) {
    this.isAddressSelected = true;
    this.addressId = address.id;
    this.addressSelected = address;
    this.updateLocationFC.controls.location.setValue(address.property_location);
    this.updateLocationFC.controls.google_address.setValue(null);
    const selectedAddress = this.updateLocationFC.get("location");
    selectedAddress.disable({ onlySelf: true });
    this.findAdress = [];
  }

  public isUpdateLocation = false;
  adminupdateLocation() {
    if (this.updateLocationFC.invalid) {
      this.isUpdateLocation = true;
      return false;
    }
    if (
      (!this.addressSelected || this.addressSelected == "") &&
      !this.openGeolocationTab
    ) {
      //
      this._TS.error("Please select address");
      console.log("plesae select address");
      return false;
    }
    if (
      (!this.selectedAddressId || this.selectedAddressId == "") &&
      this.openGeolocationTab
    ) {
      //
      this._TS.error("Please select google address");
      console.log("plesae selecte ");
      return false;
    }
    const data = {
      address: this.updateLocationFC.value.location
        ? this.updateLocationFC.value.location
        : null,
      google_address: this.updateLocationFC.value.google_address,
      camplaint_id: this.complaintDetails["id"],
      address_id: this.addressId ? this.addressId : null,
      street_name: null,
      location_type: 1,
    };
    this._CEService.updateLocationadmince(data).subscribe((data) => {
      this._TS.success("Location updated");
      this.updatelocationPopoUp.nativeElement.click();
      this.comlaintAllDetails();
    });
  }

  googleAddress(value: any, type: string) {
    this.updateLocationFC.controls.google_address.setValue(
      value.formatted_address
    );
    this.updateLocationFC.get("location").setValue(null);
    // this.updateLocationFC.get('location_id').setValue(null);
    this.updateLocationFC.get("location").disable({ onlySelf: true });
    // this.whereForm.controls.address.clearValidators();
    // this.whereForm.controls.address.setErrors(null);
    // this.whereForm.controls.address_id.clearValidators();
    // this.whereForm.controls.address_id.setErrors(null);
    console.log(
      "google address",
      this.updateLocationFC.controls.google_address.value
    );
    this.selectedAddressId =
      this.updateLocationFC.controls.google_address.value;
    this.addressId = null;
    //   console.log("google value set ", this.selectedAddressId)
  }

  // inspectionControls() {
  //   this.inspectionForm = this._FB.group({
  //     inspection_date: ['', Validators.required],
  //     inspection_time: ['', Validators.required],
  //     contact_number: ['', Validators.required],
  //     follow_up_date: ['', Validators.required],
  //     location_occupied: ['', Validators.required],
  //     person_spoken_to: ['', Validators.required],
  //     number_of_days_to_abate: ['', Validators.required],
  //     vacate_premise_by: ['', Validators.required],
  //     inspection_findings: ['', Validators.required],
  //     internal_notes: ['', Validators.required],

  //   })
  // }

  // get inspectionCon() { return this.inspectionForm.controls }
  workOrderCont() {
    this.workOrderCon = this._FB.group({
      // depratement: ['', Validators.required],
      due_date: ["", Validators.required],
      assign_to: ["", Validators.required],
      type_of_work: ["", Validators.required],
      phone_number: [
        "",
        [
          Validators.required,
          Validators.pattern("^[0-9]{3}-[0-9]{3}-[0-9]{4}$"),
        ],
      ],
      description_of_work: ["", Validators.required],
      contact_person: ["", Validators.required],
    });
  }

  get workOrder() {
    return this.workOrderCon.controls;
  }

  addWork() {
    if (this.workOrderCon.invalid) {
      this.isAddWordOrder = true;
      return false;
    }

    this.workOrderCon.value.inspection_id = this.ins.id;
    this.workOrderCon.value.application_id = this.ins.application_id;

    this._CEService.addWorkOrder(this.workOrderCon.value).subscribe((data) => {
      this.workOrderCon.reset();
      this._TS.success("Work order added");
      this.addWorkOrder.nativeElement.click();
      this.comlaintAllDetails();
    });
  }
  courtTicketCon() {
    this.courtTicketForm = this._FB.group({
      date: ["", Validators.required],
      notes: ["", Validators.required],
      docket_number: ["", Validators.required],
    });
  }

  get notesCourtConValidates() {
    return this.courtTicketForm.controls;
  }

  notesTicketCon() {
    this.notesTicketForm = this._FB.group({
      type: ["", Validators.required],
      delivery: ["", Validators.required],
      reference_number: ["", Validators.required],
    });
  }
  get notesTicketConValidates() {
    return this.notesTicketForm.controls;
  }

  inspectorControls() {
    this.assignInspectorForm = this._FB.group({
      inspector: ["", Validators.required],
      comment: [],
      schedule_date: [],
    });
  }

  get insCon() {
    return this.assignInspectorForm.controls;
  }
  get courtCon() {
    return this.courtTicketForm.controls;
  }
  public inspectorId: any;
  public schedule_date: any;
  public assign_inspector: any;

  courtid: any;
  isCourtTrue = true;
  isInsp = true;
  latestinspection: any;
  assign_id: any;
  comlaintAllDetails() {
    this.newArryj = [];
    this._CEService.getAllComplaintDetails(this.comlainId).subscribe((data) => {
      this.complaintDetails = data.response;
      this.assign_inspector = this.complaintDetails
        ? this.complaintDetails.assign_inspector
        : null;
      for (let index = 0; index < this.assign_inspector.length; index++) {
        this.assign_inspector = this.assign_inspector[0];
        this.assign_id = this.assign_inspector.id;
      }
      this.inspectorId = this.assign_inspector
        ? this.assign_inspector.inspector
        : null;
      this.schedule_date = this.assign_inspector
        ? this.assign_inspector.schedule_date
        : null;
      console.log("complain detail ", this.complaintDetails.inspection);
      // this.inspectorName = (assign_inspector && assign_inspector.assign_inspector.name)
      this.getInspector();
      console.log("latestinspection", this.latestinspection);
      this.complaintDetails.inspection.forEach((insp: any, i) => {
        if (i == 0) {
          this.latestinspection = insp;
          console.log("latestinspection", this.latestinspection);
        }

        insp.court_ticket.forEach((court: any, courtIndex) => {
          court.active = false;
          debugger;

          // if(this.isId == court.id){
          //   court.active = true;
          // }
          if (i == 0 && courtIndex == 0 && this.isCourtTrue) {
            console.log("data is this.ins===>", court);
            // this.ins = court;
            court.active = true;
            this.CourtDetails = court;
          }

          if (this.courtid == court.id) {
            court.active = true;
            this.CourtDetails = court;
          }

          // if(insp.active == true){
          //   this.newArryj.push(insp)
          //   this.ins = insp
          // }
          // if(insp.active != true){
          //   this.newArryj.push(insp)
          // }
        });

        // debugger
        //  console.log("court  data is ===>",insp.court_ticket,this.CourtDetails)
        if (this.isId == insp.id) {
          insp.active = true;
        }

        if (i == 0 && this.isInsp) {
          this.ins = insp;
          insp.active = true;
          this.workOrdrDetails = this.ins["work_order_request"][0];
        }
        if (insp.active == true) {
          this.newArryj.push(insp);
          this.ins = insp;
        }
        if (insp.active != true) {
          this.newArryj.push(insp);
        }
      });

      console.log(this.complaintDetails.inspection);
      // if(this.complaintDetails.inspection){
      //   // this.isTrue = true;
      //  this.complaintDetails.inspection.forEach((x:any) => {

      //   })
      if (this.isTrue) {
        this.newArryj.forEach((x) => {
          if (this.ins.id == x.id) x.active = true;
        });
      }
      if (this.ins) {
        if (this.ins.work_order_request) {
          this.ins.work_order_request.forEach((item, i) => {
            if (i == 0) {
              item.Workactive = true;
            }
            console.log("sort Data is", i);
          });
        }
      }
    });
  }
  courtdetails(id) {
    this.courtid = id;
    this.isCourtTrue = false;
    this.comlaintAllDetails();
  }

  workOrderDeatils() {}
  getInspector() {
    this.dashboardSrvice
      .getInspectors({
        department_id: 1,
        city_id: this.complaintDetails.city_id,
      })
      .subscribe((data) => {
        this.inspectorList = data.response;
        console.log("inspectorList", this.inspectorList);
        if (this.inspectorId) {
          this.inspectorList.filter((item) => {
            if (item.id == this.inspectorId) {
              this.assignInspectorForm.patchValue({
                inspector: this.inspectorId,
                schedule_date: this.schedule_date
                  ? new Date(this.schedule_date)
                  : null,
              });
            }
          });
        }
      });
  }
  deleteVoliations(violation) {
    console.log("violation", violation);
    const payload = {
      inspection_id: violation.inspection_id,
      id: violation.id,
    };
    // return;
    this._CEService.deleteVoliations(payload).subscribe((data) => {
      this.comlaintAllDetails();
      this._TS.success("Violations deleted successfully");
    });
  }

  assigenInspector() {
    if (this.assignInspectorForm.invalid) {
      this.isAssignInspector = true;
      return false;
    }

    if (this.assign_id) {
      this.assignInspectorForm.value.assigned_id = this.assign_id;
    }
    this.assignInspectorForm.value.application_id = this.complaintDetails.id;
    this.assignInspectorForm.value.inspector = Number(
      this.assignInspectorForm.value.inspector
    );
    this._CEService
      .assignInspector(this.assignInspectorForm.value)
      .subscribe((data) => {
        this.isAssignInspector = false;
        this.comlaintAllDetails();
        this.assignInspector.nativeElement.click();
        this.assignInspectorForm.reset();
        this._TS.success("Inspector has been assigned");
      });
  }

  // public isInspection = false
  // inspections() {
  //   if (this.mytime) {
  //     this.inspectionForm.controls.inspection_time.setErrors(null)
  //   }
  //   if (this.inspectionForm.invalid) {
  //     this.isInspection = true
  //     return false
  //   }
  //   this.inspectionForm.value.inspection_time = this.mytime
  //   this.inspectionForm.value.application_id = this.complaintDetails.id
  //   this._CEService.inspectionForCe(this.inspectionForm.value).subscribe(data => {
  //     this.inspectionForm.reset()
  //     this.isInspection = false;
  //     this._TS.success('Inspection successfully')
  //   })
  // }

  selectTime() {
    this.datePopUp.nativeElement.click();
  }

  navigateInspectionPage() {
    this.router.navigate(["admin/ce/ce-inspection"], {
      queryParams: {
        id: this.comlainId,
        city_id: this.complaintDetails.city_id,
      },
    });
  }
  isAddedNoteTicket = false;
  addCourtTicket(inspectionId) {
    this.isAddedNoteTicket = true;
    if (this.courtTicketForm.invalid) {
      this.isAddedNoteTicket = true;
      return false;
    }
    this.courtTicketForm.value.inspection_id = inspectionId;
    this.courtTicketForm.value.application_id = Number(this.comlainId);

    this._CEService
      .addCourtTiket(this.courtTicketForm.value)
      .subscribe((data) => {
        this.courtTicketForm.reset();
        this.isAddedNoteTicket = false;
        this.comlaintAllDetails();
        this._TS.success("Added successfully");
      });
  }

  addNotesTicket(inspectionId) {
    this.isAddedCourtTicket = true;

    if (this.notesTicketForm.invalid) {
      this.isAddedCourtTicket = true;
      return false;
    }
    this.notesTicketForm.value.inspection_id = inspectionId;
    this.notesTicketForm.value.application_id = Number(this.comlainId);

    this._CEService
      .addNotesTiket(this.notesTicketForm.value)
      .subscribe((data) => {
        this.notesTicketForm.reset();
        this.notesTicketForm.controls.type.setValue("");
        this.notesTicketForm.controls.delivery.setValue("");
        this.isAddedCourtTicket = false;
        this.comlaintAllDetails();
        this._TS.success("Notes Added successfully");
      });
  }

  voidCourtTicket(ticketId, inspectionId) {
    const data = {
      application_id: Number(this.comlainId),
      inspection_id: Number(inspectionId),
      court_id: Number(ticketId),
    };
    this._CEService.voidCourtTiket(data).subscribe((data) => {
      this.comlaintAllDetails();
      this._TS.success("Void successfully");
    });
  }

  voidNoteTicket(ticketId, inspectionId) {
    const data = {
      application_id: Number(this.comlainId),
      inspection_id: Number(inspectionId),
      id: Number(ticketId),
    };
    this._CEService.voidNoteTiket(data).subscribe((data) => {
      this.comlaintAllDetails();
      this._TS.success("Void successfully");
    });
  }
  public ins: any;
  public isId: any;
  insDetails(id) {
    this.isInsp = false;
    this.isId = id;
    this.complaintDetails.inspection.forEach((insp: any) => {
      if (id == insp.id) {
        this.ins = insp;
        this.ins.active = insp.id;
        console.log("inspection_type===>", this.ins);
        this.comlaintAllDetails();
        this.isTrue = false;
      }
    });
    console.log("active data is", this.ins);
  }

  public imageName: any;
  public attachment: any;
  public image = [];
  public i = 1;
  public formData = new FormData();
  media(event1) {
    this.imageName = event1.target.files[0].name;
    this.attachment = event1.target.files[0];
    this.formData.append("inspection_attachment", this.attachment);
    this.image.push({ name: this.imageName, date: new Date(), id: this.i });
    this.i = this.i + 1;
    this.uploadDoc(this.ins);
    console.log(event1.target.files[0]);
  }

  uploadDoc(ins) {
    this.formData.append("inspection_id", this.ins.id);
    this.formData.append("application_id", this.ins.application_id);

    this._CEService.uploadImage(this.formData).subscribe((data) => {
      this._TS.success("file uploaded successfully ");
      this.comlaintAllDetails();
      this.image = [];
      this.formData = new FormData();
    });
  }
  // isTrue=true;
  deleteImage(id, inspection) {
    const data = {
      id: id,
      inspection_id: inspection.id,
      application_id: inspection.application_id,
    };
    this._CEService.deleteImage(data).subscribe((data) => {
      // this.isTrue = false;

      this.comlaintAllDetails();
      this._TS.success("file deleted successfully");
    });
  }

  phoneNumberFormate() {
    var value: string;
    var autoFillValue = "-";
    if (
      this.workOrderCon.value.phone_number &&
      this.workOrderCon.value.phone_number.length === 3
    ) {
      this.workOrderCon.controls.phone_number.setValue(
        this.workOrderCon.value.phone_number.concat(autoFillValue)
      );
    }
    if (
      this.workOrderCon.value.phone_number &&
      this.workOrderCon.value.phone_number.length === 7
    ) {
      this.workOrderCon.controls.phone_number.setValue(
        this.workOrderCon.value.phone_number.concat(autoFillValue)
      );
    }
  }

  keyPressNumbers(event) {
    var charCode = event.which ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57) && charCode != 45) {
      event.preventDefault();
      return false;
    } else {
      this.phoneNumberFormate();
    }
  }

  public workOrdrDetails: any;
  public CourtDetails: any;
  workOrdrDeatils(details) {
    this.workOrdrDetails = details;
    this.ins.work_order_request.forEach((work: any) => {
      if (work.id == this.workOrdrDetails.id) {
        work.Workactive = true;
      } else {
        work.Workactive = false;
      }
    });
    // console.log( this.ins.work_order_request)
  }

  localstprageData: any;
  public department = {
    role_id: null,
    department_id: null,
  };

  getLocalStorage() {
    const department = this.adminAuthService.getPermission();
    if (department && department.role_id != 1 && department.role_id != 2) {
      this.department = department;
      this.localstprageData = department.department;
    } else {
      const localStorageData = this.adminAuthService.getSaveDepartment();
      const department = this.adminAuthService.getPermission();
      this.department = localStorageData;
      if (this.department) {
        this.department.role_id = department.role_id;
      }
      this.localstprageData = localStorageData;
    }
  }

  workOrderConReset() {
    this.workOrderCon.reset();
  }
  clearAddress() {
    const updateLocationFC = this.updateLocationFC.get("location");
    updateLocationFC.enable({ onlySelf: false });
    updateLocationFC.setValue(null);
    this.isAddressSelected = false;
    this.addressId = null;
  }
  clearForm() {
    this.assignInspectorForm.reset();
    this.isAssignInspector = false;
  }
}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminAuthenticationService } from 'src/app/core/authentication/admin-authentication.service';
import { CertificatesService } from 'src/app/core/http/admin/certificates.service';
import { Department } from '../../../../core/models/admin/department.model'
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  public readUnreadData: any
  public activateHousing: boolean;
  public activateFire: boolean;
  public activateAssesorTaxesZon: boolean;
  public currentUrl: string;
  public localstprageData: Department
  public isCertificate = false
  public isCodeEnforcement = false
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private certificateService: CertificatesService,
    private adminAuthService: AdminAuthenticationService
  ) { }

  ngOnInit() {

    this.currentUrl = this.route.snapshot.url.join('/');
    if (this.currentUrl === 'housing') {
      this.activateHousing = true;
    }
    if (this.currentUrl === 'assesor') {
      this.activateAssesorTaxesZon = true;
    }
    if (this.currentUrl === 'fire') {
      this.activateFire = true;
    }
    this.getLocalStorage();
    this.getRole();


  }

  navigateByUrl(url: string, type: number): void {
    if (type === 1) {
      this.router.navigate([url],{queryParams:{tabType:1}});
      localStorage.setItem('department', JSON.stringify({ department_id: 1, department_name: 'housing' }));

    }
    if (type === 2) {
      localStorage.setItem('department', JSON.stringify({ department_id: 2, department_name: 'fire' }));
      this.router.navigateByUrl(url);
    }
    if (type === 3) {
      localStorage.setItem('department', JSON.stringify({ department_id: 3, department_name: 'assessor' }));
      this.router.navigateByUrl(url);
    }

    if (type === 6) {
      localStorage.setItem('department', JSON.stringify({ department_id: 6, department_name: 'cco' }));
      this.router.navigateByUrl(url);
    }


  }
  //localstprageData:any;
  getLocalStorage() {
    const department = this.adminAuthService.getPermission()
    if (department) {
      this.localstprageData = department.department;

    }
    
    if (department && department.role_id) {
      this.showApplicationAndComplaintType()
    }
  }

  roleId: number;
  getRole() {
    this.adminAuthService.getUserRole().subscribe(data => {
      this.roleId = data;
    })
  }
  navigateByCE(url) {
    this.router.navigateByUrl(url);
  }

test(){
  
}
  

  manageIcon(value: string) {
    if (value == 'crt') {
      this.isCertificate = true;
      this.isCodeEnforcement = false;
    }
    else if (value == 'ce') {
      this.isCertificate = false;
      this.isCodeEnforcement = true;
    }
  }

  showApplicationAndComplaintType() {
    this.certificateService.readUnredApplication().subscribe(data => {
      this.readUnreadData = data['response']
    })
  }
}

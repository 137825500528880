import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup,FormBuilder, Validators } from '@angular/forms';
import { CeComplaintService } from 'src/app/core/http/admin/ce-complaint.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DashboardService } from 'src/app/core/http';
import { environment } from 'src/environments/environment';
import * as xlsx from 'xlsx';
import { AddComplaintService } from 'src/app/core/http/users/code-Enforcement/add-complaint.service';
import { codeEnforcementSettingConfig } from 'src/app/configs';

@Component({
  selector: 'app-summary-index',
  templateUrl: './summary-index.component.html',
  styleUrls: ['./summary-index.component.css']
})
export class SummaryIndexComponent implements OnInit {
  public setting: any;
  public totalPage:any;
  public selectedFile = null;
  public errorMessage = '';
  public imageName:string;
  public description = null;
  public hideModal = false;
  public courtData = [];
  public selectedAllData = [];
  public updateCourtForm:FormGroup;
  public maxDate = new Date();
  public offset:any;
  public currentPagination:any;
  public totalPagination:any;
  public page = 1;
  public id:any;
  public isInspection = false;
  public type_List = [];
  public minDate = moment(new Date()).add(-50, 'years').toDate();
  public lastFromDate:any;
  public lastToDate:any;
  public last:any;
  public isDaterangepickerOpen = false;
  public bsRangeValue: Date[];
  public ShowDateField:boolean = true;
  public isDate=true;
  public optionValue:any;
  public isTrue = true;
  public payLoad = {
    page:1,
    searchString:null,
    inspector_id:null,
    fromDate:null,
    toDate:null,
    totlaPage:null,
    complain_type:null,
    complain_sub_type:null,
    status:null
  };
  public city_id:any;
  public data = [];
  public inspectorList = [];
  public pages:any;
  public env:any;
  public sub_typeList = [];

  constructor( private _CEService: CeComplaintService,    private dashboardSrvice: DashboardService, public  add_complainService: AddComplaintService,
  public  _TS:ToastrService,private update: FormBuilder,private router: Router) {   
     this.setting = codeEnforcementSettingConfig
  }
  @ViewChild('checkbox', { static: false }) checkbox: ElementRef;
  @ViewChild('court',{static:false})court:ElementRef;
  @ViewChild('courtInspector',{static:false})courtInspector:ElementRef
  @ViewChild('courtDate',{static:false})courtDate:ElementRef;
  @ViewChild('myModal', { static: true }) myModal: ElementRef;
  @ViewChild('report', { static: false }) report: ElementRef;
  @ViewChild('type', { static: false }) type: ElementRef;
  @ViewChild('subType', { static: false }) subType: ElementRef;
  @ViewChild('inspector', { static: false }) inspector: ElementRef;
  @ViewChild('status', { static: false }) status: ElementRef;

  ngOnInit() { 
    this.env = environment;
    this.city_id = JSON.parse(localStorage.getItem('currentAdmin'))
    this.updateCourtFormStatus();
    this.getSummaryIndex();
    this.getInspector();
    if(this.city_id.id){
      this.typeList();
    }
  }
  updateCourtFormStatus(){
   this.updateCourtForm = this.update.group({
    ticket_status:['',Validators.required],
    fine_amount:['',Validators.required],
    court_fees:['',Validators.required],
    date: ['', Validators.required],
    notes: ['', Validators.required],
    docket_number: ['', Validators.required],
    
   })
  }
  get updateCourtForms() { return this.updateCourtForm.controls }
  getSummaryIndex(){
    if(!this.payLoad.searchString){
      delete this.payLoad.searchString;
    }if(!this.payLoad.inspector_id){
      delete this.payLoad.inspector_id;
    }
    if(!this.payLoad.fromDate || !this.payLoad.toDate ){
      delete this.payLoad.fromDate;
      delete this.payLoad.toDate;
    }
    if(!this.payLoad.complain_type){
      delete this.payLoad.complain_type;
    }
    if(!this.payLoad.totlaPage){
      delete this.payLoad.totlaPage;
    }
    if(!this.payLoad.complain_sub_type){
      delete this.payLoad.complain_sub_type;
    }
    if(!this.payLoad.inspector_id){
      delete this.payLoad.inspector_id;
    }
    if(!this.payLoad.status){
      delete this.payLoad.status;
    }
    this._CEService.summaryIndex(this.payLoad).subscribe(data => {
     this.courtData = data.response;
     this.courtData.forEach(item => {
      item.assign_inspector =  item.assign_inspector[0];
    });
     this.totalPage = data.total;
     if(this.isTrue){
       this.getNumbers();
     }

     if(this.selectedAllData.length > 0){
      this.courtData.filter(courItem=>{
        this.selectedAllData.filter(item=>{
            if(item.active == true && courItem.id == item.id){
              courItem.active = true;
              courItem.page = this.pages;
              item.page = this.pages;
            }
       })
      })
     }
      let unique =  this.courtData.filter(item=>{
        if(item.active == true){
          return item
        }
      })
      if( this.courtData.length == unique.length &&  this.courtData.length != 0 ){
        this.checkbox.nativeElement.checked = true;
      }
      else if (this.courtData.length != unique.length){
        this.checkbox.nativeElement.checked = false;
      }
        this.selectedAllData = this.selectedAllData.filter(item=>item.active == true)
        this.offset = data.offset;
        this.currentPagination = data.page
        this.totalPagination = data.total;
    })
  }
  isDocketNum:any;
  getID(item){
     this.id = item.id
   this.isDocketNum =   item.docket_number
     if(!item.docket_number){
      this.updateCourtForm.controls.ticket_status.clearValidators();
      this.updateCourtForm.controls.ticket_status.setErrors(null); 
      this.updateCourtForm.controls.fine_amount.clearValidators();
      this.updateCourtForm.controls.fine_amount.setErrors(null); 
      this.updateCourtForm.controls.court_fees.clearValidators();
      this.updateCourtForm.controls.court_fees.setErrors(null); 
     }else{
      this.updateCourtForm.controls.ticket_status.setValidators(Validators.required);
      this.updateCourtForm.controls.fine_amount.setValidators(Validators.required);
      this.updateCourtForm.controls.court_fees.setValidators(Validators.required);
     }
     this.updateCourtForm.controls.date.clearValidators();
     this.updateCourtForm.controls.date.setValue(null);
     this.updateCourtForm.controls.date.setValidators(Validators.required);
    //  this.updateCourtForm.controls.date.setErrors(null); 
    console.log("data is ==>",item.date)
     this.updateCourtForm.patchValue({
      date:item.date ? new Date(item.date) : new Date(),
      ticket_status:item.ticket_status?item.ticket_status:"",
      fine_amount:item.fine_amount,
      court_fees:item.court_fees,
      docket_number:item.docket_number,
      notes:item.notes
   }
   
  );
  }
  updateCourt(){
    debugger
    if(this.updateCourtForm.invalid){
      this.isInspection = true;
      return true;
    }
    console.log(this.updateCourtForm.value.ticket_status)
    if(this.updateCourtForm['controls'].ticket_status.value == ""){
      this.updateCourtForm.value.ticket_status = null;
     }
     let payload={
      id:this.id,
      ...this.updateCourtForm.value
     }
    
    this._CEService.updateCourtTicket(payload).subscribe(data => {
       this.getSummaryIndex();
       this.court.nativeElement.click()
      this.updateCourtForm.reset()
      this._TS.success('court update successfully.')
    }, error => {
    })
  }
  paginate(value: any) {
     this.payLoad.page = value;

     this.getSummaryIndex();
  }
  redirectPage(){
    this.router.navigate(['/admin/ce/complaint'])
  }
  searchCourt(ev){
    if(ev.target.value){
       this.payLoad.searchString = ev.target.value;
      //  this.ShowDateField = false;
      this.getSummaryIndex();

    }else{
      delete this.payLoad.searchString 

      this.getSummaryIndex();
    }

  }
  getInspector() {
    this.dashboardSrvice.getInspectors({ department_id: 1,city_id:null }).subscribe(data => {
      this.inspectorList = data.response;
      console.log("inspectorList",this.inspectorList)
    })
  }
  selectValue(val){
    // console.log("selected val",val.target.value)
    if(val == 'all'){
      this.payLoad.inspector_id = null;
      this.getSummaryIndex();
    }
    else if(val){
      this.payLoad.inspector_id = val;
      this.ShowDateField = false;
      this.getSummaryIndex();
    }
  }
  selectMonth(val){
    const date = new Date();
    this.optionValue = val
    if(val == 0){
      this.isDate = false;
      this.ShowDateField = true;
      // this.bsRangeValue = []
      // this.maxDate = new Date();
      // this.minDate = moment(new Date()).add(-50, 'years').toDate();
      this.isDaterangepickerOpen=true;
    }

      else if(val == 1){
      this.lastFromDate  = date.toISOString(); 
      this.lastToDate = date.toISOString();
      this.payLoad.fromDate =this.lastFromDate;
      this.payLoad.toDate =this.lastToDate;
      
      }else if(val == 2){
      date.setDate(date.getDate() - 1)
      // console.log(date.toString())
      this.payLoad.fromDate =date.toISOString();
      this.payLoad.toDate =date.toISOString()
      }else if(val == 3){
        let last7Days = new Date(date.getTime() - 6 * 24 * 60 * 60 * 1000);
        this.payLoad.fromDate =last7Days.toISOString();
        this.payLoad.toDate =date.toISOString();
      }else if(val == 4){
      this.last = new Date(date.getTime() - 7 * 24 * 60 * 60 * 1000);
      let lastWeek = new Date(this.last - 6 * 24 * 60 * 60 * 1000);
      // console.log("lastWeek",lastWeek,this.last)
      this.payLoad.fromDate =lastWeek.toISOString();
      this.payLoad.toDate =this.last.toISOString();
      }
      this.getSummaryIndex();
  }

  handleDateRange(event) {
    // if(event == null ){
    //   event = [];
    // }
    if (event.length > 0  ) {
    this.payLoad.fromDate = (new Date(event[0]).toISOString()),
    this.payLoad.toDate = (new Date(event[1]).toISOString())
      // this.searchApplication();
      // this.uncheckApplication();
      this.getSummaryIndex();
  }
  }


  selectAll(isCheck){
    if(isCheck == true){
      this.courtData.forEach(item=>{
        item.active = true;
        item.page = this.pages;
        this.selectedAllData.filter(val=>{
          if( item.active  == true && item.id == val.id){
            val.active = true;
          }
        });
      })
    }else if(isCheck == false){
      this.courtData.forEach(item=>{
        item.active = false;
        item.page = this.pages;
        this.selectedAllData.filter(val=>{
          if( item.active  == false && item.id == val.id ){
            val.active = false;
          }
        });
      })
    }
    this.selectedAllData.push(...this.courtData);
    this.selectedAllData = this.selectedAllData.reduce((unique, o) => {
      if(!unique.some(obj => obj.id === o.id)) {
        unique.push(o);
      }
      return unique;
    },[])      
    this.selectedAllData = this.selectedAllData.filter(item=>item.active == true)
    console.log("data is selectedAllData ",this.selectedAllData)
    
  }
  selectApplication(isCheck,val){
    if(isCheck == true){
      this.courtData.filter((item,i)=>{
        if( val.id == item.id){
          item.active = true;
          this.selectedAllData.filter(val=>{
            if(item.active == true &&  val.id == item.id){
              val.active = true;
            }
          });
        }
      })
      this.data =  this.courtData.filter(item=>item.active == true)
      if( this.data.length ==  this.courtData.length){
        this.checkbox.nativeElement.checked = true
      }
    }
    else if( isCheck == false){
      this.courtData.filter(item=>{
        if( val.id == item.id){
          item.active = false;
          this.selectedAllData.filter(val=>{
            if(item.active == false &&  val.id == item.id ){
              val.active = false;
            }
          });
        }
      })
      this.data =  this.courtData.filter(item=>item.active == true)
      if( this.data.length != this.courtData.length){
        this.checkbox.nativeElement.checked = false;
      }
    }
    this.selectedAllData.push(...this.courtData);
    this.selectedAllData = this.selectedAllData.reduce((unique, o) => {
      if(!unique.some(obj => obj.id === o.id)) {
        unique.push(o);
      }
      return unique;
    },[])
    this.selectedAllData = this.selectedAllData.filter(item=>item.active == true)
    console.log("data is selectedAllData ",this.selectedAllData)
  }
  voidCourt(){
  let selectedAllData=  this.selectedAllData.filter(item=>item.active ==true)
  if(!selectedAllData.length ){
    this._TS.warning('select one court application for void ')
    return false;
  }
  let id = selectedAllData.map(item=>{return{id:item.id}})
  const data = {
    courtIndexDelete: id
  }
  // return
  console.log(data)

    this._CEService.voidCourtIndex(data).subscribe(data => {
      this.reSetFilter()
      this._TS.success('Void successfully')

    })

  }

  reSetFilter(){
    this.payLoad = {
      page:1,
      searchString:null,
      inspector_id:null,
      fromDate:null,
      toDate:null,
      totlaPage:null,
      complain_type:null,
      complain_sub_type:null,
      status:null,

    };
    this.sub_typeList = [];
    this.ShowDateField = false;
    this.selectedAllData = [];
    this.type.nativeElement.value = "";
    this.subType.nativeElement.value = "";
    this.inspector.nativeElement.value = "";
    this.status.nativeElement.value = "";
    this.bsRangeValue = [];
    this.getSummaryIndex();

  }
  

  downloadTemplate(id){
    window.open(this.env.host+`/api/ce/admin/download-court/${id}?downloadType=${6}&exe=${1}`,'_blank')
  }
  export(format: string){
    let  currentAdmin = JSON.parse(localStorage.getItem('currentAdmin'))
    let body: any = {}
    body.format = format;
    body.format = format;

    body.page = 1
    // body.type = this.type;
    // body.occupancy_type = this.type
    let url='/api/ce/admin/export-court?page=1'
    if(currentAdmin.role_id == 1){
      currentAdmin.id = null;
    }
     console.log("download api===>",this.env.host + url + '&' + 'searchString=' + this.payLoad.searchString + '&' + 'fromDate=' + this.payLoad.fromDate + '&' + 'toDate=' +  this.payLoad.toDate)
    // return;
    // window.open(this.env.host + url )

    window.open(this.env.host + url + '&' + 'searchString=' + this.payLoad.searchString + '&' + 'fromDate=' + this.payLoad.fromDate + '&' + 'toDate=' +  this.payLoad.toDate  );

    // this._CEService.getComplaintFile(body);
  
}
descriptionTrue = false
discription(val){
  console.log(val)
 if(val.length == 0){
  this.descriptionTrue = true;
 }else{
  this.descriptionTrue = false;
 }
}

selectFiles(event) {
  this.selectedFile = event.target.files[0];
  this.imageName = event.target.files[0].name;
}
public postSelected  = [];
postSelectedData(value){
  const formData = new FormData();
  if(this.selectedAllData.length == 0){
    this._TS.warning("please select at least one application for send notification");
    return true;
  }else{
    if(value == 1){
      this.myModal.nativeElement.click()
      return true;
    }
  }
   
  if(value == 2){
    if (!this.selectedFile ) {
      this.errorMessage = 'please upload Document'
    }
    if (!this.description) {
      this.descriptionTrue = true;
    }
    if(!this.selectedFile || !this.description)
    return false;
  }
 
 

  this.postSelected = this.selectedAllData.map(item=>{
    return {
      address_id:item.address_id,
      address:item.address,
      airtport_address:item.airtport_address,
      business_zip_code:item.business_zip_code,
      city_id:item.city_id,
      createdAt:item.createdAt,
      ein:item.ein,
      expiry_permit_date:item.expiry_permit_date,
      gate:item.gate,
      google_address:item.google_address,
      id:item.id,
      is_business_license:item.is_business_license,
      issue_permit_date:item.issue_permit_date,
      last_updatted_date:item.last_updatted_date,
      legal_address:item.legal_address,
      license_no:item.license_no,
      license_type:item.license_type,
      location_type:item.location_type,
      payment_status:item.payment_status,
      payroll_status:item.payroll_status,
      permit_number:item.permit_number,
      police_status:item.police_status,
      postal_address:item.postal_address,
      reference_number:item.reference_number,
      standar_license:item.standar_license,
      status:item.status,
      submitted_date:item.submitted_date,
      terminal:item.terminal,
      type:item.type,
      updatedAt:item.updatedAt,
      user_id:item.user_id,
      zoning_status:item.zoning_status,
      user_detail:item.user_detail,
    }
  })
  
   formData.append(`mail_array`,JSON.stringify(this.selectedAllData));
   formData.append(`document`,this.selectedFile);
   formData.append(`body`,this.description);
   console.log("mail data",formData)
   this._CEService.postSelectedData(formData).subscribe((data: any) => {
    // this.uncheckApplication();
    this._TS.success('notifications sent successfully')
    this.myModal.nativeElement.click();
    this.description = null;
    this.selectedFile  = null;
    this.reSetFilter();
  }, (error: any) => {

  });
}
clearSelectedFile() {
  this.selectedFile = null;
  this.errorMessage = '';
}

exportToExcel() {
  if(this.selectedAllData.length == 0){
    this._TS.warning("please select at least one application for export");
    return true;
  }
  const ws: xlsx.WorkSheet =   
  xlsx.utils.table_to_sheet(this.report.nativeElement);
  const wb: xlsx.WorkBook = xlsx.utils.book_new();
  xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
  xlsx.writeFile(wb, 'report.xlsx');
  this.reSetFilter();

 }

 numbers = [];
 getNumbers(){
  this.numbers = [];

  console.log("",this.totalPage)
   for (var i = 10; i <= this.totalPage; i+=10) {
    // const element = 10  
    console.log("log data ===>",i)
    this.numbers.push({count:i})
   
    
   }
   if(i >= this.totalPage){
    this.numbers.push({count:i})

   }
 }
 selectNumbers(value){
  this.payLoad.totlaPage = value;
  this.getSummaryIndex();
  this.isTrue = false;

 }

 typeList(){
  this._CEService.typeList(this.city_id.id).subscribe(data => {
    this.type_List = data;
    console.log("type",this.type_List)
  })
}
selectType(value){
  if(value == 'all'){
    this.payLoad.complain_type = null;
    this.sub_typeList = [];
    this.subType.nativeElement.value = "";
    this.payLoad.complain_sub_type = null;
    this.getSummaryIndex();
  }
 else if(value){
  this.payLoad.complain_type = value;
  this.subTypeList(value)
  this.getSummaryIndex();
 }
}
selectSubType(value){
  if(value == 'all'){
    this.payLoad.complain_sub_type = null;
    this.getSummaryIndex();
  }
  else if(value){
    this.payLoad.complain_sub_type = value;
    this.getSummaryIndex();
   }
   
 }
 selectStatus(value){
  if(value == 'all'){
    this.payLoad.status = null;
    this.getSummaryIndex();
  }
  else if(value){
    this.payLoad.status = value;
    this.getSummaryIndex();
   }
   
 }

 subTypeList(value){
  this.add_complainService.subTypeList(value).subscribe(data => {
    this.sub_typeList = data;
  })
}
allSatfflist = [];
allSatff() {
  this._CEService.getRoleByIDstaff(5).subscribe(data => {
    this.allSatfflist = data.response;
  })
}


}

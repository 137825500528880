
/**
 * API URLS
 */
class UrlHelper {

  private apiPrefix = '/api';
  private authPrefix = '/auth/api';
  private adminPrefix = `${this.apiPrefix}/admin`;
  private userPrefix = `${this.apiPrefix}`;
  private codeEnforcementPrefix = `${this.apiPrefix}/ce`;
  private codeEnforcementAdminPrefix = `${this.apiPrefix}/ce/admin`;



  getAuthUrls(urls: object) {
    let updatedUrl: object = {};
    for (let url in urls) {
      updatedUrl[url] = this.authPrefix + urls[url];
    }
    return updatedUrl;
  }

  getAdminUrls(urls: object) {
    let updatedUrl: object = {};
    for (let url in urls) {
      updatedUrl[url] = this.adminPrefix + urls[url];
    }
    return updatedUrl;
  }

  getCodeEnfocementUserUrls(urls: object) {
    let updatedUrl: object = {};
    for (let url in urls) {
      updatedUrl[url] = this.codeEnforcementPrefix + urls[url];
    }
    return updatedUrl;
  }

  getCodeEnfocementAdminUrls(urls: object) {
    let updatedUrl: object = {};
    for (let url in urls) {
      updatedUrl[url] = this.codeEnforcementAdminPrefix + urls[url];
    }
    return updatedUrl;
  }

  getUserUrls(urls: object) {
    let updatedUrl: object = {};
    for (let url in urls) {
      updatedUrl[url] = this.userPrefix + urls[url];
    }
    return updatedUrl;
  }

  getUrls(urls: object) {
    let updatedUrl: object = {};
    for (let url in urls) {
      updatedUrl[url] = this.apiPrefix + urls[url];
    }
    return updatedUrl;
  }
}

const baseUrls = {
  application: '/application',

  unitList: '/application/habitability/unit-list',
  //searchaddress: '/application/address',
  searchaddress: '/city/address',
  applicationMedia: '/application/media-upload',
}

const authUrls = {
  login: `/login`,
  register: `/register`,
  adminLogin: '/admin/login',
  sendotp: `/sendOTP`,
  verifyOtp: `/verifyOTP`,
  resetPassword: `/createPassword`,
  createPasswordForAdmin: `/staff/create-password`,
}

const CEUserUrls = {

  addComplaint: `/complaint`,
  searchaddressForCe: `/city/address`,
  uploadImageForComp: `/complaint-attachments`,
  getImage: `/complaint-attachments`,
  deleteImageForComp: `/delete-complaint-attachment`,
  getComplaint: `/complaint`,
  searchComplaint: `/complaint`,
  getCity: `/city-admin`,
  typeListUser:'/complain/type',
  subTypeListUser:'/complain/sub-type',


}

const CEAdminUrls = {
  allStff: `/internal/staff`,
  complaintDetails: `/complaint`,
  inspectionForCE: `/inspection`,
  assignInspector: `/assign-inspector`,
  addCourtTicket: `/inspection/court_ticket`,
  addNotesTicket: `/inspection/note`,
  voidCourtTicket: `/inspection/court_ticket/void`,
  uploadImage: `/inspection/document`,
  uploadImageWorkorder: `/inspection/work_order_request/inspection-document`,
  deleteImage: `/inspection/document/delete`,
  deleteImageWorkOrder: `/inspection/work_order_request/inspection-documet-delete`,
  addWorkOrder: `/inspection/work_order_request`,
  getWorkOrders: `/inspection/work_order_request`,
  workOrderDetails: `/inspection/work_order_request/details`,
  workassignInspector: `/inspection/work_order_request/assign_inspector`,
  workOrderInspection: `/inspection/work_order_request/inspection`,
  updatelocationforCE: `/update-location`,
  deleteVoliations: `/inspection/violation/delete`,
  getCourtTicket:'/inspection/court_tickets',
  updateCourtTicket:'/inspection/court_ticket/update',
  getNotes:'/inspection/notes',
  updateNoteTicket:'/inspection/note/update',
  voidNoteTiket:'/inspection/note/void',
  assignWorderInspector:'/assign-work-order-inspector',
  voidCourtIndex:'/inspection/court_tickets/void',
  voidNoticeIndex:'/inspection/notes/void',
  getRoleByIDstaff:'/staff-by-role',
  addType:'/complain/type',
  typeList:'/complain/type',
  addSubType:'/complain/sub-type',
  subTypeList:'/complain/sub-type',
  deleteType:'/complain/delete-type',
  deleteSubType:'/complain/delete-sub-type',
  sendMail:'/share-email-document',
  sendMailNotices:'/share-email-document',
  summaryIndex:'/complaint-summary'

}

const adminUrl = {
  allCertificates: `/assign/certificates`,
  certificatesFilters: `/assign/certificates`,
  getordianceCategoryList:'/unique-ordinance',

  // allCertificates: `/certificates`,
  //certificatesFilters: `/certificates/filters`,
  certificatesDetail: `/certificates/detail`,
  certificatesFiles: `/certificates/file/`,
  dashboardDeleteMedia: `/certificates/delete`,
  dashboardUploadMedia: `/certificates/upload`,
  dashboardUpdateMedia: `/application/media-update`,
  deleteCOandChMedia: `/application/media-delete`,
  scheduleInspection: `/schedule/inspection`,
  assignInspector: `/assign-inspector`,
  cancelInspector: `/cancel-inspector`,
  updateApplicationStatus: `/cancel-request`,
  searchAddressByStreetIndex: `/search-address-by-index`,
  updateApplicantDetail: `/update-applicant-detail`,
  uodateFeeAndPayments: `/update-payment-detail`,
  uploadApplicantAttachment: `/save-applicant-attachments`,
  deleteApplicantAttachment: `/delete-applicant-attachments`,
  addNewInspection: `/add-new-inspection`,
  addPickUp: `/save-applicant-pickup`,
  addNotes: `/save-applicant-notes`,
  ordinancePagination: `/ordinance`,
  //addNewOrdinance: `/add-new-ordinance`,
  addNewOrdinance: `/housing/violation`,

  saveSubmissionReview: `/save-submission-review`,
  savePaymentDetail: `/save-payment-detail`,
  cityWard: `/city/wards`,
  cityFilterWard: `/filter/city/wards`,

  sendApplicantEmail: `/send-applicant-email`,
  paymetsSummary: `/payment-history`,
  creteStaff: `/staff/create`,
  allStaff: `/staffs`,
  departments: `/departments`,
  allRole: `/roles`,
  createRole: `/role/create`,
  getRoleById: `/role/update`,
  updateRole: `/role/update`,
  deleteRole: `/role/delete`,
  permisions: `/permissions`,
  getSigleStaff: `/staff/update`,
  updateStaff: `/staff/update`,
  deleteStaff: `/staff/delete`,
  setPassword: `/user/password/create`,
  sendMailByClerk: `/clerk-submission-review`,
  getClerkMail: `/get-applicant-email`,
  assignedInspector: `/assign-inspector`,
  inspectors: `/inspectors`,
  assignedInspectorByDepartent: '/inspectors/assigned',
  userDepartment: `/users/department`,
  transferRequest: `/application/transfer`,
  inspection: `/inspector/housing/inspection`,
  getInspection: `/inspector/housing/inspections`,
  assesorInspection: `/inspector/assessor/inspection`,
  getAssesorInspection: `/inspector/assessor/inspections`,
  inspectionForTaxes: `/inspector/taxes/inspection`,
  getInspectionForTaxes: `/inspector/taxes/inspections`,
  inspectionForZoning: `/inspector/zoning/inspection`,
  getInscpectionForZoning: `/inspector/zoning/inspections`,
  getViolation: `/housing/violations`,
  deleteFee: `/fee/delete`,
  inspectionForFire: `/inspector/fire/inspection`,
  getInspectionForFire: `/inspector/fire/inspections`,
  getViolationForFire: `/views/violations`,
  addViolationForFire: `/fire/violation`,
  externalReview: `/external-review`,
  assignCertifictesByManger: `/manager/review`,
  changeStatusInVoida: `/inspection/void`,
  acceptapplication: `/application/accept`,
  rescheduleInspector: `/reschedule-inspector`,
  changeInspector: `/change-inspector`,
  addAttechment: `/save-applicant-attachments`,
  addapplicationAttectment: `/update-applicant-attachments`,
  deleteAttecment: `/delete-applicant-attachment`,
  createcityAdmin: `/city-admin/create`,
  cityAdmin: `/city-admin`,
  getsingleAdmin: `/city-admin/update`,
  updateAdmin: `/city-admin/update`,
  crteatePass: `/crteatePass`,
  createPass: `/city-admin/password/create`,
  deleteAdmin: `/city-admin/delete`,
  addOrdinace: `/ordinance/create`,
  updateOrdince: `/ordinance/update`,
  getSingleOrdinance: `/ordinance/update`,
  deleteOrdinance: `/ordinance/delete`,
  emailAndPicupNotes: `/applicant_notes-pickup-email`,
  deleteEmailPickUp: `/applicant_notes-pickup-email/delete`,
  applicationTimeLine: `/application-timeline`,
  setStripId: `/set-admin-stripe-account`,
  waiveOffpayment: `/waive-off-payment`,
  voidTransaction: `/soft-delete-payment`,
  unitbyInspector: `/assigned-units`,
  uploadCsvCityAddress: `/upload/address`,
  sendInviteForCity: `/city-admin/send-password-link`,
  sendInvitationForStaff: `/city-admin/send-password-link`,
  uploadOrdinance: `/upload/ordinance`,
  readUnreadApplciationAndComplaint: `/total-unread-application`,
  addBlockDate: `/block/calender`,
  getBlockDate: `/block/calender`,
  deleteBlockDate: `/block/calender/delete`,
  indexBlockCalendar: `/index/block/calender`,
  addInspectionDate: `/manage/inspection`,
  getInspectionDate: `/manage/inspection`,
  deleteCCOAttachment: `/application/cco/document/delete`,
  ccoAttachment: `/application/cco/document`,
  deleteInspection: `/manage/inspection/delete`,
  requestedDates: '/application-inspection-suggestion',
  voidSubmition: `/clerk-submission-review/void`,
  exportPaymentSummaryFile: `/export-payment-history`,
  addFeeByAdmin: `/add-payment`,
  activateApplicationByAdmin: `/application-active`,
  addRefundAmount: '/add/refunded-amount',
  updateRefundAmount: '/update/refunded-amount',
  deleteRefundAmount: '/delete/refunded-amount',
  adminEditUnit:'/habitability/update-unit',
  unitAdminList: '/application/habitability/unit-list',
 

};

const userUrl = {
  userallCertificates: `/certificates/`,
  achaccount: `/ach-account`,
  achaccountList: `/ach-accounts_of_user`,
  addachaccount: `/ach-account`,
  achAccountVerify: '/ach-account/verify',
  sendLinkOnBuyer: `/share-link`,
  timelineapplication: `/application-timeline`,
  tokenVerify: `/ach-account/card/verify`,
  payments: `/application/payment`,
  adminCity: `/application/cities`,
  cancelApplication: `/application/cancel-request`,
  withdrawApplication: `/application/withdraw`,
  editUserProfile: `/user/profile`,
  changePassword: `/user/change-password`,
  verifyLink: `/verify-share-link`,
  UploadImage: `/upload-buyer-id`,
  applicationComplete: `/application/complete`,
  verifyEmail: `/verify-email-link`,
  addUnits: `/application/habitability/add-unit`,
  addUnitsAfterPayments: `/application/habitability/add-unit-after-payment`,
  cheackaddress: `/application/check-address`,
  deletAchAccount: `/ach-account/delete`,
  userTransferApplication: `/application/transfer`,
  userTransferEmailVerify: `/verify_transfer_application`,
  cancelTransferApplication: `/application/cancel-transfer-application`,
  needHelp: `/need-help`,
  submitIncompleteApplication: `/application/complete`,
  getUnits: `/ch-payment-units`,
  addStaff: `/user/team_member`,
  getStaffList: `/user/team_members`,
  createPassword: `/user/team_member/create-password`,
  getSingleStff: `/user/team_member`,
  assignMember: `/add-application-team_member`,
  addOwnerAndBuyer: `/share-link-to-buyer-owner`,
  removeStaff: `/remove-application-team_member`,
  addSingleStaff: `/add-single-application-team_member`,
  completeApplication: `/application-complete-on-member-tab`,
  Uploadccodoc: `/application/cco/document`,
  getCCODoc: `/application/cco/document`,
  assignInspectionDateByuser: `/application-inspection-suggestion`,
  activateApplication: `/application/active`,
  getDocumentsList: `/application/media-upload-list`,
  uploadApplicantDocument: `/application/applicant-document-upload`,
  editIncompleteApplication: `/application/edit`,
  editUnit: `/application/habitability/update-unit`,
  deleteUnit: `/application/habitability/delete-unit`,
  deleteSchedules: '/admin/cancel-assign-inspector',

}

const UrlHelperClass = new UrlHelper();

export const urls = {
  ...UrlHelperClass.getAdminUrls(adminUrl),
  ...UrlHelperClass.getAuthUrls(authUrls),
  ...UrlHelperClass.getUrls(baseUrls),
  ...UrlHelperClass.getUserUrls(userUrl),
  ...UrlHelperClass.getCodeEnfocementUserUrls(CEUserUrls),
  ...UrlHelperClass.getCodeEnfocementAdminUrls(CEAdminUrls),


}




import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { appToaster, settingConfig } from 'src/app/configs';
import { AdminAuthenticationService } from 'src/app/core/authentication/admin-authentication.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DashboardService, StaffService } from 'src/app/core/http';
import { Certificates, AddressSearch } from 'src/app/shared/index';
import { Observable, Subscription } from 'rxjs';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { environment } from 'src/environments/environment';
import { formatDate } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-submission-and-reviews',
  templateUrl: './submission-and-reviews.component.html',
  styleUrls: ['./submission-and-reviews.component.css']
})
export class SubmissionAndReviewsComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('inspectionModal', { static: false }) inspectionModal: ElementRef;
  public isOpen = false
  @Output() messageEvent = new EventEmitter<string>();
  @Input() certificatesChild: Observable<any>;
  @Input() previousApplicationBtnClicked: any;
  public submissionIncompleteForm: FormGroup;
  public env: any;
  public unit: any;
  public assigneInpectorForm: FormGroup;
  public decisionForm: FormGroup;
  public taxesInspectionForm: FormGroup;
  public zoningForm: FormGroup;
  public applicationStatusAndDateForm: FormGroup;
  public acceptApplicationForm: FormGroup;
  public rescheduleInspectorForm: FormGroup;
  public changeInspectorForm: FormGroup;
  public isReschedule = false
  public isHide = false
  public settings: any;
  public certificate = "/api/admin/download-certificate/"

  public department = {
    role_id: null,
    department_id: null,
  }
  public disabledDates = []

  dropdownSettings1: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'value',
    selectAllText: 'Select All',
    unSelectAllText: 'Deselect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };

  public submissionStatus: [{}];
  public isSendMailForIncompleteSubmission = false;
  public isAssignInspector = false;
  public isSubmissionApplicationStatus = true;
  public isAssignInpector = false;
  public isChangeInspector = false;
  public certificateDetail: Certificates = {
    id: null,
    address: null,
    ward: null,
    applicant_address: null,
    applicant_email: null,
    applicant_name: null,
    applicant_phone: null,

    owner_name: null,
    owner_email: null,
    owner_phone: null,
    owner_address: null,
    owner_type: null,

    buyer_address: null,
    buyer_business: null,
    buyer_business_type: null,
    buyer_email: null,
    buyer_id: null,
    buyer_name: null,
    buyer_phone: null,
    buyer_type: null,

    is_attic_finished: null,
    is_basement_finished: null,
    no_of_bathrooms: null,
    no_of_bedrooms: null,
    no_of_commercial_units: null,
    no_of_kitchens: null,
    no_of_residential_units: null,
    point_of_contact: null,
    application_housing_status: null,
    application_zoning_status: null,
    application_taxes_status: null,
    application_assessor_status: null,
    application_fire_status: null,

  };
  public clerkmail = [{}];
  public inspectors = [{}];
  public assignInspector = [];
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'email',
    selectAllText: 'Select All',
    unSelectAllText: 'Deselect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
   
  };
  message: string = "Hola Mundo!"
  constructor(
    private adminAuthService: AdminAuthenticationService,
    private formBuilder: FormBuilder,
    private dashboardService: DashboardService,
    private staffService: StaffService,
    public _TS: ToastrService,
    private route: ActivatedRoute,
    private router: Router,


  ) {
    this.settings = settingConfig;
  }
  minDate: Date;
  public certificateDetailsData: any;
  public applicationPaymentStatus: number;
  public units = [];
  public allUnits = []
  public allTransaction = []
  public insType = []
  public submitionReview = []
  public housing_inspection_date_suggestion: any;
  public fire_inspection_date_suggestion: any

  public scheduleReviewAccordionExpanded = false;
  public submissionReviewAccordionExpanded = false;

  public failedInspectionCount: any;
  public currentAdmin: any;
  public modify = {}
  public tabType: any

  private subscription: Subscription = new Subscription();

  ngOnInit() {

    this.env = environment;
    this.applicationPaymentStatus = this.settings.applicationpayment_Status;
    this.route.queryParams.subscribe(data => {
      this.modify = { ...data }
      this.tabType = data.tabType

      console.log("modify data is ===>",this.modify)
    })
    this.minDate = moment(new Date()).toDate();
    this.adminAuthService.getUserInfo().subscribe(data => {
      this.currentAdmin = data;
    });
    console.log('current Admin : ', this.currentAdmin);
    this.certificatesChild.subscribe(data => {
      data['failedUnitIds'] = [];
      data['passedUnitIds'] = [];
      this.certificateDetail = data;

      console.log("certificateDetail is ===>",this.certificateDetail)

      this.housing_inspection_date_suggestion = this.certificateDetail['housing_inspection_date_suggestion'];
      this.fire_inspection_date_suggestion = this.certificateDetail['fire_inspection_date_suggestion']
      this.submitionReview = this.certificateDetail['submissionReview']

      console.log("value is submitionReview===>",this.submitionReview)
      this.allTransaction = data.allTransaction;
      this.failedInspectionCount = 0;
      if (this.certificateDetail['showAllInspection'].length > 0) {
        this.certificateDetail['showAllInspection'].forEach((inspectionObj) => {
          const failedUnitId = data['failedUnitIds'].find(failedUnitId => failedUnitId.unit_id == inspectionObj.unit_id);
          const passedUnitId = data['passedUnitIds'].find(passedUnitId => passedUnitId.unit_id == inspectionObj.unit_id);
          if (inspectionObj.decision == 3 && (inspectionObj.department_id == 1 || inspectionObj.department_id == 6) && inspectionObj.isVoid == null && ((passedUnitId && passedUnitId.unit_id !== inspectionObj.unit_id) || this.certificateDetail['showAllInspection'].length == 1 || (!passedUnitId))) {
            this.failedInspectionCount = this.failedInspectionCount + 1;
            data['failedUnitIds'].push({ unit_id: inspectionObj.unit_id, type_value: inspectionObj.type_value });
          } else if (inspectionObj.decision == 2 && (inspectionObj.department_id == 1 || inspectionObj.department_id == 6) && inspectionObj.isVoid == null) {
            data['passedUnitIds'].push({ unit_id: inspectionObj.unit_id, type_value: inspectionObj.type_value });
            if (failedUnitId && failedUnitId.unit_id === inspectionObj.unit_id && this.certificateDetail['showAllInspection'].length > 1) {
              let unitFailedCount = 0;
              for (let i = 0; i < data['failedUnitIds'].length; i++) {
                if (data['failedUnitIds'][i].unit_id == inspectionObj.unit_id) {
                  unitFailedCount++;
                }
              }
              if (unitFailedCount == data['failedUnitIds'].length) {
                this.failedInspectionCount = 0
              } else {
                this.failedInspectionCount = this.failedInspectionCount - 1;
              }
            }
          }
          // if (this.failedInspectionCount > 1) {
          //   const paymentDoneInspectionId = data['allTransaction'].find(paymentSuccessInspectionId => inspectionObj.id ==  paymentSuccessInspectionId.inspection_id && (paymentSuccessInspectionId.status == 3 || paymentSuccessInspectionId.status == 5));
          //   if (paymentDoneInspectionId) {
          //     this.failedInspectionCount = 1;
          //   }
          // }

        })
      }
      if (this.certificateDetail.occupancy_type == 3) {
        this.insType = this.settings.inspection_type_status.filter(type => {
          if (type.key != 1 && type.key != 2) {
            return type
          }
        })
      } else {
        if (this.certificateDetail.occupancy_type == 1 || this.certificateDetail.occupancy_type == 2) {
          this.insType = this.settings.inspection_type_status.filter(type => {
            if (type.key == 1 || type.key == 2) {
              return type
            }
          })
        }
      }
      this.units = data.units;


      console.log("unit data is ====>",this.units)
      this.certificateDetailsData = data
      if (this.certificateDetail.occupancy_type == 2) {
        this.findUnits();
      }
      console.log(this.certificateDetail.application_housing_status, 'null')
      console.log(this.certificateDetail)
      this.inItForm();
      if (this.previousApplicationBtnClicked) {
        if (this.previousApplicationBtnClicked) {
          this.applicationStatusAndDateForm.get('initial_inspection_date').setValidators(Validators.required);
          this.applicationStatusAndDateForm.get('initial_inspection_date').setErrors({ required: true });
          this.applicationStatusAndDateForm.get('initial_inspection_date').updateValueAndValidity();
          this.applicationStatusAndDateForm.get('inspector').setValidators(Validators.required);
          this.applicationStatusAndDateForm.get('inspector').setErrors({ required: true });
          this.applicationStatusAndDateForm.get('inspector').updateValueAndValidity();
        }
      }
      this.acceptApplicationForm.controls.message.setValue('Dear Applicant, Your application is incomplete for the following reasons')
      this.getLocalStorage()
      this.getSubmitionAppliction();
      this.getMailOfSubmissionClerk();
      this.getInspector();
      this.subscription.add(this.dashboardService.obsAssignedInspectionsLatest.subscribe((data: any) => {
        this.assiendInspector();
      }));

      this.seltedEmailBuyerAndApplicant();
      if (this.department.department_id == 4) {
        this.getInspectionForTaxes();
      }
      if (this.department.department_id == 3) {
        this.getAssesorInspection();
      }
      if (this.department.department_id == 5) {
        this.getInspectionForZoning();
      }
      this.getInspection();
      this.getDate()

    })

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnChanges(): void {
    if (this.applicationStatusAndDateForm) {
      if (this.previousApplicationBtnClicked) {
        this.applicationStatusAndDateForm.get('initial_inspection_date').setValidators(Validators.required);
        this.applicationStatusAndDateForm.get('initial_inspection_date').setErrors({ required: true });
        this.applicationStatusAndDateForm.get('initial_inspection_date').updateValueAndValidity();
        this.applicationStatusAndDateForm.get('inspector').setValidators(Validators.required);
        this.applicationStatusAndDateForm.get('inspector').setErrors({ required: true });
        this.applicationStatusAndDateForm.get('inspector').updateValueAndValidity();
      } else if (!this.previousApplicationBtnClicked) {
        this.applicationStatusAndDateForm.get('initial_inspection_date').clearValidators();
        this.applicationStatusAndDateForm.get('initial_inspection_date').setErrors(null);
        this.applicationStatusAndDateForm.get('initial_inspection_date').updateValueAndValidity();
        this.applicationStatusAndDateForm.get('inspector').clearValidators();
        this.applicationStatusAndDateForm.get('inspector').setErrors(null);
        this.applicationStatusAndDateForm.get('inspector').updateValueAndValidity();
      }
    }
  }

  allInspection = []
  public lastInspection = [];
  public isVoid: number = 0;
  public isInspectionFound = false;
  public showManager = false;
  getInspection() {
    this.showManager = false;
    this.dashboardService.getInspectionForHousing(this.department.department_id, this.certificateDetail.id).subscribe(data => {
      this.allInspection = data.response;
      console.log(this.allInspection);
      if (this.allInspection.length > 0) {
        this.isInspectionFound = true
        this.allInspection.forEach((inspectObj) => {
          if (inspectObj.isVoid == null) {
            this.showManager = true;
          }
        });
      }
      // this.findFeildInspection();
      if (this.allInspection.length > 0) {
        this.lastInspection = this.allInspection.filter((data, i) => {
          if (i == 0) {
            return data
          }

        })
        this.isVoid = this.lastInspection[0].isVoid
        console.log(this.isVoid)
      }
      console.log(this.lastInspection)
    })
  }


  findUnits() {
    debugger
    this.allUnits = []
    this.units.filter(data => {
      if ((data.status == 3 && data.decision == null) || (data.status == 5 && data.decision == null)) {
        console.log('1')
        this.allUnits.push({ id: data.id, value: (data.unit) })
        // data.decision == 3 ||
      } else if (( data.decision == 1) && data.status == null) {
                console.log('2')
        this.allUnits.push({ id: data.id, value: (data.unit) })
      } else if ((data.status == 3 && data.decision == 6) || (data.status == 5 && data.decision == 6)) {
                console.log('3')
        this.allUnits.push({ id: data.id, value: (data.unit) })
      }
    })
    debugger
    console.log(this.allUnits, ' this.allUnits');
  }
  sendMessage() {
    this.messageEvent.emit(this.message)
  }

  inItForm() {
    this.submissionIncomplete();
    this.assigneInpector();
    this.decisions();
    this.decisionAndCC();
    this.zoningControls();
    this.applicationAndDate();
    this.acceptApplication();
    this.changeInspectorControls();
    this.rescheduleInspectorControls();
  }


  zoningControls() {
    this.zoningForm = this.formBuilder.group({
      decision: ['', Validators.required],
      // zoning: ['', Validators.required],
      remark: [''],

    })
  }

  changeInspectorControls() {
    this.changeInspectorForm = this.formBuilder.group({
      inspector: ['', Validators.required]
    })
  }

  rescheduleInspectorControls() {
    this.rescheduleInspectorForm = this.formBuilder.group({
      inspection_date: ['', Validators.required],
      inspectionTime: ['', Validators.required]
    })
  }

  acceptApplication() {
    this.acceptApplicationForm = this.formBuilder.group({
      status: [''],
      to: ['', Validators.required],
      message: ['', Validators.required],
      cc: [''],
    })
  }
  public celrkSendData: any;
  public isIncompleteApplication = false
  sendMailByClerk() {
    debugger
    if (this.clerkStatus == '7') {
      this.acceptApplicationForm.controls.to.setErrors(null)
      this.acceptApplicationForm.controls.message.setErrors(null)

    }
    this.isIncompleteApplication = true;
    if (this.acceptApplicationForm.invalid) {
      return false
    }
    if (this.clerkStatus == '6') {
      this.celrkSendData = {
        application_id: this.certificateDetail.id,
        department_id: this.department.department_id,
        application_status: Number(this.clerkStatus),
        user_email_to: this.acceptApplicationForm.value.to,
        // user_email_cc: this.acceptApplicationForm.value.cc,
        user_email_cc: this.ccEmails,

        message: this.acceptApplicationForm.value.message,
      }
    } else if (this.clerkStatus == '7') {
      this.celrkSendData = {
        application_id: this.certificateDetail.id,
        department_id: this.department.department_id,
        application_status: Number(this.clerkStatus),
      }
    }

    this.dashboardService.sendMailByClerk(this.celrkSendData).subscribe(data => {
      debugger
      this.acceptApplicationForm.reset();
      this.ccEmails = []
      this.isIncompleteApplication = false;
      this.messageEvent.emit(this.message);
    })
  }

  applicationAndDate() {
    this.applicationStatusAndDateForm = this.formBuilder.group({
      application_status: ['', Validators.required],
      date: ['', Validators.required],
      initial_inspection_date: [''],
      inspector: ['']
    })
  }


  public isZoning = false
  inspectionForZoning() {
    this.isZoning = true
    if (this.zoningForm.invalid) {
      return false
    }
    const zonigData = {
      application_id: this.certificateDetail.id,
      department_id: Number(this.department.department_id),
      remark: this.zoningForm.value.remark,
      zoning: this.zoningForm.value.zoning,
      decision: Number(this.zoningForm.value.decision)
    }
    this.dashboardService.inspectionForZoning(zonigData).subscribe(data => {
      this.zoningForm.reset();
      this.isZoning = false;
      this.getInspectionForZoning();
      this.messageEvent.emit(this.message);
    })
  }
  public lastZoningInspection = []
  public zoningInspection = [];
  public isVoidZoning: number = 0;
  getInspectionForZoning() {
    this.dashboardService.getInspectionForZoning(this.department.department_id, this.certificateDetail.id).subscribe(data => {
      this.zoningInspection = data.response;
      this.allTransaction.map(data => {
        this.zoningInspection.map(value => {
          if (data.inspection_id == value.id && data.status == 1) {
            value.isVoid = 2
          }

        })
      })
      if (this.zoningInspection.length > 0) {
        this.lastZoningInspection = this.zoningInspection.filter((data, i) => {
          if (i == 0) {
            return data
          }

        })
        this.isVoidZoning = this.lastZoningInspection[0].isVoid;
        console.log(this.isVoidZoning)

      }
    })
  }

  public isChageTaxesField = false;
  changeTaxesValue(value: string) {
    if (value == "8") {
      this.taxesInspectionForm.controls['to'].setValue(this.certificateDetail.applicant_email);
      this.isChageTaxesField = true
    } else {
      this.isChageTaxesField = false

    }
  }
  decisionAndCC() {
    this.taxesInspectionForm = this.formBuilder.group({
      desicion: ['', Validators.required],
      to: ['', Validators.required],
      cc: [''],
      select: [''],
      balanceType: ['', Validators.required],
      message: [''],
      approximateAmount: ['', Validators.required],




    })
  }
  public isDecisionAndcc = false

  inspectionForTaxes() {
    this.isDecisionAndcc = true;
    if (this.taxesInspectionForm.value.desicion == "7") {
      this.taxesInspectionForm.controls.balanceType.setErrors(null)
      this.taxesInspectionForm.value.balanceType = "0"
      this.taxesInspectionForm.controls.approximateAmount.setErrors(null)
      this.taxesInspectionForm.value.approximateAmount = "0"
      this.taxesInspectionForm.controls.cc.setErrors(null)
      this.taxesInspectionForm.controls.to.setErrors(null)

    }
    if (this.taxesInspectionForm.invalid) {
      return false
    }
    const data = {
      application_id: this.certificateDetail.id,
      department_id: this.department.department_id,
      decision: this.taxesInspectionForm.value.desicion,
      balance_type: this.taxesInspectionForm.value.balanceType,
      approximate_amount: this.taxesInspectionForm.value.approximateAmount,
      remark: this.taxesInspectionForm.value.message,
    }
    this.dashboardService.inspectionForTaxes(data).subscribe(sata => {
      this.isDecisionAndcc = false;
      this.taxesInspectionForm.reset();
      this.getInspectionForTaxes();
      this.messageEvent.emit(this.message)
    })
  }

  public taxesInspection = []
  public lastTaxesInspection = [];
  public isTaxesVoid: number = 0;
  getInspectionForTaxes() {
    this.dashboardService.getInspectionForTaxes(this.department.department_id, this.certificateDetail.id).subscribe(data => {
      this.taxesInspection = data.response;
      this.allTransaction.map(data => {
        this.taxesInspection.map(value => {
          if (data.inspection_id == value.id && data.status == 1) {
            value.isVoid = 2
          }

        })
      })
      if (this.taxesInspection.length > 0) {
        this.lastTaxesInspection = this.taxesInspection.filter((data, i) => {
          if (i == 0) {
            return data
          }

        })
        this.isTaxesVoid = this.lastTaxesInspection[0].isVoid;

      }

    })
  }

  // get getdecision() { return this.decisionForm.controls }
  get submissionIncompleteText() { return this.submissionIncompleteForm.controls }
  get assignInpector() { return this.assigneInpectorForm.controls }
  get getdecision() { return this.decisionForm.controls }
  get getDecisionAndCC() { return this.taxesInspectionForm.controls };
  get zoning() { return this.zoningForm.controls };
  get getApplicationAndStatus() { return this.applicationStatusAndDateForm.controls };
  get getacceptApplication() { return this.acceptApplicationForm.controls };
  get changeIns() { return this.changeInspectorForm.controls };
  get rescheduleIns() { return this.rescheduleInspectorForm.controls };


  getInspector() {
    const data = {
      department_id: Number(this.department.department_id),
      city_id: this.certificateDetail['admin_id']
    }
    this.dashboardService.getInspectors(data).subscribe(data => {
      this.inspectors = data.response;
      console.log(this.inspectors, 'this.inspectors')
    })
  }
  decisions() {
    this.decisionForm = this.formBuilder.group({
      desicion: ['', Validators.required],
      propertClass: ['', Validators.required],
      propertyOwner: ['', Validators.required],
      no_of_residential_units: ['', Validators.required],
      no_of_commercial_units: ['', Validators.required],
      comments: ['', Validators.required],
    })
  }

  public isDecision = false;
  assesorInspection() {
    this.isDecision = true;
    if (this.decisionForm.invalid) {
      return false
    }
    const data = {
      department_id: this.department.department_id,
      application_id: this.certificateDetail.id,
      decision: this.decisionForm.value.desicion,
      property_class: this.decisionForm.value.propertClass,
      property_owner: this.decisionForm.value.propertyOwner,
      no_of_residential_units: this.decisionForm.value.no_of_residential_units,
      no_of_commerical_units: this.decisionForm.value.no_of_commercial_units,
      remark: this.decisionForm.value.comments,

    }
    this.dashboardService.assesorInpection(data).subscribe(data => {
      this.isDecision = false;
      this.decisionForm.reset();
      this.getAssesorInspection();
      this.messageEvent.emit(this.message)

    });
  }

  public allAssesorInspection = [];
  public lastInspectionForFire = [];
  public isVoidForAssesor: number = 0

  getAssesorInspection() {
    this.dashboardService.getAssesorInspection(this.department.department_id, this.certificateDetail.id).subscribe(data => {
      this.allAssesorInspection = data.response;
      console.log(this.allAssesorInspection, 'this.allAssesorInspection')
      this.allTransaction.map(data => {
        this.allAssesorInspection.map(value => {
          if (data.inspection_id == value.id && data.status == 1) {
            value.isVoid = 2
          }

        })
      })
      if (this.allAssesorInspection.length > 0) {
        this.lastInspectionForFire = this.allAssesorInspection.filter((data, i) => {
          if (i == 0) {
            return data
          }

        })
        this.isVoidForAssesor = this.lastInspectionForFire[0].isVoid

      }
    })
  }

  getMailOfSubmissionClerk() {
    const data = {
      application_id: this.certificateDetail.id,
    }
    this.dashboardService.getClerkMail(data).subscribe(comlaint => {
      this.clerkmail = comlaint.response;
    })
  }

  submissionIncomplete() {
    this.submissionIncompleteForm = this.formBuilder.group({
      application_status: ['', Validators.required],
      comments: ['', Validators.required],
    })
  }

  assigneInpector() {
    this.assigneInpectorForm = this.formBuilder.group({
      // units: [null, Validators.required],
      units: ['', Validators.required],
      application_status: [''],
      inspectionDate: ['', Validators.required],
      timeRange: ['', Validators.required],
      inpector: ['', Validators.required],
      type: ['', Validators.required],
      selectUnit: [],
    })
  }




  // decisions() {
  //   this.decisionForm = this.formBuilder.group({
  //     desicion: ['', Validators.required],
  //     propertClass: ['', Validators.required],
  //     propertyOwner: ['', Validators.required],
  //     no_of_residential_units: ['', Validators.required],
  //     no_of_commercial_units: ['', Validators.required],
  //     comments: ['', Validators.required],
  //   })
  // }
  //  public isDecision = false
  //   decisionsAndProperty() {
  //     this.isDecision = true;
  //     if (this.decisionForm.invalid) {
  //       return false
  //     }
  //   }

  getSubmitionAppliction() {
    this.submissionStatus = this.settings.application_status.filter((_) => {
      if (_.key == 6 || _.key == 7) {
        return _
      }
    })
  }


  public selectUnit = []
  selectUnits(value: any) {

    this.selectUnit.push(value);
    this.assigneInpectorForm.controls.units.setValue(value)
    // this.selectUnit.map((data:any) => {
    //   if (data.id != value.id) {
    //     this.selectUnit.push(value);

    //   }
    // })
    //this.assigneInpectorForm.controls.units.setValue(value)

  }
  onUnitDeSelect(value) {
    this.selectUnit.map((data, i) => {
      if (value.id == data.id) {
        this.selectUnit.splice(i, 1)
      }
    })
  }

  onDelectAllUnits(event) {

    this.selectUnit = []
    this.assigneInpectorForm.controls.units.setValue(null)

  }

  onSelectAllUnits(value) {

    this.selectUnit = []
    value.map(data => {
      this.selectUnit.push(data)
    })
    this.assigneInpectorForm.controls.units.setValue(value)
  }

  public isUnit = false
  assigneInpectors() {
    debugger
    console.log(this.department, 'this.department')
    this.isAssignInpector = true;
    if (this.department.department_id == 3 || this.department.department_id == 4 || this.department.department_id == 5) {
      this.assigneInpectorForm.controls.inspectionDate.setErrors(null);
      this.assigneInpectorForm.controls.type.setErrors(null);
    }
    if (this.certificateDetail.occupancy_type == 1 || this.certificateDetail.occupancy_type == 3) {
      this.assigneInpectorForm.controls.units.setErrors(null);

    }

    if (this.assigneInpectorForm.invalid) {
      return false
    }

    if (this.certificateDetail.occupancy_type == 3) {
      const checkIfInspectionTypePassed = this.certificateDetail['passedUnitIds'].find(obj => Number(this.assigneInpectorForm.value.type) == obj.type_value);
      if (checkIfInspectionTypePassed) {
        this._TS.error(`Please void earlier ${this.settings.getSetting('inspection_type_status', checkIfInspectionTypePassed.type_value).toLowerCase()} inspection`);
        return false;
      }
    }
    const data = {
      application_id: this.certificateDetail.id,
      //application_status: this.assigneInpectorForm.value.application_status,
      inspection_date: this.assigneInpectorForm.value.inspectionDate,
      inspection_range: Number(this.assigneInpectorForm.value.timeRange),
      inspector_id: Number(this.assigneInpectorForm.value.inpector),
      department_id: this.department.department_id,
      unit_id: this.selectUnit,
      type_key: this.typeValue,
      type_value: Number(this.assigneInpectorForm.value.type),
      // unit_id: this.assigneInpectorForm.value.units,

    }

    this.dashboardService.assignedInspector(data).subscribe(data => {
      debugger
      this.assigneInpectorForm.reset();
      this.isUnit = !this.isUnit
      this.selectUnit = []
      this.isAssignInspector = false
      this.messageEvent.emit(this.message);
      this.isAssignInpector = false;
      this.findUnits()
    })

  }

  localstprageData: any;
  getLocalStorage() {

    const department = this.adminAuthService.getPermission()
    if (department && department.role_id != 1 && department.role_id != 2) {
      this.department = department
      console.log("department is is 1===>",this.department)
      this.localstprageData = department.department;
    } else {
      const localStorageData = this.adminAuthService.getSaveDepartment()
      const department = this.adminAuthService.getPermission()
      this.department = localStorageData
      console.log("department is is 2===>",this.department)

      this.department.role_id = department.role_id
      this.localstprageData = localStorageData
      console.log("department is is 3===>",this.department)

    }
  }

  assiendInspector() {
    const data = {
      application_id: this.certificateDetail.id,
      department_id: this.department.department_id
    }
    this.dashboardService.getAssignedInspector(data).subscribe(data => {
      this.assignInspector = data.response;
      console.log("=====================>")
      console.log("houssing inspector",this.assignInspector)
    console.log("=====================>")

      debugger
      console.log(data.response, '+++++++++++++++++++++++')
    })
  }

  selectSubmissionStatus(value: string) {
    if (value == "6") {
      this.submissionIncompleteForm.controls['application_status'].setValue(value);
      this.isSendMailForIncompleteSubmission = true;
      this.isAssignInspector = false;
      this.isSubmissionApplicationStatus = false

    }
    else if (value == "7") {
      this.assigneInpectorForm.controls['application_status'].setValue(value);
      this.isSendMailForIncompleteSubmission = false;
      this.isAssignInspector = true;
      this.isSubmissionApplicationStatus = false
    } else if (value == "3") {
      this.isSendMailForIncompleteSubmission = false;
      this.isAssignInspector = false;
      this.isSubmissionApplicationStatus = true
    }

  }

  public isSendMessage = false
  submmitionSendUser() {

    this.isSendMessage = true;
    if (this.submissionIncompleteForm.invalid) {
      return false
    }
    const data = {
      application_id: this.certificateDetail.id,
      application_status: this.submissionIncompleteForm.value.application_status,
      message: this.submissionIncompleteForm.value.comments
    }
    this.dashboardService.sendMailByClerk(data).subscribe(data => {
      this.getMailOfSubmissionClerk();
      this.submissionIncompleteForm.reset();
      this.isSendMessage = false;
    })
  }


  public emailBuyerAndApplicatnt = []
  seltedEmailBuyerAndApplicant() {

    this.emailBuyerAndApplicatnt = []
    if (this.certificateDetail.applicant_email) {
      this.emailBuyerAndApplicatnt.push({id:1,email:this.certificateDetail.applicant_email});
    }
    if (this.certificateDetail.buyer_email) {
      this.emailBuyerAndApplicatnt.push({id:2,email:this.certificateDetail.buyer_email});

    }
  }
  // ******************Clerk code end*****************************************************

  //
  // addApplicationStatusAndDate() {
  //   this.isApplicationStatusAndDate = true;
  //   if (this.applicationStatusAndDateForm.invalid) {
  //     return false
  //   }
  // }
  public isApplicationStatusAndDate = false
  assigenCertificateByManger() {
    this.isApplicationStatusAndDate = true;
    if (this.applicationStatusAndDateForm.invalid) {
      return false
    }
    const data = {
      application_id: this.certificateDetail.id,
      certificate_decision_type: this.applicationStatusAndDateForm.value.application_status,
      certificate_expiration_date: this.applicationStatusAndDateForm.value.date,
      department: this.department.department_id
    }
    if (this.previousApplicationBtnClicked) {
      data['is_previous_application'] = 1;
      data['initial_inspection_date'] = this.applicationStatusAndDateForm.value.initial_inspection_date;
      data['inspector'] = this.applicationStatusAndDateForm.value.inspector;
    } else {
      data['is_previous_application'] = 0;
    }
    this.dashboardService.assignCertifictesByManger(data).subscribe(data => {
      this.applicationStatusAndDateForm.reset();
      // this.applicationStatusAndDateForm.get('application_status').setValue('');
      // this.applicationStatusAndDateForm.get('date').setValue('');
      // this.applicationStatusAndDateForm.get('initial_inspection_date').setValue('');
      // this.applicationStatusAndDateForm.get('inspector').setValue('');
      this.isApplicationStatusAndDate = false;
      this.messageEvent.emit(this.message)
    })
  }

  public isIncomplete = false;
  public isAcceptApplication = false;
  public clerkStatus: string
  newSubmitionAndReviews(event) {
    this.clerkStatus = (event)
    if (event == "6") {
      this.acceptApplicationForm.controls['to'].setValue(this.certificateDetail.applicant_email);

      this.isIncomplete = true
    } else {
      this.isIncomplete = false

    }

  }

  delete(data) {
    this.dashboardService.deleteSchedule({ schedule_id: data.id, application_id: data.application_id, department_id: data.department_id }).subscribe((data) => {
      // this.assiendInspector();
      location.reload();
    })
  }

  public schedule_id: number
  public staff_id: number
  openRescheduleScreen(currentInspector: any) {
    if(currentInspector.is_inspected == 4 ){
      this._TS.error('Can not Reschedule Cancel Application');
      return false;
    }
    this.isReschedule = true
    this.isChangeInspector = false;
    //let date = new  Date('12/10/2020')
    this.schedule_id = currentInspector.id
    this.staff_id = currentInspector.staff_id
    this.rescheduleInspectorForm.controls.inspection_date.setValue(new Date(currentInspector.inspection_date))
    this.rescheduleInspectorForm.controls.inspectionTime.setValue(currentInspector.inspection_range)

  }


  openChangeInspectorScreen(currentInspector: any) {
    this.schedule_id = currentInspector.id;
    this.staff_id = currentInspector.staff_id;
    this.isChangeInspector = true;
    this.isReschedule = false
    this.changeInspectorForm.controls.inspector.setValue(currentInspector.staff_id)
  }

  public isChangeInsp = false
  changeInspector() {
    this.isChangeInsp = true;
    if (this.changeInspectorForm.invalid) {
      return false
    }
    const data = {
      "schedule_id": this.schedule_id,
      "department_id": this.department.department_id,
      "inspector_id": Number(this.changeInspectorForm.value.inspector),
    }
    this.dashboardService.changeInspector(data).subscribe(data => {
      this.isChangeInspector = false
      this.assiendInspector()
    })
  }


  public isReScheduleValidation = false
  rescheduleInspector1() {
    this.isReScheduleValidation = true;
    if (this.rescheduleInspectorForm.invalid) {
      return false
    }
    const rescheduleData = {
      "schedule_id": this.schedule_id,
      "application_id": this.certificateDetail.id,
      "department_id": this.department.department_id,
      "inspection_date": this.rescheduleInspectorForm.value.inspection_date,
      "inspection_range": Number(this.rescheduleInspectorForm.value.inspectionTime),
      "inspector_id": this.staff_id
    }
    this.dashboardService.rescheduleInspector(rescheduleData).subscribe(data => {
      this.isReschedule = false
      this.messageEvent.emit(this.message)
      this.assiendInspector();
    })
  }

  public inspectionId: any;
  getInspectionId(inspectionId: any) {
    this.inspectionId = inspectionId
  }

  changeInspectionStatusInVoid() {
    const data = {
      id: this.inspectionId
    }
    this.dashboardService.changeStatusInVoid(data).subscribe(data => {
      this.hideModal();
      if (this.department.department_id == 4) {
        this.getInspectionForTaxes()
      }
      else if (this.department.department_id == 5) {
        this.getInspectionForZoning();
      } else if (this.department.department_id == 3) {
        this.getAssesorInspection();
      }
      this.messageEvent.emit(this.message)

    })
  }

  hideModal() {
    this.inspectionModal.nativeElement.click();
  }
  public blockDate = []
  getDate() {

    this.disabledDates = []
    this.staffService.getBlockDate().subscribe(data => {
      this.blockDate = data['response']
      if (this.blockDate && this.blockDate.length > 0) {
        this.blockDate.forEach(data => {
          //    let newDate = formatDate(new Date(data.block_date),'yyyy-MM-dd','en_US')
          let newDate = new Date(data.block_date)
          if (this.certificateDetail.ward == data.ward && this.certificateDetail.occupancy_type == 1 && this.department.role_id != 2 && this.department.role_id != 3) {
            this.disabledDates.push(newDate)

          }
        })
      }
      console.log(this.disabledDates, '..............')
    })
  }
  public isOpenCalenadar = true
  selectDate() {

    this._TS.error('Please Add ward then assign inspector')
  }
  public typeValue: any
  public typekey: any

  selectTypevalue(typeValue) {
    this.typekey = Number(typeValue)
    this.insType.forEach(data => {
      if (this.typekey == data.key) {
        this.typeValue = data.value
      }
    })
  }

  voidSubmission(id) {
    this.staffService.voidSubmition({ id: id, application_id: this.certificateDetail.id }).subscribe(data => {
      this.isIncomplete = false;
      this._TS.success('Void Submission')
      this.messageEvent.emit(this.message)

    })
  }

  public items = ['tet', 'dd']
  public ccEmails = []
  onAdding(value) {

    this.ccEmails.push(value.name)
  }

  onRemoving(value) {


    this.ccEmails.forEach((data, i) => {
      if (data == value.name) {
        this.ccEmails.splice(i, 1)
      }
    })
    console.log(this.ccEmails)
  }

  toggleScheduleReviewAccordion() {
    this.scheduleReviewAccordionExpanded = !this.scheduleReviewAccordionExpanded;
  }

  toggleSubmissionReviewAccordion() {
    this.submissionReviewAccordionExpanded = !this.submissionReviewAccordionExpanded;
  }

  cancelBtnResudle(){
    this.isReschedule = false;
  }


}


import * as _ from 'lodash';
import { of as ObservableOf, Observable } from 'rxjs';

export const codeEnforcementSettingConfig = {

    complaint_category: [
        {
            key: 1,
            value: "Animal Controll"
        },
        {
            key: 2,
            value: "Code Enforcement"
        },
        {
            key: 3,
            value: "Garbage"
        },
        {
            key: 4,
            value: "Health"
        },
        {
            key: 5,
            value: "Request Lien"
        },
    ],

    ce_complaint_category: [
        {
            key: 1,
            value: "Construction without permit"
        },
        {
            key: 2,
            value: "Construction Work After Hours"
        },
        {
            key: 3,
            value: "Construction Work on Weekends"
        },
        {
            key: 4,
            value: "Damaged Fencing, Porch, Stairs or Driveway"
        },
        {
            key: 5,
            value: "Debris, Rubbish, Junk on Property"
        },
        {
            key: 6,
            value: "Exterior Violations"
        },
        {
            key: 7,
            value: "High Grass/Weeds, Overgrown vegetation"
        },
        {
            key: 8,
            value: "Illegal Apartment, Over Crowding"
        },
        {
            key: 9,
            value: "Illegal Dumping"
        },
        {
            key: 10,
            value: "Illegal Signs Posted"
        },
        {
            key: 11,
            value: "Improper Signs on property"
        },
        {
            key: 12,
            value: "Interior Housing Violations"
        },
        {
            key: 13,
            value: "No Heat or Hot Water"
        },
        {
            key: 14,
            value: "Trash Can Left Out"
        },
        {
            key: 15,
            value: "Trash, Litter or Garbage on Property"
        },
        {
            key: 16,
            value: "Vacant, Abandon or Unsafe House/Building"
        },
        {
            key: 17,
            value: "Zoning Violations"
        },
    ],
    tabValue: [
        {
            key: 1,
            value: "What"
        },
        {
            key: 2,
            value: "When"
        },
        {
            key: 3,
            value: "Where"
        },
        {
            key: 4,
            value: "Who"
        },
        {
            key: 1,
            value: "Internal"
        },
        {
            key: 2,
            value: "External"
        },
    ],


    priority: [
        {
            key: 1,
            value: "Important"
        },
        {
            key: 2,
            value: "Serious"
        },
        {
            key: 3,
            value: "Critical"
        },
    ],


    // ce_complaint_status: [
    //     {
    //         value: 0,
    //         key: "Draft"
    //     },
    //     {
    //         value: 1,
    //         key: "Completed"
    //     },
    //     {
    //         value: 2,
    //         key: "Accepted"
    //     },
    //     {
    //         value: 3,
    //         key: "Cancelled"
    //     },
    //     {
    //         value: 4,
    //         key: "Transferred"
    //     },
    //     {
    //         value: 5,
    //         key: "Incomplete"
    //     },
    // ],






    ce_complaint_status: [{
    
    value: null,
    
    key: "Draft"
    
    },
    
    {
    
    value: 1,
    
    key: "Completed"
    
    },
    
    // {
    
    // value: 2,
    
    // key: "Submitted" // change at time of payment
    
    // },
    
    // {
    
    // value: 3,
    
    // key: "Cancelled"
    
    // },
    
    // {
    
    // value: 4,
    
    // key: "Transferred"
    
    // },
    
    // {
    
    // value: 5,
    
    // key: "Incomplete"
    
    // },
    
    {
    
    value: 6,
    
    key: "Assign Inspector"
    
    },
    
    {
    
    value: 7,
    
    key: "Contacted owner"
    
    },
    
    {
    
    value: 8,
    
    key: "Initial Inquiry"
    
    },
    
    {
    
    value: 9,
    
    key: "Issued Verval Warning"
    
    },
    
    
    
    
    {
    
    value: 10,
    
    key: "Issued Violation Court"
    
    },
    
    {
    
    value: 11,
    
    key: "Issued Violation Notices"
    
    },
    
    {
    
    value: 12,
    
    key: "Legecy"
    
    },
    
    {
    
    value: 13,
    
    key: "No Cause"
    
    },
    
    {
    
    value: 14,
    
    key: "Violation Corrected"
    
    }
    
    
    
    
    ],
    
   
    
    
    
        
        
        



    action_taken: [
        {
            value: 1,
            key: "Contacted owner"
        },
        {
            value: 2,
            key: "Initial Inquiry"
        },
        {
            value: 3,
            key: "Issued Verval Warning"
        },
        {
            value: 4,
            key: "Issued Violation"
        },
        {
            value: 5,
            key: "Legecy"
        },
        {
            value: 6,
            key: "No Cause"
        },
        {
            value: 7,
            key: "Violation Corrected"
        },
    ],

    ce_inspectionType: [
        {
            value: 1,
            key: "Notice"
        },
        {
            value: 2,
            key: "Court Case"
        },
    ],
    voliations_given: [
        {
            value: 1,
            key: "Property Owner"
        },
        {
            value: 2,
            key: "Tenant"
        },
       
    ],

    department: [
        {
            value: 6,
            key: "Sanitation"
        },
        {
            value: 7,
            key: "Public Works"
        },
        {
            value: 8,
            key: "Parks"
        },
        {
            value: 9,
            key: "Sewers and Drains"
        },
        {
            value: 10,
            key: "Urban Leauge"
        }
    ],
   
    delivery:[
        {
            key:'Certified Mail',
            value:1
        },
        {
            key:'First Class',
            value:2
        },
        {
            key:'Hand delivered',
            value:3
        },
        {
            key:'Priority Mail',
            value:4
        },
        {
            key:'Regular Mail',
            value:5
        }
    ],
    notes:[
        {
            key:'Inspection Req. Letter',
            value:1
        },
        {
            key:'Warning Summons',
            value:2
        },
        {
            key:'Voliations Notice',
            value:3
        },
        {
            key:'Court Summons',
            value:4
        },
    ],

    departMent:[
        {
            key:'Code Enforcement',
            value:1
        },
        {
            key:'Sanitation',
            value:2
        },
        {
            key:'Public Work',
            value:3
        },
        {
            key:'Downtown',
            value:4
        },
       
    ],

  
        
        
        

    getSettingAsObservable(prop: any, condition: any): Observable<any> {
        const object = _.find(this[prop], {
            key: condition
        });

        return ObservableOf(object ? object.value : '');
    },

    getSetting(prop: any, condition: any): any {
        const object = _.find(this[prop], {
            key: condition
        });

        return object ? object.value : '';
    },

    getSettingForAction(prop: any, condition: any): any {
        const object = _.find(this[prop], {
            value: condition
        });

        return object ? object.key : '';
    },

    getSettingShortForm(prop: any, condition: any): any {

        const object = _.find(this[prop], {
            key: condition
        });

        return object ? object.shortForm : '';
    },
    
    getSummaryStatus(prop: any, condition: any): any {
        console.log("value sis prop",prop,'condition',condition)

        const object = _.find(this[prop], {
            key: condition,
        });

        return object ? object.shortForm : '';
    },


    getKeysByName(formField, formFieldValue) {
        let key = ''
        if (formField != '') {
          codeEnforcementSettingConfig[formField].forEach(ele => {
            if(ele.value == formFieldValue) {
              key = ele.key;
            }
          });
          return key;
        } 
        // else if (formField == '') {
        //   codeEnforcementSettingConfig.forEach(ele => {
        //     if (ele.value == formFieldValue) {
        //       key = ele.key;
        //     }
        //   });
        //   return key;
        // }
      }
}



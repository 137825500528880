import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { tap, map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(private http: HttpClient) { }

  createRole(toUpdateData: any): Observable<any> {
    const href = `${environment['createRole']}`;
    return this.http.post<any>(href, toUpdateData).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }
  getRoleById(id): Observable<any> {
    const href = `${environment['getRoleById']}/${id}`;
    return this.http.get<any>(href).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
            return data;
          }

        }
      )
    );
  }

  deleteRole(id): Observable<any> {
    const href = `${environment['deleteRole']}/${id}`;
    return this.http.post<any>(href,'').pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
            return data;
          }

        }
      )
    );
  }
  updateRole(toUpdateData: any,id): Observable<any> {
    const href = `${environment['updateRole']}/${id}`;
    return this.http.post<any>(href, toUpdateData).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  getPermitions(): Observable<any> {
    const href = `${environment['permisions']}`;
    return this.http.get<any>(href).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
            return data;
          }

        }
      )
    );
  }

}

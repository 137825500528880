import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { codeEnforcementSettingConfig } from 'src/app/configs';
import { CeComplaintService } from 'src/app/core/http/admin/ce-complaint.service';
import { AdminAuthenticationService } from 'src/app/core/authentication/admin-authentication.service';

@Component({
  selector: 'app-work-orders',
  templateUrl: './work-orders.component.html',
  styleUrls: ['./work-orders.component.css']
})
export class WorkOrdersComponent implements OnInit {

  public searchQuery: any;
  public allComplaint = [];
  public currentPagination: number = Number(1);
  public totalPagination: number = Number(0);
  public offset: any = 1;
  public page: number = 1
  public settings: any
  public searchString: string
  public adminData:any;
  public isTrue = true;
  
  constructor(
    private _CEService: CeComplaintService,
    private router: Router,
    public adminAuthService:AdminAuthenticationService,
  ) {
    this.settings = codeEnforcementSettingConfig
  }

  ngOnInit() {
    //this.getCompalint()
    this.getWorkOrders();
    this.getAdminData();
  }

  // searchApplication() {

  // }
  getAdminData() {
    this.adminAuthService.getAdmin().subscribe(data => {
      this.adminData = data;   
      if( this.adminData.role_id == 2 &&  (this.adminData.id == 361 || this.adminData.id == 452)){
        this.isTrue = false;
        return true;
      }
      let city_id =  this.adminData ?  this.adminData.city_details : null;
      city_id =  city_id ?  city_id.admin_id : null;
      if( (this.adminData.role_id == 2 || this.adminData.role_id == 3 || this.adminData.role_id == 4 || this.adminData.role_id == 5) &&  (city_id == 361 || city_id == 452)){
        this.isTrue = false;
        return true;
      }
      if(this.adminData.role_id != 2 &&  this.adminData.id != 361){
        this.isTrue = true;
        return true;
      }  
    })
    
  }
 
  navigate(){
    if( this.adminData.role_id == 2 &&  (this.adminData.id == 361 || this.adminData.id == 452)){
      this.isTrue = true;
      this.router.navigate(['/admin/ce/complaint'])
      return true;
    }
    let city_id =  this.adminData ?  this.adminData.city_details : null;
      city_id = city_id ?  city_id.admin_id : null;
      if( (this.adminData.role_id == 2 || this.adminData.role_id == 3 || this.adminData.role_id == 4 || this.adminData.role_id == 5) &&  (city_id == 361 || city_id == 452)){
        this.isTrue = true;
        this.router.navigate(['/admin/ce/complaint']);
        return true;
      }
    
    if(this.adminData.role_id != 2 &&  this.adminData.id == 361){
      this.isTrue = false;
      this.router.navigate(['/admin/certificates/housing'],{ queryParams: { tabType: 1 } })
      return true;
    }
  }
  getCompalint() {
    this._CEService.getComplaint({ page: this.page }).subscribe(com => {
      this.allComplaint = com.response;
      this.totalPagination = com.total;
      this.offset = com.offset;
      this.currentPagination = com.page;
      console.log(this.allComplaint)
    })
  }

  public workOders = []
  getWorkOrders() {
    this._CEService.getWorkOrders({ page: this.page }).subscribe(com => {
      this.workOders = com.response;
      this.totalPagination = com.total;
      this.offset = com.offset;
      this.currentPagination = com.pages;
      // console.log(this.workOders,"+++++++++++++++++++++")
    })
  }



  paginate(page) {
    this.page = page;
    // this.getCompalint();
    this.getWorkOrders();

  }

  addComplaint() {
    this.router.navigate(['/admin/ce/add-complaint'])
  }
  redirectPage(){
    this.router.navigate(['/admin/certificates/housing'],{ queryParams: { tabType: 1 } })
  }

  navigateDetailsPage(cmpId,id) {
    this.router.navigate(['/admin/ce/work-orders-details'], { queryParams: {cmpId:cmpId, id: id } })
  }

  searchComplain() {
    console.log("searchQuery",this.searchQuery)
    if(this.searchQuery != undefined || !this.searchQuery){
      if(!this.searchQuery){
        this.searchQuery = ""
      }
      this._CEService.getWorkOrders({ searchString: this.searchQuery }).subscribe(com => {
        this.workOders = com['response'];
        this.totalPagination = com.total;
        this.offset = com.offset;
        this.currentPagination = com.page;
      })
    }
    

  }

}




import { Component, OnInit } from '@angular/core';
import { StaffService } from '../../../../../core/http/admin/staff.service';
import { Department } from '../../../../../core/models/admin/department.model'
import { roles } from '../../../../../core/models/admin/roles.model'
import { Router, ActivatedRoute } from '@angular/router';
import { RoleService } from 'src/app/core/http';
@Component({
  selector: 'app-active-inactiverole',
  templateUrl: './active-inactiverole.component.html',
  styleUrls: ['./active-inactiverole.component.css']
})
export class ActiveInactiveroleComponent implements OnInit {
  public deparments: Department[];
  public roles: roles[];
  public depatment:any = {
    department_id:null
  }
  public department_id: string;
  constructor(
    private staffService: StaffService,
    private router: Router,
    private route: ActivatedRoute,
    private roleService: RoleService
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(data => {
      this.department_id = data.department_id
    })
    if(this.department_id){
      this.getAllRole();

    }
  }

  allDepartment() {
    this.staffService.getDepartment().subscribe(data => {
      this.deparments = data.response;
    })
  }

  getAllRole() {
    this.depatment.department_id = this.department_id
    this.staffService.getRoll(this.depatment).subscribe(data => {
      this.roles = data.response
    })
  }

  getRoleById(roleId: string) {
    this.router.navigate(['/admin/roles/permissions'], { queryParams: { roleId: roleId , department_id:this.department_id  } })

  }
  deleteRole(roleId: string) {
    this.roleService.deleteRole(roleId).subscribe(data => { })
    this.getAllRole();
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SignaturePadModule } from '@ng-plus/signature-pad';
//import { SignaturePadModule } from 'angular2-signaturepad';
import { TagInputModule } from 'ngx-chips';

import { AdminRoutingModule } from './admin-routing.module';
import { FormsModule } from '@angular/forms';
import { LayoutsModule } from 'src/app/core/layouts.module';
import { DataTablesModule } from 'angular-datatables';
import { ZoningComponent } from './certificates/zoning/zoning.component';
import { PaymetsSummaryComponent } from './certificates/paymets-summary/paymets-summary.component';
import { ManageStaffComponent } from './certificates/manage-staff/manage-staff.component';
import { AddnewStaffMemberComponent } from './certificates/housing/addnew-staff-member/addnew-staff-member.component';
import { RolesAndPermissionsComponent } from './certificates/housing/roles-and-permissions/roles-and-permissions.component';
import { ManageDepartmentAndRoleComponent } from './certificates/manage-department-and-role/manage-department-and-role.component';
import { ActiveInactiveroleComponent } from './certificates/housing/active-inactiverole/active-inactiverole.component'
import { FilterPipe } from 'src/app/core/pipe/search.pipe';
import { CertificateDetailsComponent } from './certificates/housing/certificate-details/certificate-details.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { SubmissionAndReviewsComponent } from './certificates/housing/certificate-details/submission-and-reviews/submission-and-reviews.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { InspectionsComponent } from './certificates/housing/inspections/inspections.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ManageCityComponent } from './certificates/manage-city/manage-city.component';
import { AddCityComponent } from './certificates/add-city/add-city.component';
import { AddOrdinanceComponent } from './certificates/add-ordinance/add-ordinance.component';
import { OrdinanceListComponent } from './certificates/ordinance-list/ordinance-list.component';
import { ApplicationTimelineComponent } from './certificates/application-timeline/application-timeline.component';
import {NgxScrollToFirstInvalidModule} from '@ismaestro/ngx-scroll-to-first-invalid';
import { ComplaintComponent } from './code-enforcement/complaint/complaint.component';
import { AddCopmalaintFromAdminComponent } from './code-enforcement/add-copmalaint-from-admin/add-copmalaint-from-admin.component';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { ComplaintDetailsComponent } from './code-enforcement/complaint-details/complaint-details.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { InspectionComponent } from './code-enforcement/inspection/inspection.component';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { WorkOrdersComponent } from './code-enforcement/work-orders/work-orders.component';
import { WorkOrderDetailsComponent } from './code-enforcement/work-order-details/work-order-details.component';
import { WorkOrderInspectionComponent } from './code-enforcement/work-order-inspection/work-order-inspection.component';
import { ShowWardComponent } from './certificates/show-ward/show-ward.component';
import { AddWardComponent } from './certificates/add-ward/add-ward.component';
import { BuilingCCOComponent } from './certificates/builing-cco/builing-cco.component';
import { AddInspectionDateComponent } from './certificates/add-inspection-date/add-inspection-date.component';

import { NgxMaskModule } from 'ngx-mask';
import { CourtDetailsComponent } from './code-enforcement/court-details/court-details.component';
import { NotesDetailsComponent } from './code-enforcement/notes-details/notes-details.component';
import { StaffAddTypeComponent } from './certificates/housing/staff-add-type/staff-add-type.component';
import { SummaryIndexComponent } from './code-enforcement/summary-index/summary-index.component'

@NgModule({
  declarations: [AdminRoutingModule.components,BuilingCCOComponent,
     FilterPipe, ZoningComponent, PaymetsSummaryComponent, ManageStaffComponent, AddnewStaffMemberComponent,
      RolesAndPermissionsComponent, ManageDepartmentAndRoleComponent, ActiveInactiveroleComponent, 
      CertificateDetailsComponent, SubmissionAndReviewsComponent, InspectionsComponent, ManageCityComponent, 
      AddCityComponent, AddOrdinanceComponent, OrdinanceListComponent, ApplicationTimelineComponent, ComplaintComponent, AddCopmalaintFromAdminComponent, 
      ComplaintDetailsComponent, 
      InspectionComponent, WorkOrdersComponent, WorkOrderDetailsComponent, WorkOrderInspectionComponent, ShowWardComponent, AddWardComponent, AddInspectionDateComponent, CourtDetailsComponent, NotesDetailsComponent, StaffAddTypeComponent, SummaryIndexComponent],
  imports: [
    CommonModule,
    TypeaheadModule.forRoot(),
    AdminRoutingModule,
    FormsModule,
    NgMultiSelectDropDownModule.forRoot(),
    SignaturePadModule,
    TagInputModule,
    NgbModule,
    LayoutsModule,
    NgxPaginationModule,
    NgbModule,
    MatDatepickerModule,
    MatNativeDateModule,
    SatNativeDateModule,
    SatDatepickerModule,
    MatFormFieldModule,
    DataTablesModule,
    NgxScrollToFirstInvalidModule,
    TimepickerModule.forRoot(),
    NgxMaskModule.forRoot(),

    BsDatepickerModule.forRoot(),
    TooltipModule.forRoot(),
    AccordionModule.forRoot(),
    NgxMaterialTimepickerModule

  ]
})
export class AdminModule { }

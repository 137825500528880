import { CertificatesService } from './../../../../core/http/admin/certificates.service';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Certificates } from 'src/app/shared/index';
import { Subject, observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DataLayerManager } from '@agm/core';
import { FormGroup, FormControl } from '@angular/forms';
import { appToaster, settingConfig } from 'src/app/configs';
import { DashboardService } from 'src/app/core/http';
import { Router, ActivatedRoute } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { AdminAuthenticationService } from 'src/app/core/authentication/admin-authentication.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
@Component({
  selector: 'app-builing-cco',
  templateUrl: './builing-cco.component.html',
  styleUrls: ['./builing-cco.component.css']
})
export class BuilingCCOComponent implements OnInit {

  public settings: any;
  public certificates: Certificates[];
  public type: any = 3;
  public offset: any = 1;
  public currentPagination: number = Number(1);
  public searchQuery: string = '';
  public dateRange: string;
  public showCertificateOfOccupancy: boolean = true;
  public showCertificateOfHabitability: boolean = false;
  public showCertificateOfCoun: boolean = false;

  public modify = {}
  public isResult = false
  public applyFilter = []
  public pagination: {
    pages: any,
    total: number,
  } = {
      pages: 0,
      total: 0,
    };

  public department = {
    role_id: null,
    department_id: null,
    id:null,
  }
  public filterForm = new FormGroup({
    checkStatus: new FormControl()
  });
  @ViewChild('hideuploadCSV', { static: false }) hideuploadCSV: ElementRef;

  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'Deselect All',
    itemsShowLimit: 3,
    allowSearchFilter: true,

  };

  public filtersApplied = 0;
  public adminData:any;
  constructor(
    private certificateService: CertificatesService,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private dashboardService: DashboardService,
    private adminAuthService: AdminAuthenticationService,
    private _TS: ToastrService
  ) {
    this.settings = settingConfig;
    this.getAdminData();
  }
  public ward = [];
  public applicationPaymentStatus: number;
  public queryParamValue = {};
  public user = {
    id: null,
    role_id: null,
  }

  inspectorFilter = false;
  inspectorModalOpened = false;

  ngOnInit() {
    this.user = this.adminAuthService.getPermission()
    this.applyFilter
    Object.keys(this.priorApproval).forEach(data => {
      console.log(data)
      console.log(this.priorApproval[data].isSelected)
      if (this.priorApproval[data].isSelected) {
        this.modify[data] = 1;
        this.applyallFilter()
      }
    })
    this.route.queryParams.subscribe(data => {
      this.modify = { ...data };
      if (Object.keys(this.modify).length > 0) {
        // if (this.priorApproval.submitted.isSelected) {
        //   this.modify['submitted'] = 1
        // }
        this.filter()
        this.checkpriorApprovalValue();
        this.checkInspectionValue();
        this.checkhValueStatus();

        this.applyallFilter();

      }
      else {
        this.getAllCertificates({ type: this.type });

      }
    })
    this.applicationPaymentStatus = this.settings.applicationpayment_Status;

    this.getAllCertificates({ type: this.type });
    this.getLocalStorage();
    this.getInspector();
    // this.settings.ward.map(data => {
    //   this.ward.push({ id: data.key, name: data.value })
    // })
    // .
    if (this.department.role_id != 5) {
      this.groupBy['inspector'] = {
        text: "Inspector",
        value: 0,
        isSelected: false
      }
      this.groupByFilter = Object.keys(this.groupBy);
    }
  }

  getAdminData() {
    this.adminAuthService.getAdmin().subscribe(data => {
      this.adminData = data;
      console.log("roleId",this.adminData)
      
      // if( this.adminData.role_id ==  4 && this.adminData.city_details.admin_id == 361){
      //   this.router.navigate(['/admin/ce/complaint'])
      // }
    })
  }



  navigateOnDetailPage(certificates) {
    if (certificates.application_status != 5) {
      this.router.navigate(['/admin/certificates/details/', certificates.id], { queryParams: { ...this.modify } })
    }

  }
  private getAllCertificates(params: object) {
    if (params['department'] != 6) {
      params['department'] = 6;
    }
    this.certificateService.getAllCertificates(params).subscribe(({ response, ...pagination }) => {
      this.certificates = response;
      console.log(this.certificates, "****")
      this.offset = pagination.offset;
      this.pagination.total = pagination.total;
      this.pagination.pages = Array(pagination.pages).fill(0).map((x, i) => i + 1);
      this.currentPagination = Number(pagination.currentPage);
    });
  }


  public keyValue = []
  getValue(event) {
    //
    if (event.target.value == "5") {
      if (event.target.checked) {
        this.keyValue.push({ cancel: 5 })
      } else {
        this.keyValue.map(((data, i) => {
          if (data.cancel == 5) {
            this.keyValue.splice(i, 1)
          }
        }))
      }
    } else if (event.target.value == "1") {
      if (event.target.checked) {
        this.keyValue.push({ pending: 1 })
      } else {
        this.keyValue.map(((data, i) => {
          if (data.pending == 1) {
            this.keyValue.splice(i, 1)
          }
        }))
      }
    }
    console.log(this.keyValue)
  }



  localstprageData: any;
  getLocalStorage() {
    const department = this.adminAuthService.getPermission()
    if (department && department.role_id != 1 && department.role_id != 2) {
      this.department = department
      this.localstprageData = department.department;
      this.getWards()
    } else {
      const localStorageData = this.adminAuthService.getSaveDepartment()
      const department = this.adminAuthService.getPermission()
      this.department = localStorageData
      this.department.role_id = department.role_id
      this.department.id = department.id
      this.localstprageData = localStorageData
      this.getWards()

    }
  }
  getWards() {
    this.certificateService.getWard({ city_id: this.department['id'] }).subscribe(data => {
      this.ward = data['response']
      let i = 0
      this.ward.map(data => {
        this.ward.push({ id: i, name: data.ward })
        i = i + 1
      })
      this.ward = this.ward.filter(flt => {
        if (flt.id) {
          return flt
        }
      })
    })

  }


  public inspectors = []
  getInspector() {

    const data = {
      department_id: Number(this.department.department_id)
    }
    this.dashboardService.getInspectors(data).subscribe(data => {
      this.inspectors = data.response;
    })
  }

  public filterObject = {
    cancel: {
      text: "Cancel",
      value: 0,
      isSelected: false
    },
    inspected: {
      text: "Inspected",
      value: 0,
      isSelected: false
    },
    pendingInspection: {
      text: "Pending Inspection",
      value: 0,
      isSelected: false
    },
    illegalconversion: {
      text: "Illegal conversion",
      value: 0,
      isSelected: false
    },
    issueTemporaryCertificate: {
      text: "Issued Temporary Certificate",
      value: 0,
      isSelected: false
    },
    incomplete: {
      text: "Incomplete",
      value: 0,
      isSelected: false
    },

    issueCertificate: {
      text: "Issued Certificate",
      value: 0,
      isSelected: false
    },
    accepted: {
      text: "Accepted",
      value: 0,
      isSelected: false
    }
  }
  public filters: string[] = Object.keys(this.filterObject);



  public priorApproval = {
    draft: {
      text: "Draft",
      value: 0,
      isSelected: false
    },
    complete: {
      text: "Complete",
      value: 0,
      isSelected: false
    },
    submitted: {
      text: "Submitted",
      value: 0,
      isSelected: false
    },
    withdrawn: {
      text: "Withdrawn",
      value: 0,
      isSelected: false
    },
    // payment_status_pending: {
    //   text: "Payment pending",
    //   value: 0,
    //   isSelected: false
    // },
  }

  checkpriorApprovalValue() {

    Object.keys(this.modify).forEach(data => {
      Object.keys(this.priorApproval).forEach(checkValue => {
        if (data === checkValue) {
          this.priorApproval[checkValue].isSelected = true
          this.priorApproval[checkValue].value = 1
        }
      })


    })
  }

  checkhValueStatus() {
    Object.keys(this.modify).forEach(data => {
      Object.keys(this.filterObject).forEach(checkValue => {
        if (data === checkValue) {
          this.filterObject[checkValue].isSelected = true
          this.filterObject[checkValue].value = 1
        }
      })
    })
  }


  checkInspectionValue() {
    Object.keys(this.modify).forEach(data => {
      Object.keys(this.inspectionStatus).forEach(checkValue => {
        if (data === checkValue) {
          this.inspectionStatus[checkValue].isSelected = true
          this.inspectionStatus[checkValue].value = 1
        }
      })
    })
  }



  public priorApprovals: string[] = Object.keys(this.priorApproval);


  sendRequestForPariorApproval(check, event) {

    console.log(event.target.checked)
    if (check == "submitted" && !event.target.checked) {
      this.priorApproval.submitted.isSelected = event.target.checked
    }
    this.priorApproval[check].value = event.target.checked ? 1 : 0;
    // this.modify = {};
    Object.keys(this.priorApproval).forEach((value) => {
      if (this.priorApproval[value].value === 1) {
        this.isResult = true,
          this.modify[value] = this.priorApproval[value].value;
        this.priorApproval[value].isSelected = true
      }
      else {
        Object.keys(this.modify).forEach((objvalue, i) => {
          if (value == objvalue) {
            delete this.modify[objvalue]

          }

        })
      }
      if (this.from && this.to) {
        Object.keys(this.dateSet).forEach((date) => {
          console.log(this.dateSet[date], '**********')
          this.modify[date] = this.dateSet[date]
          this.modify[date] = this.dateSet[date]
        })
      }
    })
    //this.sendValueInurl()
  }

  sendValueInurl() {
    this.router.navigate(['/admin/certificates/cco'], { queryParams: { ...this.modify } })
    this.applyallFilter()
    if (this.dateKey == 'reqdates') {
      this.getReustedDate()
    } else if(this.dateKey == 'sub') {
      this.filterDateAndInspection()

    }
  }

  applyallFilter() {
    this.applyFilter = Object.keys(this.modify);
    this.filtersApplied = this.modify['inspector_is_See_All'] == 1 ? this.applyFilter.length - 1 : this.applyFilter.length;
  }

  // removeFilter(value: string) {
  //
  //   Object.keys(this.modify).forEach(data => {
  //     if (value === data) {
  //       delete this.modify[data]
  //       console.log(this.modify)

  //     }
  //   })
  //   Object.keys(this.priorApproval).forEach(check=>{
  //     if(value == check){
  //       this.priorApproval[check].isSelected = false
  //this.priorApproval[check].value = 1
  //     }
  //   })

  //   Object.keys(this.filterObject).forEach(check=>{
  //     if(value == check){
  //       this.filterObject[check].isSelected = false
  //this.filterObject[check].value = 1

  //     }
  //   })
  //   Object.keys(this.inspectionStatus).forEach(check=>{
  //     if(value == check){
  //       this.inspectionStatus[check].isSelected = false
  //this.filterObject[check].value = 1

  //     }
  //   })
  //    this.sendValueInurl()
  // }

  public inspectionStatus = {
    pass: {
      text: "Pass",
      value: 0,
      isSelected: false
    },
    fail: {
      text: "Fail",
      value: 0,
      isSelected: false
    },
    no_entry: {
      text: "No-Entry",
      value: 0,
      isSelected: false
    },
  }

  public inspection: string[] = Object.keys(this.inspectionStatus);


  housingInspectionStatus(check, event) {
    console.log(event.target.checked)
    this.inspectionStatus[check].value = event.target.checked ? 1 : 0;
    //this.modify = {};
    Object.keys(this.inspectionStatus).forEach((value) => {
      if (this.inspectionStatus[value].value === 1) {
        this.isResult = true,
          this.modify[value] = this.inspectionStatus[value].value;
        this.inspectionStatus[value].isSelected = true
      }
      else {
        Object.keys(this.modify).forEach((objvalue, i) => {
          if (value == objvalue) {
            delete this.modify[objvalue]

          }

        })
      }
      if (this.from && this.to) {
        Object.keys(this.dateSet).forEach((date) => {
          console.log(this.dateSet[date], '**********')
          this.modify[date] = this.dateSet[date]
          this.modify[date] = this.dateSet[date]
        })
      }
    })
    //this.sendValueInurl()

  }
  public groupBy = {
    ward: {
      text: "Ward",
      value: 0,
      isSelected: false
    },
    // inspector: {
    //   text: "Inspector",
    //   value: 0,
    //   isSelected: false
    // },
  }

  public groupByFilter: string[] = Object.keys(this.groupBy);
  sendRequest(check, event) {
    console.log(event.target.checked)
    this.filterObject[check].value = event.target.checked ? 1 : 0;
    //  this.modify = {};
    Object.keys(this.filterObject).forEach((value) => {
      if (this.filterObject[value].value === 1) {
        this.isResult = true,
          this.modify[value] = this.filterObject[value].value;
        this.filterObject[value].isSelected = true
      } else {
        Object.keys(this.modify).forEach((objvalue, i) => {
          if (value == objvalue) {
            delete this.modify[objvalue]

          }

        })
      }
      if (this.from && this.to) {
        Object.keys(this.dateSet).forEach((date) => {
          console.log(this.dateSet[date], '**********')
          this.modify[date] = this.dateSet[date]
          this.modify[date] = this.dateSet[date]
        })
      }
    })
    //this.sendValueInurl()

  }
  public isCheacked = false;
  inspectorSelect(check, event) {

    this.groupBy[check].value = event.target.checked ? 1 : 0;
    this.isCheacked = event.target.checked
    Object.keys(this.groupBy).forEach(value => {
      if (this.groupBy[value].value === 1) {

        this.groupBy[value].isSelected = true
      }
    })
  }

  preventDeafultChecked(event){
    event.preventDefault();
  }

  public selectInspectors = [];
  public selectWard = []
  selectInspector(value, seelctValue) {

    if (seelctValue == 'inspector') {
      this.selectInspectors.push(value.id);
      this.groupBy[seelctValue].isSelected = true
    }
    else if (seelctValue == 'ward') {
      this.selectWard.push(value.name);
      this.groupBy[seelctValue].isSelected = true
    }

  }

  deSelect(value, seelctValue) {
    if (seelctValue == 'inspector') {
      this.selectInspectors.map((data, i) => {
        if (value.id == data) {
          this.selectInspectors.splice(i, 1)
        }
      })
      if (this.selectInspectors.length == 0) {
        this.groupBy[seelctValue].isSelected = false;
      }
    } else if (seelctValue == 'ward') {
      this.selectWard.map((data, i) => {
        if (value.name == data) {
          this.selectWard.splice(i, 1)
        }
      })
      if (this.selectWard.length == 0) {
        this.groupBy[seelctValue].isSelected = false;
      }
    }

  }




  filter(val = null) {

    // if (this.priorApproval.submitted.isSelected) {
    //   this.modify['submitted'] = 1
    // }
    if (this.selectInspectors.length > 0 && this.selectWard.length > 0 && (val == null || this.groupByFilter.length > 1)) {
      this.applicationFilter({ department: 1, type: this.type, ...this.modify, inspectorsId: JSON.stringify(this.selectInspectors), ward: JSON.stringify(this.selectWard), })
    } else if (this.selectInspectors.length > 0 && val == 3) {
      if (this.inspectorModalOpened) {
        delete this.modify["inspector_is_See_All"];
        this.inspectorFilter = true;
        this.applicationFilter({ department: 1, type: this.type, ...this.modify, inspectorsId: JSON.stringify(this.selectInspectors) })
      } else if (this.groupByFilter.length > 1) {
        this.applicationFilter({ department: 1, type: this.type, ...this.modify, inspectorsId: JSON.stringify(this.selectInspectors) })
      }
    } else if (this.selectInspectors.length == 0 && this.selectWard.length > 0 && val == null)
      this.applicationFilter({ department: 1, type: this.type, ...this.modify, ward: JSON.stringify(this.selectWard), })
    else {
      this.applicationFilter({ department: 1, type: this.type, ...this.modify, })
    }

  }



  clearAllFilter() {

    this.modify = {}
    Object.keys(this.filterObject).forEach((value) => {
      this.filterObject[value].isSelected = false;
      this.filterObject[value].value = 0
      this.isResult = false
      this.getAllCertificates({ type: this.type })
    })
    Object.keys(this.priorApproval).forEach((value1) => {
      this.priorApproval[value1].isSelected = false;
      this.priorApproval[value1].value = 0
    })
    Object.keys(this.inspectionStatus).forEach((value1) => {
      this.inspectionStatus[value1].isSelected = false;
      this.inspectionStatus[value1].value = 0
    })

    Object.keys(this.groupBy).forEach((value1) => {
      this.groupBy[value1].isSelected = false;
      this.groupBy[value1].value = 0
      this.selectInspectors = [];
    })
    this.applyallFilter();
    this.router.navigate(['/admin/certificates/cco'])

  }

  clearAll() {

    // this.modify = {}
    this.filtersApplied = 0;
    for (let key in this.modify) {
      if (key != 'inspector_is_See_All') {
        delete this.modify[key];
      }
    }
    // this.applyFilter = Object.keys(this.modify);
    this.applyFilter = [];
    Object.keys(this.priorApproval).forEach((value) => {
      this.priorApproval[value].value = 0
      this.priorApproval[value].isSelected = false
    })
    Object.keys(this.inspectionStatus).forEach((value) => {
      this.inspectionStatus[value].isSelected = false
      this.inspectionStatus[value].value = 0
    })
    Object.keys(this.filterObject).forEach((value) => {
      this.filterObject[value].isSelected = false
      this.filterObject[value].value = 0
    })
    Object.keys(this.groupBy).forEach(value => {
      this.groupBy[value].isSelected = false
      this.groupBy[value].value = 0
    })
    this.router.navigate(['/admin/certificates/cco'], { queryParams: { ...this.modify } })
    if(this.from && this.to ){
     this.from = null
     this.to = null
      this.getAllCertificates({type:this.type, ...this.modify})
    } else {
      this.getAllCertificates({type:this.type, ...this.modify})
    }
  }

  applicationFilter(params: object) {

    this.certificateService.getAllCertificates(params).subscribe(({ response, ...pagination }) => {
      this.certificates = response;
      this.offset = pagination.offset;
      this.pagination.total = pagination.total;
      this.pagination.pages = Array(pagination.pages).fill(0).map((x, i) => i + 1);
      this.currentPagination = Number(pagination.currentPage);
    })
  }

  private filterCertificates(params: any): void {

    this.certificateService.filterCertificates(params).subscribe(({ response, ...pagination }) => {
      this.certificates = response;
      this.pagination.total = pagination.total;
      this.pagination.pages = Array(pagination.pages).fill(0).map((x, i) => i + 1);
      this.currentPagination = Number(pagination.currentPage);
    });
  }

  roundTheValue(value: number): number {
    return Math.floor(value);
  }

  public page: number = 1;
  paginateTheIndexPage(value: number): void {
    this.page = value
    if (this.isResult || this.modify['inspector_is_See_All'] == 1) {
      this.getAllCertificates({ department: 1, type: this.type, page: value, ...this.modify })
    }
    else if (this.modify['inspector_is_See_All'] != 1 && this.inspectorFilter) {
      this.getAllCertificates({ department: 1, type: this.type, page: value, ...this.modify, inspectorsId: JSON.stringify(this.selectInspectors) })
    }
    else if (!this.isResult && this.from && this.to) {
      this.getAllCertificates({ department: 1, type: this.type, page: value, from: this.from, to: this.to,is_submitted_date:1 })
    }
    else if (!this.isResult && Object.keys(this.modify).length > 0 ) {
      this.getAllCertificates({ department: 1, type: this.type, page: value, ...this.modify })
    }
    else if (Object.keys(!this.modify)) {
      this.getAllCertificates({ department: 1, type: this.type, page: value });
    }
  }


  searchCertificates(): void {
    if (this.searchQuery.length > 2) {
      if (this.isResult || this.modify['inspector_is_See_All'] == 1) {
        this.filterCertificates({ search_query: this.searchQuery, department: this.settings.housingDepartmentId, ...this.modify, type: this.type, page: this.page })
      }
      else if (this.from && this.to) {
        if (this.modify['inspector_is_See_All'] == 1) {
          this.filterCertificates({ search_query: this.searchQuery, department: this.settings.housingDepartmentId, from: this.from, to: this.to, type: this.type, page: this.page, ...this.modify });
        } else {
          this.filterCertificates({ search_query: this.searchQuery, department: this.settings.housingDepartmentId, from: this.from, to: this.to, type: this.type, page: this.page });
        }
      } else {
        if (this.modify['inspector_is_See_All'] == 1) {
          this.filterCertificates({ search_query: this.searchQuery, department: this.settings.housingDepartmentId, type: this.type, page: this.page, ...this.modify })
        } else {
          this.filterCertificates({ search_query: this.searchQuery, department: this.settings.housingDepartmentId, type: this.type, page: this.page })
        }
      }
    }
    if (this.searchQuery.length === 0) {
      if (this.modify['inspector_is_See_All'] == 1) {
        this.getAllCertificates({ type: this.type, page: this.page, ...this.modify });
      } else {
        this.getAllCertificates({ type: this.type, page: this.page });
      }
    }
    // if (this.searchQuery.length <= 2) {
    //   this.paginate(this.pagination.pages)
    // }
  }

  getCertifaicateFile(format: string) {
    let body: any = {}
    body.format = format;
    body.type = this.type;
    body.occupancy_type = this.type
    this.certificateService.getCertifaicateFile(body);
  }

  // dateFilterMethod(event: any) {
  //   if (event.value === null || event.value === undefined) {
  //     this.paginate(this.pagination.pages)
  //   }
  //   if (event.value) {
  //     const selectedDate = new Date(event.value);
  //     let year: any = selectedDate.getFullYear();
  //     let month: any = selectedDate.getMonth() + 1;
  //     let date: any = selectedDate.getDate();

  //     if (month <= 9) {
  //       month = '0' + month;
  //     }
  //     if (date <= 9) {
  //       date = '0' + date;
  //     }
  //     let searchDate = [year, month, date].join('-');
  //     this.filterCertificates({
  //       date_from: searchDate
  //     });
  //   }
  // }

  // onSubmit() {
  //   const status = Number(this.filterForm.get('checkStatus').value);
  //   if (status >= 0 && status <= 5) {
  //     this.filterCertificates({
  //       checkStatus: status
  //     });
  //   }
  //   if (status === null || status === undefined) {
  //     this.paginate(this.pagination.pages);
  //   }
  // }

  showAndHideCertificate(value: number) {
    if (value === 1) {
      this.showCertificateOfOccupancy = true;
      this.showCertificateOfHabitability = false;
      this.showCertificateOfCoun = false

      this.type = 1;
      this.getAllCertificates({ type: this.type });
    } else if (value === 2) {
      this.showCertificateOfHabitability = true;
      this.showCertificateOfOccupancy = false;
      this.showCertificateOfCoun = false

      this.type = 2;
      this.getAllCertificates({ type: this.type });
    } else if (value === 3) {
      this.showCertificateOfHabitability = false;
      this.showCertificateOfOccupancy = false;
      this.showCertificateOfCoun = true
      this.type = 3;
      this.getAllCertificates({ type: this.type });
    }
  }

    activateCurrentApplication(id: number, applicationStatus) {
    if (applicationStatus != 11) {
      const activateRequest = {
        application_id: id,
        application_status: 1,
        action: 'activate'
      };
      this.dashboardService.updateApplicationStatus(activateRequest).subscribe(data => {
        if (data) {
          this.getAllCertificates({ type:this.type });
        }
      });
    } else {
      const payload = {
        application_id: id
      };
      this.dashboardService.activateApplicationByAdmin(payload).subscribe((data: any) => {
        if (data) {
          this.clearAll();
        }
      });
    }

  }

  public from: any;
  public to: any;

  public dateSet = {
    from: null,
    to: null,
  }
  public dateKey:string
  selectDate(event, value) {
    if (event) {
      this.from = moment(event[0]).format("YYYY-MM-DDT00:00:00.000") + "Z";
      this.to = moment(event[1]).format("YYYY-MM-DDT00:00:00.000") + "Z";
      if (this.from && this.to) {
        this.dateSet.from = this.from,
          this.dateSet.to = this.to
          this.dateKey = value
        // if (value == 'reqdates') {
        //   this.getReustedDate()
        // } else {
        //   this.filterDateAndInspection()

        // }

      }
    }
  }

  getReustedDate() {

    const data ={
      department_id:this.department.department_id,
      from:this.from,
      to:this.to,
      is_inspection_suggestion_date:1,
      type:this.type
    }
    this.certificateService.getAllCertificates(data).subscribe(({ response, ...pagination }) => {
      this.certificates = response;
      console.log(this.certificates, "****")
      this.offset = pagination.offset;
      this.pagination.total = pagination.total;
      this.pagination.pages = Array(pagination.pages).fill(0).map((x, i) => i + 1);
      this.currentPagination = Number(pagination.currentPage);
    });
  }


  filterDateAndInspection() {
    if (this.isResult) {
      if (this.from && this.to) {
        Object.keys(this.dateSet).forEach((date) => {
          console.log(this.dateSet[date], '**********')
          this.modify[date] = this.dateSet[date]
          this.modify[date] = this.dateSet[date]
        })
      }
      this.searchBydate({ department: 1, ...this.modify, type: this.type, page: this.page })
    }
    else {
      this.searchBydate({ department: 1, from: this.from, to: this.to, type: this.type, page: this.page,is_submitted_date:1 })
    }
  }

  searchBydate(params: object) {
    this.certificateService.filterCertificates(params).subscribe(({ response, ...pagination }) => {
      this.certificates = response;
      this.pagination.total = pagination.total;
      this.pagination.pages = Array(pagination.pages).fill(0).map((x, i) => i + 1);
      this.currentPagination = Number(pagination.currentPage);
    });
  }
  navigateOnTimeLinePage(applicationId) {
    this.router.navigate(['/admin/certificates/time-line'], { queryParams: { applicationId: applicationId } })
  }

  public selectCSVFile: File
  public imageName: any
  public imgURL: any
  selectCSV(files) {

    console.log(files);
    if (files && files.length > 0) {
      let file: File = files.item(0);
      this.selectCSVFile = file
      console.log(file.name);
      this.imageName = file.name;
      console.log(file.size);
      console.log(file.type);
      var reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = (_event) => {
        this.imgURL = reader.result;
      }
    }
  }

  hideImage() {
    this.imgURL = null
  }
  uploadCSV() {
    this.user = this.adminAuthService.getPermission()
    let formData = new FormData();
    formData.append('city_address', this.selectCSVFile),
      formData.append('id', this.user.id),


      this.dashboardService.uploadCsvForCityAdmin(formData).subscribe(csv => {
        this._TS.success('Address CSV uploaded');
        this.imgURL = null,
          this.hideuploadCSV.nativeElement.click()
      })
  }
  public insdateSet = {
    inspectionfrom: null,
    inspectionto: null,
  }
  public schdateSet = {
    schedule_date_from: null,
    schedule_date_to: null,
  }
  inspectionfrom: Date
  inspectionto: Date

  schedule_date_from: Date
  schedule_date_to: Date

  selectInspectionDate(event, isScheduled) {
    if (event && !isScheduled) {
      this.inspectionfrom = event[0].toISOString()
      this.inspectionto = event[1].toISOString()
      if (this.inspectionfrom && this.inspectionto) {
        this.insdateSet.inspectionfrom = this.inspectionfrom,
          this.insdateSet.inspectionto = this.inspectionto
        //this.dateKey = value
      }
      if (this.inspectionfrom && this.inspectionto) {
        Object.keys(this.insdateSet).forEach((date) => {
          console.log(this.insdateSet[date], '**********')
          this.modify[date] = this.insdateSet[date]
        })
        this.modify['type'] = 3;
      }

      if (this.schedule_date_from && this.schedule_date_to) {
        Object.keys(this.schdateSet).forEach((date) => {
          console.log(this.schdateSet[date], '**********')
          delete this.modify[date];
        })
      }
    } else if (event && isScheduled) {
      this.schedule_date_from = event[0].toISOString()
      this.schedule_date_to = event[1].toISOString()
      if (this.schedule_date_from && this.schedule_date_to) {
        this.schdateSet.schedule_date_from = this.schedule_date_from,
          this.schdateSet.schedule_date_to = this.schedule_date_to
        //this.dateKey = value
      }
      if (this.schedule_date_from && this.schedule_date_to) {
        Object.keys(this.schdateSet).forEach((date) => {
          console.log(this.schdateSet[date], '**********')
          this.modify[date] = this.schdateSet[date];
          this.isResult = true
        })
        this.modify['type'] = 3;
      }
      if (this.inspectionfrom && this.inspectionto) {
        Object.keys(this.insdateSet).forEach((date) => {
          console.log(this.insdateSet[date], '**********')
          delete this.modify[date] ;
          this.isResult = true
        })
      }
    }
  }

  public isScheduled = false;

  checkInspectedOrScheduledFilter(val){
    if (val == 1) {
      this.isScheduled = true;
    } else if (val == 2) {
      this.isScheduled = false;
    }
  }

applyTodayAndTommarowfilter(value:string){
    if(value == 'isRequestedDateToday'){
      this.applicationFilter({ department: 6, isRequestedDateToday:1 })

    }
    else if(value == 'isRequestedDateTomorrow'){
      this.applicationFilter({ department: 6, isRequestedDateTomorrow:1 })

    }
    else if (value == 'isRequestedDateTodayInspected') {
      if (!this.isScheduled) {
        this.applicationFilter({ department: 6, inspected_days: 1, type: 3  })
      } else {
        this.applicationFilter({ department: 6, scheduled_days: 1, type: 3  })
      }
    }
    else if (value == 'isRequestedDateNext7Days') {
      if (!this.isScheduled) {
        this.applicationFilter({ department: 6, inspected_days: 2, type: 3 })
      } else {
        this.applicationFilter({ department: 6, scheduled_days: 2, type: 3 })
      }
    }
    else if (value == 'isRequestedDateNext10Days') {
      if (!this.isScheduled) {
        this.applicationFilter({ department: 6, inspected_days: 3, type: 3 })
      } else {
        this.applicationFilter({ department: 6, scheduled_days: 3, type: 3 })
      }
    }
  }


  seeAllApplication(val) {
    this.inspectorModalOpened = false;
    this.inspectorFilter = false;
    // this.inspectors = [];
    // this.selectInspectors = [];
    // this.selectInspectors.map((data, i) => {
    //   this.selectInspectors.splice(i, 1)
    // })
    if (val == 1) {
      delete this.modify["inspector_is_See_All"];
      this.filter(val);
    } else if (val == 2) {
      this.modify['inspector_is_See_All'] = 1;
      this.filter(val);
    }
  }

  applyInspectorFilter() {
    this.inspectorModalOpened = true;
  }

  goToPaymentsSummary(){
    this.router.navigate(['admin/certificates/paymetsSummary'],  { queryParams: { housing: true, department_id: 6, occupancy_type: 3} })
  }


}




import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { tap, map } from 'rxjs/operators';
import { DataLayerManager } from '@agm/core';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  public assignedInspectionsLatest = new BehaviorSubject(null);
  public obsAssignedInspectionsLatest = this.assignedInspectionsLatest.asObservable();

  constructor(private http: HttpClient) { }

  getCertificate(type): Observable<any> {
    const href = `${environment['certificate']}`;
    return this.http.post<any>(href, type).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  inspection(type): Observable<any> {
    const href = `${environment['inspection']}`;
    return this.http.post<any>(href, type).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  transferReq(type): Observable<any> {
    const href = `${environment['transferRequest']}`;
    return this.http.post<any>(href, type).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  filterCertificate(data): Observable<any> {
    console.log("dashborad")
    const href = `${environment['certificateByLocation']}`;
    return this.http.post<any>(href, data).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  getClerkMail(data): Observable<any> {
    console.log("dashborad")
    const href = `${environment['getClerkMail']}`;
    return this.http.post<any>(href, data).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  assignCertifictesByManger(data): Observable<any> {
    console.log("dashborad")
    const href = `${environment['assignCertifictesByManger']}`;
    return this.http.post<any>(href, data).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  assesorInpection(data): Observable<any> {
    const href = `${environment['assesorInspection']}`;
    return this.http.post<any>(href, data).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  inspectionForTaxes(data): Observable<any> {
    const href = `${environment['inspectionForTaxes']}`;
    return this.http.post<any>(href, data).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  assignedInspector(data): Observable<any> {
    const href = `${environment['assignedInspector']}`;
    return this.http.post<any>(href, data).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  getInspectors(data): Observable<any> {
    const href = `${environment['inspectors']}`;
    return this.http.post<any>(href, data).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  getAssignedInspector(data): Observable<any> {
    const href = `${environment['assignedInspectorByDepartent']}`;
    return this.http.post<any>(href, data).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  deleteFee(data): Observable<any> {
    const href = `${environment['deleteFee']}`;
    return this.http.post<any>(href, data).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  inspectionForZoning(data): Observable<any> {
    const href = `${environment['inspectionForZoning']}`;
    return this.http.post<any>(href, data).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  getInspectionForZoning(departmentId, iTitleSearchID): Observable<any> {
    const href = `${environment['getInscpectionForZoning']}/${departmentId}/${iTitleSearchID}`;
    return this.http.get<any>(href).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }
  //inspector/inspections/8


  getInspectionForHousing(departmentId, iTitleSearchID): Observable<any> {
    const href = `${environment['getInspection']}/${departmentId}/${iTitleSearchID}`;
    return this.http.get<any>(href).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  getInspectionForFire(departmentId, iTitleSearchID): Observable<any> {
    const href = `${environment['getInspectionForFire']}/${departmentId}/${iTitleSearchID}`;
    return this.http.get<any>(href).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }


  getInspectionForTaxes(departmentId, iTitleSearchID): Observable<any> {
    const href = `${environment['getInspectionForTaxes']}/${departmentId}/${iTitleSearchID}`;
    return this.http.get<any>(href).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }


  getAssesorInspection(departmentId, iTitleSearchID): Observable<any> {
    const href = `${environment['getAssesorInspection']}/${departmentId}/${iTitleSearchID}`;
    return this.http.get<any>(href).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  getAllViolation(departmentId, iTitleSearchID): Observable<any> {
    const href = `${environment['getViolation']}/${departmentId}/${iTitleSearchID}`;
    return this.http.get<any>(href).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  getAllViolationForFire(inspectionId): Observable<any> {
    const href = `${environment['getViolationForFire']}/${inspectionId}`;
    return this.http.get<any>(href).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  getCertificateDetail(iTitleSearchID): Observable<any> {
    const href = `${environment['certificatesDetail']}/${iTitleSearchID}`;
    return this.http.get<any>(href).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  getUsersDepartment(departmentId): Observable<any> {
    const href = `${environment['userDepartment']}/${departmentId}`;
    return this.http.get<any>(href).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  externalReviews(applicationId): Observable<any> {
    const href = `${environment['externalReview']}/${applicationId}`;
    return this.http.get<any>(href).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }



  deleteDashboardMedia(query: any): Observable<any> {
    const href = `${environment['dashboardDeleteMedia']}`;
    return this.http.get<any>(href, { params: query }).pipe(tap(
      (data) => {
        if (data.status === 'success') {
        }
        return data;
      }
    ));
  }

  waiveOffpayment(data: any) {
    const href = `${environment['waiveOffpayment']}`;
    return this.http.post<any>(href, data).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  voidtranscation(data: any) {
    const href = `${environment['voidTransaction']}`;
    return this.http.post<any>(href, data).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  postDashboardMedia(media: any): Observable<any> {
    const href = `${environment['dashboardUploadMedia']}`
    const formData = new FormData();
    formData.append(media.name, media.value);
    formData.append('id', media.id);
    formData.append('doc_id', media.doc_id);
    return this.http.post<any>(href, formData).pipe(tap(
      (data) => {
        if (data.status === 'success') {
        }
        return data;
      }
    ));
  }

  updateMedia(media: any): Observable<any> {
    const href = `${environment['dashboardUpdateMedia']}`
    const formData = new FormData();
    if(!media.name){
      media.name = 'applicant_photo_id'
    }
    formData.append(media.name, media.value);
    formData.append('application_id', media.application_id);
    formData.append('doc_id', media.doc_id);
    return this.http.post<any>(href, formData).pipe(tap(
      (data) => {
        if (data.status === 'success') {
        }
        return data;
      }
    ));
  }

  deletecoandchDoc(data: any): Observable<any> {
    const href = `${environment['deleteCOandChMedia']}`
    // const formData = new FormData();
    // formData.append(media.name, media.value);
    // formData.append('id', media.id);
    // formData.append('doc_id', media.doc_id);
    return this.http.post<any>(href, data).pipe(tap(
      (data) => {
        if (data.status === 'success') {
        }
        return data;
      }
    ));
  }

  assignInspector(inspectorId): Observable<any> {
    const href = `${environment['assignInspector']}`;
    return this.http.post<any>(href, inspectorId).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  scheduleInspection(inspectorData: any): Observable<any> {
    const href = `${environment['scheduleInspection']}`;
    return this.http.post<any>(href, inspectorData).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  cancelInspector(cancelInspectorData: any): Observable<any> {
    const href = `${environment['cancelInspector']}`;
    return this.http.post<any>(href, cancelInspectorData).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  updateApplicationStatus(updateRequestData: any): Observable<any> {
    const href = `${environment['updateApplicationStatus']}`;
    return this.http.post<any>(href, updateRequestData).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  searchAddressByStreetIndex(searchQuery: any): Observable<any> {
    const href = `${environment['searchAddressByStreetIndex']}`;
    return this.http.get(href, { params: searchQuery }).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }



  updateApplicantDetail(toUpdateData: any): Observable<any> {
    // debugger
    const href = `${environment['updateApplicantDetail']}`;
    console.log(toUpdateData)
    return this.http.post<any>(href, toUpdateData).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  updateFeeAndPaymets(toUpdateData: any): Observable<any> {
    const href = `${environment['uodateFeeAndPayments']}`;
    return this.http.post<any>(href, toUpdateData).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  addPichUpNotes(toUpdateData: any): Observable<any> {
    const href = `${environment['addPickUp']}`;
    return this.http.post<any>(href, toUpdateData).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  emailAndPicupList(type: any): Observable<any> {
    const href = `${environment['emailAndPicupNotes']}`;
    return this.http.post<any>(href, type).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }


  deleteEmailPickUp(type: any, id): Observable<any> {
    const href = `${environment['deleteEmailPickUp']}/${id}`;
    return this.http.post<any>(href, type).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }




  addNotes(toUpdateData: any): Observable<any> {
    const href = `${environment['addNotes']}`;
    return this.http.post<any>(href, toUpdateData).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  uploadApplicantAttachment(media: any): Observable<any> {
    const href = `${environment['uploadApplicantAttachment']}`;
    const formData = new FormData();
    formData.append(media.name, media.attachment);
    formData.append('application_id', media.application_id);
    return this.http.post<any>(href, formData).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  uploadApplicantDocument(formData): Observable<any> {
    const href = `${environment['uploadApplicantDocument']}`;
    return this.http.post<any>(href, formData).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  deleteApplicantAttachment(toDelete: any): Observable<any> {
    const href = `${environment['deleteApplicantAttachment']}`;
    return this.http.post<any>(href, toDelete).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  addNewInspection(data: any): Observable<any> {
    const href = `${environment['addNewInspection']}`;
    return this.http.post<any>(href, data).pipe(map((returnedData: any) => {
      if (returnedData.status === 'success') {
        return returnedData.response;
      }
    }));
  }
  getOrdinanceData(page: any): Observable<any> {
    const href = `${environment['ordinancePagination']}`
    return this.http.get<any>(href, { params: page }).pipe(
      map(
        ({ status, ...rest }) => {
          if (status === 'success') {
          }
          return rest;
        }
      )
    );
  }

  getunitByInspector(page: any): Observable<any> {
    const href = `${environment['unitbyInspector']}`
    return this.http.get<any>(href, { params: page }).pipe(
      map(
        ({ status, ...rest }) => {
          if (status === 'success') {
          }
          return rest;
        }
      )
    );
  }


  submitNewViolation(toSubmit: any) {
    const href = `${environment['addNewOrdinance']}`;
    return this.http.post<any>(href, toSubmit).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  addViolationForFire(toSubmit: any) {
    const href = `${environment['addViolationForFire']}`;
    return this.http.post<any>(href, toSubmit).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  inspectionForFire(toSubmit: any) {
    const href = `${environment['inspectionForFire']}`;
    return this.http.post<any>(href, toSubmit).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  saveSubmissionReview(toSubmit: any): Observable<any> {
    const href = `${environment['saveSubmissionReview']}`;
    return this.http.post<any>(href, toSubmit).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  savePaymentDetail(toSubmit: any): Observable<any> {
    const href = `${environment['savePaymentDetail']}`;
    return this.http.post<any>(href, toSubmit).pipe(map((data: any) => {
      if (data.status === 'success') {
        console.log(data.response)
        return data.response;
      }
    }));
  }

  sendApplicantEmail(toSubmit: any): Observable<any> {
    const href = `${environment['sendApplicantEmail']}`;
    return this.http.post<any>(href, toSubmit).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  sendMailByClerk(toUpdateData: any): Observable<any> {
    const href = `${environment['sendMailByClerk']}`;
    return this.http.post<any>(href, toUpdateData).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }



  changeStatusInVoid(toUpdateData: any): Observable<any> {
    const href = `${environment['changeStatusInVoida']}`;
    return this.http.post<any>(href, toUpdateData).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  acceptApplication(toUpdateData: any): Observable<any> {
    const href = `${environment['acceptapplication']}`;
    return this.http.post<any>(href, toUpdateData).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  changeInspector(data): Observable<any> {
    console.log("dashborad")
    const href = `${environment['changeInspector']}`;
    return this.http.post<any>(href, data).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  rescheduleInspector(data): Observable<any> {
    console.log("dashborad")
    const href = `${environment['rescheduleInspector']}`;
    return this.http.post<any>(href, data).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }
  addAttecment(data): Observable<any> {
    console.log("dashborad")
    const href = `${environment['addAttechment']}`;
    return this.http.post<any>(href, data).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }
  addApplicationAttecment(data): Observable<any> {
    console.log("dashborad")
    const href = `${environment['addapplicationAttectment']}`;
    return this.http.post<any>(href, data).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  ccoaddApplicationAttecment(data): Observable<any> {
    console.log("dashborad")
    const href = `${environment['ccoAttachment']}`;
    return this.http.post<any>(href, data).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  deleteAttecment(data): Observable<any> {
    console.log("dashborad")
    const href = `${environment['deleteAttecment']}`;
    return this.http.post<any>(href, data).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  deleteCCoAttecment(data): Observable<any> {
    console.log("dashborad")
    const href = `${environment['deleteCCOAttachment']}`;
    return this.http.post<any>(href, data).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  uploadCsvForCityAdmin(data): Observable<any> {
    console.log("dashborad")
    const href = `${environment['uploadCsvCityAddress']}`;
    return this.http.post<any>(href, data).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }


  uploadOrdinance(data): Observable<any> {
    console.log("dashborad")
    const href = `${environment['uploadOrdinance']}`;
    return this.http.post<any>(href, data).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  getapplicationTimelineApplication(applicationId: any): Observable<any> {
    const href = `${environment['applicationTimeLine']}/${applicationId}`
    return this.http.get<any>(href,).pipe(
      map(
        ({ status, ...rest }) => {
          if (status === 'success') {
          }
          return rest;
        }
      )
    );
  }

  activateApplicationByAdmin(payload) {
    const href = `${environment['activateApplicationByAdmin']}`;
    return this.http.post<any>(href, payload).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  getDocumentsList(query) {
    const href = `${environment['getDocumentsList']}`;
    return this.http.get<any>(href, { params: query }).pipe(tap(
      (data) => {
        if (data.status === 'success') {
          return data;
        }
        return data;
      }
    ));
  }
  deleteSchedule(data: any): Observable<any> {
    const href = `${environment['deleteSchedules']}`;
    return this.http.post<any>(href, data).pipe(map((data: any) => {
      if (data.status === 'success') {
        const storage = localStorage;
        storage.setItem('inspectionData', JSON.stringify(data.response));
        return data;
      }
      return data;
    }))
  }

  addRefund(data: any): Observable<any> {
    const href = `${environment['addRefundAmount']}`;
    return this.http.post(href, data).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data;
      }
    }))
  }

  updateRefund(data: any): Observable<any> {
    const href = `${environment['updateRefundAmount']}`;
    return this.http.post(href, data).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data;
      }
    }))
  }

  deleteRefunds(data: any): Observable<any> {
    debugger
    const href = `${environment['deleteRefundAmount']}`;
    return this.http.post(href, data).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data;
      }
    }))
  }
  


}









import { Component, OnInit } from '@angular/core';
import { CertificatesService } from 'src/app/core/http/admin/certificates.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DashboardService } from 'src/app/core/http';
import { AdminAuthenticationService } from 'src/app/core/authentication/admin-authentication.service';

interface Timeline {
  application_id: number,
  application_status: number,
  application_details: object
}
@Component({
  selector: 'app-application-timeline',
  templateUrl: './application-timeline.component.html',
  styleUrls: ['./application-timeline.component.css']
})
export class ApplicationTimelineComponent implements OnInit {

  public applicationId: number;
  public applicationData: Array<Timeline>;
  public department = {
    role_id: null,
    department_id: null,
  }
  constructor(
    private route: ActivatedRoute,
    private router:Router,
    private adminAuthService: AdminAuthenticationService,
    private dashboardService: DashboardService,
    private certificateService: CertificatesService
  ) { }

  ngOnInit() {

    // this.getApplication()
    this.route.queryParams.subscribe(data => {
      this.applicationId = data.applicationId
      console.log(this.applicationId)
    })
    this.getLocalStorage();
    this.getApplication();
  }


  getLocalStorage() {
    const department = this.adminAuthService.getPermission()
    if (department && department.role_id != 1 && department.role_id != 2) {
      this.department = department
    } else {
      const localStorageData = this.adminAuthService.getSaveDepartment()
      const department = this.adminAuthService.getPermission()
      this.department = localStorageData
      this.department.role_id = department.role_id
    }
  }

  public isValue = false
  public applicationDetaillength: number
  getApplication() {
    //this.avatar = `${environment.host}${environment.imageBasePath}${profile}`;
    this.dashboardService.getapplicationTimelineApplication(this.applicationId).subscribe(data => {
      this.applicationData = data.response
      console.log(data)
      // this.applicationDetaillength =   this.applicationData[0].application_details ;
      this.applicationData.map((timeline: any) => {

        
        timeline.applicationdetailLength = Object.keys(timeline.application_details).length
        if(timeline.application_status == 57 || timeline.application_status == 39 ||  timeline.application_status == 58){
          timeline.applicationdetailLength = 0
        }
        Object.keys(data.timeline_application_status_config).map((status, i) => {
          if (timeline.application_status == i) {
            timeline.applicationKey = data.timeline_application_status_config[i]
          }
          // if (timeline.application_status == 17 || timeline.application_status == 20) {
          //   timeline.imagePath = `${environment.host}${environment.imageBasePath}${timeline.application_details.violationDetails.violationImage}`;
          // }
        })
      })
      console.log(this.applicationData);
    })
  }

  isDropDown(){
    this.applicationData
  }

  navigateUrl() {
    
    let department = JSON.parse(localStorage.getItem('department'))
    console.log(department)
    // if (department.department_id == 1) {

    // }els
    switch (department.department_id) {
      case (1):{
        this.router.navigate(['/admin/certificates/housing'])
        break;
      }
      case (2):{
        this.router.navigate(['/admin/certificates/fire'])
        break;
      }
      case (3):{
        this.router.navigate(['/admin/certificates/assesor'])
        break;
      }
      case (4):{
        this.router.navigate(['/admin/certificates/taxes'])
        break;
      }
      case (5):{
        this.router.navigate(['/admin/certificates/zoning'])
        break;
      }
      
      default:{
        this.router.navigate(['/admin/certificates/cco'])

        break;
      }
        
    }
  }

}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DashboardService, StaffService } from 'src/app/core/http';
import { Router } from '@angular/router';
import { AdminAuthenticationService } from 'src/app/core/authentication/admin-authentication.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-ordinance-list',
  templateUrl: './ordinance-list.component.html',
  styleUrls: ['./ordinance-list.component.css']
})
export class OrdinanceListComponent implements OnInit {
  @ViewChild('hideuploadCSV', { static: false }) hideuploadCSV: ElementRef;
  @ViewChild('deleteOrd', { static: false }) deleteOrd: ElementRef;

  public ordinance: any = [];
  public totalPagination: number = Number(0);
  public offset: any = 1;
  public currentPagination: number = Number(1);
  public pagination: {
    pages: any,
    total: number,
  } = {
      pages: 0,
      total: 0,
    };
  constructor(
    private router: Router,
    private dashboardService: DashboardService,
    private staffService: StaffService,
    private adminAuthService: AdminAuthenticationService,
    private _TS: ToastrService
  ) { }

  ngOnInit() {
    this.getOrdinance({ page: this.currentPagination });

  }

  credentialsKeyForAdmin = 'currentAdmin';

  public getOrdinance(params: any) {
    const savedCredentials: any = JSON.parse(localStorage.getItem(this.credentialsKeyForAdmin));
    if (savedCredentials) {
      params.admin_id = savedCredentials.id
    }
    this.dashboardService.getOrdinanceData(params).subscribe(({ response, ...pagination }) => {
      this.ordinance = response.docs;
      this.totalPagination = response.total;
      this.offset = response.offset;
      this.currentPagination = response.currentPage;
    });
  }

  paginate(value: number): void {
    this.getOrdinance({ page: value });
  }

  navigateToAddOrdinance(ordinanceId) {
    this.router.navigate(['/admin/add/ordinance'], { queryParams: { id: ordinanceId } })
  }
  public ordinanceId: number
  getId(id) {
    this.ordinanceId = id;
  }
  deleteOrdinance() {

    this.staffService.deleteOrdinance(this.ordinanceId).subscribe(data => {
      this.getOrdinance({ page: this.currentPagination });
      this.deleteOrd.nativeElement.click();
      this._TS.success('Ordinance deleted')
    })
  }

  public selectCSVFile: File
  public imageName: any
  public imgURL: any
  selectCSV(files) {
    console.log(files);
    if (files && files.length > 0) {
      let file: File = files.item(0);
      this.selectCSVFile = file
      this.imageName = file.name;
      var reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = (_event) => {
        this.imgURL = reader.result;
      }
    }
  }

  hideImage() {
    this.imgURL = null
  }

  //public user: any;
  uploadOrdinance() {
    // this.user = this.adminAuthService.getPermission()
    let formData = new FormData();
    formData.append('ordinance', this.selectCSVFile),
      //   formData.append('id', this.user.id),
      this.dashboardService.uploadOrdinance(formData).subscribe(csv => {
        this.getOrdinance({ page: this.currentPagination });
        this._TS.success('Upload file successfully');
        this.imgURL = null,
          this.hideuploadCSV.nativeElement.click()
      })
  }
}

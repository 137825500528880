import { Component, OnInit ,ElementRef,ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StaffService } from 'src/app/core/http';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { CeComplaintService } from 'src/app/core/http/admin/ce-complaint.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-city',
  templateUrl: './add-city.component.html',
  styleUrls: ['./add-city.component.css']
})
export class AddCityComponent implements OnInit {
  public addnewStaffForm: FormGroup;
  public addType: FormGroup;
  public addSubType: FormGroup;
  public isUpdate = false;
  public isSubmitted = false;
  public staffId: number;
  public isSubmittedType = false;
  public type_List = [];
  public sub_typeList =[];
  public type_id:any;
  public isSubmittedSubType = false;
  public singleAdminData = {
    name: null,
    email: null,
    phone: null,
    status: null,
    city_name:null,
    stripe_account_id:null
  };
  public activeTab = {
    cityAdmin: true,
    type: false,
    subType: false,
    zoningPermit:false,
  };
  
  @ViewChild('typeModal', { static: false }) typeModal: ElementRef;
  @ViewChild('subTypeModal', { static: false }) subTypeModal: ElementRef;
  @ViewChild('selectTypeNme', { static: false }) selectTypeNme: ElementRef;
  constructor(
    private formBuilder: FormBuilder,
    private staffService: StaffService,
    private route: ActivatedRoute,
    private router: Router,
    private _CEService: CeComplaintService,
    private TS: ToastrService


  ) { }

  ngOnInit() {
    this.staffForm();
    this.addSubTypeForm();
    this.addTypeForm();
    this.route.queryParams.subscribe(staff => {
      this.staffId = staff.staffId;
      
      if (this.staffId) {
        this.setCurrentCityAdmin(this.staffId);
        this.getSingleAdmin();
        this.typeList();
        this.subTypeList();
        console.log("if block", this.staffId )

      } else {
        let admin = this.getCurrentCityAdmin().cityAdmin.id;
        if (admin) {
           this.staffId = admin;
           console.log("else block", this.staffId )
           this.getSingleAdmin();
        }
      }
    })
    this.addnewStaffForm.controls.status.setValue(true) 
  }
  setCurrentCityAdmin(cityAdmin) {
    sessionStorage.setItem('cityAdmin', JSON.stringify({ cityAdmin }));
  }

  getCurrentCityAdmin() {
    let data = sessionStorage.getItem('cityAdmin');
    if (data) {
      return JSON.parse(data);
    } else {
      return null;
    }
  }
  showHideTab(tab) {
    console.log("value is ==>",tab)
    debugger
    if ((tab == 'type') && !this.getCurrentCityAdmin()) {
      this.TS.error("Please add city admin first.");
      return false;
    }
    this.activeTab = {
      cityAdmin: false,
      type: false,
      subType: false,
      zoningPermit:false,
    }
    this.activeTab[tab] = true;
  }
  staffForm() {
    this.addnewStaffForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      phone: ['', [Validators.required, Validators.pattern('^[0-9]{3}-[0-9]{3}-[0-9]{4}$')]],
      // department_id: ['', [Validators.required]],
      // role_id: ['', [Validators.required]],
      stripe_account_id: [''],
      city_name: [''],
      status: [''],
    });
  }

  addTypeForm(){
    this.addType = this.formBuilder.group({
      type: ['', Validators.required],
    });
  }

  get addTypeFormValidaters () { return this.addType.controls }


  addSubTypeForm(){
    this.addSubType = this.formBuilder.group({
      typeId: ['', Validators.required],
      subtype: ['', Validators.required],
    });
  }
  get addSubTypeValidaters () { return this.addSubType.controls }

  addTypeNew(){
    this.addType.reset(); 
    this.type_id = null;
  }
  editType(value){
    this.type_id = value.id;
    if(value.id){
      this.addType.controls.type.setValue(value.type_name)
     }
  }
  addtype(){  
   
    if(this.addType.invalid){
      this.isSubmittedType = true;
      return true;
    }
    let payload = {
      type_name:this.addType.controls.type.value,
      city_id: this.staffId,
      type_id:this.type_id
    }
    if(!this.type_id){
     delete  payload.type_id
    }
    this._CEService.addType(payload).subscribe(data => {
      this.type_List =  data;
      this.addType.reset();
      this.isSubmittedType = false;
      this.typeModal.nativeElement.click();
      if(!this.type_id){
        this.TS.success("Type add Successfully")
      }else{
        this.TS.success("Type edit Successfully")
      }
      this.typeList();
      // this.router.navigate(['/admin/manage/city'])
    })

  }
  addNewSubType(){
    this.addSubType.reset();
    this.selectTypeNme.nativeElement.value = "";
    this.typeSub_id = null;
    this.type_idForSubType = null;

  }
  typeList(){
    this._CEService.typeList(this.staffId).subscribe(data => {
      this.type_List = data;
    })
  }
  subTypeList(){
    this._CEService.subTypeList(this.staffId).subscribe(data => {
      this.sub_typeList = data;
    })
  }
  public type_idForSubType:any;
  public typeSub_id:any;
  seleceType(id){
    this.type_idForSubType = id
  }
  addSubtype(){
    // if(!this.typeSub_id){
    //   this.addSubType.reset();
    // }
    if(this.addSubType.invalid){
      this.isSubmittedSubType = true;
      return true;
    }
    let payload = {
      type_name:this.addSubType.controls.subtype.value,
      city_id: this.staffId,
      type_id:this.type_idForSubType,
      sub_type_id:this.typeSub_id
    }
    if(!this.typeSub_id){
      delete payload.sub_type_id
    }
    this._CEService.addSubType(payload).subscribe(data => {
      this.sub_typeList =  data;
      this.addSubType.reset();
      this.isSubmittedSubType = false;
      if(!this.typeSub_id){
        this.TS.success("Sub Type add Successfully")
      }else{
        this.TS.success("Sub Type edit Successfully")
      }
      this.subTypeModal.nativeElement.click();
      this.subTypeList();
    })
  }
  editSubType(value){
    this.typeSub_id = value.id;
    this.type_idForSubType = value.type_id
    if(value.id){
      this.addSubType.controls.typeId.setValue(value.type_id);
      this.addSubType.controls.subtype.setValue(value.type_name)
     }
  }


  getSingleAdmin() {
    this.staffService.getCitySingleAdmin(this.staffId).subscribe(data => {
      this.singleAdminData = data;
      this.isUpdate = true;
      this.addnewStaffForm.controls.name.setValue(this.singleAdminData.name)
      this.addnewStaffForm.controls.email.setValue(this.singleAdminData.email)
      this.addnewStaffForm.controls.phone.setValue(this.singleAdminData.phone)
      this.addnewStaffForm.controls.status.setValue(this.singleAdminData.status)
      this.addnewStaffForm.controls.city_name.setValue(this.singleAdminData.city_name)
      this.addnewStaffForm.controls.stripe_account_id.setValue(this.singleAdminData.stripe_account_id)
    })
  }


  updateCity() {

    this.staffService.updateAdmin(this.addnewStaffForm.value, this.staffId).subscribe(data => {
      // this.addnewStaffForm.reset()
      this.isUpdate = true;
      this.setCurrentCityAdmin(data);
      this.showHideTab('type');
      this.TS.success("City edit successfully")
      if(data.id){
        this.staffId = data.id;
        this.typeList();
        this.subTypeList();
      }
     
      // this.router.navigate(['/admin/manage/city'])
    })
  }
  removeCurrentCityAdmin() {
    sessionStorage.removeItem('cityAdmin');
  }
  blank(){
    this.removeCurrentCityAdmin();
  }

  addNewCityAdmin() {
    this.isSubmitted = true
    if (this.addnewStaffForm.invalid) {
      return false
    }
    if (this.addnewStaffForm.value.status && this.addnewStaffForm.value.status != '') {
      this.addnewStaffForm.value.status = 1
    } else {
      this.addnewStaffForm.value.status = 0

    }
    this.staffService.createCityAdmin(this.addnewStaffForm.value).subscribe(data => {
      // this.addnewStaffForm.reset()
      this.isSubmitted = false,
      this.isUpdate = true;
      this.staffId = data.id
      this.setCurrentCityAdmin(data);
      this.showHideTab('type');
      this.TS.success("City create successfully")
      // this.getSingleAdmin();
        // this.router.navigate(['/admin/manage/city'])

    })
  }

  selectStatus(event) {
    if (event.target.checked) {
      this.addnewStaffForm.value.status = 1
    } else {
      this.addnewStaffForm.value.status = 0

    }
  }

  get staffMember() { return this.addnewStaffForm.controls }

  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 48 || charCode > 57) && charCode != 45) {
      event.preventDefault();

      return false;
    } else {
      this.phoneNumberFormate()
    }
  }

  phoneNumberFormate() {
    var value: string
    var autoFillValue = '-'
      if (this.addnewStaffForm.value.phone.length === 3) {
        this.addnewStaffForm.controls.phone.setValue(this.addnewStaffForm.value.phone.concat(autoFillValue))
      }
      if (this.addnewStaffForm.value.phone.length === 7 ) {
        this.addnewStaffForm.controls.phone.setValue(this.addnewStaffForm.value.phone.concat(autoFillValue))
      }
    }

    deleteType(id,city_id){
      let payload  = {
        type_id:id,
        city_id:city_id
      }
      this._CEService.deleteType(payload).subscribe(data => {
        this.type_List = data;
        this.subTypeList();
        this.TS.success("type delete successfully")
      })
    }

    deleteSubType(id,city_id,type_id){
      let payload  = {
        sub_type_id:id,
        city_id:city_id,
        type_id:type_id
      }
      this._CEService.deleteSubType(payload).subscribe(data => {
        this.sub_typeList = data;
        this.TS.success("Sub type delete successfully")
      })
    }

}

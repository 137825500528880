import { Component, OnInit } from '@angular/core';
import { StaffService } from '../../../../core/http/admin/staff.service';
import { Department } from '../../../../core/models/admin/department.model'
import { roles } from '../../../../core/models/admin/roles.model'
import { AdminAuthenticationService } from 'src/app/core/authentication/admin-authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-manage-department-and-role',
  templateUrl: './manage-department-and-role.component.html',
  styleUrls: ['./manage-department-and-role.component.css']
})
export class ManageDepartmentAndRoleComponent implements OnInit {
  public deparments: Department[];
  public roles: roles[];
  public adminData:any;
  public isTrue = true;
  constructor(    private staffService: StaffService,
    public adminAuthService:AdminAuthenticationService,
    private router: Router,

    ) { }

  ngOnInit() {
    this.allDepartment();
    this.getAdminData();
  }

  allDepartment() {
    this.staffService.getDepartment().subscribe(data => {
      this.deparments = data.response;
    })
  }
  getAdminData() {
    this.adminAuthService.getAdmin().subscribe(data => {
      this.adminData = data;  
      debugger 
      if( this.adminData.role_id == 2 &&  (this.adminData.id == 361 || this.adminData.id == 452)){
        this.isTrue = false;
        return true;
      }
      let city_id =  this.adminData ?  this.adminData.city_details : null;
      city_id = city_id ?  city_id.admin_id : null;
      if( (this.adminData.role_id == 2 || this.adminData.role_id == 3 || this.adminData.role_id == 4 || this.adminData.role_id == 5) &&  (city_id == 361 || city_id == 452)){
        this.isTrue = false;
        return true;
      }
      // if(this.adminData.role_id == 2 &&  (this.adminData.id == 361 || this.adminData.id == 452) ){
      //   this.isTrue = false;
      //   console.log("run")
      //   return true;
      // }  
    })
    
  }
 
  navigate(){
    if( this.adminData.role_id == 2 &&  (this.adminData.id == 361 || this.adminData.id == 452)){
      this.isTrue = true;
      this.router.navigate(['/admin/ce/complaint'])
      return true;
    }
    if( (this.adminData.role_id == 2 || this.adminData.role_id == 3 || this.adminData.role_id == 4 || this.adminData.role_id == 5) &&  this.adminData.city_details.admin_id == 361){
      this.isTrue = true;
      this.router.navigate(['/admin/ce/complaint']);
      return true;
    }
    if(this.adminData.role_id != 2 &&  this.adminData.id != 361){
      this.isTrue = false;
      this.router.navigate(['/admin/certificates/housing']);
      return true;
    }
  }

}

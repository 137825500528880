import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { tap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CeComplaintService {
  public downloadFile: string;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  getComplaint(page): Observable<any> {
    const url = `${environment['getComplaint']}`;
    return this.http.get<any>(url, { params: page }).pipe(
      map(
        ({ status, ...rest }) => {
          if (status === 'success') {
          }
          return rest;
        }
      )
    );
  }
  getComplaintFile(data: any): void {
    let params = new HttpParams();
    console.log("data format is ===>",this.downloadFile)

    params = params.set('page', data.type);
    this.downloadFile = `${environment.host}` + `${environment['certificatesFiles']}` + `${data.format}` + params.toString();

console.log("data format is ===>",this.downloadFile)
    return;
    window.open(this.downloadFile, '_blank');
  }
  postSelectedData(payload): Observable<any> {
    const href = environment['sendMail'];
    return this.http.post<any>(href, payload).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  sendMailNotices(payload): Observable<any> {
    const href = environment['sendMailNotices'];
    return this.http.post<any>(href, payload).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }
  getWorkOrders(page): Observable<any> {
    const url = `${environment['getWorkOrders']}`;
    return this.http.get<any>(url, { params: page }).pipe(
      map(
        ({ status, ...rest }) => {
          if (status === 'success') {
          }
          return rest;
        }
      )
    );
  }

  getAllComplaintDetails(id): Observable<any> {
    const url = `${environment['complaintDetails']}/${id}`;
    return this.http.get<any>(url).pipe(
      map(
        ({ status, ...rest }) => {
          if (status === 'success') {
          }
          return rest;
        }
      )
    );
  }

  

  getRoleByIDstaff(id): Observable<any> {
    const url = `${environment['getRoleByIDstaff']}/${id}`;
    return this.http.get<any>(url).pipe(
      map(
        ({ status, ...rest }) => {
          if (status === 'success') {
          }
          return rest;
        }
      )
    );
  }

  getAllWorkOrderDetails(data): Observable<any> {
    const url = `${environment['workOrderDetails']}`;
    return this.http.get<any>(url, { params: data }).pipe(
      map(
        ({ status, ...rest }) => {
          if (status === 'success') {
          }
          return rest;
        }
      )
    );
  }


  assignInspector(toUpdateData: any): Observable<any> {
    const href = `${environment['assignInspector']}`;
    return this.http.post<any>(href, toUpdateData).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  assignWorderInspector(toUpdateData: any): Observable<any> {
    const href = `${environment['assignWorderInspector']}`;
    return this.http.post<any>(href, toUpdateData).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  updateLocationadmince(toUpdateData: any): Observable<any> {
    const href = `${environment['updatelocationforCE']}`;
    return this.http.post<any>(href, toUpdateData).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  workassignInspector(toUpdateData: any): Observable<any> {
    const href = `${environment['workassignInspector']}`;
    return this.http.post<any>(href, toUpdateData).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  addWorkOrder(toUpdateData: any): Observable<any> {
    const href = `${environment['addWorkOrder']}`;
    return this.http.post<any>(href, toUpdateData).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  getSatff(): Observable<any> {
    const href = `${environment['allStff']}`
    return this.http.get<any>(href).pipe(
      map(
        ({ status, ...rest }) => {
          if (status === 'success') {
          }
          return rest;
        }
      )
    );
  }

  searchComplaint(toUpdateData: any): Observable<any> {
    const href = `${environment['searchComplaint']}`;
    return this.http.get<any>(href, { params: toUpdateData }).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data;
      }
    }));
  }

  addCourtTiket(toUpdateData: any): Observable<any> {
    const href = `${environment['addCourtTicket']}`;
    return this.http.post<any>(href, toUpdateData).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  addNotesTiket(toUpdateData: any): Observable<any> {
    const href = `${environment['addNotesTicket']}`;
    return this.http.post<any>(href, toUpdateData).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  getCourtTiket(page): Observable<any> {
    const href = `${environment['getCourtTicket']}`;
    return this.http.get<any>(href,{ params: page }).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data;
      }
    }));

  }
  getNotes(page): Observable<any> {
    const href = `${environment['getNotes']}`;
    return this.http.get<any>(href,{ params: page }).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data;
      }
    }));

  }
  summaryIndex(page): Observable<any> {
    const href = `${environment['summaryIndex']}`;
    return this.http.get<any>(href,{ params: page }).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data;
      }
    }));

  }
  updateCourtTicket(toUpdateData: any): Observable<any> {
    const href = `${environment['updateCourtTicket']}`;
    return this.http.post<any>(href, toUpdateData).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  updateNoteTicket(toUpdateData: any): Observable<any> {
    const href = `${environment['updateNoteTicket']}`;
    return this.http.post<any>(href, toUpdateData).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  voidCourtTiket(toUpdateData: any): Observable<any> {
    const href = `${environment['voidCourtTicket']}`;
    return this.http.post<any>(href, toUpdateData).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  voidNoteTiket(toUpdateData: any): Observable<any> {
    const href = `${environment['voidNoteTiket']}`;
    return this.http.post<any>(href, toUpdateData).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }
  voidCourtIndex(toUpdateData: any): Observable<any> {
    const href = `${environment['voidCourtIndex']}`;
    return this.http.post<any>(href, toUpdateData).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  voidNoticeIndex(toUpdateData: any): Observable<any> {
    const href = `${environment['voidNoticeIndex']}`;
    return this.http.post<any>(href, toUpdateData).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  uploadImage(toUpdateData: any): Observable<any> {
    const href = `${environment['uploadImage']}`;
    return this.http.post<any>(href, toUpdateData).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }
  uploadImageWorkorder(toUpdateData: any): Observable<any> {
    const href = `${environment['uploadImageWorkorder']}`;
    return this.http.post<any>(href, toUpdateData).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  deleteImage(toUpdateData: any): Observable<any> {
    const href = `${environment['deleteImage']}`;
    return this.http.post<any>(href, toUpdateData).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }
  deleteImageWorkOrder(toUpdateData: any): Observable<any> {
    const href = `${environment['deleteImageWorkOrder']}`;
    return this.http.post<any>(href, toUpdateData).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  deleteVoliations(toUpdateData: any): Observable<any> {
    const href = `${environment['deleteVoliations']}`;
    return this.http.post<any>(href, toUpdateData).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  inspectionForCe(toUpdateData: any): Observable<any> {
    const href = `${environment['inspectionForCE']}`;
    return this.http.post<any>(href, toUpdateData).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  inspectionForWorkOrder(toUpdateData: any): Observable<any> {
    const href = `${environment['workOrderInspection']}`;
    return this.http.post<any>(href, toUpdateData).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  addType(addType: any): Observable<any> {
    const href = `${environment['addType']}`;
    return this.http.post<any>(href, addType).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  typeList(City_id: any): Observable<any> {
    const href = `${environment['typeList']}/${City_id}`;
    return this.http.get<any>(href).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  addSubType(addSubType: any): Observable<any> {
    const href = `${environment['addSubType']}`;
    return this.http.post<any>(href, addSubType).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  subTypeList(City_id: any): Observable<any> {
    const href = `${environment['subTypeList']}/${City_id}`;
    return this.http.get<any>(href).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  deleteType(deleteType: any): Observable<any> {
    const href = `${environment['deleteType']}`;
    return this.http.post<any>(href, deleteType).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }
  deleteSubType(deleteSubType: any): Observable<any> {
    const href = `${environment['deleteSubType']}`;
    return this.http.post<any>(href, deleteSubType).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

}

import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

/**
 * Prefixes all requests with `environment.host`.
 */

@Injectable()
export class ApiPrefixInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url == 'http://api.ipify.org/?format=json') {
      request = request.clone({ url: request.url });
      return next.handle(request);
    }
    else {
      request = request.clone({ url: environment.host + request.url });
      return next.handle(request);
    }

  }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StaffService } from '../../../../../core/http/admin/staff.service';
import { Department } from '../../../../../core/models/admin/department.model';
import { roles } from '../../../../../core/models/admin/roles.model';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminAuthenticationService } from 'src/app/core/authentication/admin-authentication.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-addnew-staff-member',
  templateUrl: './addnew-staff-member.component.html',
  styleUrls: ['./addnew-staff-member.component.css']
})
export class AddnewStaffMemberComponent implements OnInit {
  public addnewStaffForm: FormGroup;
  public departmets: Department[];
  public roles: roles[];
  public isSubmitted = false;
  public isToggelButton: boolean = true;
  public isUptdate: boolean = false;
  public sigleStaffData: any;
  public staffId: number;
  public type: number = 1
  public allAdmin = []
  public department = {
    role_id: null,
  }

  dropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: 'id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'Deselect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };

  constructor(
    private formBuilder: FormBuilder,
    private staffService: StaffService,
    private router: Router,
    private route: ActivatedRoute,
    private adminAuthService: AdminAuthenticationService,
    private _TS: ToastrService

  ) { }

  ngOnInit() {
    this.staffForm();
    this.getLocalStorage();
    this.allDepartment();
    //this.getAllRole();
    this.route.queryParams.subscribe(staff => {
      this.staffId = staff.staffId;
    })
    if (this.staffId) {
      this.isUptdate = true;
      this.getSingleStaff();
    }
    if (this.department.role_id == 1) {
      this.allCityAdmin({ type: this.type })
    }

  }

  staffForm() {
    this.addnewStaffForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      phone: ['', [Validators.required, Validators.minLength(6)]],
      department_id: ['', [Validators.required]],
      role_id: ['', [Validators.required]],
      status: [''],
      admin_id: ['', Validators.required]
    });
  }


  getLocalStorage() {
    this.department = this.adminAuthService.getPermission()
  }

  allCityAdmin(params: object): void {
    this.staffService.getCityAdmin(params).subscribe((data) => {
      this.allAdmin = data.response.docs;
      this.allAdmin = this.allAdmin.map((data => {
        return { id: data.id, name: data.name }
      }))
      // this.offset = data.response.offset;
      // this.pagination.total = data.response.total;
      // this.pagination.pages = Array(data.response.pages).fill(0).map((x, i) => i + 1);
      // this.currentPagination = Number(data.response.currentPage);

    })
  }



  get staffMember() { return this.addnewStaffForm.controls }

  public admin_id: number
  onItemSelect(event) {

    this.admin_id = event.id;
    this.addnewStaffForm.value.admin_id = event.id
  }

  addStaffMember() {
    this.isSubmitted = true
    if (this.department.role_id != 1) {
      this.addnewStaffForm.controls.admin_id.setErrors(null);
    } else {
      this.addnewStaffForm.value.admin_id = this.admin_id
    }
    if (this.addnewStaffForm.invalid) {
      return false
    }
    if (this.addnewStaffForm.value.status == '') {
      this.addnewStaffForm.value.status = 0
    }
    if (this.isToggelButton) {
      this.addnewStaffForm.value.status = 1
    }

    this.staffService.createStaff(this.addnewStaffForm.value).subscribe(data => {
      this.addnewStaffForm.reset();
      this.router.navigate(['/admin/manage/staff'])
      this.isSubmitted = false;
    })

  }

  getSingleStaff() {
    
    this.staffService.getStaffById(this.staffId).subscribe(data => {
      this.sigleStaffData = data.response;
      this.getAllRole(this.sigleStaffData.department_id)
      this.addnewStaffForm.controls['name'].setValue(this.sigleStaffData.name);
      this.addnewStaffForm.controls['email'].setValue(this.sigleStaffData.email);
      this.addnewStaffForm.controls['phone'].setValue(this.sigleStaffData.phone);
      this.addnewStaffForm.controls['department_id'].setValue(this.sigleStaffData.department_id);
      this.addnewStaffForm.controls['role_id'].setValue(this.sigleStaffData.role_id);
      if (this.department.role_id != 1) {
        this.addnewStaffForm.controls['admin_id'].setValue(this.sigleStaffData.admin_id);

      }
      if (this.sigleStaffData.status) {
        this.addnewStaffForm.controls['status'].setValue(this.sigleStaffData.status);
        this.isToggelButton = true;
      } else {
        this.addnewStaffForm.controls['status'].setValue(this.sigleStaffData.status);
        this.isToggelButton = false;
      }
    })
  }

  updateStaff() {
    
    this.isSubmitted = true
    if (this.department.role_id != 1) {
      this.addnewStaffForm.controls.admin_id.setErrors(null);
    }
    if (this.addnewStaffForm.invalid) {
      return false
    }
    if (this.addnewStaffForm.value.status == '') {
      this.addnewStaffForm.value.status = 0
    }
    this.staffService.updateStaff(this.addnewStaffForm.value, this.staffId).subscribe(data => {
      this._TS.success('staff edited successfully');
      this.router.navigate(['/admin/manage/staff'])
      
     })
  }


  allDepartment() {
    this.staffService.getDepartment().subscribe(data => {
      this.departmets = data.response;
    })
  }

  getAllRole(value: any) {
    const department = {
      department_id: value
    }
    this.staffService.getRoll(department).subscribe(data => {
      this.roles = data.response
      console.log(this.roles)
   this.roles =    this.roles.filter((role) => {
        if (value == 1) {
          return role
        } else {
          if (role.id != 3) {
            return role
          }
        }
      })
      this.roles = this.roles.filter((role: any) => {
        if (role.status === "1") {
          return role
        }
      })

    })
  }

  selectStatus(value: any) {
    if (value.target.checked) {
      this.addnewStaffForm.value.status = 1
    } else {
      this.addnewStaffForm.value.status = 0
    }
  }

  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 48 || charCode > 57) && charCode != 45) {
      event.preventDefault();

      return false;
    } else {
      this.phoneNumberFormate()
    }
  }

  phoneNumberFormate() {
    var value: string
    var autoFillValue = '-'
      if (this.addnewStaffForm.value.phone.length === 3) {
        this.addnewStaffForm.controls.phone.setValue(this.addnewStaffForm.value.phone.concat(autoFillValue))
      }
      if (this.addnewStaffForm.value.phone.length === 7 ) {
        this.addnewStaffForm.controls.phone.setValue(this.addnewStaffForm.value.phone.concat(autoFillValue))
      }
    }
}
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { tap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AddComplaintService {
  public sessionCompalint = 'complaint';
  public env = environment;

  constructor(private http: HttpClient,

  ) { }



  public getIPAddress() {
    return this.http.get("/ip/address");
  }

  addComlaint(data): Observable<any> {
    
    const url = `${environment['addComplaint']}`;
    const applicationID = this.getApplicationID();
    if (applicationID) {
      data['id'] = applicationID
    }
    return this.http.post<any>(url, data).pipe(
      tap(
        (data) => {
          if (data.status === 'success' && Object.keys(data.response).length > 0) {
            this.setComlaint(data.response)
          }
          return data;
        }
      )
    );
  }


  uloadImage(data): Observable<any> {
    
    const url = `${environment['uploadImageForComp']}`;
    return this.http.post<any>(url, data).pipe(
      tap(
        (data) => {
          if (data.status === 'success' && Object.keys(data.response).length > 0) {

          }
          return data;
        }
      )
    );
  }

  deleteImage(data): Observable<any> {
    
    const url = `${environment['deleteImageForComp']}`;
    return this.http.post<any>(url, data).pipe(
      tap(
        (data) => {
          if (data.status === 'success' && Object.keys(data.response).length > 0) {

          }
          return data;
        }
      )
    );
  }


  setComlaint(complaint: any) {
    sessionStorage.setItem(this.sessionCompalint, JSON.stringify(complaint));
  }


  getApplicationID(): any {
    const complaint = this.getApplication()
    if (complaint) {
      return complaint['id']
    }
    return null;
  }


  private getApplication() {
    const session = sessionStorage.getItem(this.sessionCompalint);

    if (session) {
      return JSON.parse(session);
    }
    return false;
  }


  getSearchAddress(query: any) {
    const href = `${environment['searchaddress']}`;
    return this.http.get<any>(href, { params: query }).pipe(
      tap(
        (data) => {
          if (data.status === 'success' && Object.keys(data.response).length > 0) {
            data.response.map((res: any) => {
              if (res.property_location) {
                res.singleAddress = res.property_location
                res.address = `${res.property_location}`
              }
              if (res.street) {
                res.singleStreet = res.street
                res.address = `${res.street}`
              }
            });
          }
          return data;
        }
      )
    );
  }

  getImage(applicationId: any): Observable<any> {
    const href = `${environment['getImage']}`
    return this.http.get<any>(href,{params:applicationId}).pipe(
      map(
        ({ status, ...rest }) => {
          if (status === 'success') {
          }
          return rest;
        }
      )
    );
  }

  getCity(): Observable<any> {
    const href = `${environment['getCity']}`
    return this.http.get<any>(href).pipe(
      map(
        ({ status, ...rest }) => {
          if (status === 'success') {
          }
          return rest;
        }
      )
    );
  }


  getSatff(): Observable<any> {
    const href = `${environment['allStff']}`
    return this.http.get<any>(href).pipe(
      map(
        ({ status, ...rest }) => {
          if (status === 'success') {
          }
          return rest;
        }
      )
    );
  }

  getIp(): Observable<any> {
    const href = `${environment['ip']}`
    return this.http.get<any>(href).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  
  typeList(City_id: any): Observable<any> {
    const href = `${environment['typeListUser']}/${City_id}`;
    return this.http.get<any>(href).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }

  subTypeList(City_id: any): Observable<any> {
    const href = `${environment['subTypeListUser']}/${City_id}`;
    return this.http.get<any>(href).pipe(map((data: any) => {
      if (data.status === 'success') {
        return data.response;
      }
    }));
  }
 


}

import { AuthenticationService } from './../../../authentication/authentication.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { AdminAuthenticationService } from 'src/app/core/authentication/admin-authentication.service';
import { ApplicationService, DashboardService } from 'src/app/core/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  public userEmail;
  public roleId: number;
  public UserId: any;
  public localstprageData:any;
  public activateHousing:any;
  public activateFire:any;
  public adminData:any;
  public isTrue = true;
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private adminAuthService: AdminAuthenticationService,
    private applicationService: ApplicationService,
    private dashboardService: DashboardService,
    private _TS: ToastrService
  ) { }

  ngOnInit() {
    this.getAdminData();
    this.getRole();
    this.authenticationService.getAdminInfoAndUserInfo().subscribe(user => {
      this.UserId = user.id
      this.userEmail = (user.email && user.name) ? user.name : user.email;
    });
  }
  logoutUser() {
    this.authenticationService.logout().subscribe(res => {
      if (res) {
        this.applicationService.apllicationStatus(1)
        this.router.navigate(['/']);
      }
    });
  }

  navigateByUrl(url: string) {
    localStorage.removeItem('wardTab')
    this.router.navigateByUrl(url)
  
  }
  getAdminData() {
    debugger
    this.adminAuthService.getAdmin().subscribe(data => {
      this.adminData = data;
      console.log("roleId",this.adminData)
      if( this.adminData.role_id == 2 &&  (this.adminData.id == 361 || this.adminData.id == 452)){
        this.isTrue = false;
        return true;
      }
      let city_id =  this.adminData ?  this.adminData.city_details : null;
      city_id =  city_id ?  city_id.admin_id : null;
      if( (this.adminData.role_id == 2 || this.adminData.role_id == 3 || this.adminData.role_id == 4 || this.adminData.role_id == 5) &&  (city_id == 361 || city_id == 452)){
        this.isTrue = false;
        return true;
      }
    })
  }

  getRole() {
    this.adminAuthService.getUserRole().subscribe(data => {
      this.roleId = data;
      console.log("roleId",this.roleId)
    })
  }



  navigateByUrlTab(url: string, type: number): void {
    if (type === 1) {
      this.router.navigate([url],{queryParams:{tabType:1}});
      localStorage.setItem('department', JSON.stringify({ department_id: 1, department_name: 'housing' }));

    }
    if (type === 2) {
      localStorage.setItem('department', JSON.stringify({ department_id: 2, department_name: 'fire' }));
      this.router.navigateByUrl(url);
    }
    if (type === 3) {
      localStorage.setItem('department', JSON.stringify({ department_id: 3, department_name: 'assessor' }));
      this.router.navigateByUrl(url);
    }

    if (type === 6) {
      localStorage.setItem('department', JSON.stringify({ department_id: 6, department_name: 'cco' }));
      this.router.navigateByUrl(url);
    }


  }
  navigateByCE(url) {
    this.router.navigateByUrl(url);
  }

}

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { StaffService } from '../../../../core/http/admin/index';
import { staff } from '../../../../core/models/admin/index'
import { Router } from '@angular/router';
import { DEC } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ThrowStmt } from '@angular/compiler';
import { ToastrService } from 'ngx-toastr';
import { AdminAuthenticationService } from 'src/app/core/authentication/admin-authentication.service';
//import {  BsModalRef } from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-manage-staff',
  templateUrl: './manage-staff.component.html',
  styleUrls: ['./manage-staff.component.css']
})
export class ManageStaffComponent implements OnInit {
  @ViewChild('exampleModals', { static: false }) exampleModals: ElementRef;
  @ViewChild('inviteSend', { static: false }) inviteSend: ElementRef;

  public staffList: staff[];
  setPasswordForm: FormGroup;

  public isHide = false
  public isHidePasswordModel = false;
  submitted = false;
  public offset: any = 10;
  public type: any = 1;
  public currentPagination: number = Number(1);
  public pagination: {
    pages: any,
    total: number,
  } = {
      pages: 0,
      total: 0,
    };
    public adminData:any;
    public isTrue = true;
  constructor(
    private staffSerivce: StaffService,
    private router: Router,
    private _TS:ToastrService,
    public adminAuthService:AdminAuthenticationService,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.getAdminData();
    this.allStaffList({ page: this.type });
    this.passwordForm();
    
  }
 

  allStaffList(params: object): void {
    this.staffSerivce.getStaffList(params).subscribe((data) => {
      this.staffList = data.response;
      // this.offset = pagination.offset;
      this.pagination.total = data.total;
      this.pagination.pages = Array(data.pages).fill(0).map((x, i) => i + 1);
      this.currentPagination = Number(data.page);

    })
  }
  getAdminData() {
    this.adminAuthService.getAdmin().subscribe(data => {
      this.adminData = data;   
      if( this.adminData.role_id == 2 &&  (this.adminData.id == 361 || this.adminData.id == 452)){
        this.isTrue = false;
        return true;
      }
      let city_id =  this.adminData ?  this.adminData.city_details : null;
      city_id =  city_id ?  city_id.admin_id : null;
      if( (this.adminData.role_id == 2 || this.adminData.role_id == 3 || this.adminData.role_id == 4 || this.adminData.role_id == 5) &&  (city_id == 361 || city_id == 452)){
        this.isTrue = false;
        return true;
      }
      if(this.adminData.role_id != 2 &&  this.adminData.id != 361){
        this.isTrue = true;
        return true;
      }  
    })
    
  }
 
  navigate(){
    if( this.adminData.role_id == 2 &&  (this.adminData.id == 361 || this.adminData.id == 452)){
      this.isTrue = true;
      this.router.navigate(['/admin/ce/complaint'])
      return true;
    }
    if( (this.adminData.role_id == 2 || this.adminData.role_id == 3 || this.adminData.role_id == 4 || this.adminData.role_id == 5) &&  this.adminData.city_details.admin_id == 361){
      this.isTrue = true;
      this.router.navigate(['/admin/ce/complaint']);
      return true;
    }
    if(this.adminData.role_id != 2 &&  this.adminData.id != 361){
      this.isTrue = false;
      this.router.navigate(['/admin/certificates/housing']);
      return true;
    }
  }

  paginate(value: number): void {
    this.allStaffList({ page: value });
  }

  getSingleStaff(staffId) {
    this.router.navigate(['/admin/addnew/staff'], { queryParams: { staffId: staffId } })
  }
  staffId: number
  getStaffId(staffId: number) {
    this.staffId = staffId
  }

  deleteStaff() {
    this.staffSerivce.deleteStaff(this.staffId).subscribe(data => { })
    this.allStaffList({ page: this.type });
    this.isHide = true
  }

  get f() { return this.setPasswordForm.controls; }

  passwordForm() {
    this.setPasswordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      cnfPassword: ['', Validators.required]
    }, {
    });
  }
  staffIdForSetPass: number
  findDepartmentId(value: any) {
    this.staffIdForSetPass = value
  }


  setPassword() {
    this.submitted = true;
    if (this.setPasswordForm.invalid) {
      return false
    }
    this.setPasswordForm.value.id = this.staffIdForSetPass;
    this.staffSerivce.setPassword(this.setPasswordForm.value).subscribe(data => {
      this._TS.success('password changed successfully')
      this.submitted = false;
      this.setPasswordForm.reset();
      this.hideModel()
    })

  }
  hideModel() {
    this.exampleModals.nativeElement.click();
  }

  

  passwordMatch(value: string) {
    if (value == 'conpass') {
      return this.setPasswordForm.controls.password.value === this.setPasswordForm.controls.cnfPassword.value
        ? false : this.setPasswordForm.get('cnfPassword').setErrors({ 'incorrect': true });
    } else if (value == 'pass' && this.setPasswordForm.controls.password.value === this.setPasswordForm.controls.cnfPassword.value) {
      this.setPasswordForm.get('cnfPassword').setErrors(null);
    } else if (value == 'pass' && this.setPasswordForm.controls.password.value != this.setPasswordForm.controls.cnfPassword.value) {
      this.setPasswordForm.get('cnfPassword').setErrors({ 'incorrect': true });
    }
  }

  createPassword(){
    
  }

  sendInvitation() {
    this.staffSerivce.inviteSendForCityAndStaff({ id: this.staffIdForSetPass }).subscribe(data => {
      // this._TS.success('Invite send on your mail');
      this.inviteSend.nativeElement.click()
    })
  }

}
